import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { catalogReviewsAction } from "../../../redux/Actions/CatalogActions";

import { Ratings } from "./../../common/ratings/Ratings";
import LoadingError from "../../common/errorMessage/LoadingError";

import Slider from "react-slick";
import "./Reviews.scss";

import reviewSvg from "../../../assets/images/homePageImg/OnlineReview.svg";
import ChakraUiSpinner from "../../common/loader/ChakraUiSpinner";
import { Avatar, WrapItem } from "@chakra-ui/react";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

import { reviewsSettings } from "../../../constants/reactSlickSettingsData";
import { isVideo } from "../../../helper/isVideo";

export const Reviews = () => {
  const dispatch = useDispatch();

  const catalogReviews = useSelector((state) => state.catalogReviews);
  const { reviews, loading, error } = catalogReviews;

  useEffect(() => {
    dispatch(catalogReviewsAction());
  }, [dispatch]);

  return (
    <section
      className="reviews d-flex justify-content-center mt-5"
      id="reviews"
      tabIndex={0}
    >
      {loading ? (
        <ChakraUiSpinner />
      ) : error ? (
        <LoadingError variant="alert-danger">{error}</LoadingError>
      ) : (
        <div className="container">
          <h2>Отзывы</h2>
          <div className="reviews__block">
            <Slider {...reviewsSettings}>
              {reviews?.length ? (
                reviews
                  ?.map((el, index) => (
                    <div key={index} className="testimonial-container">
                      <div className="fas fa-quote-right fa-quote">
                        <FaQuoteRight />
                      </div>
                      <div className="fas fa-quote-left fa-quote">
                        <FaQuoteLeft />
                      </div>
                      <p className="testimonial" id="style__scroll_reviews">
                        {el.comment}
                      </p>
                      {el.file &&
                        (isVideo(el.file) ? (
                          <figure>
                            <video
                              src={el.file}
                              style={{
                                width: "100%",
                                maxHeight: "300px",
                                objectFit: "cover",
                              }}
                              controls
                              className="mb-1"
                            >
                              Ваш браузер не поддерживает воспроизведение видео.
                            </video>
                          </figure>
                        ) : (
                          <figure>
                            <img
                              src={el.file}
                              alt="Картинка товара"
                              className="mb-1"
                              style={{
                                width: "100%",
                                maxHeight: "300px",
                                objectFit: "cover",
                              }}
                            />
                          </figure>
                        ))}
                      <div className="user">
                        <WrapItem>
                          <Avatar
                            size="xl"
                            name={el.name}
                            src={el.profile ? el.profile : el.name}
                            className="user-image"
                          />
                        </WrapItem>
                        <div className="user-details">
                          <h4 className="username">{el.name}</h4>
                          <div className="role">
                            <Ratings value={el.ratings} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                  .reverse()
              ) : (
                <div className="empty-reviews">
                  <img src={reviewSvg} alt="Отзывы" />
                </div>
              )}
            </Slider>
          </div>
        </div>
      )}
    </section>
  );
};
