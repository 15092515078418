import { CheckboxBtn } from '../../../assets/svg/Svg';
import './CustomRadioCheckbox.scss';

export const CustomRaidioCheckbox = (props) => {
  return (
    <>
      <label className="custom-radio-checkbox" htmlFor={props.id}>
        <input {...props} id={props.id} disabled={props.disabled}/>
        <CheckboxBtn />
      </label>
    </>
  );
};
