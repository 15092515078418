import { ContuctComandData } from "../../../constants/requisitesData";

import "./ContuctComand.scss";

export const ContuctComand = () => {
  return (
    <div className="container">
      <h4>Контакты команды</h4>
      <div className="d-flex flex-column flex-md-row gap-4">
        {ContuctComandData.map((el) => (
          <div key={el.id} className="text-center text-md-start">
            <p className="d-flex gap-3 align-items-center mb-3">
              <img src={el.img} alt={el.title} />
              {el.title}
            </p>
            <p>{el.phone}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
