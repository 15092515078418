import React, { Suspense, lazy } from "react";

import ProfileProductionProvider from "../../../context/ProfileProductionProvider";
import { Loader } from "../../common/loader/Loader";
import OrderedList from "../orderedList/OrderedList";
import ProfileAddProductToCatalog from "../profileAddProductToCatalog/ProfileAddProductToCatalog";
import ProfileAddProduction from "../profileAddProduction/ProfileAddProduction";
import { ProfileForm } from "../profileForm/ProfileForm";

const ExchangeRate = lazy(() => import("../showExchangeRate/ExchangeRate"));

const ProfileTabContent = ({ userInfo }) => {
  return (
    <div
      className="tab-content col-lg-9 pb-5 pt-lg-0 pt-3 "
      id="v-pills-tabContent"
    >
      <div
        className="tab-pane fade show active"
        id="v-pills-home"
        role="tabpanel"
        aria-labelledby="v-pills-home-tab"
      >
        <OrderedList />
      </div>
      <div
        className="tab-pane fade"
        id="v-pills-profile"
        role="tabpanel"
        aria-labelledby="v-pills-profile-tab"
      >
        <ProfileForm />
      </div>
      <div
        className="tab-pane fade"
        id="v-pills-currency"
        role="tabpanel"
        aria-labelledby="v-pills-currency-tab"
      >
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ExchangeRate />
        </Suspense>
      </div>
      {userInfo?.role === "seller" ? (
        <div
          className="tab-pane fade"
          id="v-pills-role"
          role="tabpanel"
          aria-labelledby="v-pills-role-tab"
        >
          <ProfileAddProductToCatalog />
        </div>
      ) : null}
      {userInfo?.role === "seller" ? (
        <div
          className="tab-pane fade"
          id="v-pills-my-production"
          role="tabpanel"
          aria-labelledby="v-pills-my-production-tab"
        >
          <ProfileProductionProvider>
            <ProfileAddProduction userInfo={userInfo} />
          </ProfileProductionProvider>
        </div>
      ) : null}
    </div>
  );
};

export default ProfileTabContent;
