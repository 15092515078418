import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";

const PaymentTimelineElement = ({ instruction }) => {
  return (
    <VerticalTimelineElement
      contentStyle={{
        background: "#007382",
        color: "#fff",
      }}
      contentArrowStyle={{ borderRight: "7px solid  #232631" }}
      iconStyle={{
        background: instruction.iconBg,
        overflow: "hidden",
      }}
      icon={
        <div style={{ height: "100%" }}>
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            src={instruction.icon}
            alt={instruction.company_name}
          />
        </div>
      }
    >
      <h6 className="p-4">
        <span>{instruction.title}.</span> {instruction.instruction}
      </h6>
      {instruction.mbankImg ? (
        <img src={instruction.mbankImg} alt={instruction.company_name} />
      ) : (
        ""
      )}
    </VerticalTimelineElement>
  );
};

export default PaymentTimelineElement;
