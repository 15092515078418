import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadCatalogImagesAction } from "../../../../../redux/Actions/ProfieActions";

import { Skeleton, Stack, useToast } from "@chakra-ui/react";

import { FaCloudUploadAlt } from "react-icons/fa";
import { MdOutlineDeleteForever } from "react-icons/md";

import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

const ProductionBannersAds = ({ setImages, images, text, fieldType }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const uniqueIdentifierRef = useRef(null);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        uniqueIdentifierRef.current = uuidv4();
        if (images.length >= 3) {
          toast({
            title: "Максимальное количество изображений - 3",
            status: "info",
            isClosable: true,
            position: "top-right",
            duration: 1000,
          });
          return;
        }

        const uploadedImages = await Promise.all(
          acceptedFiles.map(async (file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            return new Promise((resolve) => {
              reader.onload = () => {
                resolve(reader.result);
              };
            });
          })
        );

        const uploadResult = await dispatch(
          uploadCatalogImagesAction({
            images: uploadedImages,
            id: uniqueIdentifierRef.current,
          })
        );

        if (uploadResult.success) {
          const newImageObjects = uploadResult.responseArr.map((img) => ({
            img,
            textHeader: "",
            textDescription: "",
          }));

          setImages((prev) => [...prev, ...newImageObjects]);
        }
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    },
    [dispatch, setImages, images, toast]
  );

  const catalogUploadImages = useSelector(
    (state) => state.catalogUploadImages || {}
  );
  const { catalogUploadError, catalogUploadLoading } =
    catalogUploadImages[uniqueIdentifierRef.current] || {};

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 3,
  });

  const updateTextHeader = (imageUrl, newText) => {
    setImages(
      images.map((imgObj) =>
        imgObj.img === imageUrl ? { ...imgObj, textHeader: newText } : imgObj
      )
    );
  };

  const updateTextDescription = (imageUrl, newText) => {
    setImages(
      images.map((imgObj) =>
        imgObj.img === imageUrl
          ? { ...imgObj, textDescription: newText }
          : imgObj
      )
    );
  };

  const handleRemoveImage = (imageObjectToRemove) => {
    const updatedImages = images.filter(
      (imageObject) => imageObject.img !== imageObjectToRemove.img
    );
    setImages(updatedImages);
  };

  return (
    <React.Fragment>
      <label>{text}</label>
      <div className={`mb-4 main-profile-image`}>
        <div className="main-profile-image__content d-flex align-items-center flex-column justify-content-center  gap-2">
          <div
            className="d-flex align-items-center flex-column justify-content-center"
            {...getRootProps()}
          >
            <FaCloudUploadAlt className="uploadImage" />
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="mb-0 text-center ">Перетащите файлы сюда...</p>
            ) : (
              <>
                <p className="mb-0 text-center ">
                  Перетащите файлы сюда или нажмите, чтобы выбрать файлы.
                </p>
                <p className="mb-0 text-center ">
                  Максимальное количество файлов для загрузки - 3 файла.
                </p>
                <p className="mb-0 text-center ">
                  Размер файла для загрузки - до 10мб.
                </p>
              </>
            )}
          </div>
        </div>
        {catalogUploadError && (
          <p className="cart-error-message">{catalogUploadError}</p>
        )}
        {catalogUploadLoading ? (
          <Stack>
            <Skeleton height="20px" borderRadius="10px" />
            <Skeleton height="20px" borderRadius="10px" />
            <Skeleton height="20px" borderRadius="10px" />
          </Stack>
        ) : (
          <div className="profile-images-container d-flex flex-column gap-2">
            {images?.map((imageObj, index) => (
              <div
                key={index}
                className="image-preview-banner-container position-relative"
              >
                {fieldType === "ProductionImagesBanner" && (
                  <img src={imageObj.img} alt="Фото" />
                )}
                <div
                  className="productionBanner__text position-absolute d-flex flex-column gap-2"
                  style={{ bottom: "5px", left: "5px", right: "5px" }}
                >
                  <input
                    type="text"
                    placeholder="Заголовок"
                    value={imageObj.textHeader}
                    onChange={(e) =>
                      updateTextHeader(imageObj.img, e.target.value)
                    }
                  />
                  <textarea
                    name="production banner description"
                    cols="20"
                    rows="1"
                    placeholder="Описание"
                    value={imageObj.textDescription}
                    onChange={(e) =>
                      updateTextDescription(imageObj.img, e.target.value)
                    }
                  ></textarea>
                </div>
                <MdOutlineDeleteForever
                  onClick={() => handleRemoveImage(imageObj)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProductionBannersAds;
