import React from "react";
import "./DepartmentCards.scss";

import { departmentCardsData } from "../../../constants/departmentCardsData";
import { Link } from "react-router-dom";

export const DepartmentCards = () => {
  return (
    <div className="deportment-cards ">
      <div className="container py-5 my-5 px-0">
        {departmentCardsData.map((el, index) => (
          <div
            key={index}
            className="cards d-flex
        flex-column align-items-start justify-content-end p-3"
            style={{ backgroundImage: `url(${el.img})` }}
          >
            <h4>{el.title}</h4>
            <Link to={el.link}>Перейти</Link>
          </div>
        ))}
      </div>
    </div>
  );
};
