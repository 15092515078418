import {
  FABRICS_GET_FAIL,
  FABRICS_GET_REQUEST,
  FABRICS_GET_SUCCESS,
} from "../constants/FabricsConsts";

// FABRIC GET REDUCER
export const fabricsReducer = (state = { fabricsList: [] }, action) => {
  switch (action.type) {
    case FABRICS_GET_REQUEST:
      return { loading: true, fabricsList: [] };
    case FABRICS_GET_SUCCESS:
      return {
        loading: false,
        fabricsList: action.payload,
      };
    case FABRICS_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
