import { Link } from 'react-router-dom';
import Phone from "./../assets/images/contuctUsimg/телефон.svg";
import Email from "./../assets/images/contuctUsimg/емайл.svg";
import Geo from "./../assets/images/contuctUsimg/локация.svg";
import Work from "./../assets/images/contuctUsimg/работа.svg";
import Calc from "./../assets/images/contuctUsimg/калькулятор.svg";
import Manager from "./../assets/images/contuctUsimg/менеджер.svg";

export const ContuctComandData = [
  {
    id: 1,
    img: Calc,
    title: "Бухгалтерия",
    phone: (
      <Link to="https://api.whatsapp.com/send/?phone=996772122111">
        + 996 772 12 21 11
      </Link>
    ),
  },
  {
    id: 2,
    img: Manager,
    title: "Менеджер по продажам",
    phone: (
      <Link to="https://api.whatsapp.com/send/?phone=996772122111">
        + 996 772 12 21 11
      </Link>
    ),
  },
  {
    id: 3,
    img: Manager,
    title: "Менеджер по продажам",
    phone: (
      <Link to="https://api.whatsapp.com/send/?phone=996552456654">
        + 996 552 45 66 54
      </Link>
    ),
  },
  {
    id: 4,
    img: Manager,
    title: "Менеджер по продажам",
    phone: (
      <Link to="https://api.whatsapp.com/send/?phone=996551494701">
        + 996 551 49 47 01
      </Link>
    ),
  },
];

export const ContuctCompanyData = [
  {
    id: 1,
    img: Phone,
    title: "Телефон",
    link: (
      <Link to="https://api.whatsapp.com/send/?phone=996772122111">
        + 996 772 12 21 11
      </Link>
    ),
  },
  {
    id: 2,
    img: Email,
    title: "Email",
    link: <Link to="mailto:haymaneken@gmail.com">haymaneken@gmail.com</Link>,
  },
  {
    id: 3,
    img: Geo,
    title: "Адрес",
    link: (
      <Link
        target="target_blank"
        to="https://yandex.ru/map-widget/v1/?um=constructor%3A9da533d270438c1bc565cf3e8a21c3b2baad95796a6eaf394c4dd3ddc5ebc25d&amp;source=constructor"
      >
        Село Восток, ул. Ленина 58А
      </Link>
    ),
  },
  {
    id: 4,
    img: Work,
    title: "Режим работы",
    link: "пн–пт с 10:00 до 19:00",
  },
];

export const RequisitesData = [
  "ОсОО «МАНЕКЕН»",
  "ОГРН 1147746077159",
  "ИНН 7708806062",
  "АО «КЫРГЫЗСТАН БАНК»",
  "БИК 044525974",
  "р/с 40702810110000004992",
  "к/с 30101810145250000974",
];
