import React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  WrapItem,
} from "@chakra-ui/react";
import { FaUserAlt } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/Actions/UserAction";

export const HeaderUserDropDown = ({ setActive }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const loginPath = `/login?redirect=${encodeURIComponent(location.pathname)}`;
  const logoutHandler = () => {
    dispatch(logout());
    setActive("");
  };
  return (
    <>
      {userInfo && !userInfo?.message ? (
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            {userInfo ? (
              <WrapItem>
                <Avatar
                  size="sm"
                  name={userInfo ? userInfo.name : ""}
                  src={userInfo ? userInfo.profile : ""}
                />
              </WrapItem>
            ) : (
              <FaUserAlt />
            )}
          </MenuButton>
          <MenuList>
            <MenuItem>
              <Link
                onClick={() => {
                  setActive("");
                }}
                className="dropdown-item"
                to="/profile"
              >
                Профиль
              </Link>
            </MenuItem>
            <MenuItem>
              <Link className="dropdown-item" to="#" onClick={logoutHandler}>
                Выйти
              </Link>
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            <FaUserAlt size="23px" />
          </MenuButton>
          <MenuList>
            <MenuItem>
              <Link
                onClick={() => {
                  setActive("");
                }}
                className="dropdown-item"
                to="/register"
              >
                Зарегистрироваться
              </Link>
            </MenuItem>
            {!userInfo?.message ? (
              <MenuItem>
                <Link
                  onClick={() => {
                    setActive("");
                  }}
                  className="dropdown-item"
                  to={loginPath}
                >
                  Войти
                </Link>
              </MenuItem>
            ) : (
              ""
            )}
          </MenuList>
        </Menu>
      )}
    </>
  );
};
