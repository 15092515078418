import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  decreaseQty,
  increaseQty,
} from "../../../../redux/Actions/CartActions";
import plusSvg from "./../../../../assets/images/cart/plus-square 1.svg";
import minusSvg from "./../../../../assets/images/cart/dash-square 1.svg";
import trashSvg from "./../../../../assets/images/cart/x-square 1.svg";
import { CurrencyConverter } from "../../../common/currencyConverter/CurrencyConverter";
import { selectCurrency } from "../../../../redux/slices/CatalogSlice";

export const CatalogCart = ({ cart, handleRemoveItem }) => {
  const selectedCurrency = useSelector(selectCurrency);
  const dispatch = useDispatch();
  const handleIncreaseQty = (index) => {
    dispatch(increaseQty(index));
  };

  const handleDecreaseQty = (index) => {
    dispatch(decreaseQty(index));
  };

  return (
    <>
      {cart.map((el, index) => (
        <section
          key={index}
          className="shopping-cart__item d-flex align-items-center justify-content-between flex-column p-4 flex-xl-row"
        >
          <div className="shopping-cart__item_description d-flex flex-column align-items-center flex-md-row">
            <figure className="d-flex align-items-center justify-content-center">
              <img src={el.image} alt={el.title} title="картинка товара" />
            </figure>
            <div className="shopping-cart__items_item">
              <Link
                to={`/catalogs/${el.item}`}
                className="d-flex gap-1 align-items-center justify-content-center justify-content-md-start "
              >
                <span className="my-2 text-center text-md-start">
                  Заказ из «Товары»{" "}
                </span>
                <span
                  style={{
                    borderRadius: "2px",
                    color: "#fff",
                    padding: "0px 5px",
                    backgroundColor:
                      !el.isWholesale && el.isWholesale !== undefined
                        ? "#008000"
                        : "#535df0",
                    width: "max-content",
                  }}
                >
                  {el.isWholesale !== undefined
                    ? el.isWholesale
                      ? "Оптом"
                      : "Розница"
                    : "Оптом"}
                </span>
                {el.hasSample && el.qty === 1 ? (
                  <span
                    style={{
                      borderRadius: "2px",
                      color: "#fff",
                      padding: "0 5px",
                      backgroundColor: "#c50db2",
                      width: "max-content",
                    }}
                  >
                    Образец
                  </span>
                ) : (
                  ""
                )}
              </Link>
              <Link to={`/catalogs/${el.item}`}>
                <p className="my-2 text-center text-md-start">{el.title}</p>
              </Link>
              <p>
                <strong>Доп-но:</strong>
                {el.additionaly ? el.additionaly.name : "Стандартная упаковка"}
              </p>
              <p className="d-flex align-items-center gap-1">
                <strong>Цвет:</strong>
                {el?.color?.map((el, index) => (
                  <span
                    key={index}
                    className="color-circle"
                    style={{
                      backgroundColor: el.color || el,
                      backgroundImage: `url(${el.color})`,
                      backgroundPosition: "center",
                      width: "30px",
                      height: "50px",
                      borderRadius: "initial",
                      backgroundSize: "cover",
                    }}
                  ></span>
                ))}
              </p>
              <p style={{ maxWidth: "250px" }}>
                <strong>Ткань:</strong>
                {el.textile}
              </p>
              <p className="d-flex align-items-center gap-1">
                <strong>Размер:</strong>
                {el.hasSample && el.qty === 1 ? (
                  <span style={{ textTransform: "lowercase" }}>
                    {el.sampleSize}
                  </span>
                ) : !el.isWholesale && el.isWholesale !== undefined ? (
                  el.sizes.map((size, index) => (
                    <span key={index} style={{ textTransform: "lowercase" }}>
                      {size.size || size}
                    </span>
                  ))
                ) : el.isWholesale && el.isWholesale !== undefined ? (
                  el.sizes.map((size, index) => (
                    <span key={index} style={{ textTransform: "lowercase" }}>
                      {size.size || size}
                    </span>
                  ))
                ) : (
                  el.qty === 1 ||
                  (el.isWholesale === undefined &&
                    el.sizes.map((size, index) => (
                      <span key={index} style={{ textTransform: "lowercase" }}>
                        {size.size || size}
                      </span>
                    )))
                )}
              </p>
            </div>
          </div>
          <div className="shopping-cart__count d-flex align-items-center flex-column flex-md-row ">
            <div className="shopping-cart__items_count my-2 d-flex align-items-center justify-content-between">
              <img
                src={minusSvg}
                alt="удалить один товар"
                title="удалить один товар"
                onClick={() => handleDecreaseQty(index)}
                className={`${
                  el.hasSample && el.qty === 1 ? "disabled-btn" : ""
                }`}
              />
              <span>
                {el.hasSample && el.qty === 1 ? (
                  <span
                    style={{
                      color: "#c50db2",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Образец
                  </span>
                ) : (
                  <>
                    {!el.isWholesale && el.isWholesale === undefined ? (
                      <p className="mb-0">
                        {Math.round(el.qty / el.qtyOfGoodsInPack)} уп.
                      </p>
                    ) : (
                      ""
                    )}
                    <strong>{el.qty} шт.</strong>
                  </>
                )}
              </span>
              <img
                src={plusSvg}
                alt="добавить один товар"
                title="добавить один товар"
                onClick={() => handleIncreaseQty(index)}
                className={`${
                  el.hasSample && el.qty === 1 ? "disabled-btn" : ""
                }`}
              />
            </div>
            <div className="shopping-cart__items_price d-flex align-items-center justify-content-between flex-column flex-md-row">
              <div className="shopping-cart__items_price_width">
                <p className="mb-0 mx-0 mx-md-1 mx-lg-4 d-flex gap-3 gap-md-0 justify-content-between">
                  <span>цена за 1 шт.:</span>
                  {selectedCurrency === "KGS" ? (
                    <strong>
                      {el.hasSample && el.qty === 1 ? el.samplePrice : el.price}{" "}
                      KGS
                    </strong>
                  ) : (
                    <CurrencyConverter
                      total={
                        el.hasSample && el.qty === 1 ? el.samplePrice : el.price
                      }
                    />
                  )}
                </p>
                {el.qty !== 1 ? (
                  <p className="mb-0 mx-0 mx-md-1 mx-lg-4 d-flex gap-3 gap-md-0 justify-content-between">
                    <span>цена за общее кол-во.:</span>
                    {selectedCurrency === "KGS" ? (
                      <strong>{el.price * el.qty} KGS</strong>
                    ) : (
                      <CurrencyConverter total={el.price * el.qty} />
                    )}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <img
                src={trashSvg}
                alt="удалить товар"
                title="удалить товар"
                onClick={() => handleRemoveItem(el)}
              />
            </div>
          </div>
        </section>
      ))}
    </>
  );
};
