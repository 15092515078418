import React from "react";
import { Button, Collapse } from "@chakra-ui/react";

const ItemDescription = ({ item }) => {
  const [show, setShow] = React.useState(false);

  const handleToggle = () => setShow(!show);
  return (
    <div className="single-item-wrapper__description mt-3">
      <Collapse
        startingHeight={item.description?.length < 155 ? 24 : 45}
        in={show}
      >
        <p className="text-center text-md-start">{item.description}</p>
      </Collapse>
      {item.description?.length > 155 ? (
        <Button size="sm" fontSize={12} onClick={handleToggle} mt="0.4rem">
          {show ? "Скрыть" : "Подробнее"}
        </Button>
      ) : (
        ""
      )}
    </div>
  );
};

export default ItemDescription;
