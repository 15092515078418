import React from "react";
import LinkNav from "../../../../hooks/LinkNav";
import { Link } from "react-router-dom";

const FooterContacts = ({ pageLink, pageTitle, data, style }) => {
  return (
    <React.Fragment>
      <li>
        <LinkNav to={`/${pageLink}`} className="active">
          {pageTitle}
        </LinkNav>
      </li>
      <ul className="d-flex align-items-end gap-3">
        {data.map((el) => (
          <li key={el.id} className="m-0">
            <Link to={el.link}>
              <img src={el.img} alt={el.name} style={style} />
            </Link>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};

export default FooterContacts;
