import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  currentChatUser: "",
  applicationInfo: "",
  chats: [],
  search: "",
  selectedApplicationCategory: "",
  activeMobileChat: false,
  isVisibleChat: true,
  activeMobileContacts: true,
  activeTab:true,
  activeTabCategory:false,
  messages: [],
  fetchAgain: false,
  socketConnected: false,
  inputMessage: "",
  showInputFields: false,
  selectedChatCompare:null
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setCurrentChatUser: (state, action) => {
      state.currentChatUser = action.payload;
    },
    setApplicationInfo: (state, action) => {
      state.applicationInfo = action.payload;
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSelectedApplicationCategory: (state, action) => {
      state.selectedApplicationCategory = action.payload;
    },
    setActiveMobileChat: (state, action) => {
      state.activeMobileChat = action.payload;
    },
    setIsVisibleChat: (state, action) => {
      state.isVisibleChat = action.payload;
    },
    setActiveMobileContacts: (state, action) => {
      state.activeMobileContacts = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setFetchAgain: (state, action) => {
      state.fetchAgain = action.payload;
    },
    setSocketConnected: (state, action) => {
      state.socketConnected = action.payload;
    },
    setInputMessage: (state, action) => {
      state.inputMessage = action.payload;
    },
    setShowInputFields: (state, action) => {
      state.showInputFields = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setActiveTabCategory: (state, action) => {
      state.activeTabCategory = action.payload;
    },
    setSelectedChatCompare: (state, action) => {
      state.selectedChatCompare = action.payload;
    },
  },
});

export const {
  setNotifications,
  setCurrentChatUser,
  setApplicationInfo,
  setChats,
  setSearch,
  setSelectedApplicationCategory,
  setActiveMobileChat,
  setIsVisibleChat,
  setActiveMobileContacts,
  setMessages,
  setFetchAgain,
  setSocketConnected,
  setInputMessage,
  setShowInputFields,
  setActiveTab,
  setActiveTabCategory,
  setSelectedChatCompare
} = chatSlice.actions;

export const selectNotifications = (state) => state.chat.notifications;
export const selectCurrentChatUser = (state) => state.chat.currentChatUser;
export const selectApplicationInfo = (state) => state.chat.applicationInfo;
export const selectChats = (state) => state.chat.chats;
export const selectSearch = (state) => state.chat.search;
export const selectApplicationCategory = (state) =>
  state.chat.selectedApplicationCategory;
export const selectActiveMobileChat = (state) => state.chat.activeMobileChat;
export const selectVisibleChat = (state) => state.chat.isVisibleChat;
export const selectActiveMobileContacts = (state) =>
  state.chat.activeMobileContacts;
export const selectMessages = (state) => state.chat.messages;
export const selectFatchAgain = (state) => state.chat.fetchAgain;
export const selectSocketConnected = (state) => state.chat.socketConnected;
export const selectInputMsg = (state) => state.chat.inputMessage;
export const selectShowInputFields = (state) => state.chat.showInputFields;
export const selectActiveTab = (state) => state.chat.activeTab;
export const selectActiveTabCategory = (state) => state.chat.activeTabCategory;
export const selectChatCompare = (state) => state.chat.selectedChatCompare;

export default chatSlice.reducer;
