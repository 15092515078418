import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import ZoomImageOnHover from "../../../common/zoomImageOnHover/ZoomImageOnHover";
import { videoExtensions } from "../../../../constants";

const FabricMainSlider = ({ fabricData, activeColorsImg }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (fabricData && fabricData.fabricColors) {
      const index = fabricData.fabricColors.findIndex(
        (img) => img.img === activeColorsImg
      );
      sliderRef.current?.slickGoTo(index);
    }
  }, [activeColorsImg, fabricData]);

  const settings = {
    customPaging: function (i) {
      const fabricColor = fabricData?.fabricColors?.[i];
      if (!fabricColor) {
        return <a href="/">Unavailable</a>;
      }

      const url = fabricColor.img;
      return (
        <a href="/">
          {videoExtensions?.some((extension) => url.endsWith(extension)) ? (
            <video src={url} />
          ) : (
            <img src={url} alt="Фото" />
          )}
        </a>
      );
    },
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: "slick-dots slick-thumb",
  };

  return (
    <div className="fabric-main-slider">
      <Slider {...settings} ref={sliderRef}>
        {fabricData?.fabricColors?.map((el, index) => (
          <ZoomImageOnHover
            key={index}
            src={el.img}
            className={` ${activeColorsImg === el.img ? "slick-active" : ""}`}
          />
        ))}
      </Slider>
    </div>
  );
};

export default FabricMainSlider;
