export const sberPaymentInstructions = [
  {
    title: "1",
    company_name: "Сбербанк",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687774767/instuctionImages/first_h4lzb5.png",
    instruction:
      "В приложении Сбербанк Онлайн открываем раздел Платежи и переводы за рубеж",
  },
  {
    title: "2",
    company_name: "Сбербанк",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687774766/instuctionImages/second_elqbx6.png",
    instruction: 'Выбираем "По номеру телефона"',
  },
  {
    title: "3",
    company_name: "Сбербанк",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687774767/instuctionImages/third_wacthl.png",
    instruction: 'Выбираем "Киргизия"',
  },
  {
    title: "4",
    company_name: "Сбербанк",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687774767/instuctionImages/four_cbg3z0.png",
    instruction: "Наберите номер телефона получателя: +996 705 045 664",
  },
  {
    title: "5",
    company_name: "Сбербанк",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687774767/instuctionImages/five_oaoclb.png",
    instruction: 'Выбрать банк получателя "КБ Кыргызстан"',
  },
  {
    title: "6",
    company_name: "Сбербанк",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687774766/instuctionImages/six_bgavdy.png",
    instruction:
      "Проверьте номер получателя, выберите счет списания и укажите сумму",
  },
  {
    title: "7",
    company_name: "Сбербанк",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687774767/instuctionImages/seven_m2xht0.png",
    instruction:
      "Проверьте данные получателя, ознакомьтесь с комиссией и подтвердите платеж",
  },
  {
    title: "8",
    company_name: "Сбербанк",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    instruction:
      "Далее для подтверждения оплаты отправьте электронную квитанцию и номер заказа на whatsapp: +996 (772) 122 111. Приятной покупки!",
  },
];

export const tinkoffPaymentInstructions = [
  {
    title: "1",
    company_name: "Тинькофф",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687786756/instuctionImages/t1_dgmrhr.png",
    instruction:
      'В приложении Тинькофф открываем раздел "Платежи" - "Переводы по телефону"',
  },
  {
    title: "2",
    company_name: "Тинькофф",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687786758/instuctionImages/t2_w7im8e.png",
    instruction: "Приложение перекинет вас на контакты в телефоне ",
  },
  {
    title: "3",
    company_name: "Тинькофф",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687786749/instuctionImages/t3_it1pma.png",
    instruction: 'Выбираем "Киргизия"',
  },
  {
    title: "4",
    company_name: "Тинькофф",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687786749/instuctionImages/t4_erkxpg.png",
    instruction: "Наберите номер телефона получателя: +996 705 045 664",
  },
  {
    title: "5",
    company_name: "Тинькофф",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687786747/instuctionImages/t5_ea6eyf.png",
    instruction: 'Выбрать банк получателя "КБ Кыргызстан"',
  },
  {
    title: "6",
    company_name: "Тинькофф",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687786766/instuctionImages/t6_lncobg.png",
    instruction:
      "Проверьте номер получателя, укажите сумму и подтвердите перевод",
  },
  {
    title: "7",
    company_name: "Тинькофф",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    mbankImg:
      "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687786749/instuctionImages/t7_kv9kyk.png",
    instruction: "Готово! ",
  },
  {
    title: "8",
    company_name: "Тинькофф",
    icon: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1687775665/instuctionImages/mbank_psi5or.png",
    iconBg: "#E6DEDD",
    instruction:
      "Далее для подтверждения оплаты отправьте электронную квитанцию и номер заказа на whatsapp: +996 (772) 122 111. Приятной покупки!",
  },
];

export const paymentMethodData = [
  {
    id: 1,
    img: "https://mbank.kg/media/seo/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F_%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B0.png",
    name: "MBank",
    link: "payment",
  },
  {
    id: 2,
    img: "https://3dnews.ru/assets/external/illustrations/2022/05/23/1066465/636.png",
    name: "Тинькофф",
    link: "payment",
  },
  {
    id: 3,
    img: "https://etu.ru/assets/cache/images/1280x800-sber-designnews-ru%281%29.85f.jpeg",
    name: "Сбербанк",
    link: "payment",
  },
];
