import React from "react";

const SaleType = ({ item }) => {
  return (
    <p
      style={{
        backgroundColor:
          !item.isWholesale && item.isWholesale !== undefined ? "#008000" : "",
      }}
    >
      {item.isWholesale !== undefined
        ? item.isWholesale
          ? "Оптом"
          : "Розница"
        : "Оптом"}
    </p>
  );
};

export default SaleType;
