import {
  PROFILE_UPLOAD_AVATAR_FAIL,
  PROFILE_UPLOAD_AVATAR_REQUEST,
  PROFILE_UPLOAD_AVATAR_RESET,
  PROFILE_UPLOAD_AVATAR_SUCCESS,
  PROFILE_UPLOAD_CATALOG_IMAGES_REQUEST,
  PROFILE_UPLOAD_CATALOG_IMAGES_SUCCESS,
  PROFILE_UPLOAD_CATALOG_IMAGES_FAIL,
  PROFILE_UPLOAD_CATALOG_IMAGES_RESET,
  PROFILE_UPLOAD_CATALOG_IMAGE_REQUEST,
  PROFILE_UPLOAD_CATALOG_IMAGE_SUCCESS,
  PROFILE_UPLOAD_CATALOG_IMAGE_FAIL,
  PROFILE_UPLOAD_CATALOG_IMAGE_RESET,
} from "../constants/ProfileConstants";

export const profileUploadAvatarReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_UPLOAD_AVATAR_REQUEST:
      return { loading: true };
    case PROFILE_UPLOAD_AVATAR_SUCCESS:
      return { loading: false, success: true, avatar: action.payload };
    case PROFILE_UPLOAD_AVATAR_FAIL:
      return { loading: false, error: action.payload };
    case PROFILE_UPLOAD_AVATAR_RESET:
      return {};
    default:
      return state;
  }
};

export const profileUploadCatalogImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_UPLOAD_CATALOG_IMAGES_REQUEST:
      return {
        ...state,
        [action.id]: { catalogUploadLoading: true },
      };
    case PROFILE_UPLOAD_CATALOG_IMAGES_SUCCESS:
      return {
        ...state,
        [action.id]: {
          catalogUploadLoading: false,
          catalogUploadSuccess: true,
          catalogImages: action.payload,
        },
      };
    case PROFILE_UPLOAD_CATALOG_IMAGES_FAIL:
      return {
        ...state,
        [action.id]: {
          catalogUploadLoading: false,
          catalogUploadError: action.payload,
        },
      };
    case PROFILE_UPLOAD_CATALOG_IMAGES_RESET:
      return { ...state, [action.id]: {} };
    default:
      return state;
  }
};

export const profileUploadCatalogImageReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_UPLOAD_CATALOG_IMAGE_REQUEST:
      return {
        ...state,
        [action.id]: { catUploadLoading: true },
      };
    case PROFILE_UPLOAD_CATALOG_IMAGE_SUCCESS:
      return {
        ...state,
        [action.id]: {
          catUploadLoading: false,
          catUploadSuccess: true,
          catImages: action.payload,
        },
      };
    case PROFILE_UPLOAD_CATALOG_IMAGE_FAIL:
      return {
        ...state,
        [action.id]: {
          catUploadLoading: false,
          catUploadError: action.payload,
        },
      };
    case PROFILE_UPLOAD_CATALOG_IMAGE_RESET:
      return { ...state, [action.id]: {} };
    default:
      return state;
  }
};
