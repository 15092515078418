import { $apiPrivate } from "../../api/axios";
import {
  DELETE_CHAT_FAIL,
  DELETE_CHAT_REQUEST,
  DELETE_CHAT_SUCCESS,
} from "../constants/ChatConsts";
import { clearStorage } from "./UserAction";

// DELETE CHAT
export const deleteChatAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_CHAT_REQUEST,
    });

    await $apiPrivate.delete(`/applications/chat/${id}`);

    dispatch({
      type: DELETE_CHAT_SUCCESS,
      payload: id,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: DELETE_CHAT_FAIL,
      payload: message,
    });
  }
};
