import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { sortItems } from "../../../../constants/catalogCategoriesSortFilterData";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import SelectedCategoryDrawer from "./selectedCategoryDrawer/SelectedCategoryDrawer";
import {
  selectActiveSort,
  setActiveAgeGroup,
  setActiveSort,
  setSelectedCategory,
} from "../../../../redux/slices/CatalogSlice";
import { useDispatch, useSelector } from "react-redux";

const HeaderCategoryBtn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = queryString.parse(location.search);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeCategoryType, setActiveCategoryType] = useState(null);
  const [selectedChildGroup, setSelectedChildGroup] = useState([]);
  const activeSort = useSelector(selectActiveSort);

  const openDrawerHandler = (name) => {
    setActiveCategoryType(name);
  };

  const openChildSubCategory = (ageGroup) => {
    setSelectedChildGroup(ageGroup);
  };

  const onModalClose = () => {
    setActiveCategoryType(null);
    onClose();
  };

  const onSortCatalog = (type) => {
    dispatch(setActiveSort(type));
  };

  const onSelectSortType = (index) => {
    onSortCatalog(index);
    dispatch(setActiveSort(index));
    const newSearchParams = { ...searchParams, sortBy: activeSort.type };
    const newSearch = queryString.stringify(newSearchParams);
    navigate(`?${newSearch}`);
    navigate("/catalogs");
    dispatch(setSelectedCategory(""));
    dispatch(setActiveAgeGroup(""));
  };

  return (
    <div className="menu-category d-flex align-items-center justify-content-center ">
      <Button
        onClick={onOpen}
        as={IconButton}
        icon={<GiHamburgerMenu size={31} />}
        variant="outline"
        padding="0"
      />
      <Drawer placement="left" onClose={onModalClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent className="header-drawer">
          <DrawerBody className="d-flex flex-column align-items-start gap-2 ">
            {sortItems.map((obj, index) => (
              <Button
                onMouseEnter={() => openDrawerHandler(obj.name)}
                className={`d-flex alig-items-center ${
                  activeSort.type === obj.type ? "active" : ""
                }`}
                onClick={() => onSelectSortType(obj)}
                key={`${obj.id}_${index}`}
              >
                {obj.icon}
                {obj.name}
              </Button>
            ))}

            <SelectedCategoryDrawer
              activeCategoryType={activeCategoryType}
              openChildSubCategory={openChildSubCategory}
              selectedChildGroup={selectedChildGroup}
              onClose={onClose}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default HeaderCategoryBtn;
