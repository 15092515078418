import React from "react";
import { Avatar } from "@chakra-ui/react";
import { BsFillGeoAltFill } from "react-icons/bs";

const ProductionInfoPageHeader = ({ productionItem }) => {
  return (
    <div
      className="productionInfoPage__header p-3 d-flex align-items-center gap-3 my-4"
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${
          productionItem?.brandInfo?.brandImage
            ? productionItem?.brandInfo?.brandImage[0]
            : "https://res.cloudinary.com/drdqjr8r6/image/upload/v1706161077/bg%20images/Section_1_ycduc6.png"
        }')`,
        backgroundPosition: "center",
        backgroundSize: "100%",
      }}
    >
      <div className="productionInfoPage__header__logo">
        <Avatar
          size="2xl"
          name={productionItem?.brandInfo?.brandName}
          src={productionItem?.brandInfo?.brandLogo}
        />
      </div>

      <div className="productionInfoPage__header__info">
        <h1>{productionItem?.brandInfo?.brandName}</h1>
        <p>{productionItem?.description}</p>

        <span className="d-flex align-items-center gap-2">
          <BsFillGeoAltFill /> Бишкек, Кыргызстан
        </span>
      </div>
    </div>
  );
};

export default ProductionInfoPageHeader;
