import React, { useState } from "react";
import { videoExtensions } from "../../../constants";

const ZoomImageOnHover = ({ src, className }) => {
  const [hover, setHover] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleMouseMove = (e) => {
    setPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  };

  return (
    <React.Fragment>
      {videoExtensions?.some((ext) => src?.toLowerCase().endsWith(ext)) ? (
        <div>
          <video src={src} autoPlay={false} controls></video>
        </div>
      ) : null}
      <div
        className={`${hover ? "hover" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        style={{
          width: hover ? "200%" : "100%",
          height: hover ? "200%" : "100%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <img
          className={className}
          src={src}
          alt="Изображение товара"
          style={{
            transform: hover
              ? `translate(${-position.x / 2}px, ${-position.y / 2}px)`
              : "translate(0, 0)",
            width: hover ? "100%" : "100%",
            height: hover ? "100%" : "100%",
            position: "absolute",
            cursor: "zoom-in",
            objectFit: "cover",
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default ZoomImageOnHover;
