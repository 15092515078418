import React from "react";
import { AddIcon } from "@chakra-ui/icons";
import { Badge } from "@chakra-ui/react";
import AddProductionInstruction from "../../profilePage/profileAddProduction/addProductionCatalogItems/addProductionInstruction/AddProductionInstruction";

const CustomSaveBtn = ({ handleAdd, data }) => {
  return (
    <section className="custom__save__btn w-100 d-flex align-items-center justify-content-between mt-4">
      <AddProductionInstruction descriptionText='Для чего кнопка "Сохранить"?'>
        Нажмите "Сохранить", чтобы добавить введенные данные в список. Например,
        для пополнения списка специализаций вашего цеха. Или при добавлении
        карточек товара, в разделе "Товары производства"
      </AddProductionInstruction>
      <button
        type="button"
        className="save__btn__info d-flex align-items-center justify-content-center gap-2"
        onClick={handleAdd}
      >
        Сохранить <AddIcon />{" "}
        <Badge ml="1" fontSize="0.8em" colorScheme="green">
          {data?.length}
        </Badge>
      </button>
    </section>
  );
};

export default CustomSaveBtn;
