import { BiFemale } from "react-icons/bi";
import { FaMale } from "react-icons/fa";
import { GiConverseShoe } from "react-icons/gi";
import { MdOutlineChildCare, MdFiberNew, MdLooksOne } from "react-icons/md";
import { PiHandbagSimpleBold } from "react-icons/pi";
import { TiThSmallOutline } from "react-icons/ti";
import { TbMoneybag } from "react-icons/tb";

export const categories = [
  "Футболка",
  "Свитшоты",
  "Пиджак",
  "Худи",
  "Пуловер",
  "Рубашка",
  "Двойка",
  "Костюм",
  "Куртка",
  "Комбинезон",
  "Платья",
  "Тройка",
  "Спорт",
  "Лонгслив",
  "Топ",
  "Блузка",
  "Белье",
  "Юбка",
  "Жакеты",
  "Жилеты",
  "Пальто",
  "Брюки",
  "Широкие брюки",
  "Джинсы",
  "Кардиганы",
  "Пуховики",
  "Плащи",
  "Лосины",
  "Тедди",
  "Бомбер",
  "Шуба",
  "Шорты",
  "Халаты",
  "Ветровка",
  "Водолазка",
  "Пижамы",
  "Туники",
  "Мусульманская",
  "Пляжная одежда",
  "Будущим мамам",
  "Корсеты",
];

export const maleCategories = [
  "Футболка",
  "Худи",
  "Свитшоты",
  "Пиджак",
  "Пуловер",
  "Рубашка",
  "Поло-рубашки",
  "Двойка",
  "Парка",
  "Костюм",
  "Тройка",
  "Куртка",
  "Ветровка",
  "Жилеты",
  "Жакеты",
  "Футболки с графическими принтами",
  "Спорт",
  "Тренировочная одежда",
  "Кардиганы",
  "Пальто",
  "Пуховики",
  "Пижамы",
  "Брюки",
  "Шерстяные брюки",
  "Хлопковые брюки",
  "Джинсы",
  "Тедди",
  "Бомбер",
  "Лонгслив",
  "Водолазка",
  "Белье",
  "Халаты",
  "Шорты",
  "Пляжная одежда",
];

export const genders = [
  { id: 1, value: "male", label: "Мужские" },
  { id: 2, value: "female", label: "Женские" },
  { id: 3, value: "child", label: "Детские" },
  { id: 4, value: "accessories", label: "Аксессуары" },
  { id: 5, value: "shoes", label: "Обувь" },
];

export const childGirlBoy = [
  {
    id: 1,
    ageGroup: "0 - 12 месяцев",
    categories: [
      "Боди",
      "Комбинезон",
      "Рукавички",
      "Распашонки",
      "Комбинезоны-слипы",
      "Кофты",
      "Ползунки",
      "Шапочки",
      "Штанишки",
      "Футболка",
      "Двойка",
      "Белье",
    ],
  },
  {
    id: 2,
    ageGroup: "1 - 3 года | мальчик",
    categories: [
      "Боди",
      "Комбинезон",
      "Толстовки",
      "Джинсы",
      "Пальто",
      "Куртки",
      "Кофты",
      "Футболка",
      "Брюки",
      "Шорты",
      "Спортивный костюм",
      "Трикотаж",
      "Двойка",
      "Белье",
    ],
  },
  {
    id: 3,
    ageGroup: "1 - 3 года | девочка",
    categories: [
      "Боди",
      "Комбинезон",
      "Толстовки",
      "Платья",
      "Легинсы",
      "Джинсы",
      "Пальто",
      "Куртки",
      "Кофты",
      "Футболка",
      "Брюки",
      "Шорты",
      "Спортивный костюм",
      "Трикотаж",
      "Двойка",
      "Белье",
    ],
  },
  {
    id: 4,
    ageGroup: "3 - 7 лет | мальчик",
    categories: [
      "Комбинезон",
      "Толстовки",
      "Джинсы",
      "Пальто",
      "Куртки",
      "Кофты",
      "Футболка",
      "Брюки",
      "Шорты",
      "Спортивный костюм",
      "Трикотаж",
      "Рубашки",
      "Двойка",
      "Пуховики",
      "Пижамы",
      "Тедди",
      "Бомбер",
      "Ветровка",
      "Водолазка",
      "Белье",
      "Халаты",
    ],
  },
  {
    id: 5,
    ageGroup: "3 - 7 лет | девочка",
    categories: [
      "Комбинезон",
      "Толстовки",
      "Платья",
      "Легинсы",
      "Джинсы",
      "Пальто",
      "Куртки",
      "Кофты",
      "Футболка",
      "Брюки",
      "Шорты",
      "Спортивный костюм",
      "Трикотаж",
      "Рубашки",
      "Двойка",
      "Пуховики",
      "Пижамы",
      "Тедди",
      "Бомбер",
      "Ветровка",
      "Водолазка",
      "Белье",
      "Халаты",
    ],
  },
  {
    id: 6,
    ageGroup: "7 - 14 лет | мальчик",
    categories: [
      "Комбинезон",
      "Толстовки",
      "Джинсы",
      "Пальто",
      "Куртки",
      "Кофты",
      "Футболка",
      "Брюки",
      "Шорты",
      "Спортивный костюм",
      "Трикотаж",
      "Рубашки",
      "Двойка",
      "Тедди",
      "Пуховики",
      "Пижамы",
      "Тедди",
      "Бомбер",
      "Ветровка",
      "Водолазка",
      "Белье",
      "Халаты",
    ],
  },
  {
    id: 7,
    ageGroup: "7 - 14 лет | девочка",
    categories: [
      "Комбинезон",
      "Толстовки",
      "Платья",
      "Легинсы",
      "Джинсы",
      "Пальто",
      "Куртки",
      "Кофты",
      "Футболка",
      "Брюки",
      "Шорты",
      "Спортивный костюм",
      "Трикотаж",
      "Рубашки",
      "Двойка",
      "Тедди",
      "Бомбер",
      "Ветровка",
      "Пуховики",
      "Пижамы",
      "Водолазка",
      "Белье",
      "Халаты",
    ],
  },
  {
    id: 8,
    ageGroup: "14 - 18 лет | мальчик",
    categories: [
      "Комбинезон",
      "Толстовки",
      "Джинсы",
      "Пальто",
      "Куртки",
      "Кофты",
      "Футболка",
      "Брюки",
      "Шорты",
      "Спортивный костюм",
      "Трикотаж",
      "Рубашки",
      "Двойка",
      "Тедди",
      "Бомбер",
      "Ветровка",
      "Пижамы",
      "Пуховики",
      "Водолазка",
      "Халаты",
      "Белье",
    ],
  },
  {
    id: 9,
    ageGroup: "14 - 18 лет | девочка",
    categories: [
      "Комбинезон",
      "Толстовки",
      "Платья",
      "Легинсы",
      "Джинсы",
      "Пальто",
      "Куртки",
      "Кофты",
      "Футболка",
      "Брюки",
      "Шорты",
      "Спортивный костюм",
      "Трикотаж",
      "Рубашки",
      "Двойка",
      "Юбка",
      "Жакеты",
      "Кардиганы",
      "Пуховики",
      "Пижамы",
      "Плащи",
      "Лосины",
      "Тедди",
      "Бомбер",
      "Ветровка",
      "Пуховики",
      "Водолазка",
      "Белье",
      "Халаты",
    ],
  },
];

export const accessories = [
  "Сумки",
  "Рюкзаки",
  "Ремни",
  "Пояса",
  "Аксессуары для волос",
  "Аксессуары для одежды",
  "Бижутерия",
  "Галстуки",
  "Бабочки",
  "Головные уборы",
  "Зонты",
  "Кошельки",
  "Носовые платки",
  "Очки",
  "Перчатки",
  "Варежки",
  "Платки",
  "Религиозные",
  "Часы",
  "Чемоданы",
];

export const shoes = [
  "Аксессуары для обуви",
  "Ортопедическая обувь",
  "Мужская",
  "Женская",
  "Детская",
];

export const sortItems = [
  { name: "Мужчинам", type: "male", icon: <FaMale /> },
  { name: "Женщинам", type: "female", icon: <BiFemale /> },
  { name: "Детям", type: "child", icon: <MdOutlineChildCare /> },
  { name: "Аксессуары", type: "accessories", icon: <PiHandbagSimpleBold /> },
  { name: "Обувь", type: "shoes", icon: <GiConverseShoe /> },
  { name: "Новинки", type: "newItems", icon: <MdFiberNew /> },
];
export const wholesaleOrRetailItems = [
  { name: "Все товары", type: "allItems", icon: <TiThSmallOutline /> },
  { name: "Оптом", type: "wholesale", icon: <TbMoneybag /> },
  { name: "Розница", type: "retail", icon: <MdLooksOne /> },
];
export const sortByRatingPrice = [
  { name: "По популярности", type: "ratings" },
  { name: "По цене", type: "price" },
  { name: "По дате", type: "createdAt" },
];

export const selectionOfSalesType = [
  { id: 1, value: "wholesale", label: "Оптом" },
  { id: 2, value: "retail", label: "Розница" },
];
