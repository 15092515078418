export const PreviousArrowSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-arrow-left"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
      />
    </svg>
  );
};

export const NextArrowSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-arrow-right"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
      />
    </svg>
  );
};

export const SmallLogoSvg = () => {
  return (
    <svg
      version="1.1"
      id="Слой_1"
      width={60}
      height={60}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      fill="#141850"
      viewBox="0 0 1080 1080"
      style={{ enableBackground: "new 0 0 1080 1080" }}
      xmlSpace="preserve"
    >
      <g>
        <polygon
          className="st0"
          points="476.54,414.14 470.35,387.83 339.55,488.44 540,1037.92 439.78,469.86 	"
        />
        <polygon
          className="st0"
          points="609.65,387.83 603.46,414.14 640.22,469.86 540,1037.92 740.45,488.44 	"
        />
        <path
          className="st0"
          d="M486.57,206.54c15.65-0.18,31.29-0.41,46.94-0.88c7.82-0.18,15.65-0.53,23.48-0.83
		c7.83-0.32,15.65-0.57,23.48-1.05c15.66-0.81,31.31-1.78,46.98-3.25c-15.61-1.95-31.23-3.4-46.86-4.7
		c-7.81-0.72-15.63-1.21-23.44-1.77c-7.81-0.55-15.63-1.13-23.44-1.56c-15.63-0.95-31.26-1.67-46.89-2.33
		c-7.62-0.31-15.25-0.58-22.87-0.82c9.52-7.87,19.01-15.77,28.42-23.76c15.53-13.1,30.85-26.44,46.11-39.87
		c15.24-13.44,30.25-27.17,45.15-41.03c14.84-13.93,29.62-27.93,43.83-42.62c-17.07,11.24-33.57,23.16-50.01,35.16
		c-16.38,12.07-32.65,24.27-48.69,36.75c-16.03,12.5-31.98,25.08-47.73,37.91c-15.79,12.78-31.42,25.76-46.97,38.83l-18.71,15.74
		l24.3,0.14C455.28,206.69,470.92,206.69,486.57,206.54z"
        />
      </g>
    </svg>
  );
};

export const IsVisiblePopup = ({ isVisiblePopup }) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isVisiblePopup ? "rotated" : ""}
    >
      <path
        d="M10 5C10 5.16927 9.93815 5.31576 9.81445 5.43945C9.69075 5.56315 9.54427 5.625 9.375 5.625H0.625C0.455729 5.625 0.309245 5.56315 0.185547 5.43945C0.061849 5.31576 0 5.16927 0 5C0 4.83073 0.061849 4.68424 0.185547 4.56055L4.56055 0.185547C4.68424 0.061849 4.83073 0 5 0C5.16927 0 5.31576 0.061849 5.43945 0.185547L9.81445 4.56055C9.93815 4.68424 10 4.83073 10 5Z"
        fill="#2C2C2C"
      />
    </svg>
  );
};

export const SearchSvg = () => {
  return (
    <svg
      id="Layer_1"
      version="1.1"
      viewBox="0 0 512 512"
      width="512px"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M448.3,424.7L335,311.3c20.8-26,33.3-59.1,33.3-95.1c0-84.1-68.1-152.2-152-152.2c-84,0-152,68.2-152,152.2  s68.1,152.2,152,152.2c36.2,0,69.4-12.7,95.5-33.8L425,448L448.3,424.7z M120.1,312.6c-25.7-25.7-39.8-59.9-39.8-96.3  s14.2-70.6,39.8-96.3S180,80,216.3,80c36.3,0,70.5,14.2,96.2,39.9s39.8,59.9,39.8,96.3s-14.2,70.6-39.8,96.3  c-25.7,25.7-59.9,39.9-96.2,39.9C180,352.5,145.8,338.3,120.1,312.6z"
        fill="rgba(91, 91, 91, 1)"
      />
    </svg>
  );
};

export const DeleteSvg = ({ setSearchQuary }) => {
  return (
    <svg
      onClick={() => setSearchQuary("")}
      enableBackground="new 0 0 128 128"
      height="25px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 128 128"
      width="25px"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <path
            fill="rgba(91, 91, 91, 1)"
            d="M84.815,43.399c-0.781-0.782-2.047-0.782-2.828,0L64.032,61.356L46.077,43.399c-0.781-0.782-2.047-0.782-2.828,0    c-0.781,0.781-0.781,2.047,0,2.828l17.955,17.957L43.249,82.141c-0.781,0.78-0.781,2.047,0,2.828    c0.391,0.39,0.902,0.585,1.414,0.585s1.023-0.195,1.414-0.585l17.955-17.956l17.955,17.956c0.391,0.39,0.902,0.585,1.414,0.585    s1.023-0.195,1.414-0.585c0.781-0.781,0.781-2.048,0-2.828L66.86,64.184l17.955-17.957C85.597,45.447,85.597,44.18,84.815,43.399z     M64.032,14.054c-27.642,0-50.129,22.487-50.129,50.127c0.002,27.643,22.491,50.131,50.133,50.131    c27.639,0,50.125-22.489,50.125-50.131C114.161,36.541,91.674,14.054,64.032,14.054z M64.036,110.313h-0.002    c-25.435,0-46.129-20.695-46.131-46.131c0-25.435,20.693-46.127,46.129-46.127s46.129,20.693,46.129,46.127    C110.161,89.617,89.47,110.313,64.036,110.313z"
          />
        </g>
      </g>
    </svg>
  );
};

export const DeleteProfileImageSvg = ({ handleRemoveImage, url, colorObj }) => {
  return (
    <svg
      onClick={() => handleRemoveImage(url || "" || colorObj)}
      enableBackground="new 0 0 128 128"
      height="40px"
      stroke="#fff"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 128 128"
      width="40px"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <path
            fill="rgba(91, 91, 91, 1)"
            d="M84.815,43.399c-0.781-0.782-2.047-0.782-2.828,0L64.032,61.356L46.077,43.399c-0.781-0.782-2.047-0.782-2.828,0    c-0.781,0.781-0.781,2.047,0,2.828l17.955,17.957L43.249,82.141c-0.781,0.78-0.781,2.047,0,2.828    c0.391,0.39,0.902,0.585,1.414,0.585s1.023-0.195,1.414-0.585l17.955-17.956l17.955,17.956c0.391,0.39,0.902,0.585,1.414,0.585    s1.023-0.195,1.414-0.585c0.781-0.781,0.781-2.048,0-2.828L66.86,64.184l17.955-17.957C85.597,45.447,85.597,44.18,84.815,43.399z     M64.032,14.054c-27.642,0-50.129,22.487-50.129,50.127c0.002,27.643,22.491,50.131,50.133,50.131    c27.639,0,50.125-22.489,50.125-50.131C114.161,36.541,91.674,14.054,64.032,14.054z M64.036,110.313h-0.002    c-25.435,0-46.129-20.695-46.131-46.131c0-25.435,20.693-46.127,46.129-46.127s46.129,20.693,46.129,46.127    C110.161,89.617,89.47,110.313,64.036,110.313z"
          />
        </g>
      </g>
    </svg>
  );
};

export const HomeSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-house"
      viewBox="0 0 16 16"
    >
      <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
    </svg>
  );
};

// PLUS BTN IN CATALOG ITEM PAGE AND IN PRODUCTION COUNT
export const IncreaseQty = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="17.6392" y1="8.5" x2="0.186411" y2="8.5" stroke="#3C4151" />
      <line x1="8.89941" x2="8.89941" y2="17" stroke="#3C4151" />
    </svg>
  );
};

// MINUS BTN IN CATALOG ITEM PAGE
export const DecreaseQty = () => {
  return (
    <svg
      width="18"
      height="1"
      viewBox="0 0 18 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="17.542" y1="0.5" x2="0.0892429" y2="0.5" stroke="#3C4151" />
    </svg>
  );
};

//used in production slider
export const ThreeDots = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-three-dots"
      viewBox="0 0 16 16"
    >
      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
    </svg>
  );
};

//used in production model
export const FlipBtn = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={30}>
      <path
        fill="#5b5b5b"
        d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"
      />
    </svg>
  );
};

//checked custom radio btn used in ff and etc
export const CheckboxBtn = () => {
  return (
    <svg viewBox="0 0 64 64" height="2em" width="2em">
      <path
        d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
        pathLength="575.0541381835938"
        className="path"
      ></path>
    </svg>
  );
};

//GREEN EXIST ITEM MARKER USING IN HEADER
export const GreenMarker = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      className="bi bi-check"
      viewBox="0 0 16 16"
    >
      <path
        fill="green"
        d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
      />
    </svg>
  );
};

export const EditProfileSvg = () => {
  return (
    <svg
      width="25"
      className="bi-x-circle position-absolute"
      style={{ top: "40px", left: "6px", cursor:'pointer' }}
      height="25"
      viewBox="0 0 25 25"
      fill="#4b505e"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1046_69)">
        <path
          d="M12.5 23.4375C9.59919 23.4375 6.8172 22.2852 4.76602 20.234C2.71484 18.1828 1.5625 15.4008 1.5625 12.5C1.5625 9.59919 2.71484 6.8172 4.76602 4.76602C6.8172 2.71484 9.59919 1.5625 12.5 1.5625C15.4008 1.5625 18.1828 2.71484 20.234 4.76602C22.2852 6.8172 23.4375 9.59919 23.4375 12.5C23.4375 15.4008 22.2852 18.1828 20.234 20.234C18.1828 22.2852 15.4008 23.4375 12.5 23.4375ZM12.5 25C15.8152 25 18.9946 23.683 21.3388 21.3388C23.683 18.9946 25 15.8152 25 12.5C25 9.18479 23.683 6.00537 21.3388 3.66117C18.9946 1.31696 15.8152 0 12.5 0C9.18479 0 6.00537 1.31696 3.66117 3.66117C1.31696 6.00537 0 9.18479 0 12.5C0 15.8152 1.31696 18.9946 3.66117 21.3388C6.00537 23.683 9.18479 25 12.5 25Z"
          fill="#4b505e"
        />
        <path
          d="M16.4009 7.34991L16.5046 7.24613C16.5828 7.16807 16.6755 7.10615 16.7775 7.06392C16.8795 7.02169 16.9889 6.99997 17.0993 7C17.2098 7.00003 17.3191 7.02182 17.4211 7.06411C17.5231 7.1064 17.6158 7.16837 17.6939 7.24648C17.7719 7.32459 17.8339 7.41731 17.8761 7.51935C17.9183 7.62139 17.94 7.73075 17.94 7.84118C17.94 7.95162 17.9182 8.06096 17.8759 8.16298C17.8336 8.26499 17.7716 8.35768 17.6935 8.43575L17.5898 8.53883C17.7673 8.73793 17.8619 8.9974 17.8543 9.26404C17.8466 9.53068 17.7373 9.7843 17.5487 9.97291L10.3806 17.1419C10.3357 17.1865 10.2796 17.2181 10.2183 17.2332L7.43238 17.9297C7.37408 17.9442 7.31302 17.9434 7.25512 17.9273C7.19723 17.9113 7.14448 17.8805 7.102 17.838C7.05951 17.7955 7.02874 17.7428 7.01268 17.6849C6.99661 17.627 6.9958 17.5659 7.01032 17.5076L7.70679 14.7216C7.72203 14.6605 7.75356 14.6047 7.79803 14.56L14.5134 7.84442C14.4463 7.79715 14.3646 7.77514 14.2827 7.78228C14.2009 7.78941 14.1243 7.82524 14.0663 7.88342L11.7735 10.177C11.7411 10.2094 11.7027 10.235 11.6604 10.2526C11.6181 10.2701 11.5727 10.2791 11.527 10.2791C11.4812 10.2791 11.4358 10.2701 11.3935 10.2526C11.3512 10.235 11.3128 10.2094 11.2804 10.177C11.248 10.1446 11.2223 10.1062 11.2048 10.0639C11.1873 10.0216 11.1783 9.97622 11.1783 9.93043C11.1783 9.88464 11.1873 9.83929 11.2048 9.79699C11.2223 9.75468 11.248 9.71624 11.2804 9.68387L13.5746 7.391C13.7633 7.20226 14.0171 7.09292 14.2839 7.0854C14.5507 7.07789 14.8103 7.17278 15.0093 7.35061C15.2007 7.17957 15.4483 7.08497 15.705 7.08484C15.9616 7.08471 16.2093 7.17907 16.4009 7.34991ZM15.9523 7.88342C15.887 7.81814 15.7985 7.78146 15.7061 7.78146C15.6138 7.78146 15.5252 7.81814 15.4599 7.88342L8.358 14.9842L7.82589 17.1134L9.95501 16.5813L17.057 9.48049C17.0894 9.44814 17.1151 9.40971 17.1327 9.3674C17.1502 9.32509 17.1593 9.27974 17.1593 9.23393C17.1593 9.18812 17.1502 9.14277 17.1327 9.10046C17.1151 9.05815 17.0894 9.01972 17.057 8.98737L15.9523 7.88342Z"
          fill="#4b505e"
        />
      </g>
      <defs>
        <clipPath id="clip0_1046_69">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
