import React from "react";
import { Seo } from "../seo/Seo";
import { Breadcrumbs } from "../components/common/breadCrumbs/Breadcrumbs";
import infoCircle from "../assets/images/constrImg/info-circle.svg";

import "./styles/DeliveryPage.scss";
import { Link } from "react-router-dom";
import { deliveryPageData } from "../constants/deliveryData";

const DeliveryPage = () => {
  return (
    <section className="delivery-container">
      <Seo
        title="Доставка"
        description="Способы доставки"
        name="Манекен"
        type="website"
        keywords="Доставка, Maneken, Манекен, Одежда оптом, Производство одежды, Ткани, Фулфилмент, Fulfilment"
      />
      <div className="delivery-about position-relative ">
        <div className="container px-0">
          <h1 className="text-center text-md-start">Доставка</h1>
          <Breadcrumbs />
          <p className="p-0 text-center text-lg-start ">
            Выберите удобный способ доставки на нашем сайте по самой выгодной
            цене! Мы предоставляем нашим клиентам доступ к самой низкой
            стоимости доставки, чтобы сделать ваше онлайн-покупательское
            впечатление еще более приятным. Беззаботно получайте свои заказы,
            зная, что мы заботимся о вашем комфорте и доступности наших услуг.
            Узнайте больше о наших вариантах доставки и выбирайте опцию, которая
            наилучшим образом соответствует вашим потребностям.
          </p>
        </div>
      </div>
      <main className="main-content container">
        <div className="main-content__grid">
          {deliveryPageData.map((el) => (
            <figure key={el.id}>
              <Link to={el.link} target="_blank">
                <img src={el.img} alt={el.name} />
              </Link>
            </figure>
          ))}
        </div>
        <p className="mt-5 d-flex align-items-center gap-2">
          <img src={infoCircle} alt="info" /> Срок и стоимость доставки заказов
          рассчитываются автоматически при оформлении заказа.
        </p>
        <p className="d-flex align-items-center gap-2">
          <img src={infoCircle} alt="info" /> В случае расхождения между
          расчетным и фактическим весом отправления после упаковки, стоимость
          доставки может быть скорректирована.
        </p>
        <p className=" d-flex align-items-center gap-2">
          <img src={infoCircle} alt="info" /> Стоимость доставки зависит от
          тарифов, установленных выбранной логистической компанией, подробнее с
          тарифами можете ознакомиться на сайте компаний.
        </p>
      </main>
    </section>
  );
};

export default DeliveryPage;
