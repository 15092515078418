import axios from "axios";
import inMemoryJwt from "../services/inMemoryJwt";

export const $api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
});

export const $apiPrivate = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
});

$apiPrivate.interceptors.request.use(
  (config) => {
    const accessToken = inMemoryJwt.getToken();

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
