import { ourTeamData } from '../../../constants/aboutUsPageData';
import { Button } from './../../common/button/Button';

import './ComandSection.scss';

export const ComandSection = () => {
  return (
    <section className="about-us-page__comand">
      {ourTeamData.map((el) => (
        <div className="about-us-page__comand__block container" key={el.id}>
          <h5>{el.title}</h5>
          <p>{el.description}</p>
          <figure>
            <img src={el.img} alt={el.title} title={el.title} />
          </figure>
          <Button to="/cooperation">Условия сотрудничества</Button>
        </div>
      ))}
    </section>
  );
};
