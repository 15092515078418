import React from "react";
import ProductionImagesSlider from "./productionImagesSlider/ProductionImagesSlider";
import { Card, CardBody, CardFooter, Heading, Stack } from "@chakra-ui/react";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineCursorArrowRipple } from "react-icons/hi2";
import { FaCartShopping } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { TbListDetails } from "react-icons/tb";
import { GiSewingMachine } from "react-icons/gi";

const ProductionMain = ({ catalogs, index }) => {
  const words = catalogs?.description?.split(" ");
  const isLongText = words?.length > 5;
  const displayedText = isLongText
    ? words.slice(0, 10).join(" ") + "..."
    : catalogs?.description;

  return (
    <section className="production__design__wrapper gap-2" id={catalogs._id}>
      <Card
        direction={{ base: "column", md: "row" }}
        overflow="hidden"
        variant="outline"
        className="container"
      >
        <ProductionImagesSlider catalogs={catalogs} />
        <Stack>
          <CardBody>
            <Heading size="md" className="d-flex align-items-center gap-2">
              <GiSewingMachine color="#118638" />{" "}
              {catalogs?.brandInfo.brandName?.toUpperCase()}
            </Heading>
            <div className="d-flex align-items-center flex-wrap gap-1">
              <p className="mb-0">{displayedText}</p>
            </div>
            <div>
              <strong>Специализация: </strong>
              {catalogs?.specialization?.slice(0, 7).map((el, index, array) => (
                <span key={index}>
                  {el.title}
                  {index < array.length - 1 ? "," : ""}
                </span>
              ))}
              {catalogs?.specialization?.length > 7 && "..."}
            </div>
          </CardBody>

          <CardFooter className="gap-2 pt-0 justify-content-around justify-content-md-start  ">
            <Link
              to={`/constructor?activeSlide=${index}`}
              className="animated-button d-flex align-items-center gap-2 justify-content-center"
            >
              <FaCartShopping /> Товары{" "}
              <HiOutlineCursorArrowRipple size="30px" />
            </Link>
            <Link
              to={`/production/${catalogs._id.toString()}`}
              className="animated-button d-flex align-items-center gap-2 justify-content-center"
            >
              <TbListDetails /> Подробнее
              <HiOutlineCursorArrowRipple size="30px" />
            </Link>
            <Link
              to={`https://api.whatsapp.com/send/?phone=${catalogs?.productionPhoneNumber}`}
              target="target_blank"
              className="animated-button d-flex align-items-center gap-2 justify-content-center"
            >
              <FaWhatsapp /> Связаться{" "}
              <HiOutlineCursorArrowRipple size="30px" />
            </Link>
          </CardFooter>
        </Stack>
      </Card>
    </section>
  );
};

export default ProductionMain;
