import React from "react";

const ProductInput = ({
  value = "",
  setState,
  placeholder,
  type,
  label,
  required,
  style,
  productValue,
  brandNameError,
  setBrandNameError,
  setDescriptionError,
  descriptionError,
}) => {
  return (
    <div className={`mb-4`} style={style}>
      <label htmlFor={productValue} className="form-label mb-0">
        {label}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        className={`form-control ${
          brandNameError || descriptionError ? "cart-border-error" : ""
        }`}
        id={productValue}
        required={required}
        value={value}
        onChange={(e) => {
          setState(e.target.value);
          if (brandNameError) {
            setBrandNameError("");
          }
          if (descriptionError) {
            setDescriptionError("");
          }
        }}
      />
      {brandNameError ||
        (descriptionError && (
          <p className="cart-error-message">
            {brandNameError || descriptionError}
          </p>
        ))}
    </div>
  );
};

export default ProductInput;
