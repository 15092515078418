import { Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";

const FabricList = ({ fabricData, onFabricSelect }) => {
  const firstFabricActive = fabricData[0]?._id;
  const [activeFabricId, setActiveFabricId] = useState(firstFabricActive);

  const handleFabricSelect = (fabric) => {
    setActiveFabricId(fabric._id);
    onFabricSelect(fabric);
  };

  return (
    <div className="fabric-lists">
      {fabricData?.map((el) => (
        <div
          key={el._id}
          onClick={() => handleFabricSelect(el)}
          className={el._id === activeFabricId ? "active" : ""}
        >
          <div>
            {el.title}
            <Tooltip
              label={el.description}
              hasArrow
              placement="top"
              borderRadius="10px"
              padding="10px"
            >
              <img src={el?.img} alt={el?.title} title={el?.title} />
            </Tooltip>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FabricList;
