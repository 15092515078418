import { Badge } from "@chakra-ui/react";
import React from "react";
import AddProductionInstruction from "./../addProductionInstruction/AddProductionInstruction";
import ProductMainImage from "../../../profileAddProductToCatalog/productMainImage/ProductMainImage";
import AddProductionTextiles from "../addProductionTextiles/AddProductionTextiles";
import ProductSizes from "../../../profileAddProductToCatalog/productSizes/ProductSizes";
import AddImageColors from "./addImageColors/AddImageColors";
import ProductImages from "../../../profileAddProductToCatalog/productImages/ProductImages";
import { ProfileProductionCatalogState } from "../../../../../context/ProfileProductionCatalogProvider";
import ProfileCatalogItemsGabarits from "../../../profileCatalogItemsGabarits/ProfileCatalogItemsGabarits";

const AddProductionCatalogItem = ({
  productionCatalog,
  handleAddProductionCatalogItem,
  handleSaveProductionCatalogItem,
  isVisibleModelsAdd,
  setIsVisibleModelsAdd,
}) => {
  const {
    productionTitle,
    setProductionTitle,
    productionPrice,
    setProductionPrice,
    productionMainImage,
    setProductionMainImage,
    productionAdditionalImage,
    setProductionAdditionalImage,
    productionSizes,
    setProductionSizes,
    productionTitleError,
    setProductionTitleError,
    productionPriceError,
    setProductionPriceError,
    productionMainImageError,
    setProductionMainImageError,
    productionSizesError,
    setProductionSizesError,
    productWeight,
    setProductWeight,
  } = ProfileProductionCatalogState();

  const handleOpenPopup = () => {
    setIsVisibleModelsAdd(!isVisibleModelsAdd);
  };
  return (
    <section className={`profile__production__add__catalog `}>
      <section>
        <AddProductionInstruction descriptionText="Зачем заполнять форму ниже?">
          Заполнив форму ниже, вы получите карточку товара с указанием названия,
          цены, типа ткани, основного изображения и цветов. При нажатии на
          каждый цвет будут отображены изображения товара: вид спереди и вид
          сзади, соответствующие выбранному цвету.{" "}
          <strong>
            Каждое заполнение формы и нажатие "Сохранить товар" добавляют новый
            товар в ваш каталог.
          </strong>{" "}
          Добавив нужное количество товаров, нажмите кнопку{" "}
          <strong>"Опубликовать"</strong>
        </AddProductionInstruction>
        <p className="profile__production__add__catalog__header__text mt-3">
          1. Информация о товаре
        </p>
        <div>
          <label htmlFor="production-title">Название товара:</label>
          <input
            type="text"
            id="production-title"
            className={`form-control ${
              productionTitleError ? "cart-border-error" : ""
            }`}
            placeholder="Например: Футболка"
            value={productionTitle}
            onChange={(e) => {
              setProductionTitle(e.target.value);
              setProductionTitleError("");
            }}
          />
          {productionTitleError && (
            <p className="cart-error-message">{productionTitleError}</p>
          )}
        </div>
        <div>
          <label htmlFor="production-price" className="mt-4">
            Цена:
          </label>
          <input
            type="number"
            id="production-price"
            className={`form-control ${
              productionPriceError ? "cart-border-error" : ""
            }`}
            placeholder="Например: 1000"
            value={productionPrice}
            onChange={(e) => {
              setProductionPrice(e.target.value);
              setProductionPriceError("");
            }}
          />
          {productionPriceError && (
            <p className="cart-error-message">{productionPriceError}</p>
          )}
        </div>
        <AddProductionTextiles
          text="Ткань товара: (укажите через запятую)"
          placeholder="Например: хлопок, шерсть, шелк и тд."
        />

        <ProductSizes
          sizes={productionSizes}
          setSizes={setProductionSizes}
          text="Размер товара: (укажите через запятую)"
          placeholder="Например: xs, s, m, xl и тд."
          productionSizesError={productionSizesError}
          setProductionSizesError={setProductionSizesError}
        />
        <ProfileCatalogItemsGabarits
          productWeight={productWeight}
          setProductWeight={setProductWeight}
        />
        <AddProductionInstruction descriptionText="Для чего основная картинка товара?">
          Загрузите изображение товара. Это изображение будет отображаться в
          качестве основной картинки товара, в вашей карточке товара.
        </AddProductionInstruction>
        <ProductMainImage
          text="Основная картинка товара:"
          image={productionMainImage}
          setImage={setProductionMainImage}
          productionMainImageError={productionMainImageError}
          setProductionMainImageError={setProductionMainImageError}
        />

        <ProductImages
          images={productionAdditionalImage}
          setImages={setProductionAdditionalImage}
          text={"Дополнительные картинки и видео товара:"}
          fieldType="ProductionImages"
        />

        <p className="profile__production__add__catalog__header__text ">
          3. Добавление товара по цветам
        </p>

        <AddProductionInstruction descriptionText='Как добавить товар по цветам?'>
          Загрузите изображения товара (вид спереди и вид сзади) и укажите его
          цвет. Эти изображения будут представлены в качестве дополнительных
          вариантов при выборе товара. При смене цвета будут автоматически
          изменяться соответствующие изображения товара, которые вы загрузили.
          После заполнения данной информации и нажатия кнопки "Сохранить" вы
          добавите новый цвет товара с соответствующими изображениями. Можете
          повторить эту операцию для каждого цвета товара.
        </AddProductionInstruction>

        <AddImageColors
          handleSaveProductionCatalogItem={handleSaveProductionCatalogItem}
          isVisibleModelsAdd={isVisibleModelsAdd}
          handleOpenPopup={handleOpenPopup}
        />
      </section>

      <div className="d-flex justify-content-start mt-3 gap-3">
        <button
          type="button"
          className="profile__production__add__submit__btn"
          onClick={handleAddProductionCatalogItem}
        >
          Сохранить товар
          <Badge ml="1" fontSize="0.8em" colorScheme="green">
            {productionCatalog.length}
          </Badge>
        </button>
      </div>
    </section>
  );
};

export default AddProductionCatalogItem;
