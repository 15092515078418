import React, { useLayoutEffect, useRef } from "react";

import moment from "moment";
import "moment/locale/ru";

import { Avatar, Tooltip } from "@chakra-ui/react";
import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
} from "../../../../../helper/ChatSettings";
import { BiTime } from "react-icons/bi";
import { useSelector } from "react-redux";
import { selectMessages } from "../../../../../redux/slices/ChatSlice";

const CurrentChatMsgs = () => {
  const messagesEndRef = useRef(null);
  const messages = useSelector(selectMessages);
  const user = useSelector((state) => state.userLogin.userInfo);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      block: "nearest",
      behavior: "smooth",
    });
  };

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);
  return (
    <div>
      {messages &&
        messages?.map((m, i) => (
          <div style={{ display: "flex", alignItems: "center" }} key={m._id}>
            {(isSameSender(messages, m, i, user?.id) ||
              isLastMessage(messages, i, user?.id)) && (
              <Tooltip label={m.sender.name} placement="bottom-start" hasArrow>
                <Avatar
                  mr={1}
                  size="sm"
                  cursor="pointer"
                  name={m?.sender?.name}
                  src={m?.sender?.profile}
                />
              </Tooltip>
            )}
            <p
              className="mb-0 d-flex flex-column"
              style={{
                backgroundColor: `${
                  m?.sender?._id === user?.id ? "rgb(67 74 168)" : "#f5f7fb"
                }`,
                marginLeft: isSameSenderMargin(messages, m, i, user?.id),
                marginTop: isSameUser(messages, m, i, user?.id) ? 3 : 10,
                borderRadius: `${
                  m?.sender?._id === user?.id
                    ? " 10px 0 10px 10px "
                    : " 0 10px 10px 10px"
                }`,
                padding: "10px 20px",
                maxWidth: "75%",
                color: `${m?.sender?._id === user?.id ? "#fff" : "#000"}`,
              }}
            >
              <span>{m?.content}</span>
              <span
                className="d-flex align-items-center "
                style={{ fontSize: "8px" }}
              >
                <BiTime /> {moment(m.createdAt).format("LL")}
              </span>
            </p>
          </div>
        ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default CurrentChatMsgs;
