import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSocketConnected } from '../redux/slices/ChatSlice';
import { socket } from '../socket';

const useSocketConnection = (user) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      socket.connect();
      socket.emit('setup', user);
      const handleConnected = () => dispatch(setSocketConnected(socket.connected));
      const handleDisconnected = () => dispatch(setSocketConnected(false));

      socket.on('connected', handleConnected);
      socket.on('disconnect', handleDisconnected);

      return () => {
        socket.off('connected', handleConnected);
        socket.off('disconnect', handleDisconnected);
      };
    }
  }, [dispatch, user]);
};

export default useSocketConnection;
