import React, { useEffect, useState } from "react";

import TypingLoader from "./typingLoader/TypingLoader";
import PrivateChatInput from "./privateChatInput/PrivateChatInput";
import PublicChatInputs from "./publicChatInputs/PublicChatInputs";

import { Spinner, useToast } from "@chakra-ui/react";
import { $apiPrivate } from "../../../api/axios";
import {
  selectCurrentChatUser,
  selectInputMsg,
  selectMessages,
  selectSocketConnected,
  selectVisibleChat,
  setInputMessage,
  setMessages,
  setSelectedChatCompare,
} from "../../../redux/slices/ChatSlice";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "./../../../socket";

const ApplicationsChatInputs = ({
  purchaseInfo,
  setPurchaseInfo,
  inputRef,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const currentChatUser = useSelector(selectCurrentChatUser);
  const isVisibleChat = useSelector(selectVisibleChat);
  const messages = useSelector(selectMessages);
  const socketConnected = useSelector(selectSocketConnected);
  const inputMessage = useSelector(selectInputMsg);

  const [typing, setTyping] = useState(false);
  const [istyping, setIsTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typingTimer, setTypingTimer] = useState(null);

  const fetchMessages = async () => {
    if (!currentChatUser) return;

    try {
      setLoading(true);

      const { data } = await $apiPrivate.get(
        `/applications/message/${currentChatUser?._id}`
      );

      dispatch(setMessages(data));
      setLoading(false);

      socket.emit("join chat", currentChatUser._id);
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: "Failed to Load the Messages",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  const sendMessageToUser = async () => {
    socket.emit("stop typing", currentChatUser._id);
    try {
      dispatch(setInputMessage(""));
      const { data } = await $apiPrivate.post(`/applications/message`, {
        content: inputMessage,
        chatId: currentChatUser,
      });
      socket.emit("new message", data);
      dispatch(setMessages([...messages, data]));
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: "Failed to send the Message",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  useEffect(() => {
    socket.on("typing", () => setIsTyping(true));
    socket.on("stop typing", () => setIsTyping(false));

    return () => {
      socket.off("typing");
      socket.off("stop typing");
    };
  }, [dispatch]);

  useEffect(() => {
    fetchMessages();

    dispatch(setSelectedChatCompare(currentChatUser));
    return () => {
      if (currentChatUser) {
        socket.emit("leave chat", currentChatUser._id);
      }
    };
    // eslint-disable-next-line
  }, [dispatch, currentChatUser]);

  const typingHandler = (e) => {
    dispatch(setInputMessage(e.target.value));

    if (!socketConnected) return;

    if (!typing) {
      setTyping(true);
      socket.emit("typing", currentChatUser._id);
    }

    clearTimeout(typingTimer);

    const newTimer = setTimeout(() => {
      socket.emit("stop typing", currentChatUser._id);
      setTyping(false);
    }, 3000);

    setTypingTimer(newTimer);
  };

  return (
    <div>
      {loading && (
        <Spinner
          position="absolute"
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          top="50%"
          left="50%"
          size="xl"
        />
      )}
      {istyping && <TypingLoader istyping={istyping} />}

      <div className="input-fields w-100">
        {isVisibleChat === false ? (
          <PrivateChatInput
            typingHandler={typingHandler}
            sendMessageToUser={sendMessageToUser}
          />
        ) : (
          <PublicChatInputs
            purchaseInfo={purchaseInfo}
            setPurchaseInfo={setPurchaseInfo}
            inputRef={inputRef}
          />
        )}
      </div>
    </div>
  );
};

export default ApplicationsChatInputs;
