import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import LoadingError from "../../../common/errorMessage/LoadingError";
import ProductInput from "../productInput/ProductInput";
import ProductSizes from "../productSizes/ProductSizes";
import ProductGenderChoose from "../productGenderChoose/ProductGenderChoose";
import ProductDescription from "../productDescription/ProductDescription";
import ProductMainImage from "../productMainImage/ProductMainImage";
import ProductImages from "../productImages/ProductImages";
import ProductOwner from "../productOwner/ProductOwner";
import ProductColors from "../productColors/ProductColors";
import ProductAddHeader from "../productAddHeader/ProductAddHeader";
import { productInputData } from "../../../../constants/index";
import ProfileCatalogItemsGabarits from "../../profileCatalogItemsGabarits/ProfileCatalogItemsGabarits";
import ProductImageUploadInfo from "../productImageUploadInfo/ProductImageUploadInfo";
import ProductSampleBuy from "../productSampleBuy/ProductSampleBuy";
import ProductSalesTypeChoose from "../productSalesTypeChoose/ProductSalesTypeChoose";

const ProfileAddProductionItemModal = (props) => {
  const {
    submitHandler,
    error,
    loading,
    state,
    updateState,
    category,
    setCategory,
    sizes,
    setSizes,
    owner,
    setOwner,
    onClose,
    isOpen,
    gender,
    setGender,
    description,
    setDescription,
    image,
    setImage,
    images,
    setImages,
    colors,
    setColors,
    ageGroup,
    setAgeGroup,
    product,
    productWeight,
    setProductWeight,
    isSample,
    setIsSample,
    samplePrice,
    setSamplePrice,
    isWholesale,
    setIsWholesale,
  } = props;

  return (
    <Modal size="3xl" onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Добавление</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={submitHandler}>
            <div className="row mb-4">
              <div className="col">
                <div
                  className="card"
                  style={{ border: "none", borderRadius: "0" }}
                >
                  <div className="card-body">
                    {error && (
                      <LoadingError variant="alert-danger">
                        {error}
                      </LoadingError>
                    )}
                    {loading && (
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner
                          className="my-5"
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="xl"
                        />
                      </div>
                    )}
                    <ProductSalesTypeChoose
                      isWholesale={isWholesale}
                      setIsWholesale={setIsWholesale}
                      setIsSample={setIsSample}
                    />
                    <ProductGenderChoose
                      gender={gender}
                      setGender={setGender}
                      category={category}
                      setCategory={setCategory}
                      ageGroup={ageGroup}
                      setAgeGroup={setAgeGroup}
                      product={product}
                    />
                    <ProductSampleBuy
                      isSample={isSample}
                      isWholesale={isWholesale}
                      setIsSample={setIsSample}
                      setSamplePrice={setSamplePrice}
                    />
                    {isSample && (
                      <ProductInput
                        value={samplePrice}
                        placeholder="Введите розничную цену"
                        type="number"
                        setState={setSamplePrice}
                        label="Розничная цена"
                        required={isSample}
                        productValue="samplePrice"
                      />
                    )}
                    {productInputData.map((el) => (
                      <ProductInput
                        key={el.id}
                        value={state[el.value]}
                        placeholder={
                          el.value === "price" && !isWholesale
                            ? "Введите розничную цену"
                            : el.placeholder
                        }
                        type={el.type}
                        setState={(value) => updateState(el.value, value)}
                        label={
                          el.value === "price" && !isWholesale
                            ? "Розничная цена"
                            : el.label
                        }
                        required={el.required}
                        productValue={el.value}
                        style={
                          el.value === "qtyOfGoodsInPack" && !isWholesale
                            ? { display: "none" }
                            : el.style
                        }
                      />
                    ))}

                    <ProductSizes
                      sizes={sizes}
                      setSizes={setSizes}
                      text="Размер товара: (укажите через запятую)"
                      placeholder="Например: xs, s, m, xl и тд."
                      product={product}
                    />
                    <ProfileCatalogItemsGabarits
                      productWeight={productWeight}
                      setProductWeight={setProductWeight}
                    />

                    <ProductDescription
                      setDescription={setDescription}
                      description={description}
                    />
                    <ProductMainImage
                      image={image}
                      setImage={setImage}
                      text="Основная картинка товара:"
                    />

                    <ProductImages
                      images={images}
                      setImages={setImages}
                      colors={colors}
                      setColors={setColors}
                      text={"Картинки и видео товара:"}
                      fieldType="ProductImages"
                    />

                    <ProductImageUploadInfo />

                    <ProductColors
                      colors={colors}
                      setColors={setColors}
                      fieldType="ProductImages"
                    />
                    <ProductOwner
                      owner={owner}
                      setOwner={setOwner}
                      ownerName="Контакты владельца товара"
                      text="Ф.И.О"
                    />
                  </div>
                </div>
              </div>
            </div>
            <ProductAddHeader loading={loading} />
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProfileAddProductionItemModal;
