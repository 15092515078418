import { useEffect, useRef } from "react";
import { $api } from "../api/axios";
import inMemoryJwt from "../services/inMemoryJwt";

const useRefreshAccessToken = (user, setIsUserLogged) => {
  const effectRun = useRef(false);
  useEffect(() => {
    if (effectRun.current === false) {
      const refreshAccessToken = async () => {
        try {
          if (user) {
            const { data } = await $api.post("/users/refresh");
            const { accessToken, accessTokenExpiration } = data;
            inMemoryJwt.setToken(accessToken, accessTokenExpiration);
          }
        } catch (error) {
          console.error("Error refreshing access token:", error);
        } finally {
          setIsUserLogged(true);
        }
      };

      refreshAccessToken();

      return () => {
        effectRun.current = true;
      };
    }
  }, [user, setIsUserLogged]);

  return null;
};

export default useRefreshAccessToken;
