import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@chakra-ui/icons";

export const DropdownMenu = ({ title, links, setActive, active }) => (
  <Menu>
    <MenuButton as={Button} rightIcon={<ChevronDownIcon />} background="none">
      {title}
    </MenuButton>
    <MenuList>
      {links?.map((link) => (
        <Link
          to={link.id}
          key={link.id}
          onClick={() => setActive(link.title)}
          className="d-flex align-items-center"
          style={{ "--dynamic-background": `url(${link.img})` }}
        >
          <MenuItem
            className={`gap-2 ${active === link.title ? "active" : ""}`}
          >
            {link.title}
          </MenuItem>
        </Link>
      ))}
    </MenuList>
  </Menu>
);
