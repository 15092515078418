import {
  CATALOG_LIST_REQUEST,
  CATALOG_LIST_SUCCESS,
  CATALOG_LIST_FAIL,
  CATALOG_DETAILS_REQUEST,
  CATALOG_DETAILS_SUCCESS,
  CATALOG_DETAILS_FAIL,
  CATALOG_CREATE_REVIEW_REQUEST,
  CATALOG_CREATE_REVIEW_SUCCESS,
  CATALOG_CREATE_REVIEW_FAIL,
  CATALOG_CREATE_REVIEW_RESET,
  CATALOG_REVIEW_REQUEST,
  CATALOG_REVIEW_SUCCESS,
  CATALOG_REVIEW_FAIL,
  CATALOG_CREATE_REQUEST,
  CATALOG_CREATE_SUCCESS,
  CATALOG_CREATE_RESET,
  CATALOG_CREATE_FAIL,
  PROFILE_LIST_REQUEST,
  PROFILE_LIST_SUCCESS,
  PROFILE_LIST_FAIL,
  PROFILE_CATALOG_DELETE_REQUEST,
  PROFILE_CATALOG_DELETE_SUCCESS,
  PROFILE_CATALOG_DELETE_FAIL,
  PROFILE_CATALOG_EDIT_REQUEST,
  PROFILE_CATALOG_EDIT_SUCCESS,
  PROFILE_CATALOG_EDIT_FAIL,
  PROFILE_CATALOG_UPDATE_REQUEST,
  PROFILE_CATALOG_UPDATE_SUCCESS,
  PROFILE_CATALOG_UPDATE_FAIL,
  PROFILE_CATALOG_UPDATE_RESET,
} from "./../constants/CatalogConstants";

// CATALOG ITEMS LIST
export const catalogListReducer = (
  state = { loading: true, catalogs: [] },
  action
) => {
  switch (action.type) {
    case CATALOG_LIST_REQUEST:
      return { loading: true, catalogs: [] };
    case CATALOG_LIST_SUCCESS:
      return {
        loading: false,
        pages: action.payload.pages,
        page: action.payload.page,
        category: action.payload.category,
        sortBy: action.payload.sortBy,
        catalogs: action.payload.catalogs,
        hasPreviousPage: action.payload.hasPreviousPage,
        hasNextPage: action.payload.hasNextPage,
        womenCount: action.payload.womenCount,
        menCount: action.payload.menCount,
        childrenCount: action.payload.childrenCount,
        totalCount: action.payload.totalCount,
        newItems: action.payload.newItems,
      };
    case CATALOG_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// SINGLE CATALOG ITEM LIST
export const catalogSingleItemReducer = (
  state = { item: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case CATALOG_DETAILS_REQUEST:
      return { ...state, loading: true };
    case CATALOG_DETAILS_SUCCESS:
      return { loading: false, item: action.payload };
    case CATALOG_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CATALOG REVIEWS
export const catalogCreateReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case CATALOG_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case CATALOG_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case CATALOG_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case CATALOG_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  reviews: [],
  error: null,
};

export const catalogReviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATALOG_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CATALOG_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reviews: action.payload,
        error: null,
      };
    case CATALOG_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        reviews: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

// CREATE CATALOGS ITEM
export const catalogItemCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CATALOG_CREATE_REQUEST:
      return { loading: true };
    case CATALOG_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case CATALOG_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CATALOG_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// GET ALL CATALOGS ITEM
export const profileCatalogListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PROFILE_LIST_REQUEST:
      return { loading: true, products: [] };
    case PROFILE_LIST_SUCCESS:
      return { loading: false, products: action.payload };
    case PROFILE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// DELETE PROFILE CATALOGS ITEM
export const profileCatalogItemDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_CATALOG_DELETE_REQUEST:
      return { loading: true };
    case PROFILE_CATALOG_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PROFILE_CATALOG_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// EDIT PROFILE CATALOGS ITEM
export const profileCatalogItemEditReducer = (
  state = { item: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PROFILE_CATALOG_EDIT_REQUEST:
      return { ...state, loading: true };
    case PROFILE_CATALOG_EDIT_SUCCESS:
      return { loading: false, product: action.payload };
    case PROFILE_CATALOG_EDIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// PROFIEL UPDATE CATALOGS ITEM
export const updateProfileCatalogItemReducer = (
  state = { product: {} },
  action
) => {
  switch (action.type) {
    case PROFILE_CATALOG_UPDATE_REQUEST:
      return { loading: true };
    case PROFILE_CATALOG_UPDATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PROFILE_CATALOG_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PROFILE_CATALOG_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
