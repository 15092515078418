import {
  APPLICATIONS_LIST_FAIL,
  APPLICATIONS_LIST_REQUEST,
  APPLICATIONS_LIST_SUCCESS,
  APPLICATIONS_LIST_CREATE_REQUEST,
  APPLICATIONS_LIST_CREATE_SUCCESS,
  APPLICATIONS_LIST_CREATE_FAIL,
  APPLICATIONS_LIST_CREATE_RESET,
  UPDATE_APPLICATION_VIEW_COUNT_REQUEST,
  UPDATE_APPLICATION_VIEW_COUNT_SUCCESS,
  UPDATE_APPLICATION_VIEW_COUNT_FAIL,
  UPDATE_APPLICATION_VIEW_COUNT_RESET,
  DELETE_APPLICATION_REQUEST,
  DELETE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_FAIL,
  APPLICATIONS_REQUEST,
  APPLICATIONS_SUCCESS,
  APPLICATIONS_FAIL,
} from "../constants/ApplicationsConsts";

// GET APPLICATIONS LIST
export const applicationsListsReducer = (
  state = { loading: true, lists: [] },
  action
) => {
  switch (action.type) {
    case APPLICATIONS_LIST_REQUEST:
      return { ...state, loading: true };
    case APPLICATIONS_LIST_SUCCESS:
      return { loading: false, lists: action.payload };
    case APPLICATIONS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET APPLICATIONS LIST WITHOUT SEARCH FILTER
export const applicationsReducer = (
  state = { loading: true, lists: [] },
  action
) => {
  switch (action.type) {
    case APPLICATIONS_REQUEST:
      return { ...state, loading: true };
    case APPLICATIONS_SUCCESS:
      return { loading: false, lists: action.payload };
    case APPLICATIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CREATE APPLICATION
export const applicationsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLICATIONS_LIST_CREATE_REQUEST:
      return { loading: true };
    case APPLICATIONS_LIST_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case APPLICATIONS_LIST_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case APPLICATIONS_LIST_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// UPDATE APPLICATION
export const updateApplicationViewCount = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_APPLICATION_VIEW_COUNT_REQUEST:
      return { loading: true };
    case UPDATE_APPLICATION_VIEW_COUNT_SUCCESS:
      return { loading: false, success: true, viewCount: action.payload };
    case UPDATE_APPLICATION_VIEW_COUNT_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_APPLICATION_VIEW_COUNT_RESET:
      return {};
    default:
      return state;
  }
};

//DELETE APPLICATION
const initialState = {
  deleting: false,
  deletedApplicationId: null,
  error: null,
};

export const applicationDeleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_APPLICATION_REQUEST:
      return {
        ...state,
        deleting: true,
        error: null,
      };
    case DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        deleting: false,
        deletedApplicationId: action.payload,
        error: null,
      };
    case DELETE_APPLICATION_FAIL:
      return {
        ...state,
        deleting: false,
        deletedApplicationId: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
