import React, { useState } from "react";
import { Button } from "./../../common/button/Button";
import { useToast } from "@chakra-ui/react";

import "./ContuctForm.scss";
import { Link } from "react-router-dom";
import { $api } from "../../../api/axios";

export const ContucForm = () => {
  const [recipient_email, setContactUsEmail] = useState("");
  const [recipient_text, setContactUsText] = useState("");

  const toast = useToast();

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const sendMail = async () => {
    if (recipient_email && recipient_text) {
      if (!validateEmail(recipient_email)) {
        toast({
          title: "Введите корректный email!",
          status: "error",
          isClosable: true,
          position: "top-right",
          duration: 5000,
        });
        return;
      }
      await $api
        .post(`/send_email`, {
          recipient_email: recipient_email,
          recipient_text: recipient_text,
        })
        .then(() => console.log("Message sent successfully"))
        .catch(() => console.log("Ooops... Something went wrong"));

      setContactUsEmail("");
      setContactUsText("");
      toast({
        title: "Сообщение отправлено!",
        status: "success",
        isClosable: true,
        position: "top-right",
        duration: 500,
      });
    } else {
      toast({
        title: "Заполните все поля!",
        status: "info",
        isClosable: true,
        position: "top-right",
        duration: 1000,
      });
    }
  };

  return (
    <div className="container d-flex flex-column flex-md-row">
      <figure>
        <img
          src="https://res.cloudinary.com/drdqjr8r6/image/upload/v1700123866/bg%20images/azazefir_3d_earth_with_modern_buildings_white_background_67a7ff75-32ad-46be-87d6-29d352f7894a_ythqqe.png"
          alt="contact form"
        />
      </figure>
      <form>
        <h3>Оставить заявку</h3>
        <div>
          <label htmlFor="email">Ваш email</label>
          <input
            required
            id="email"
            type="text"
            placeholder="Введите email"
            className="form-control"
            value={recipient_email}
            onChange={(e) => setContactUsEmail(e.target.value)}
          />
        </div>
        <div>
          {" "}
          <label htmlFor="text">Напишите нам, чем мы можем вам помочь</label>
          <textarea
            name=""
            id="text"
            cols="30"
            rows="10"
            className="form-control"
            value={recipient_text || "Здравствуйте,"}
            onChange={(e) => setContactUsText(e.target.value)}
          ></textarea>
        </div>

        <div>
          <Button type="button" onClick={() => sendMail()}>
            Отправить
          </Button>
          <p className="privacy-policy-section mt-3">
            Нажимая на кнопку "Отправить", я подтверждаю, что ознакомлен и
            согласен с условиями
            <Link to="/privacy-policy">
              <strong> политики конфиденциальности </strong>
            </Link>
            и
            <Link to="/privacy-policy">
              <strong> правилами обработки персональных данных </strong>
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};
