import React, { useState, useEffect } from "react";

const ProductSizes = ({
  setSizes,
  sizesString = "",
  text,
  placeholder,
  productionSizesError,
  setProductionSizesError,
  product,
}) => {
  const [sizesInput, setSizesInput] = useState(sizesString);

  useEffect(() => {
    setSizesInput(sizesString);
  }, [sizesString]);

  useEffect(() => {
    if (product) {
      setSizesInput("");
    }
  }, [product]);

  const handleSizesInputChange = (e) => {
    const input = e.target.value;
    setSizesInput(input);

    const regex = /[\s,.]+/;
    const inputArray = input.split(regex).map((size) => size.trim());

    const newSizes = inputArray
      .filter((size) => size !== "")
      .map((size) => ({ size }));

    setSizes(newSizes);
    if (productionSizesError) {
      setProductionSizesError("");
    }
  };

  return (
    <>
      <div className="mb-4">
        <label className="form-label">{text}</label>
        <div>
          <input
            type="text"
            placeholder={placeholder}
            className={`form-control ${
              productionSizesError ? "cart-border-error" : ""
            }`}
            value={sizesInput}
            onChange={handleSizesInputChange}
          />
          {productionSizesError && (
            <p className="cart-error-message">{productionSizesError}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductSizes;
