import React from "react";
import {
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
  } from "@chakra-ui/react";
import infoCircle from "../../../../assets/images/constrImg/info-circle.svg";

const ProductImageUploadInfo = () => {
  return (
    <Popover placement="top">
      <PopoverTrigger>
        <button
          type="button"
          className="profile__info__title d-flex align-items-center gap-2 mb-4"
          style={{ cursor: "pointer", width: "fit-content", fontSize: "9px" }}
        >
          <img src={infoCircle} alt="information about size" />
          <p className="mb-0">Требования к фото и видео</p>
        </button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Требования к фото и видео</PopoverHeader>
        <PopoverBody>
          <p className="mb-0">Формат фото - PNG, JPG, WEBP</p>
          <p className="mb-0">Мин разрешение - 500 х 700</p>
          <p className="mb-0">Размер до 10 мб.</p>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ProductImageUploadInfo;
