import React from "react";
import ProfileEditProduct from "../profileEditProduct/ProfileEditProduct";
import CloseBtn from "../../../../assets/images/favorites/x-circle.svg";
import { deleteProfileCatalogItemAction } from "../../../../redux/Actions/CatalogActions";
import { EditProfileSvg } from "../../../../assets/svg/Svg";
import { Link } from "react-router-dom";
import { CurrencyConverter } from "../../../common/currencyConverter/CurrencyConverter";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { selectCurrency } from "../../../../redux/slices/CatalogSlice";

const ProfileProductCard = ({ item, productId }) => {
  const selectedCurrency = useSelector(selectCurrency);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();
  const deleteHandler = (id) => {
    if (window.confirm("Вы действительно хотите удалить товар?")) {
      dispatch(deleteProfileCatalogItemAction(id));
    }
  };

  return (
    <div
      key={item._id}
      className="profile__production__add__catalog__grid__cards"
    >
      <div className="catalog__card h-100">
        <img
          className="closeBtn"
          src={CloseBtn}
          alt="Удалить"
          onClick={() => deleteHandler(item._id)}
        />
        <button onClick={onOpen}>
          <EditProfileSvg />
        </button>
        <p className="new-clothes">{item.newItem ? item.newItem : ""}</p>
        <div className="catalog__card__image">
          <Link to={`/catalogs/profile/${item._id.toString()}`}>
            <img
              src={item.image}
              alt="Картинка товара "
              className="w-100 object-fit-cover h-100"
            />
          </Link>
        </div>
        <div className="catalog__card_description">
          <h4 className="ellipsis-text mb-0">{item.title}</h4>
          <p>
            {selectedCurrency === "KGS" ? (
              <strong>{item.price} KGS</strong>
            ) : (
              <CurrencyConverter total={item.price} />
            )}
            <strong className="oldPrice">
              {item.oldPrice !== "0" ? item.oldPrice + " сом" : ""}
            </strong>
          </p>
        </div>
      </div>

      <Modal
        size="3xl"
        onClose={onClose}
        isOpen={isOpen && item._id === productId}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Редактирование</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ProfileEditProduct item={item} productId={productId} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ProfileProductCard;
