import { $api, $apiPrivate } from "../../api/axios";
import {
  CATALOG_CREATE_FAIL,
  CATALOG_CREATE_REQUEST,
  CATALOG_CREATE_REVIEW_FAIL,
  CATALOG_CREATE_REVIEW_REQUEST,
  CATALOG_CREATE_REVIEW_SUCCESS,
  CATALOG_CREATE_SUCCESS,
  CATALOG_DETAILS_FAIL,
  CATALOG_DETAILS_REQUEST,
  CATALOG_LIST_FAIL,
  CATALOG_LIST_SUCCESS,
  CATALOG_REVIEW_FAIL,
  CATALOG_REVIEW_REQUEST,
  CATALOG_REVIEW_SUCCESS,
  PROFILE_CATALOG_DELETE_FAIL,
  PROFILE_CATALOG_DELETE_REQUEST,
  PROFILE_CATALOG_DELETE_SUCCESS,
  PROFILE_CATALOG_EDIT_FAIL,
  PROFILE_CATALOG_EDIT_REQUEST,
  PROFILE_CATALOG_EDIT_SUCCESS,
  PROFILE_CATALOG_UPDATE_FAIL,
  PROFILE_CATALOG_UPDATE_REQUEST,
  PROFILE_CATALOG_UPDATE_SUCCESS,
  PROFILE_LIST_FAIL,
  PROFILE_LIST_REQUEST,
  PROFILE_LIST_SUCCESS,
} from "../constants/CatalogConstants";
import {
  CATALOG_LIST_REQUEST,
  CATALOG_DETAILS_SUCCESS,
} from "./../constants/CatalogConstants";
import { clearStorage } from "./UserAction";

// CATALOG ITEMS ACTION
export const catalogAction = (params) => async (dispatch) => {
  try {
    dispatch({
      type: CATALOG_LIST_REQUEST,
    });
    const { data } = await $api.get(`/catalogs`, {
      params,
    });
    dispatch({ type: CATALOG_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CATALOG_LIST_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// SINGLE CATALOG ITEM ACTION

export const singleCatalogItemAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CATALOG_DETAILS_REQUEST,
    });
    const { data } = await $api.get(`/catalogs/${id}`);
    dispatch({ type: CATALOG_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CATALOG_DETAILS_FAIL,
      payload: error.response?.data?.message ?? error.message,
    });
  }
};

//catalog create review

export const createCatalogReview =
  (productId, review, path) => async (dispatch) => {
    try {
      dispatch({ type: CATALOG_CREATE_REVIEW_REQUEST });

      await $apiPrivate.post(`/${path}/${productId}/review`, review);
      dispatch({ type: CATALOG_CREATE_REVIEW_SUCCESS });
    } catch (error) {
      const message = error.response?.data?.message ?? error.message;

      if (error.response.status === 401) {
        dispatch(clearStorage());
      }
      dispatch({
        type: CATALOG_CREATE_REVIEW_FAIL,
        payload: message,
      });
    }
  };

// CATALOG REVIEWS ACTION
export const catalogReviewsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: CATALOG_REVIEW_REQUEST,
    });
    const { data } = await $api.get(`/catalogs/reviews/all`);
    dispatch({ type: CATALOG_REVIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CATALOG_REVIEW_FAIL,
      payload: error.response?.data?.message ?? error.message,
    });
  }
};

// CREATE CATALOG ITEM
export const createCatalogItemAction = (params) => async (dispatch) => {
  try {
    dispatch({
      type: CATALOG_CREATE_REQUEST,
    });

    const { data } = await $apiPrivate.post(`/catalogs/`, params);

    dispatch({ type: CATALOG_CREATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: CATALOG_CREATE_FAIL,
      payload: message,
    });
  }
};

// GET ALL CATALOG ITEMS IN PROFILE
export const getAllCatalogItemsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_LIST_REQUEST,
    });

    const { data } = await $apiPrivate.get(`/catalogs/profile`);

    dispatch({ type: PROFILE_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: PROFILE_LIST_FAIL,
      payload: message,
    });
  }
};

// DELETE PROFILE CATALOG ITEM
export const deleteProfileCatalogItemAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_CATALOG_DELETE_REQUEST,
    });

    await $apiPrivate.delete(`/catalogs/${id}`);

    dispatch({ type: PROFILE_CATALOG_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: PROFILE_CATALOG_DELETE_FAIL,
      payload: message,
    });
  }
};

// EDIT PROFILE CATALOG ITEM
export const editProfileCatalogItemAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_CATALOG_EDIT_REQUEST,
    });
    const { data } = await $api.get(`/catalogs/${id}`);
    dispatch({ type: PROFILE_CATALOG_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: PROFILE_CATALOG_EDIT_FAIL,
      payload: message,
    });
  }
};

// UPDATE PROFILE CATALOG ITEM
export const updateProfileCatalogItemAction = (product) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_CATALOG_UPDATE_REQUEST,
    });

    const { data } = await $apiPrivate.put(`/catalogs/${product._id}`, product);

    dispatch({ type: PROFILE_CATALOG_UPDATE_SUCCESS, payload: data });
    dispatch({ type: PROFILE_CATALOG_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: PROFILE_CATALOG_UPDATE_FAIL,
      payload: message,
    });
  }
};
