import React, { useEffect } from "react";
import "./CatalogBrandsSection.scss";
import { newCollectionCatalogAction } from "./../../../../../redux/Actions/NewCollecctionActions";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import LoadingError from "../../../../common/errorMessage/LoadingError";
import { CatalogCard } from "../../../catalogCards/catalogCard/CatalogCard";
import searchSvg from "../../../../../assets/images/catalogImg/SearchSvg.svg";
import { Skeleton } from '@chakra-ui/react';

const CatalogBrandSection = ({ item }) => {
  const [favorite, setFavorite] = useOutletContext();
  const newCollectionCatalog = useSelector(
    (state) => state.newCollectionCatalog
  );

  const { loading, catalogs, error } = newCollectionCatalog;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(newCollectionCatalogAction());
  }, [dispatch]);

  const filteredProducts = catalogs
    ? catalogs?.filter((product) =>
        product.email ? product.email === item.email : null
      )
    : null;

  return (
    <section className="catalog-brands-section position-relative">
      {error && <LoadingError variant={alert()}>{error}</LoadingError>}
      {loading ? (
        [...new Array(5)].map((_, index) => (
          <Skeleton key={index} />
        ))
      ) : filteredProducts?.length ? (
        filteredProducts.map((item) => (
          <CatalogCard
            key={item._id}
            {...item}
            item={item}
            favorite={favorite}
            setFavorite={setFavorite}
          />
        ))
      ) : (
        <figure
          className="d-flex flex-column align-items-center justify-content-center position-absolute"
          style={{
            width: "250px",
            left: "0",
            right: "0",
            margin: "auto",
          }}
        >
          <img src={searchSvg} alt="Search" />
          <figcaption style={{ fontWeight: "700" }} className="text-center">
            По вашему запросу ничего не найдено!
          </figcaption>
        </figure>
      )}
    </section>
  );
};

export default CatalogBrandSection;
