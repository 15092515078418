import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import toggleMenu from "./../../../assets/images/homePageImg/toggle-menu.svg";
import closeMenu from "./../../../assets/images/homePageImg/closeMenu.svg";
import logoSvg from "./../../../assets/images/ManekenLogo.svg";
import LinkNav from "../../../hooks/LinkNav";
import { useSelector, useDispatch } from "react-redux";
import { DesktopHeader } from "./desktopHeader/DesktopHeader";
import { MobileHeader } from "./mobileHeader/MobileHeader";
import { HeaderUserDropDown } from "./headerUserDropdown/HeaderUserDropDown";
import { CartItemsExist } from "./cartItemsExist/CartItemsExist";
import { FavoriteExist } from "./favoriteExist/FavoriteExist";
import { SmallLogoSvg } from "../../../assets/svg/Svg";
import "./Header.scss";

import { MdFavoriteBorder } from "react-icons/md";
import { AiOutlineShoppingCart } from "react-icons/ai";
import HeaderCategoryBtn from "./headerCategoryBtn/HeaderCategoryBtn";
import { catalogAction } from "../../../redux/Actions/CatalogActions";
import HeaderSearch from "./headerSearch/HeaderSearch";
import CurrencyChoose from "./currencyChoose/CurrencyChoose";
import { routeTitles } from "../../../constants";
import Notifications from "./../../applicationsMain/chatContacts/notifications/Notifications";
import {
  selectActiveAgeGroup,
  selectActiveSort,
  selectCatalogCategory,
  selectFilterType,
  selectKeyword,
} from "../../../redux/slices/CatalogSlice";

export const Header = ({ context }) => {
  const [favorite] = context;
  const dispatch = useDispatch();

  const [active, setActive] = useState("Главная");
  const [toggle, setToggle] = useState(false);

  const cartAdded = useSelector((state) => state.cart);
  const { cartItems, constructorItems, fulfillment } = cartAdded;

  const selectedCategory = useSelector(selectCatalogCategory);
  const activeAgeGroup = useSelector(selectActiveAgeGroup);
  const activeSort = useSelector(selectActiveSort);
  const keyword = useSelector(selectKeyword);
  const activeFilterType = useSelector(selectFilterType);

  useEffect(() => {
    dispatch(
      catalogAction({
        keyword,
        category: selectedCategory,
        sortBy: activeSort.type,
        ageGroup: activeAgeGroup,
        filterByType: activeFilterType.type,
      })
    );
  }, [
    dispatch,
    keyword,
    activeAgeGroup,
    activeSort.type,
    selectedCategory,
    activeFilterType.type,
  ]);

  const location = useLocation();

  useEffect(() => {
    setActive(routeTitles[location.pathname] || "");
  }, [location.pathname]);

  return (
    <header className="header">
      <div className="container">
        <HeaderCategoryBtn />
        <nav className="header__brand__logo  d-flex align-items-center justify-content-start ">
          <LinkNav
            to="/"
            onClick={() => {
              setActive("");
            }}
          >
            <SmallLogoSvg />
            <img src={logoSvg} alt="Логотип" />
          </LinkNav>
        </nav>
        <div className="header__navs w-100  d-flex flex-column  align-items-center justify-content-center ">
          <DesktopHeader active={active} setActive={setActive} />
          <HeaderSearch />
        </div>

        <nav className="header__cart d-flex align-items-center justify-content-end gap-3 gap-lg-3  ">
          <MobileHeader
            toggle={toggle}
            active={active}
            setActive={setActive}
            setToggle={setToggle}
          />
          <Notifications />
          <Link to="/favorite">
            <MdFavoriteBorder size={31} color="#3c4151" />
            <FavoriteExist favorite={favorite} />
          </Link>

          <Link
            className="cart-svg"
            onClick={() => {
              setActive("");
            }}
            to="/cart"
          >
            <AiOutlineShoppingCart size={30} color="#3c4151" />{" "}
            <CartItemsExist
              cartItems={cartItems}
              constructorItems={constructorItems}
              fulfillment={fulfillment}
            />
          </Link>

          <CurrencyChoose />

          <HeaderUserDropDown setActive={setActive} />

          <img
            className="toggle__menu"
            src={toggle ? closeMenu : toggleMenu}
            alt="menu"
            onClick={() => setToggle(!toggle)}
          />
        </nav>
      </div>
    </header>
  );
};
