import React from "react";
import { Link } from "react-router-dom";

import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { TbWorldWww } from "react-icons/tb";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import { HiOutlineCursorArrowRipple } from "react-icons/hi2";

import { ProductionPriceInfoSection } from "../../productionPage/productionMain/productionPriceInfoSection/ProductioPriceInfoSection";

const ProductionInfoPageMAin = ({ productionItem }) => {
  return (
    <Tabs variant="enclosed">
      <TabList>
        <Tab>
          <strong>Специализация</strong>
        </Tab>
        <Tab>
          <strong>Товары</strong>
        </Tab>
        <Tab>
          <strong>Фото производства</strong>
        </Tab>
        <Tab>
          <strong>Контакты</strong>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel height="80%">
          <div className="productionInfoPage__spec d-flex gap-3 flex-column flex-md-row">
            {Object.keys(productionItem).length > 0 && (
              <ProductionPriceInfoSection catalogs={productionItem} />
            )}

            <div className="productionInfoPage__ads d-flex flex-column gap-2">
              {productionItem?.adsCards?.map((el) => (
                <div
                  key={el._id}
                  className="d-flex flex-column align-items-center justify-content-end"
                  style={{
                    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${el.img}')`,
                  }}
                >
                  <React.Fragment>
                    <h2>{el.textHeader}</h2>
                    <p>{el.textDescription}</p>
                  </React.Fragment>
                </div>
              ))}

              <Link
                to={`https://api.whatsapp.com/send/?phone=${productionItem?.productionPhoneNumber}`}
                target="target_blank"
                className="animated-button d-flex align-items-center gap-2 justify-content-center position-relative "
              >
                <FaWhatsapp /> Связаться с{" "}
                {productionItem?.brandInfo?.brandName}
                <HiOutlineCursorArrowRipple size="30px" color="#118638" />
              </Link>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div
            className={`${
              productionItem?.productionCatalog?.length
                ? "productionInfoPage__images"
                : ""
            }`}
          >
            {productionItem?.productionCatalog?.length ? (
              productionItem?.productionCatalog?.map((el, index) => (
                <figure key={el._id}>
                  <Link to={`/constructor?activeSlide=${index}`}>
                    <img src={el.modelImg} alt={el.title} />
                  </Link>
                </figure>
              ))
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                <p className="w-100 mb-0 text-center ">Товары не добавлены!</p>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div
            className={`${
              productionItem?.brandInfo?.brandImage?.length
                ? "productionInfoPage__images"
                : ""
            }`}
          >
            {productionItem?.brandInfo?.brandImage?.length ? (
              productionItem?.brandInfo?.brandImage?.map((el, index) => (
                <figure key={`gallery-${index}`}>
                  <img src={el} alt="Производство" />
                </figure>
              ))
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                <p className="w-100 mb-0 text-center">Товары не добавлены!</p>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="d-flex align-items-start flex-column gap-3">
            <div className="d-flex align-items-center gap-2">
              <FaPhone /> <strong>Телефон:</strong>{" "}
              <Link to={`tel:${productionItem.productionPhoneNumber}`}>
                {productionItem.productionPhoneNumber}
              </Link>
            </div>
            {productionItem?.brandInfo?.brandName === "Maneken" ? (
              <div className="d-flex align-items-center gap-2">
                <FaPhone /> <strong>Телефон:</strong>{" "}
                <Link to="tel:+ 996 772 12 21 11">+ 996 772 12 21 11</Link>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex align-items-center gap-2">
              <MdOutlineAlternateEmail /> <strong>Email:</strong>{" "}
              <Link to={`mailto:${productionItem.email}`}>
                {productionItem.email}
              </Link>
            </div>
            <div className="d-flex align-items-center gap-2">
              <TbWorldWww /> <strong>Сайт:</strong>{" "}
              {productionItem?.webUrl ? (
                <Link
                  to={`https://${productionItem?.webUrl}`}
                  target="target_blank"
                >
                  {productionItem?.webUrl}
                </Link>
              ) : (
                "нет данных для отображения"
              )}
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ProductionInfoPageMAin;
