export const getCartInitialValues = (savedShippingAddress) => {
  return {
    selectedPickupPointInfo: "",
    selectedSettlement: "",
    selectedKgSettlement: "",
    paymentMethod: "",
    selectedService: "",
    country: "",
    city: "",
    address: "",
    nameLastName: savedShippingAddress.nameLastName || "",
    phoneNumber: savedShippingAddress.phoneNumber || "",
    additionalInfo: savedShippingAddress.additionalInfo || "",
    alfaCargoPrice: 0,
    redExpressCargoPrice: 0,
    searchCityCode: 44,
    searchTerm: "",
  };
};
