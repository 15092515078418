import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  selectCurrency,
  setSelectedCurrency,
} from "../../../../redux/slices/CatalogSlice";
import { useDispatch, useSelector } from "react-redux";

const CurrencyChoose = () => {
  const dispatch = useDispatch();
  const selectedCurrency = useSelector(selectCurrency);

  const handleCurrencyChange = (currency) => {
    dispatch(setSelectedCurrency(currency));
  };

  useEffect(() => {
    localStorage.setItem("selectedCurrency", JSON.stringify(selectedCurrency));
  }, [selectedCurrency]);

  const currencies = ["KGS", "RUB"];

  return (
    <Menu>
      <MenuButton as={Button} color="#3c4151" rightIcon={<ChevronDownIcon />}>
        {selectedCurrency}
      </MenuButton>
      <MenuList>
        {currencies.map((currency) => (
          <MenuItem
            key={currency}
            onClick={() => handleCurrencyChange(currency)}
          >
            {currency}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default CurrencyChoose;
