import { ContuctUs } from "../components/common/callUs/ContuctUs";
import React, { useEffect, memo, Suspense, lazy } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EmptyCart } from "../components/common/emptyCart/EmptyCart";
import { CartItems } from "../components/shoppingCart/cartItems/CartItems";
import { CartItemsTotal } from "../components/shoppingCart/cartItems/cartItemsTotal/CartItemsTotal";
import { CartItemsHeader } from "../components/shoppingCart/cartItems/cartItemsHeader/CartItemsHeader";

import "./styles/ShoppingCart.scss";
import { Loader } from "../components/common/loader/Loader";
import { useCdekItems } from "../hooks/useCdekItems";
import { Seo } from "../seo/Seo";
import { useFormik } from "formik";
import useTotalPrice from "../hooks/useTotalPrice";
import { cartValidate } from "../helper/CartValidate";
import useCdekTariffCalculator from "../hooks/useCdekTariffCalculator";
import { getCartInitialValues } from "../helper/getCartInitValues";
import { handleSubmit } from "../helper/CartFormHandleSubmit";
import { handleOrderCreation } from "../helper/CartOrderCreation";
import { handleFormikValues } from "../helper/CartFormValuesSave";
import { handleCartAddition } from "../helper/CartAddItems";

const DeliveryMethods = lazy(() =>
  import("../components/shoppingCart/deliveryMethods/DeliveryMethods")
);

const ShoppingCart = memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, colors, activeColors, additionaly, selectedOptionIndex } =
    useParams();

  const cartAdded = useSelector((state) => state.cart);
  const { cartItems, constructorItems, fulfillment } = cartAdded;

  const savedShippingAddress = useSelector(
    (state) => state.cart.shippingAddress
  );

  const cartTotalPrice = useSelector((state) => state.cartTotalPrice);
  const {
    cdekDeliveryPrice,
    alfaCargoDeliveryPrice,
    redExpressDeliveryPrice,
    yldamExressPrice,
    total,
    combinedItemsPrice,
  } = cartTotalPrice;

  const createCdekDelivery = useSelector((state) => state.createCdekDelivery);
  const { loading: cdekDeliveryLoading, error: cdekDeliveryError } =
    createCdekDelivery;

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error, loading } = orderCreate;

  const totalPriceData = useTotalPrice({
    total,
  });
  const { priceInRub } = totalPriceData;

  const { cdekItems, totalDimensions, packageCounter } = useCdekItems(
    cartItems,
    constructorItems
  );

  const formik = useFormik({
    initialValues: getCartInitialValues(savedShippingAddress),
    validate: cartValidate,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) =>
      handleSubmit(
        values,
        dispatch,
        cartItems,
        constructorItems,
        fulfillment,
        savedShippingAddress,
        cdekDeliveryPrice,
        alfaCargoDeliveryPrice,
        redExpressDeliveryPrice,
        yldamExressPrice,
        combinedItemsPrice,
        total,
        priceInRub
      ),
  });

  useEffect(() => {
    handleFormikValues(formik.values, dispatch);
  }, [dispatch, formik.values]);

  useEffect(() => {
    handleCartAddition(
      dispatch,
      id,
      activeColors,
      colors,
      additionaly,
      selectedOptionIndex,
      location.search
    );
  }, [
    dispatch,
    id,
    activeColors,
    colors,
    additionaly,
    selectedOptionIndex,
    location.search,
  ]);

  const { tariffCodeChoose, locationData } = useCdekTariffCalculator(
    dispatch,
    formik,
    totalDimensions
  );

  useEffect(() => {
    handleOrderCreation(
      order,
      success,
      tariffCodeChoose,
      locationData,
      dispatch,
      navigate,
      packageCounter,
      cdekItems,
      totalDimensions
    );
  }, [
    dispatch,
    navigate,
    order,
    cdekItems,
    totalDimensions,
    packageCounter,
    locationData,
    tariffCodeChoose,
    success,
  ]);

  return (
    <section className="shopping-cart">
      <Seo
        title="Корзина"
        name="Манекен"
        type="product"
        keywords="Maneken, Манекен, Одежда оптом, производство одежды"
      />
      {cartItems.length || constructorItems.length || fulfillment.length ? (
        <div className="container">
          <CartItemsHeader />
          <CartItems
            cartItems={cartItems}
            constructorItems={constructorItems}
            fulfillment={fulfillment}
          />
          <form
            className="delivery-payment-container row mx-0 gap-3 px-0 flex-column flex-lg-row"
            onSubmit={formik.handleSubmit}
          >
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <DeliveryMethods
                totalDimensions={totalDimensions}
                formik={formik}
              />
            </Suspense>

            <CartItemsTotal
              formik={formik}
              loading={loading}
              cdekDeliveryLoading={cdekDeliveryLoading}
              error={error}
              cdekDeliveryError={cdekDeliveryError}
              totalDimensions={totalDimensions}
            />
          </form>
        </div>
      ) : (
        <EmptyCart />
      )}
      <ContuctUs />
    </section>
  );
});

export default ShoppingCart;
