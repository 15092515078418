import React from "react";

const ProductAddHeader = ({ loading }) => {
  return (
    <div className="content-header d-flex justify-content-center">
      <button type="submit" className="btn btn-primary">
        {loading ? "Идёт загрузка..." : "Опубликовать"}
      </button>
    </div>
  );
};

export default ProductAddHeader;
