import {
  CART_ADD_ITEM,
  CART_CLEAR_ITEMS,
  CART_DECREASE_ITEM,
  CART_PAYMENT_METHOD,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  UPDATE_CART_DATA,
} from "../constants/CartConstants";
import { CART_INCREASE_ITEM } from "./../constants/CartConstants";
import {
  CONSTRUCTOR_CART_DECREASE_ITEM,
  CONSTRUCTOR_CART_INCREASE_ITEM,
} from "./../constants/ConstructorCart";
import { $api } from "../../api/axios";

export const addToCart =
  (
    id,
    qty,
    colors,
    additionaly,
    path,
    sampleSize,
    selectedActiveSize,
    qtyOfGoodsInPack,
    isWholesale,
    hasSample
  ) =>
  async (dispatch) => {
    const { data } = await $api.get(`/${path}/${id}`);

    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        item: data._id,
        title: data.title,
        owner: data.owner,
        orderFrom: data.orderFrom,
        image: data.image,
        price: data.price,
        sizes: selectedActiveSize,
        sampleSize,
        articul: data.articul,
        textile: data.textile,
        countInStock: data.countInStock,
        color: colors,
        qty: qty,
        additionaly: additionaly,
        url: data.url,
        weight: data.weight,
        samplePrice: data.samplePrice,
        qtyOfGoodsInPack,
        isWholesale,
        hasSample,
      },
    });
  };

export const removeItem = (item) => (dispatch) => {
  dispatch({ type: CART_REMOVE_ITEM, payload: item });
};

export const increaseQty = (id) => (dispatch) => {
  dispatch({ type: CART_INCREASE_ITEM, payload: id });
};

export const decreaseQty = (id) => (dispatch) => {
  dispatch({ type: CART_DECREASE_ITEM, payload: id });
};

export const constructorIncreaseQty = (id) => (dispatch) => {
  dispatch({ type: CONSTRUCTOR_CART_INCREASE_ITEM, payload: id });
};

export const decreaseQtyConstructor = (id) => (dispatch) => {
  dispatch({ type: CONSTRUCTOR_CART_DECREASE_ITEM, payload: id });
};

export const clearCart = () => (dispatch) => {
  dispatch({
    type: CART_CLEAR_ITEMS,
  });
};

//SAVE SHIPPING ADDRESS
export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_SHIPPING_ADDRESS, payload: data });
};

//SAVE PAYMENT METHOD
export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({ type: CART_PAYMENT_METHOD, payload: data });
};

// CART TOTAL PRICE SUM WITH DELIVERY PRICE
export const calculateCartPrice = (cartData) => (dispatch) => {
  dispatch({ type: UPDATE_CART_DATA, payload: cartData });
};
