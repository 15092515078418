import { Button } from "../common/button/Button";

import "./DesignMain.scss";
import { CustomTable } from "../common/customTable/CustomTable";
import { designPriceList } from "../../constants/designPageData";
import {
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { selectCurrency } from "../../redux/slices/CatalogSlice";
import { useSelector } from "react-redux";

const DesignMain = ({ catalogs }) => {
  const selectedCurrency = useSelector(selectCurrency);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <section className="production__design__wrapper" id={catalogs._id}>
      <Card
        direction={{ base: "column", md: "row" }}
        overflow="hidden"
        variant="outline"
        className="container"
      >
        <Image
          objectFit="cover"
          src="https://res.cloudinary.com/drdqjr8r6/image/upload/v1691778909/bg%20images/disign-img_xi5khh.jpg"
          alt="Maneken"
        />

        <Stack>
          <CardBody>
            <Heading size="md">MANEKEN</Heading>
            <div className="d-flex align-items-center flex-wrap gap-1">
              <span>Специализация:</span>
              {designPriceList?.slice(0, 7).map((el, index, array) => (
                <span key={index}>
                  {el.text}
                  {index < array.length - 1 ? "," : ""}
                </span>
              ))}
              {designPriceList?.length > 7 && "..."}
              <Button onClick={onOpen}>Показать все</Button>
            </div>

            <Modal isOpen={isOpen} onClose={onClose} size="xl">
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                  <CustomTable
                    data={[
                      {
                        id: 1,
                        text: "№ 1",
                        from: "разработка лекал",
                      },
                      {
                        id: 2,
                        text: "№ 2",
                        from: "пошив образца",
                      },
                    ]}
                    thead1="Услуга"
                    thead2="Название"
                  />
                  <CustomTable
                    data={designPriceList}
                    thead1="Прайс"
                    thead2={selectedCurrency === "KGS" ? "KGS" : "RUB"}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          </CardBody>

          <CardFooter className="gap-2 pt-0 justify-content-around justify-content-md-start  ">
            <Link
              to="https://api.whatsapp.com/send/?phone=996772122111"
              target="target_blank"
              className="d-flex align-items-center gap-2 justify-content-center"
            >
              <FaWhatsapp color="green" /> Связаться
            </Link>
          </CardFooter>
        </Stack>
      </Card>
    </section>
  );
};

export default DesignMain;
