import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getApplicationsAction } from "../../../redux/Actions/ApplicationsAction";
import { productionCatalogAction } from "../../../redux/Actions/ProductionAction";

import "./IncrementingCounter.scss";

const IncrementingCounter = memo(({ items, userCount }) => {
  const dispatch = useDispatch();

  const productionCatalog = useSelector((state) => state.productionCatalog);
  const { catalogs } = productionCatalog;

  const lists = useSelector((state) => state.applications.lists);

  useEffect(() => {
    const counters = document.querySelectorAll(".counter");

    counters.forEach((counter) => {
      counter.innerText = "0";

      const updateCounter = () => {
        const target = +counter.getAttribute("data-target");
        const c = +counter.innerText;

        const increment = target / 200;

        if (c < target) {
          counter.innerText = `${Math.ceil(c + increment)}`;
          if (!document.contains(counter)) return;
          setTimeout(updateCounter, 1);
        } else {
          counter.innerText = target;
        }
      };

      updateCounter();
    });

    return () => {
      counters.forEach((counter) => {
        const timeoutId = counter.dataset.timeoutId;
        if (timeoutId) clearTimeout(parseInt(timeoutId));
      });
    };
  }, []);
  
  useEffect(() => {
    dispatch(productionCatalogAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getApplicationsAction());
  }, [dispatch]);

  const cardsData = [
    {
      imgSrc:
        "https://res.cloudinary.com/drdqjr8r6/image/upload/v1706664867/bg%20images/bag-dynamic-color_atjory.svg",
      alt: "Товаров",
      value: items?.length || 0,
      counterName: "items",
      label: "Товары",
    },
    {
      imgSrc:
        "https://res.cloudinary.com/drdqjr8r6/image/upload/v1706613557/bg%20images/boy_ifcblu.svg",
      alt: "Пользователей",
      value: 270 + userCount,
      counterName: "usersCount",
      label: "Пользователи",
    },
    {
      imgSrc:
        "https://res.cloudinary.com/drdqjr8r6/image/upload/v1706613557/bg%20images/axe_zebr6o.svg",
      alt: "Производств",
      value: catalogs?.length || 1,
      counterName: "production",
      label: "Производства",
    },
    {
      imgSrc:
        "https://res.cloudinary.com/drdqjr8r6/image/upload/v1706613558/bg%20images/chat_dxg7bp.svg",
      alt: "email",
      value: lists?.length || 0,
      counterName: "requests",
      label: "Заявки",
    },
    {
      imgSrc:
        "https://res.cloudinary.com/drdqjr8r6/image/upload/v1706613557/bg%20images/scissor_niui9j.svg",
      alt: "fabric",
      value: 0,
      counterName: "fabrics",
      label: "Ткани",
    },
  ];

  return (
    <div className="incrementing-counter">
      <div className="container px-2 p-md-0 d-flex align-items-center gap-2">
        {cardsData.map((card, index) => (
          <div className="counter-container" key={index}>
            <span>{card.label}</span>
            <div className="counter" data-target={card.value}></div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default IncrementingCounter;
