import { FcRuler } from "react-icons/fc";
import applicationBg from "../assets/images/homePageImg/applications.jpg";
import aboutUsBg from "../assets/images/homePageImg/aboutUs.jpg";
import ffBg from "../assets/images/homePageImg/ff.jpg";
import constructorBg from "../assets/images/homePageImg/constructor.jpg";
import contactsBg from "../assets/images/homePageImg/contacts.jpg";
import productionBg from "../assets/images/homePageImg/production.jpg";
import designBg from "../assets/images/homePageImg/designPage.jpg";
import catalogsBg from "../assets/images/homePageImg/catalogs.jpg";
import cooperationBg from "../assets/images/homePageImg/cooperation.jpg";
import deliveryBg from "../assets/images/homePageImg/delivery.jpg";
// import fabricBg from "../assets/images/homePageImg/fabric.jpg";

export const sellersNavsLinks = [
  {
    id: "cooperation",
    title: "Условия сотрудничества",
    img: cooperationBg,
  },
  {
    id: "delivery",
    title: "Доставка",
    img: deliveryBg,
  },
];

export const buyersNavsLinks = [
  {
    id: "applications",
    title: "Заявки",
    img: applicationBg,
  },
  {
    id: "designWorks",
    title: "Дизайн отдел",
    icon: <FcRuler />,
    img: designBg,
  },
  {
    id: "production",
    title: "Производство",
    img: productionBg,
  },
  {
    id: "constructor",
    title: "Конструктор",
    img: constructorBg,
  },
  {
    id: "fulfillment",
    title: "Фулфилмент",
    img: ffBg,
  },
  // {
  //   id: "fabrics",
  //   title: "Ткани",
  //   img: fabricBg,
  // },
  {
    id: "about",
    title: "О нас",
    img: aboutUsBg,
  },
  {
    id: "contact",
    title: "Контакты",
    img: contactsBg,
  },
];

export const navsLinks = [
  {
    id: "catalogs",
    title: "Товары",
    img: catalogsBg,
  },
  {
    id: "designWorks",
    title: "Дизайн отдел",
    img: designBg,
  },
  {
    id: "production",
    title: "Производство",
    img: productionBg,
  },
  {
    id: "constructor",
    title: "Конструктор",
    img: constructorBg,
  },
  {
    id: "fulfillment",
    title: "Фулфилмент",
    img: ffBg,
  },
  // {
  //   id: "fabrics",
  //   title: "Ткани",
  //   img: fabricBg,
  // },
  // {
  //   id: "delivery",
  //   title: "Доставка",
  //   img: deliveryBg,
  // },
  {
    id: "applications",
    title: "Заявки",
    img: applicationBg,
  },
  {
    id: "about",
    title: "О нас",
    img: aboutUsBg,
  },
  {
    id: "contact",
    title: "Контакты",
    img: contactsBg,
  },
];
