import { Link } from "react-router-dom";
import favSvg from "./../../../../assets/images/catalogImg/favorite.svg";
import clickedFavSvg from "./../../../../assets/images/catalogImg/favoriteClicked.svg";
import React, { useState, useEffect } from "react";
import { CurrencyConverter } from "../../../common/currencyConverter/CurrencyConverter";
import { useSelector } from "react-redux";
import { selectCurrency } from "../../../../redux/slices/CatalogSlice";
import SaleType from "../../../common/catalogCardSaleType/SaleType";

export const CatalogCard = ({ title, item, setFavorite, favorite }) => {
  const selectedCurrency = useSelector(selectCurrency);
  const [activeFavorite, setActiveFavorite] = useState(false);

  useEffect(() => {
    // Проверяем, есть ли объект в избранном в локальном хранилище
    const isItemInFavorites = favorite.some(
      (favoriteItem) => favoriteItem._id === item._id
    );
    setActiveFavorite(isItemInFavorites);
  }, [favorite, item._id]);

  //! Функция добавления товара в избранное
  const addFavorites = (item) => {
    // Проверяем, есть ли объект в избранном
    const isItemInFavorites = favorite.some(
      (favoriteItem) => favoriteItem._id === item._id
    );
    if (!isItemInFavorites) {
      setFavorite((prevFavorite) => [...prevFavorite, item]);
      setActiveFavorite(true);
      saveFavoritesToLocalStorage([...favorite, item]);
    }
  };

  //! Функция удаления товара из избранного
  const removeFavorites = (itemId) => {
    setFavorite(favorite.filter((f) => f._id !== itemId));
    setActiveFavorite(false);
    saveFavoritesToLocalStorage(favorite.filter((f) => f._id !== itemId));
  };

  //! Функция сохранения избранных товаров в локальное хранилище
  const saveFavoritesToLocalStorage = (favorites) => {
    localStorage.setItem("favorites", JSON.stringify(favorites));
  };

  const selectFavoriteItem = () => {
    if (activeFavorite) {
      removeFavorites(item._id);
    } else {
      addFavorites(item);
    }
  };
  return (
    <div className="catalog__card position-relative">
      <img
        src={activeFavorite ? clickedFavSvg : favSvg}
        alt="Избранное"
        className={`favorite ${activeFavorite ? "active" : ""}`}
        onClick={selectFavoriteItem}
      />
      <p className="new-clothes">{item.newItem ? item.newItem : ""}</p>
      <div className="catalog__card__image">
        <Link to={`/catalogs/${item._id.toString()}`}>
          <img
            style={{ objectFit: "cover", height: "100%", width: "100%" }}
            src={item.image}
            alt="Картинка товара"
          />
        </Link>
      </div>
      <div
        className="catalog__card_description d-flex justify-content-start align-items-start 
      flex-column"
      >
        <div className="saleType mt-1 d-flex gap-1">
          <SaleType item={item} />
          {item.hasSample ? <p>Образец</p> : ""}
        </div>
        <h4 className="ellipsis-text">{title}</h4>
        <p>
          {selectedCurrency === "KGS" ? (
            <strong>{item.price} KGS</strong>
          ) : (
            <CurrencyConverter total={item.price} />
          )}
          <strong className="oldPrice">
            {item.oldPrice !== "0" ? item.oldPrice + " сом" : ""}
          </strong>
        </p>
      </div>
    </div>
  );
};
