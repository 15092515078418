import React, { useEffect } from "react";
import Tilt from "react-parallax-tilt";
import { useDispatch, useSelector } from "react-redux";
import { productionAdsCardsAction } from "../../../redux/Actions/ProductionAction";
import { Skeleton } from "@chakra-ui/react";
import LoadingError from "../../common/errorMessage/LoadingError";
import { Link } from "react-router-dom";
const ProductionCards = () => {
  const dispatch = useDispatch();

  const productionAdsCards = useSelector((state) => state.productionAdsCards);
  const { loading, error, adsCards } = productionAdsCards;
  useEffect(() => {
    dispatch(productionAdsCardsAction());
  }, [dispatch]);

  return (
    <section className="production-cards d-lg-flex align-items-lg-center justify-content-lg-between container pt-3  gap-lg-2 ">
      {loading ? (
        [...new Array(5)].map((_, index) => (
          <Skeleton className="production-cards__card" key={index} />
        ))
      ) : error ? (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center ">
          <LoadingError variant="alert-danger">{error}</LoadingError>
        </div>
      ) : (
        <>
          {adsCards?.map((el) => (
            <Tilt
              key={el._id}
              className="production-cards__card"
              style={{ backgroundImage: `url(${el?.img})` }}
              glarePosition="all"
              glareEnable="true"
              glareColor="#ffffff"
              glareMaxOpacity="0.8"
            >
              <Link
                to={`https://maneken.shop${el.link} `}
                target="_blank"
              ></Link>
            </Tilt>
          ))}
        </>
      )}
    </section>
  );
};

export default ProductionCards;
