import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../socket";
import {
  selectChatCompare,
  selectFatchAgain,
  selectMessages,
  selectNotifications,
  setFetchAgain,
  setMessages,
  setNotifications,
} from "../redux/slices/ChatSlice";

const useMessageHandler = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const messages = useSelector(selectMessages);
  const fetchAgain = useSelector(selectFatchAgain);
  const selectedChatCompare = useSelector(selectChatCompare);

  useEffect(() => {
    const handleMessageReceived = (newMessageReceived) => {
      if (
        !selectedChatCompare ||
        selectedChatCompare._id !== newMessageReceived.chat._id
      ) {
        if (!notifications.includes(newMessageReceived)) {
          dispatch(setNotifications([newMessageReceived, ...notifications]));
          dispatch(setFetchAgain(!fetchAgain));
        }
      } else {
        const messageExists = messages.some(
          (msg) => msg._id === newMessageReceived._id
        );
        if (!messageExists) {
          dispatch(setMessages([...messages, newMessageReceived]));
        }
      }
    };

    socket.on("message recieved", handleMessageReceived);

    return () => {
      socket.off("message recieved", handleMessageReceived);
    };
  }, [dispatch, notifications, messages, fetchAgain, selectedChatCompare]);
};

export default useMessageHandler;
