import React from "react";
import { Breadcrumbs } from "../../common/breadCrumbs/Breadcrumbs";

const DesignDescription = () => {
  return (
    <section className="design__description">
      <section className="container">
        <div>
          <h1>Дизайн отдел</h1>
          <Breadcrumbs />
          <p>
            Наша команда дизайнеров и отдела по разработке занимается созданием
            уникальных и привлекательных дизайнов для наших клиентов. Мы
            исследуем последние тенденции моды, анализируем цветовые палитры и
            формы, чтобы создавать модные и стильные варианты. Мы также
            учитываем индивидуальные предпочтения каждого клиента, чтобы
            разработать дизайн, отражающий его уникальность и стиль. Наш отдел
            стремится создать привлекательные и эстетически приятные дизайны,
            которые будут отличаться от обычных и подчеркнут вашу
            индивидуальность.
          </p>
        </div>
      </section>
    </section>
  );
};

export default DesignDescription;
