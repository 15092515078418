import React from "react";

import { MdOutlineDeleteForever } from "react-icons/md";

const ProductColors = ({
  setColors,
  colors,
  productionColor,
  setProductionColor,
  productionColorError,
  productionModelColorError,
  fieldType,
}) => {
  const handleRemoveImage = (colorToRemove) => {
    const updatedColors = colors.filter(
      (colorObj) => colorObj !== colorToRemove
    );
    if (colors) {
      setColors(updatedColors);
    }
  };

  const handleRemoveColor = () => {
    setProductionColor("");
  };

  return (
    <section className="mb-4">
      {fieldType === "ProductImages" ? (
        <p>Список цветов товара по картинкам:</p>
      ) : (
        <p>Список цветов товара:</p>
      )}

      <div
        className={`product__color__wrapper ${
          productionColorError || productionModelColorError
            ? "cart-border-error"
            : ""
        }`}
      >
        {colors?.length ? (
          <div className="gap-1">
            {colors.map((colorObj, index) => (
              <div
                key={index}
                style={{
                  height: "70px",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    backgroundColor: `${colorObj.color} `,
                    backgroundImage: `url(${colorObj.img})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    height: "70px",
                    width: "100%",
                    display: "inline-block",
                    borderRadius: "4px",
                  }}
                ></span>

                <MdOutlineDeleteForever
                  onClick={() => handleRemoveImage(colorObj)}
                />
              </div>
            ))}
          </div>
        ) : productionColor ? (
          <div className="gap-1">
            <div
              style={{
                height: "70px",
                position: "relative",
              }}
            >
              <span
                style={{
                  backgroundColor: `${productionColor} `,
                  height: "70px",
                  width: "100%",
                  display: "inline-block",
                  borderRadius: "4px",
                }}
              ></span>

              <MdOutlineDeleteForever
                onClick={() => handleRemoveColor(productionColor)}
              />
            </div>
          </div>
        ) : (
          <React.Fragment>
            <p className="text-center mb-0">Нет выбранных цветов</p>
            {fieldType === "ProductImages" ? (
              <p className="text-center mb-0">
                Примечание: Цвета вы можете выбрать нажимая кнопку "Цвет" на
                картинках выше!
              </p>
            ) : (
              <p className="text-center mb-0">
                Примечание: Цвета вы можете выбрать наводя курсор на картинку
                товара!
              </p>
            )}
          </React.Fragment>
        )}
      </div>
      {productionColorError && (
        <p className="cart-error-message">{productionColorError}</p>
      )}
      {productionModelColorError && (
        <p className="cart-error-message">{productionModelColorError}</p>
      )}
    </section>
  );
};

export default ProductColors;
