import { useEffect, useState } from 'react';
import { ConstructorOrderSteps } from '../../../constants/profilePageOrderStepsData';
import StepsUi from './StepsUi';

const ConstructorStep = ({ order }) => {
  const [activeStepConstructor, setActiveStepConstructor] = useState(1);

  const {
    isBuyTextile,
    isСutTextile,
    isSewing,
    isIron,
    isMarriageCheck,
    isPackage,
    isPreparingForShipping,
  } = order.constructorStepsInfo;

  const totalSteps = ConstructorOrderSteps.length;
  const width = `${(100 / (totalSteps - 1)) * (activeStepConstructor - 1)}%`;

  useEffect(() => {
    if (order.orderItems.some((el) => el.orderFrom === 'constructor')) {
      if (!order.isPaid) {
        setActiveStepConstructor(1);
      } else if (!isBuyTextile) {
        setActiveStepConstructor(2);
      } else if (!isСutTextile) {
        setActiveStepConstructor(3);
      } else if (!isSewing) {
        setActiveStepConstructor(4);
      } else if (!isIron) {
        setActiveStepConstructor(5);
      } else if (!isMarriageCheck) {
        setActiveStepConstructor(6);
      } else if (!isPackage) {
        setActiveStepConstructor(7);
      } else if (!isPreparingForShipping) {
        setActiveStepConstructor(8);
      } else if (!order.isDelivered) {
        setActiveStepConstructor(9);
      }
    }
  }, [
    isBuyTextile,
    isIron,
    isMarriageCheck,
    isPackage,
    isPreparingForShipping,
    isSewing,
    isСutTextile,
    order.isDelivered,
    order.isPaid,
    order.orderItems,
  ]);
  return (
    <>
      {order.orderItems.some((el) => el.orderFrom === 'constructor') ? (
        <>
          <h6>Товары из конструктора или производства на стадии:</h6>
          <section className="progress-steps__container mb-5" style={{ '--progress-width': width }}>
            {ConstructorOrderSteps.map((el) => (
              <StepsUi key={el.step} activeStep={activeStepConstructor} el={el} {...el} />
            ))}
          </section>
        </>
      ) : null}
    </>
  );
};

export default ConstructorStep;
