import React from "react";
import { GreenMarker } from "../../../../assets/svg/Svg";

export const FavoriteExist = ({ favorite }) => {
  return (
    <>
      {favorite.length > 0 ? (
        <div className="header__cart__count d-flex align-items-center justify-content-center ">
          <GreenMarker />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
