import { Link } from "react-router-dom";

import Slider from "react-slick";

import "./About.scss";

import { Button } from "./../../common/button/Button";

import { aboutSettings } from "../../../constants/reactSlickSettingsData";
import { aboutRightCards } from './../../../constants/index';

export const About = () => {
  return (
    <section
      className="container about-block-bg d-flex gap-1 gap-md-2 p-2 p-md-0"
      id="about"
    >
      <div className="about-left-slider">
        <Slider {...aboutSettings}>
          <div className="about-bg one d-flex flex-column align-items-center justify-content-center ">
            <div className="d-flex justify-content-between ">
              <div className="d-flex flex-column justify-content-center">
                <h1>Манекен - Мир оптовых возможностей!</h1>
                <Button to="/catalogs">Сделать покупку</Button>
              </div>
              <figure className="d-flex align-items-center justify-content-center h-100">
                <Link to="/about">
                  <img
                    title="О нас"
                    src="https://res.cloudinary.com/drdqjr8r6/image/upload/v1700725251/bg%20images/azazefir_an_image_of_3d_clothes_dummy_86e157ef-086e-43d4-9d31-7983826fe35d_1_nrcfme.svg"
                    alt="Манекен"
                  />
                </Link>
              </figure>
            </div>
          </div>
          <div className="about-bg two d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <h1>
                Присоединяйтесь к нам и дайте миру возможность
                полюбить ваш продукт!
              </h1>
            </div>
          </div>
        </Slider>
      </div>
      <div className="about-right-cards d-flex flex-column gap-1 gap-md-2 ">
        {aboutRightCards?.map((el) => (
          <div key={el.id}>
            <figure>
              <Link
                to={`/${el.link}`}
                className="d-flex align-items-center justify-content-center w-100 "
              >
                <img src={el.img} alt={el.name} title={el.name} />
                <figcaption>{el.name}</figcaption>
              </Link>
            </figure>
          </div>
        ))}
      </div>
    </section>
  );
};
