export const validateAddedColorImages = (
  productionModelImage,
  productionModelFlippedImage,
  productionModelColor,
  setProductionModelImageError,
  setProductionModelFlippedImageError,
  setProductionModelColorError,
  productionModelColors
) => {
  let isValid = true;
  if (productionModelColors.length >= 0) {
    if (!productionModelImage || !productionModelImage.trim()) {
      setProductionModelImageError("Загрузите изображение товара, вид спереди");
      isValid = false;
    } else {
      setProductionModelImageError("");
    }

    if (!productionModelFlippedImage || !productionModelFlippedImage.trim()) {
      setProductionModelFlippedImageError(
        "Загрузите изображение товара, вид сзади"
      );
      isValid = false;
    } else {
      setProductionModelFlippedImageError("");
    }

    if (!productionModelColor || !productionModelColor.trim()) {
      setProductionModelColorError("Введите цвет товара");
      isValid = false;
    } else {
      setProductionModelColorError("");
    }
  }

  return isValid;
};

export const validateAddedCatalogItems = (
  productionTitle,
  productionPrice,
  productionMainImage,
  productionSizes,
  productionTextiles,
  productionModelImage,
  productionModelFlippedImage,
  productionModelColor,
  setProductionTitleError,
  setProductionPriceError,
  setProductionMainImageError,
  setProductionSizesError,
  setProductionTextilesError,
  setProductionModelImageError,
  setProductionModelFlippedImageError,
  setProductionModelColorError,
  productionModelColors
) => {
  let isValid = true;
  if (!productionTitle || !productionTitle.trim()) {
    setProductionTitleError("Введите название товара");
    isValid = false;
  } else {
    setProductionTitleError("");
  }
  if (!productionPrice || !productionPrice.trim()) {
    setProductionPriceError("Введите цену товара");
    isValid = false;
  } else {
    setProductionPriceError("");
  }
  if (!productionTextiles.length) {
    setProductionTextilesError("Введите название ткани");
    isValid = false;
  } else {
    setProductionTextilesError("");
  }
  if (!productionSizes.length) {
    setProductionSizesError("Введите размеры товара");
    isValid = false;
  } else {
    setProductionSizesError("");
  }
  if (!productionMainImage || !productionMainImage.trim()) {
    setProductionMainImageError("Загрузите изображение товара");
    isValid = false;
  } else {
    setProductionMainImageError("");
  }
  if (productionModelColors.length === 0) {
    if (!productionModelImage || !productionModelImage.trim()) {
      setProductionModelImageError("Загрузите изображение товара, вид спереди");
      isValid = false;
    } else {
      setProductionModelImageError("");
    }
    if (!productionModelFlippedImage || !productionModelFlippedImage.trim()) {
      setProductionModelFlippedImageError(
        "Загрузите изображение товара, вид сзади"
      );
      isValid = false;
    } else {
      setProductionModelFlippedImageError("");
    }
    if (!productionModelColor || !productionModelColor.trim()) {
      setProductionModelColorError("Введите цвет товара");
      isValid = false;
    } else {
      setProductionModelColorError("");
    }
  }
  return isValid;
};
