export const validateForm = (
  brandName,
  brandLogo,
  brandImage,
  productionPhoneNumber,
  specialization,
  productionCapacity,
  quantityOfEquipment,
  description,
  setDescriptionError,
  setBrandNameError,
  setBrandLogoError,
  setBrandImageError,
  setProductionPhoneNumberError,
  setSpecializationError,
  setProductionCapacityError,
  setQuantityOfEquipmentError
) => {
  let isValid = true;

  if (!brandName || !brandName.trim()) {
    setBrandNameError("Введите название производства");
    isValid = false;
  } else {
    setBrandNameError("");
  }

  if (!description || !description.trim()) {
    setDescriptionError("Введите описание производства");
    isValid = false;
  } else {
    setDescriptionError("");
  }

  if (!brandLogo || !brandLogo.trim()) {
    setBrandLogoError("Загрузите логотип производства");
    isValid = false;
  } else {
    setBrandLogoError("");
  }

  if (!brandImage.length) {
    setBrandImageError("Загрузите изображение производства");
    isValid = false;
  } else {
    setBrandImageError("");
  }

  if (specialization.length === 0) {
    setSpecializationError("Введите специализацию производства");
    isValid = false;
  } else {
    setSpecializationError("");
  }

  if (productionPhoneNumber === null) {
    setProductionPhoneNumberError("Введите номер производства");
    isValid = false;
  } else {
    setProductionPhoneNumberError("");
  }

  if (productionCapacity.length === 0) {
    setProductionCapacityError("Введите производственную мощность цеха");
    isValid = false;
  } else {
    setProductionCapacityError("");
  }

  if (quantityOfEquipment.length === 0) {
    setQuantityOfEquipmentError("Введите количество оборудования");
    isValid = false;
  }

  return isValid;
};
