import { useEffect, useRef, useMemo } from "react";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { compareSearchParams } from "../helper/CompareSearchParams";
import { useSelector } from "react-redux";
import { selectFilterType } from "../redux/slices/CatalogSlice";

function useSearchParamsNavigation({ keyword, category, sortBy, page }) {
  const location = useLocation();
  const navigate = useNavigate();
  const prevSearchParams = useRef({});
  const activeFilterType = useSelector(selectFilterType);

  const searchParams = useMemo(() => {
    return {
      keyword: keyword,
      category: category,
      sortBy: sortBy,
      filterByType: activeFilterType.type,
    };
  }, [keyword, category, sortBy, activeFilterType.type]);

  useEffect(() => {
    if (!compareSearchParams(searchParams, prevSearchParams.current)) {
      const sortByParam = sortBy ? `&sortBy=${sortBy}` : "";
      const keywordParam = keyword ? `&keyword=${keyword}` : "";
      const categoryParam = category ? `&category=${category}` : "";
      const filterByParam = activeFilterType.type? `&filterBy=${activeFilterType.type}` : "";

      let updatedSearchParams = { pageNumber: page || 1};
      if (keywordParam) updatedSearchParams.keyword = keyword;
      if (categoryParam) updatedSearchParams.category = category;
      if (sortByParam) updatedSearchParams.sortBy = sortBy;
      if (filterByParam) updatedSearchParams.filterBy = activeFilterType.type;

      const updatedSearch = queryString.stringify(updatedSearchParams);
      navigate(`?${updatedSearch}`);

      prevSearchParams.current = searchParams;
    }
  }, [
    location.search,
    navigate,
    page,
    keyword,
    category,
    sortBy,
    searchParams,
    activeFilterType.type,
  ]);

  return { searchParams, navigate, location };
}

export default useSearchParamsNavigation;
