import { useToast } from '@chakra-ui/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearCart } from '../../../../redux/Actions/CartActions';
import { Button } from './../../../common/button/Button';
import cartSvg from './../../../../assets/images/homePageImg/Cart 1.svg';
import cartClearSvg from './../../../../assets/images/cart/trashBasket 1.svg';
import { Breadcrumbs } from '../../../common/breadCrumbs/Breadcrumbs';

export const CartItemsHeader = () => {
  const toast = useToast();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToCatalog = (e) => {
    e.preventDefault();
    navigate('/catalogs');
  };

  const handleClearCart = (e, index) => {
    e.preventDefault();
    dispatch(clearCart(index));
    toast({
      title: 'Корзина пуста!',
      status: 'success',
      isClosable: true,
      position: 'top-right',
      duration: 500,
    });
  };

  return (
    <div className="shopping-cart__header gap-3 d-flex align-items-center justify-content-between flex-column flex-lg-row">
      <div className="shopping-cart__image d-flex flex-column">
        <div>
          <figure>
            <img src={cartSvg} alt="карзина" />
          </figure>
          <h1>Корзина</h1>
        </div>
        <Breadcrumbs />
      </div>

      <div className="d-flex gap-3 align-items-center justify-content-between flex-column flex-md-row">
        <Button to="/fulfillment">Добавить фулфилмент</Button>
        <Button onClick={goToCatalog}>Продолжить покупку</Button>
        <Button onClick={handleClearCart}>
          <img src={cartClearSvg} alt="Очистить карзину" />
          Очистить карзину
        </Button>
      </div>
    </div>
  );
};
