import { savePaymentMethod } from "../redux/Actions/CartActions";
import { orderCreateAction } from "../redux/Actions/OrderActions";

export const handleSubmit = (
  values,
  dispatch,
  cartItems,
  constructorItems,
  fulfillment,
  savedShippingAddress,
  cdekDeliveryPrice,
  alfaCargoDeliveryPrice,
  redExpressDeliveryPrice,
  yldamExressPrice,
  combinedItemsPrice,
  total,
  priceInRub
) => {
  try {
    let deliveryService;
    dispatch(savePaymentMethod(values.paymentMethod));

    switch (values.selectedService) {
      case "cdek - до склада":
      case "cdek - курьерская доставка":
        deliveryService = cdekDeliveryPrice;
        break;

      case "Альфа-Карго":
        deliveryService = alfaCargoDeliveryPrice;
        break;

      case "Рэд-Экспресс":
        deliveryService = redExpressDeliveryPrice;
        break;
      case "Ылдам-Экспресс":
        deliveryService = yldamExressPrice;
        break;
      default:
        break;
    }
    dispatch(
      orderCreateAction({
        orderItems: [...cartItems, ...constructorItems, ...fulfillment],
        shippingAddress: savedShippingAddress,
        deliveryMethods: [values.selectedService, deliveryService.toFixed(2)],
        paymentMethod: values.paymentMethod,
        itemsPrice: combinedItemsPrice,
        totalPrice: total,
        totalPriceInRub: priceInRub,
      })
    );
  } catch (error) {
    console.error("Ошибка при вызове submitHandler:", error);
  }
};
