import { $api, $apiPrivate } from "../../api/axios";
import {
  APPLICATIONS_FAIL,
  APPLICATIONS_LIST_CREATE_FAIL,
  APPLICATIONS_LIST_CREATE_REQUEST,
  APPLICATIONS_LIST_CREATE_SUCCESS,
  APPLICATIONS_LIST_FAIL,
  APPLICATIONS_LIST_REQUEST,
  APPLICATIONS_LIST_SUCCESS,
  APPLICATIONS_REQUEST,
  APPLICATIONS_SUCCESS,
  DELETE_APPLICATION_FAIL,
  DELETE_APPLICATION_REQUEST,
  DELETE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_VIEW_COUNT_FAIL,
  UPDATE_APPLICATION_VIEW_COUNT_REQUEST,
  UPDATE_APPLICATION_VIEW_COUNT_SUCCESS,
} from "../constants/ApplicationsConsts";
import { clearStorage } from "./UserAction";

// GET ALL APPLICATIONS LIST
export const getApplicationsListAction =
  (search, selectedApplicationCategory) => async (dispatch) => {
    try {
      dispatch({
        type: APPLICATIONS_LIST_REQUEST,
      });

      const { data } = await $api.get(
        `/applications?search=${search}&category=${selectedApplicationCategory}`
      );

      dispatch({ type: APPLICATIONS_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: APPLICATIONS_LIST_FAIL,
        payload:
          error?.response && error?.response?.data?.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// GET ALL APPLICATIONS LIST WITHOUT SEARCH FILTER
export const getApplicationsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: APPLICATIONS_REQUEST,
    });

    const { data } = await $api.get(`/applications/applications`);

    dispatch({ type: APPLICATIONS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: APPLICATIONS_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// CREATE CATALOG ITEM
export const createApplicationsListAction = (params) => async (dispatch) => {
  try {
    dispatch({
      type: APPLICATIONS_LIST_CREATE_REQUEST,
    });

    const { data } = await $apiPrivate.post(`/applications/`, params);

    dispatch({ type: APPLICATIONS_LIST_CREATE_SUCCESS, payload: data });

    dispatch(getApplicationsListAction());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: APPLICATIONS_LIST_CREATE_FAIL,
      payload: message,
    });
  }
};

// UPDATE APPLICATION VIEW COUNT
export const updateApplicationViewCountAction =
  (params) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_APPLICATION_VIEW_COUNT_REQUEST,
      });

      const { data } = await $apiPrivate.put(
        `/applications/updateViewCount`,
        params
      );

      dispatch({ type: UPDATE_APPLICATION_VIEW_COUNT_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (error.response.status === 401) {
        dispatch(clearStorage());
      }
      dispatch({
        type: UPDATE_APPLICATION_VIEW_COUNT_FAIL,
        payload: message,
      });
    }
  };

// DELETE APPLICATION
export const deleteApplicationAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_APPLICATION_REQUEST,
    });

    await $apiPrivate.delete(`/applications/${id}`);

    dispatch({
      type: DELETE_APPLICATION_SUCCESS,
      payload: id,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: DELETE_APPLICATION_FAIL,
      payload: message,
    });
  }
};
