import React, { useState } from "react";

import { ContuctUs } from "../components/common/callUs/ContuctUs";
import DesignDescription from "../components/designDepPage/designDescription/DesignDescription";
import BrandNameSlider from "../components/common/BrandNameSlider/BrandNameSlider";

import "./styles/DesignDepPage.scss";
import { catalogs } from "../constants/designPageData";

import DesignMain from "./../components/designDepPage/DesignMain";
import { Seo } from "../seo/Seo";

const DesignDepPage = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const scrollToElement = (id) => {
    const container = document.getElementById(id);
    container.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className="design-department">
      <Seo
        title="Дизайн отдел"
        description="Наш отдел
        стремится создать привлекательные и эстетически приятные дизайны,
        которые будут отличаться от обычных и подчеркнут вашу
        индивидуальность."
        name="Манекен"
        type="product"
        keywords="Дизайн одежды, Лекала, Maneken, Манекен, Одежда оптом, Производство одежды, Ткани, Фулфилмент, Fulfilment"
      />
      <DesignDescription />
      <BrandNameSlider
        urlLink="designWorks"
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        catalogs={catalogs}
        scrollToElement={scrollToElement}
      />
      <DesignMain catalogs={catalogs.find((el) => el._id)} />
      <ContuctUs />
    </section>
  );
};

export default DesignDepPage;
