import React from "react";
import ApplicationsLists from "./applicationsLists/ApplicationsLists";
import CurrentChat from "./currentChat/CurrentChat";
import {
  selectCurrentChatUser,
  selectVisibleChat,
} from "../../../redux/slices/ChatSlice";
import { useSelector } from "react-redux";

const ApplicationsChatWindow = ({ onChatCreate }) => {
  const currentChatUser = useSelector(selectCurrentChatUser);
  const isVisibleChat = useSelector(selectVisibleChat);
  return (
    <React.Fragment>
      {isVisibleChat && <ApplicationsLists onChatCreate={onChatCreate} />}

      {currentChatUser && isVisibleChat === false && <CurrentChat />}
    </React.Fragment>
  );
};

export default ApplicationsChatWindow;
