import { useEffect } from "react";
import currency from "currency.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchExchangeRate } from "../redux/slices/ExchageRateSlice";

const useTotalPrice = ({ total }) => {
  const dispatch = useDispatch();
  const exchangeRate = useSelector((state) => state.exchangeRate.exchangeRate);
  const status = useSelector((state) => state.exchangeRate.status);

  useEffect(() => {
    dispatch(fetchExchangeRate());
  }, [dispatch]);

  const calculatePriceInRUB = () => {
    if (status === "succeeded" && total !== null && total !== undefined) {
      const priceInKGS = total;
      const priceInRUB = currency(priceInKGS).divide(exchangeRate.KGS).value;
      const formattedPrice = new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
      }).format(priceInRUB);
      return formattedPrice;
    }
    return "-";
  };

  const priceInRub = calculatePriceInRUB();

  return {
    priceInRub,
  };
};

export default useTotalPrice;
