import { GreenMarker } from "../../../../assets/svg/Svg";

export const CartItemsExist = ({
  cartItems,
  constructorItems,
  fulfillment,
}) => {
  return (
    <>
      {cartItems.length > 0 ||
      constructorItems.length > 0 ||
      fulfillment.length > 0 ? (
        <div className="header__cart__count d-flex align-items-center justify-content-center ">
          <GreenMarker />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
