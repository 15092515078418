import React from "react";

const FabricAdditionalInfo = ({ fabricData }) => {
  return (
    <div>
      <h4>Дополнительная информация:</h4>
      {fabricData?.characteristics?.map((el, index) => (
        <React.Fragment key={index}>
          <p className="mb-0 d-flex align-items-center justify-content-between ">
            <span>Артикул:</span> {fabricData.articul}
          </p>
          <p className="mb-0 d-flex align-items-center justify-content-between ">
            <span>Состав:</span> {el.textile}
          </p>
          <p className="mb-0 d-flex align-items-center justify-content-between ">
            <span>Страна производства:</span> {el.madeIn}
          </p>
          <p className="mb-0 d-flex align-items-center justify-content-between ">
            <span>Особенности ткани:</span> {el.featuresOfFabric}
          </p>
          <p className="mb-0 d-flex align-items-center justify-content-between ">
            <span>Цена:</span> {fabricData.price} сом за 1 м.п
          </p>
        </React.Fragment>
      ))}
    </div>
  );
};

export default FabricAdditionalInfo;
