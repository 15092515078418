import React from "react";

import { wholesaleOrRetailItems } from "../../../../constants/catalogCategoriesSortFilterData";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCategory,
  setActiveAgeGroup,
  setActiveSort,
  selectFilterType,
  setActiveFilterType,
} from "../../../../redux/slices/CatalogSlice";
import FilterPopover from "../../../common/catalogFilterPopover/FilterPopover";

const FilterByType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const activeFilterType = useSelector(selectFilterType);

  const searchParams = queryString.parse(location.search);

  const onSelectSortType = (type) => {
    dispatch(setActiveFilterType(type));
    const newSearchParams = {
      ...searchParams,
      filterBy: activeFilterType.type,
    };
    const newSearch = queryString.stringify(newSearchParams);
    navigate(`?${newSearch}`);
    dispatch(setSelectedCategory(""));
    dispatch(setActiveAgeGroup(""));
    if (type.type === "allItems") {
      dispatch(setActiveSort(""));
    }
  };

  return (
    <FilterPopover
      items={wholesaleOrRetailItems}
      onApply={onSelectSortType}
      activeType={activeFilterType}
    />
  );
};

export default FilterByType;
