import React from "react";

const TypingLoader = ({istyping}) => {
  return (
    <React.Fragment>
      {istyping ? (
        <div className="typing-loader">
          typing
          <span></span>
          <span></span>
          <span></span>
        </div>
      ) : (
        <div></div>
      )}
    </React.Fragment>
  );
};

export default TypingLoader;
