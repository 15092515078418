import React from "react";
import { Badge, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { getSender } from "../../../../helper/ChatSettings";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNotifications,
  setActiveMobileContacts,
  setActiveTab,
  setActiveTabCategory,
  setCurrentChatUser,
  setIsVisibleChat,
  setNotifications,
} from "../../../../redux/slices/ChatSlice";
import { useNavigate } from "react-router-dom";
import { IoMdNotificationsOutline } from 'react-icons/io';

const Notifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notifications = useSelector(selectNotifications);
  const userInfo = useSelector((state) => state.userLogin.userInfo);

  const handleNotification = (notif) => {
    navigate("/applications");
    dispatch(setIsVisibleChat(false));
    dispatch(setActiveTab(true));
    dispatch(setActiveTabCategory(false));
    dispatch(setCurrentChatUser(notif.chat));
    dispatch(setNotifications(notifications?.filter((n) => n !== notif)));
    if (window.innerWidth <= 992) {
      dispatch(setActiveMobileContacts(false));
    }
  };

  return (
    <Menu>
      <MenuButton p={1} type="button" position='relative' style={{padding:'0'}}>
        <IoMdNotificationsOutline size={32} />
        {notifications.length > 0 && (
          <Badge ml="1" fontSize="0.8em" colorScheme="green" style={{position:'absolute',top:'0'}}>
            {notifications.length}
          </Badge>
        )}
      </MenuButton>
      <MenuList pl={2}>
        {!notifications.length && "Нет новых сообщений"}
        {notifications.map((notif) => (
          <MenuItem
            key={notif._id}
            onClick={() => {
              handleNotification(notif);
            }}
          >
            {notif.chat.isGroupChat
              ? `New Message in ${notif?.chat?.chatName}`
              : `Новое сообщение от ${getSender(userInfo, notif?.chat?.users)}`}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default Notifications;
