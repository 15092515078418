export const CalculateYldamExpressDelivery = (region, weight) => {
  switch (region) {
    case "Chuy":
      return weight <= 5
        ? 250
        : weight <= 10
        ? 300
        : weight <= 15
        ? 350
        : 350 + Math.ceil((weight - 15) / 5) * 50;

    case "Talas":
    case "Naryn":
    case "IssykKol":
    case "Osh":
    case "JalalAbad":
      return weight <= 5
        ? 300
        : weight <= 10
        ? 350
        : weight <= 15
        ? 400
        : 400 + Math.ceil((weight - 15) / 5) * 50;

    case "Batken":
      return weight <= 5
        ? 350
        : weight <= 10
        ? 400
        : weight <= 15
        ? 450
        : 450 + Math.ceil((weight - 15) / 5) * 50;
        
    default:
      return 0;
  }
};
