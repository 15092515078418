import React, { useEffect, useState, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productionCatalogAction } from "../redux/Actions/ProductionAction";
import LoadingError from "../components/common/errorMessage/LoadingError";
import { Loader } from "../components/common/loader/Loader";
import Slider from "react-slick";
import "./styles/Constructor.scss";
import { useLocation } from "react-router-dom";
import { Seo } from "../seo/Seo";

const Constructor = lazy(() => import("../components/constructor/Constructor"));

const ConstructorPage = () => {
  const dispatch = useDispatch();
  const productionCatalog = useSelector((state) => state.productionCatalog);
  const { catalogs, loading, error } = productionCatalog;

  const settings = {
    customPaging: function (i) {
      return (
        <a href="/">
          <img src={`${catalogs[i].brandInfo.brandLogo}`} alt="Бренд" />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const location = useLocation();

  useEffect(() => {
    dispatch(productionCatalogAction());
  }, [dispatch]);

  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const activeSlideParam = searchParams.get("activeSlide");
    const parsedActiveSlide = Number(activeSlideParam) || 0;

    if (parsedActiveSlide !== activeSlide) {
      setActiveSlide(parsedActiveSlide);
    }
  }, [dispatch, location.search, activeSlide]);

  return (
    <section
      className={`constructor__main__wrapper container ${
        catalogs?.length > 1 ? "hasActiveSlideBrandDots" : ""
      }`}
    >
      <Seo
        title="Конструктор одежды"
        description="Индивидуализируйте каждую деталь вашего наряда, выбирая из разнообразных тканей, цветов и дизайнерских элементов."
        name="Манекен"
        type="Product"
        keywords="Maneken, Манекен, Одежда оптом, Производство одежды, Ткани, Фулфилмент, Fulfilment, Конструктор одежды, Конструктор,  Женская одежда, Мужская одежда, Детская одежда"
      />
      {loading ? (
        <Loader />
      ) : error ? (
        <LoadingError variant={alert()}>{error}</LoadingError>
      ) : (
        <React.Fragment>
          <Slider {...settings} initialSlide={activeSlide}>
            {catalogs?.map((el, index) => (
              <React.Fragment key={`${index}-${el._id}`}>
                <Suspense
                  fallback={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <Constructor
                    catalogs={el.productionCatalog}
                    {...el}
                    el={el}
                  />
                </Suspense>
              </React.Fragment>
            ))}
          </Slider>
        </React.Fragment>
      )}
    </section>
  );
};

export default ConstructorPage;
