export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const ORDER_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST";
export const ORDER_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCCESS";
export const ORDER_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL";
export const ORDER_LIST_MY_RESET = "ORDER_LIST_MY_RESET";

export const ORDER_UPDATE_REQUEST = "ORDER_UPDATE_REQUEST";
export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS";
export const ORDER_UPDATE_FAIL = "ORDER_UPDATE_FAIL";

export const ORDER_CDEK_CREATE_REQUEST = "ORDER_CDEK_CREATE_REQUEST";
export const ORDER_CDEK_CREATE_SUCCESS = "ORDER_CDEK_CREATE_SUCCESS";
export const ORDER_CDEK_CREATE_FAIL = "ORDER_CDEK_CREATE_FAIL";
export const ORDER_CDEK_CREATE_RESET = "ORDER_CDEK_CREATE_RESET";

export const FETCH_PICKUP_POINTS_REQUEST = "FETCH_PICKUP_POINTS_REQUEST";
export const FETCH_PICKUP_POINTS_SUCCESS = "FETCH_PICKUP_POINTS_SUCCESS";
export const FETCH_PICKUP_POINTS_FAILURE = "FETCH_PICKUP_POINTS_FAILURE";

export const FETCH_CDEK_SETTLEMENTS_REQUEST = "FETCH_CDEK_SETTLEMENTS_REQUEST";
export const FETCH_CDEK_SETTLEMENTS_SUCCESS = "FETCH_CDEK_SETTLEMENTS_SUCCESS";
export const FETCH_CDEK_SETTLEMENTS_FAILURE = "FETCH_CDEK_SETTLEMENTS_FAILURE";

export const ORDER_CDEK_CALCULATE_TARIFF_REQUEST =
  "ORDER_CDEK_CALCULATE_TARIFF_REQUEST";
export const ORDER_CDEK_CALCULATE_TARIFF_SUCCESS =
  "ORDER_CDEK_CALCULATE_TARIFF_SUCCESS";
export const ORDER_CDEK_CALCULATE_TARIFF_FAIL =
  "ORDER_CDEK_CALCULATE_TARIFF_FAIL";
export const ORDER_CDEK_CALCULATE_TARIFF_RESET =
  "ORDER_CDEK_CALCULATE_TARIFF_RESET";
