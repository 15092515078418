import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import {
  catalogCreateReviewReducer,
  catalogItemCreateReducer,
  catalogListReducer,
  catalogReviewsReducer,
  catalogSingleItemReducer,
  profileCatalogItemDeleteReducer,
  profileCatalogItemEditReducer,
  profileCatalogListReducer,
  updateProfileCatalogItemReducer,
} from "./CatalogReducers.js/CatalogReducers";
import {
  cartReducer,
  cartTotalPriceSumWithDeliveryReducer,
} from "./CatalogReducers.js/CartReducers";
import {
  cdekSettlementReducer,
  orderCreateCdekCalculateTariffReducer,
  orderCreateCdekDeliveryReducer,
  orderCreateReducer,
  orderDetailsReducer,
  orderListMyReducer,
  orderUpdatePaymentReducer,
  pickupPointsReducer,
} from "./CatalogReducers.js/OrderReducers";
import {
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
} from "./CatalogReducers.js/UserReducers";
import {
  productionAdsCardsReducer,
  productionCatalogReducer,
  productionEditReducer,
  productionItemCreateReducer,
  productionSingleItemReducer,
  profileProductionCatalogItemDeleteReducer,
  updateProductionReducer,
} from "./CatalogReducers.js/ProductionReducers";
import { newCollectionCatalogReducer } from "./CatalogReducers.js/NewCollectionReducer";
import {
  applicationDeleteReducer,
  applicationsCreateReducer,
  applicationsListsReducer,
  applicationsReducer,
  updateApplicationViewCount,
} from "./CatalogReducers.js/ApplicationsReducer";
import { chatDeleteReducer } from "./CatalogReducers.js/ChatReducers";
import { fabricsReducer } from "./CatalogReducers.js/FabricsReducer";
import {
  profileUploadAvatarReducer,
  profileUploadCatalogImageReducer,
  profileUploadCatalogImagesReducer,
} from "./CatalogReducers.js/ProfileReducers";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import chatReducer from "./slices/ChatSlice";
import catalogReducer from "./slices/CatalogSlice";
import SingleCatalogItemSlice from "./slices/SingleCatalogItemSlice";
import exchangeRateReducer from "./slices/ExchageRateSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["cart", "userLogin"],
};

const reducers = combineReducers({
  catalogList: catalogListReducer,
  catalogDetails: catalogSingleItemReducer,
  catalogReviewCreate: catalogCreateReviewReducer,
  catalogCreateItem: catalogItemCreateReducer,
  profileCatalog: profileCatalogListReducer,
  profileItemEdit: profileCatalogItemEditReducer,
  profileCatalogUpdate: updateProfileCatalogItemReducer,
  deleteProfileCatalogItem: profileCatalogItemDeleteReducer,
  cart: cartReducer,
  cartTotalPrice: cartTotalPriceSumWithDeliveryReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderListMy: orderListMyReducer,
  productionCatalog: productionCatalogReducer,
  catalogReviews: catalogReviewsReducer,
  newCollectionCatalog: newCollectionCatalogReducer,
  updatePaymentMethod: orderUpdatePaymentReducer,
  productionCreate: productionItemCreateReducer,
  productionUpdate: updateProductionReducer,
  productionEdit: productionEditReducer,
  applicationLists: applicationsListsReducer,
  applicationsCreate: applicationsCreateReducer,
  updateViewCount: updateApplicationViewCount,
  deleteProfileProductionCatalogItem: profileProductionCatalogItemDeleteReducer,
  chatDelete: chatDeleteReducer,
  applicationDelete: applicationDeleteReducer,
  applications: applicationsReducer,
  createCdekDelivery: orderCreateCdekDeliveryReducer,
  usersList: userListReducer,
  cdekPickUpPoints: pickupPointsReducer,
  calculateTariff: orderCreateCdekCalculateTariffReducer,
  settlements: cdekSettlementReducer,
  productionAdsCards: productionAdsCardsReducer,
  fabrics: fabricsReducer,
  production: productionSingleItemReducer,
  avatarUpload: profileUploadAvatarReducer,
  catalogUploadImages: profileUploadCatalogImagesReducer,
  catalogUploadImage: profileUploadCatalogImageReducer,
  chat: chatReducer,
  catalog: catalogReducer,
  singleCatalogItem: SingleCatalogItemSlice,
  exchangeRate: exchangeRateReducer,
});
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
export default store;
