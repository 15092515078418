import React from "react";
import { Breadcrumbs } from "../../common/breadCrumbs/Breadcrumbs";

const FabricDescription = () => {
  return (
    <section className="fabric__description ">
      <div className="container">
        <h1>Ткани</h1>
        <Breadcrumbs />
        <p>
          Добро пожаловать в мир тканей! Откройте для себя нашу обширную
          коллекцию тканей, идеально подходящих для любого проекта. Мы
          предлагаем ткани разных цветов, узоров и текстур, чтобы удовлетворить
          каждое творческое желание. Наша страница постоянно обновляется, так
          что вы всегда найдете что-то новое и вдохновляющее.
        </p>
      </div>
    </section>
  );
};

export default FabricDescription;
