import React, { memo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { ChevronRightIcon } from "@chakra-ui/icons";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import "./BreadCrumbs.scss";

import { HomeSvg } from "../../../assets/svg/Svg";
import { crumbLabels } from "../../../constants";

export const Breadcrumbs = memo(() => {
  const location = useLocation();

  const catalogList = useSelector((state) => state.catalogList.catalogs);
  const productionCatalog = useSelector(
    (state) => state.productionCatalog.catalogs
  );
  const newCollectionCatalog = useSelector(
    (state) => state.newCollectionCatalog.catalogs
  );
  let currentLink = "";

  const crumbs = [
    // Добавляем ссылку на главную страницу
    <BreadcrumbItem key="home">
      <BreadcrumbLink as={Link} to="/">
        <HomeSvg />
      </BreadcrumbLink>
    </BreadcrumbItem>,
    ...location.pathname
      .split("/")
      .filter((crumb) => crumb !== "")
      .map((crumb) => {
        currentLink += `/${crumb}`;

        let crumbLabel = "";

        try {
          // Ищем объект товара по идентификатору (части URL)
          const item = catalogList?.find((item) => item._id === crumb);
          const itemProduction = productionCatalog?.find((item) =>
            item.productionCatalog.some((subItem) => subItem._id === crumb)
          );
          let productionTitle = "";
          if (itemProduction) {
            const matchingSubItem = itemProduction.productionCatalog?.find(
              (subItem) => subItem._id === crumb
            );
            if (matchingSubItem) {
              productionTitle = matchingSubItem.title;
            }
          }
          const itemNewCollection = newCollectionCatalog?.find(
            (item) => item._id === crumb
          );

          // Заменяем только идентификатор на название товара, если объект найден
          if (item || itemProduction || itemNewCollection) {
            crumbLabel = item
              ? item.title
              : itemProduction
              ? productionTitle
              : itemNewCollection
              ? itemNewCollection.title
              : "";
          } else {
            crumbLabel = crumbLabels[crumb] || crumb;
          }
        } catch (error) {
          console.error("Ошибка в компоненте Breadcrumbs:", error);
          crumbLabel = "Ошибка";
        }

        return (
          <BreadcrumbItem key={crumb}>
            <BreadcrumbLink as={Link} to={currentLink}>
              {crumbLabel}
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      }),
  ];

  return (
    <Breadcrumb
      className="container p-0"
      spacing="8px"
      separator={<ChevronRightIcon color="gray.500" />}
    >
      {crumbs}
    </Breadcrumb>
  );
});
