export const designPriceList = [
  {
    id: 1,
    price: 2000,
    text: "худи",
  },
  {
    id: 2,
    price: 1800,
    text: "платья",
  },
  {
    id: 3,
    price: 2000,
    text: "пиджак",
  },
  {
    id: 4,
    price: 500,
    text: "брюки",
  },
  {
    id: 5,
    price: 1500,
    text: "куртки",
  },
];

export const catalogs = [
  {
    brandInfo: {
      brandName: "maneken",
      brandStories: "",
      brandLogo:
        "https://res.cloudinary.com/drdqjr8r6/image/upload/v1695140639/bg%20images/poixwsf9k4bhu9cq4sfo.svg",
      brandImage: [
        "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691778909/bg%20images/production-img_k0kolj.jpg",
        "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691645783/bg%20images/hp10_uebapk.jpg",
        "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691645782/bg%20images/hp9_y40siq.jpg",
      ],
    },
    _id: 1,
  },
];
