import React, { useState, useEffect, useRef, memo } from "react";
import "./styles/Applications.scss";
import { useDispatch, useSelector } from "react-redux";
import ApplicationsChatWindow from "../components/applicationsMain/chatWindow/ApplicationsChatWindow";
import ApplicationsChatHeader from "../components/applicationsMain/ApplicationsChatHeader";
import ApplicationsChatSearch from "../components/applicationsMain/ApplicationsChatSearch";
import ApplicationsChatInputs from "../components/applicationsMain/chatInputFields/ApplicationsChatInputs";
import ApplicationChatEmpty from "../components/applicationsMain/ApplicationChatEmpty";
import { Link } from "react-router-dom";
import LoadingError from "../components/common/errorMessage/LoadingError";
import {
  createApplicationsListAction,
  getApplicationsAction,
  getApplicationsListAction,
} from "../redux/Actions/ApplicationsAction";
import ChakraUiSpinner from "../components/common/loader/ChakraUiSpinner";
import { APPLICATIONS_LIST_RESET } from "../redux/constants/ApplicationsConsts";
import ApplicationsActiveContacts from "./../components/applicationsMain/chatContacts/ApplicationsActiveContacts";
import { Spinner } from "@chakra-ui/react";
import { Seo } from "../seo/Seo";
import {
  selectApplicationCategory,
  selectSearch,
  setActiveMobileContacts,
  setCurrentChatUser,
  setIsVisibleChat,
  setShowInputFields,
} from "../redux/slices/ChatSlice";

const ApplicationsPage = memo(() => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const applicationLists = useSelector((state) => state.applicationLists);
  const { loading: listLoading, error: listError, lists } = applicationLists;

  const search = useSelector(selectSearch);
  const selectedApplicationCategory = useSelector(selectApplicationCategory);
  const applications = useSelector((state) => state.applications);
  const { loading: appLoading, error: appError } = applications;
  const applicationsCreate = useSelector((state) => state.applicationsCreate);
  const {
    loading: createLoading,
    error: createError,
    product,
  } = applicationsCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  const [purchaseInfo, setPurchaseInfo] = useState({
    itemName: "",
    quantity: "",
    productRequirements: "",
    images: [],
    user: userInfo?.name,
    avatar: "",
    city: "",
    location: "",
    category: "",
    viewCount: 0,
  });

  const updateMobileChatState = () => {
    dispatch(setActiveMobileContacts(window.innerWidth > 992));
  };

  useEffect(() => {
    updateMobileChatState();
    window.addEventListener("resize", updateMobileChatState);
    return () => {
      window.removeEventListener("resize", updateMobileChatState);
    };
  });

  const onChatCreate = (userId, message) => {
    dispatch(setCurrentChatUser(message, userId));
    dispatch(setIsVisibleChat(false));
  };

  const onSubmitChat = (e) => {
    e.preventDefault();

    dispatch(setShowInputFields(false));

    dispatch(
      createApplicationsListAction({
        itemName: purchaseInfo.itemName,
        quantity: purchaseInfo.quantity,
        productRequirements: purchaseInfo.productRequirements,
        images: purchaseInfo.images,
        user: purchaseInfo.user,
        avatar: purchaseInfo.avatar,
        city: purchaseInfo.city,
        location: purchaseInfo.location,
        userId: userInfo?.id,
        category: purchaseInfo.category,
        viewCount: purchaseInfo.viewCount,
      })
    )
      .then(() => {
        dispatch(
          getApplicationsListAction(search, selectedApplicationCategory)
        );
        dispatch(getApplicationsAction());
      })
      .catch((error) => {
        console.error("Error deleting item:", error);
      });

    if (product) {
      dispatch({ type: APPLICATIONS_LIST_RESET });
    }
    setPurchaseInfo({
      itemName: "",
      quantity: "",
      productRequirements: "",
      images: [],
      user: userInfo?.name,
      avatar: "",
      city: "",
      location: "",
      userId: userInfo?.userId,
      category: "",
    });
  };

  useEffect(() => {
    dispatch(getApplicationsListAction(search, selectedApplicationCategory));
  }, [dispatch, search, selectedApplicationCategory]);

  useEffect(() => {
    dispatch(getApplicationsAction());
  }, [dispatch]);

  return (
    <section className="applications__wrapper">
      <Seo
        title="Заявки на покупку товара"
        description="На этой странице размещаются заявки на закупки товаров оптом. Если вы не нашли подходящий товар и требуется приобрести нужные вам товары оптом, вам достаточно опубликовать свою заявку на покупку. Поставщики автоматически предложат вам свои условия и цены, обеспечивая удобный и эффективный способ нахождения оптовых поставщиков и осуществления закупок товаров по выгодным условиям."
        name="Манекен"
        type="website"
        keywords="Заявки, Объявления манекен, Закупки товаров оптом, Maneken, Манекен, Одежда оптом, Производство одежды, Ткани, Фулфилмент, Fulfilment, Конструктор одежды, Поставщики"
      />

      <div className="position-relative">
        <div className="applications__wrapper__background">
          <ApplicationsChatHeader />
        </div>
      </div>

      <div className="applications__wrapper__container container ">
        <form className="applications__content " onSubmit={onSubmitChat}>
          <div className="applications__content__body">
            <div className="applications__content__body__wrapper">
              <ApplicationsActiveContacts onChatCreate={onChatCreate} />

              <div className="applications__content__body__wrapper__lists position-relative ">
                <ApplicationsChatSearch inputRef={inputRef} />
                {listLoading || createLoading || appLoading ? (
                  <div>
                    <Spinner
                      position="absolute"
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      top="50%"
                      left="50%"
                      size="xl"
                    />
                  </div>
                ) : listError || createError || appError ? (
                  <div className="lists-container">
                    <LoadingError variant="alert-danger">
                      {listError || createError || appError}
                    </LoadingError>
                  </div>
                ) : lists?.length > 0 ? (
                  <div className="w-100">
                    <ApplicationsChatWindow onChatCreate={onChatCreate} />
                  </div>
                ) : (
                  <ApplicationChatEmpty />
                )}

                {userInfo ? (
                  <ApplicationsChatInputs
                    purchaseInfo={purchaseInfo}
                    setPurchaseInfo={setPurchaseInfo}
                    inputRef={inputRef}
                  />
                ) : (
                  <p className="text-center my-3">
                    {loading && <ChakraUiSpinner />}
                    {error && (
                      <LoadingError variant="alert-danger">
                        {error}
                      </LoadingError>
                    )}
                    <Link to="/register">Зарегистрироваться</Link> или{" "}
                    <Link to="/login">Войти</Link> чтобы оставить заявку
                  </p>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
});

export default ApplicationsPage;
