import {
  FETCH_CDEK_SETTLEMENTS_FAILURE,
  FETCH_CDEK_SETTLEMENTS_REQUEST,
  FETCH_CDEK_SETTLEMENTS_SUCCESS,
  FETCH_PICKUP_POINTS_FAILURE,
  FETCH_PICKUP_POINTS_REQUEST,
  FETCH_PICKUP_POINTS_SUCCESS,
  ORDER_CDEK_CALCULATE_TARIFF_FAIL,
  ORDER_CDEK_CALCULATE_TARIFF_REQUEST,
  ORDER_CDEK_CALCULATE_TARIFF_SUCCESS,
  ORDER_CDEK_CREATE_FAIL,
  ORDER_CDEK_CREATE_REQUEST,
  ORDER_CDEK_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_UPDATE_FAIL,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
} from "./../constants/OrderConstants";
import { $apiPrivate } from "../../api/axios";
import { CART_CLEAR_ITEMS } from "./../constants/CartConstants";
import { clearStorage } from "./UserAction";

//CREATE ORDER
export const orderCreateAction = (order) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    });

    const { data } = await $apiPrivate.post(`/cart`, order);
    dispatch({ type: ORDER_CREATE_SUCCESS, payload: data });
    dispatch({ type: CART_CLEAR_ITEMS, payload: data });
  } catch (error) {
    const message = error.response?.data?.message ?? error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: message,
    });
  }
};

//ORDER DETAILS

export const getOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    });

    const { data } = await $apiPrivate.get(`/cart/${id}`);
    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message = error.response?.data?.message ?? error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: message,
    });
  }
};

//MY ORDER LIST

export const myOrdersListAction = () => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_LIST_MY_REQUEST,
    });

    const { data } = await $apiPrivate.get(`/cart/`);
    dispatch({ type: ORDER_LIST_MY_SUCCESS, payload: data });
  } catch (error) {
    const message = error.response?.data?.message ?? error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: ORDER_LIST_MY_FAIL,
      payload: message,
    });
  }
};

// UPDATE ORDER PAYMENT METHOD
export const updatePaymentMethod =
  (orderId, paymentMethod) => async (dispatch) => {
    try {
      dispatch({ type: ORDER_UPDATE_REQUEST });

      const { data } = await $apiPrivate.put(`/cart/${orderId}`, {
        paymentMethod,
      });

      dispatch({ type: ORDER_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(clearStorage());
      }
      dispatch({
        type: ORDER_UPDATE_FAIL,
        payload: error.response?.data?.message ?? error.message,
      });
    }
  };

//CREATE CDEK DELIVERY
export const cdekDeliveryAction = (orders) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_CDEK_CREATE_REQUEST,
    });

    const { data } = await $apiPrivate.post(`/cart/create-cdek-order`, orders);

    dispatch({ type: ORDER_CDEK_CREATE_SUCCESS, payload: data });
    dispatch({ type: CART_CLEAR_ITEMS, payload: data });
  } catch (error) {
    const message = error.response?.data?.message ?? error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: ORDER_CDEK_CREATE_FAIL,
      payload: message,
    });
  }
};

//GET CDEK DELIVERY POINTS
export const fetchPickupPoints = (params) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_PICKUP_POINTS_REQUEST,
    });

    const { data } = await $apiPrivate.get(
      `/cart/cdek/cdek-delivery-points?weight_max=${params.weight_max}&city_code=${params.city_code}`
    );
    dispatch({ type: FETCH_PICKUP_POINTS_SUCCESS, payload: data });
  } catch (error) {
    const message = error.response?.data?.message ?? error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: FETCH_PICKUP_POINTS_FAILURE,
      payload: message,
    });
  }
};

//GET CDEK DELIVERY Settlements
export const fetchCdekSettlements = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_CDEK_SETTLEMENTS_REQUEST,
    });

    const { data } = await $apiPrivate.get(
      `/cart/cdek/cdek-delivery-settlements`
    );
    dispatch({ type: FETCH_CDEK_SETTLEMENTS_SUCCESS, payload: data });
  } catch (error) {
    const message = error.response?.data?.message ?? error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: FETCH_CDEK_SETTLEMENTS_FAILURE,
      payload: message,
    });
  }
};

//CREATE CDEK DELIVERY
export const cdekCalculateTariffAction = (tariff) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_CDEK_CALCULATE_TARIFF_REQUEST,
    });

    const { data } = await $apiPrivate.post(`/cart/calculate-tariff`, tariff);

    dispatch({
      type: ORDER_CDEK_CALCULATE_TARIFF_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message = error.response?.data?.message ?? error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: ORDER_CDEK_CALCULATE_TARIFF_FAIL,
      payload: message,
    });
  }
};
