import React, { useState } from "react";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { GiClothes } from "react-icons/gi";

const SampleSectionWithSizesModal = ({ item, addToCartHandler }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [activeSize, setActiveSize] = useState(0);

  const cartItems = useSelector((state) => state.cart.cartItems);

  const handleOpenSampleSizes = () => {
    onOpen();
  };

  const handleAddToCartSample = (index, sampleSize) => {
    setActiveSize(index);
    const sampleExist = cartItems.find(
      (el) => el.qty === 1 && el.articul === item.articul
    );

    if (!sampleExist) {
      addToCartHandler(1, sampleSize);
    } else {
      toast({
        title: "Образец данного товара уже был добавлен в корзину!",
        status: "info",
        isClosable: true,
        position: "top-right",
        duration: 2000,
      });
    }
    onClose();
  };

  return (
    <React.Fragment>
      <button
        onClick={handleOpenSampleSizes}
        type="button"
        className={`addToCartBtn w-100 ${
          !item.hasSample ? "disabled-btn" : ""
        }`}
        style={{ display: !item.isWholesale || !item.hasSample ? "none" : "" }}
      >
        <GiClothes /> Заказать образец
      </button>
      <Modal isOpen={isOpen} onClose={onClose} size="xs">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Выберите размер</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {item?.sizes &&
              Array.isArray(item.sizes) &&
              item.sizes.map((el, index) => (
                <span
                  key={el._id}
                  className={` ${activeSize === index ? "active" : ""}`}
                  onClick={() => {
                    handleAddToCartSample(index, el.size);
                  }}
                >
                  {el.size}
                </span>
              ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};

export default SampleSectionWithSizesModal;
