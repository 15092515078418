import React, { useState } from "react";
import LoadingError from "../../../../common/errorMessage/LoadingError";
import { Ratings } from "../../../../common/ratings/Ratings";
import moment from "moment";
import { Link } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectComment,
  selectRatings,
  setComment,
  setRatings,
} from "../../../../../redux/slices/SingleCatalogItemSlice";
import { createCatalogReview } from "../../../../../redux/Actions/CatalogActions";
import { useParams, useLocation } from "react-router-dom";
import ProductMainImage from "../../../../profilePage/profileAddProductToCatalog/productMainImage/ProductMainImage";
import { isVideo } from "../../../../../helper/isVideo";

export const ReviewSection = (props) => {
  const { item } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const ratings = useSelector(selectRatings);
  const comment = useSelector(selectComment);
  const [file, setFile] = useState("");
  const catalogReviewCreate = useSelector((state) => state.catalogReviewCreate);
  const { loading: loadingCreateReview, error: errorCreateReview } =
    catalogReviewCreate;

  const pathnameSplit = location.pathname.split("/")[1];

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createCatalogReview(
        id,
        {
          ratings,
          comment,
          file,
        },
        pathnameSplit
      )
    );
  };

  return (
    <div
      className="reviews-block row my-5 position-relative mb-5"
      id="catalogItemReviews"
    >
      <div className="col-md-6">
        <h6 className="mb-3">Отзывы</h6>
        {item?.reviews?.length === 0 && (
          <LoadingError variant={"alert-info mt-3"}>Нет отзывов</LoadingError>
        )}
        {item.reviews.map((review) => (
          <div
            key={review._id}
            className="mb-5 mb-md-3 bg-light p-3 shadow-sm rounded d-flex flex-column gap-2"
          >
            <strong>{review.name}</strong>
            <Ratings value={review.ratings} />
            <span>{moment(review.createdAt).calendar()}</span>
            <div className="alert alert-info mt-3">
              {review.comment}
              {review.file &&
                (isVideo(review.file) ? (
                  <figure className="mt-3">
                    <video
                      src={review.file}
                      style={{
                        width: "100%",
                        maxHeight: "300px",
                        objectFit: "cover",
                      }}
                      controls
                      className="mb-1"
                    >
                      Ваш браузер не поддерживает воспроизведение видео.
                    </video>
                  </figure>
                ) : (
                  <figure>
                    <img style={{
                        width: "100%",
                        maxHeight: "300px",
                        objectFit: "cover",
                      }} src={review.file} alt="Картинка товара" className="mb-1" />
                  </figure>
                ))}
            </div>
          </div>
        ))}
      </div>
      <div className="col-md-6">
        <h6>Оставьте отзыв</h6>
        <div className="d-flex flex-column align-items-center justify-content-center">
          {loadingCreateReview && (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          )}
          {errorCreateReview && (
            <LoadingError variant="alert-danger">
              {errorCreateReview}
            </LoadingError>
          )}
        </div>
        {userInfo ? (
          <form onSubmit={submitHandler}>
            <div className="my-4">
              <strong>Рейтинг</strong>
              <div className="rating">
                <input
                  value="5"
                  name="star-radio"
                  id="star-5"
                  type="radio"
                  checked={ratings === "5"}
                  onChange={() => dispatch(setRatings("5"))}
                />
                <label htmlFor="star-5">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                      pathLength="360"
                    ></path>
                  </svg>
                </label>
                <input
                  value="4"
                  name="star-radio"
                  id="star-4"
                  type="radio"
                  checked={ratings === "4"}
                  onChange={() => dispatch(setRatings("4"))}
                />
                <label htmlFor="star-4">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                      pathLength="360"
                    ></path>
                  </svg>
                </label>
                <input
                  value="3"
                  name="star-radio"
                  id="star-3"
                  type="radio"
                  checked={ratings === "3"}
                  onChange={() => dispatch(setRatings("3"))}
                />
                <label htmlFor="star-3">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                      pathLength="360"
                    ></path>
                  </svg>
                </label>
                <input
                  value="2"
                  name="star-radio"
                  id="star-2"
                  type="radio"
                  checked={ratings === "2"}
                  onChange={() => dispatch(setRatings("2"))}
                />
                <label htmlFor="star-2">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                      pathLength="360"
                    ></path>
                  </svg>
                </label>
                <input
                  value="1"
                  name="star-radio"
                  id="star-1"
                  type="radio"
                  checked={ratings === "1"}
                  onChange={() => dispatch(setRatings("1"))}
                />
                <label htmlFor="star-1">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                      pathLength="360"
                    ></path>
                  </svg>
                </label>
              </div>
            </div>
            <div className="my-4">
              <strong>Комментарий</strong>
              <textarea
                value={comment}
                required
                onChange={(e) => dispatch(setComment(e.target.value))}
                rows="3"
                className="col-12 bg-light p-3 mt-2 border-0  shadow-sm rounded"
                name="reviews"
              ></textarea>
            </div>
            <div>
              <strong>Прикрепить файл</strong>
              <ProductMainImage setImage={setFile} image={file} />
            </div>
            <div className="my-3">
              <button
                disabled={loadingCreateReview}
                className="reviews-btn col-12 border-0 p-3  text-white"
              >
                Подтвердить
              </button>
            </div>
          </form>
        ) : (
          <div className="my-3">
            <LoadingError variant={"alert-warning"}>
              <Link to="/login">Зарегистрируйтесь</Link> чтобы оставить
              комментарий
            </LoadingError>
          </div>
        )}
      </div>
    </div>
  );
};
