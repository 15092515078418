import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

import { IoColorPaletteOutline } from "react-icons/io5";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdOutlineDeleteForever } from "react-icons/md";

import { Skeleton, Stack, useToast } from "@chakra-ui/react";

import { videoExtensions } from "../../../../constants";
import { uploadCatalogImagesAction } from "../../../../redux/Actions/ProfieActions";

const ProductImages = ({
  setImages,
  images,
  setColors,
  text,
  fieldType,
  brandImageError,
  setBrandImageError,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const uniqueIdentifierRef = useRef(null);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        uniqueIdentifierRef.current = uuidv4();

        if (images.length > 40) {
          toast({
            title: "Максимальное количество изображений - 40",
            status: "info",
            isClosable: true,
            position: "top-right",
            duration: 1000,
          });
          return;
        }
        if (brandImageError) {
          setBrandImageError("");
        }

        const uploadedImages = await Promise.all(
          acceptedFiles.map(async (file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            return new Promise((resolve) => {
              reader.onload = () => {
                resolve(reader.result);
              };
            });
          })
        );
        const uploadResult = await dispatch(
          uploadCatalogImagesAction({
            images: uploadedImages,
            id: uniqueIdentifierRef.current,
          })
        );
        if (uploadResult.success) {
          setImages((prevState) => [...prevState, ...uploadResult.responseArr]);
        }
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    },
    [
      dispatch,
      setImages,
      images.length,
      toast,
      brandImageError,
      setBrandImageError,
    ]
  );
  const catalogUploadImages = useSelector(
    (state) => state.catalogUploadImages || {}
  );
  const { catalogUploadError, catalogUploadLoading } =
    catalogUploadImages[uniqueIdentifierRef.current] || {};

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 30,
  });

  const handleImageChoose = (color) => {
    setColors((prevColors) => [...prevColors, { img: color }]);
  };

  const handleRemoveImage = (urlToRemove) => {
    const updatedImages = images.filter((url) => url !== urlToRemove);
    setImages(updatedImages);
  };

  return (
    <React.Fragment>
      <label htmlFor="productImages">{text}</label>
      <div className={`mb-4 main-profile-image`}>
        <React.Fragment>
          <div
            className={`main-profile-image__content d-flex align-items-center flex-column justify-content-center  gap-2 ${
              brandImageError ? "cart-border-error" : ""
            }`}
          >
            <div
              className="d-flex align-items-center flex-column justify-content-center"
              {...getRootProps()}
            >
              <FaCloudUploadAlt className="uploadImage" />
              <input {...getInputProps()} id="productImages" />
              {isDragActive ? (
                <p className="mb-0 text-center ">Перетащите файлы сюда...</p>
              ) : (
                <>
                  <p className="mb-0 text-center ">
                    Перетащите файлы сюда или нажмите, чтобы выбрать файлы.
                  </p>
                  <p className="mb-0 text-center ">
                    Максимальное количество файлов для загрузки - 10 файлов.
                  </p>
                  <p className="mb-0 text-center ">
                    Размер файла для загрузки - до 10мб.
                  </p>
                </>
              )}
            </div>
          </div>
          {brandImageError ||
            (catalogUploadError && (
              <p className="cart-error-message">
                {brandImageError || catalogUploadError}
              </p>
            ))}
        </React.Fragment>
        {catalogUploadLoading ? (
          <Stack>
            <Skeleton height="20px" borderRadius="10px" />
            <Skeleton height="20px" borderRadius="10px" />
            <Skeleton height="20px" borderRadius="10px" />
          </Stack>
        ) : (
          <div className="profile-images-container d-flex gap-2 flex-column ">
            {images.map((image, index) => (
              <div key={index} className="image-preview-container">
                <figure>
                  {fieldType === "ProductionImages" &&
                  videoExtensions.some((ext) =>
                    image.toLowerCase().endsWith(ext)
                  ) ? (
                    <video
                      src={image}
                      className="h-100 object-fit-cover w-100"
                      controls
                    ></video>
                  ) : fieldType === "ProductionImages" ? (
                    <img src={image} alt={`uploaded-${index}`} />
                  ) : fieldType === "ProductImages" &&
                    videoExtensions.some((ext) =>
                      image.toLowerCase().endsWith(ext)
                    ) ? (
                    <video
                      src={image}
                      className="h-100 object-fit-cover w-100"
                      controls
                    ></video>
                  ) : fieldType === "ProductImages" ? (
                    <img src={image} alt={`uploaded-${index}`} />
                  ) : (
                    ""
                  )}
                </figure>
                <div className="d-flex justify-content-end align-items-center gap-2 w-100 ">
                  {fieldType === "ProductImages" &&
                  !videoExtensions.some((ext) =>
                    image.toLowerCase().endsWith(ext)
                  ) ? (
                    <button
                      type="button"
                      onClick={() => handleImageChoose(image)}
                      className="add__colors__image d-flex align-items-center  gap-2"
                    >
                      Установить как цвет <IoColorPaletteOutline />
                    </button>
                  ) : null}

                  <button
                    type="button"
                    onClick={() => handleRemoveImage(image)}
                    className="add__colors__image d-flex align-items-center  gap-2"
                  >
                    Удалить <MdOutlineDeleteForever />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default ProductImages;
