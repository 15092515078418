import { aboutUsPageData } from '../../../constants/aboutUsPageData';
import './AboutSection.scss';

export const AboutSection = () => {
  return (
    <div className="about-us-page__section">
      {aboutUsPageData.map((el) => (
        <div key={el.id} className="d-flex flex-column flex-md-row my-5">
          <div>
            <img src={el.img} alt={el.title} />
          </div>
          <div className="mt-5 mt-md-0">
            <h4>{el.title}</h4>
            <p>{el.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
