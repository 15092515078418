import { $api } from "../../api/axios";
import {
  NEW_COLLECTION_LIST_FAIL,
  NEW_COLLECTION_LIST_REQUEST,
  NEW_COLLECTION_LIST_SUCCESS,
} from "./../constants/NewCollcetionConstants";

// NEW COLLECTION GET ITEMS ACTION
export const newCollectionCatalogAction = () => async (dispatch) => {
  try {
    dispatch({
      type: NEW_COLLECTION_LIST_REQUEST,
    });
    const { data } = await $api.get(`/catalogs/new-collection`);
    dispatch({ type: NEW_COLLECTION_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: NEW_COLLECTION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
