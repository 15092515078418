import React from "react";
import { BiSolidMessageDetail } from "react-icons/bi";

const ApplicationChatEmpty = () => {
  return (
    <div className="welcome-chat-wrapper">
      <div className="welcome-avatar mb-3">
        <BiSolidMessageDetail />
      </div>
      <h4>Добро пожаловать в чат оптовых заявок</h4>
      <p className="text-center">
        Здесь вы можете разместить свою заявку на оптовую покупку товаров, и
        квалифицированные поставщики свяжутся с вами напрямую.
      </p>
    </div>
  );
};

export default ApplicationChatEmpty;
