import moment from "moment";
import "moment/locale/ru";

const ProfileUserInfo = ({ userInfo }) => {
  return (
    <div className="d-flex flex-column align-items-center gap-2">
      <h5>
        <strong>{userInfo ? userInfo.name : ""}</strong>
      </h5>
      <p>
        дата регистрации:{" "}
        {userInfo ? moment(userInfo.createdAt).format("LL") : ""}
      </p>
    </div>
  );
};

export default ProfileUserInfo;
