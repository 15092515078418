import { useEffect, useRef } from "react";
import { teamImages } from "../constants/aboutUsPageData";

const useCarousel = () => {
  const radius = 200;
  const autoRotate = true;
  const rotateSpeed = -200;
  const imgWidth = 140;
  const imgHeight = 220;

  const odragRef = useRef(null);
  const ospinRef = useRef(null);
  const aImgRef = useRef([]);
  const animationFrameRef = useRef(null);

  useEffect(() => {
    const odrag = odragRef.current;
    const ospin = ospinRef.current;

    let startX = 0;
    let startY = 0;
    let deltaX = 0;
    let deltaY = 0;
    let rotationX = 0;
    let rotationY = 10;

    ospin.style.width = imgWidth + "px";
    ospin.style.height = imgHeight + "px";

    const applyTransform = () => {
      let newY = rotationY;
      if (newY > 180) newY = 180;
      if (newY < 0) newY = 0;

      odrag.style.transform = `rotateX(${-newY}deg) rotateY(${rotationX}deg)`;
    };

    const playSpin = (yes) => {
      if (ospin) {
        ospin.style.animationPlayState = yes ? "running" : "paused";
      }
    };

    const ground = document.querySelector("#ground");
    ground.style.width = radius * 3 + "px";
    ground.style.height = radius * 3 + "px";

    const init = (delayTime) => {
      teamImages?.forEach((product, index) => {
        if (product.img) {
          const img = aImgRef.current[index];
          img.style.transform = `rotateY(${
            index * (360 / teamImages.length)
          }deg) translateZ(${radius}px)`;
          img.style.transition = "transform 1s";
          img.style.transitionDelay = `${
            delayTime || (teamImages.length - index) / 4
          }s`;
        }
      });
    };

    init();

    if (autoRotate) {
      let animationName = rotateSpeed > 0 ? "spin" : "spinRevert";
      ospin.style.animation = `${animationName} ${Math.abs(
        rotateSpeed
      )}s infinite linear`;
    }

    const handlePointerMove = (e) => {
      e = e || window.event;
      const nX = e.clientX;
      const nY = e.clientY;
      deltaX = nX - startX;
      deltaY = nY - startY;
      rotationX += deltaX * 0.1;
      rotationY += deltaY * 0.1;
      applyTransform();
      startX = nX;
      startY = nY;
    };

    const handlePointerUp = () => {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = requestAnimationFrame(animateSpin);
      document.removeEventListener("mousemove", handlePointerMove);
      document.removeEventListener("mouseup", handlePointerUp);
    };

    const animateSpin = () => {
      deltaX *= 0.95;
      deltaY *= 0.95;
      rotationX += deltaX * 0.1;
      rotationY += deltaY * 0.1;
      applyTransform();
      playSpin(false);
      if (Math.abs(deltaX) < 0.5 && Math.abs(deltaY) < 0.5) {
        playSpin(true);
      } else {
        animationFrameRef.current = requestAnimationFrame(animateSpin);
      }
    };

    const handlePointerDown = (e) => {
      cancelAnimationFrame(animationFrameRef.current);
      e = e || window.event;
      startX = e.clientX;
      startY = e.clientY;

      document.addEventListener("mousemove", handlePointerMove);
      document.addEventListener("mouseup", handlePointerUp);
    };

    if (odrag) {
      odrag.addEventListener("mousedown", handlePointerDown);
    }

    return () => {
      if (odrag) {
        odrag.removeEventListener("mousedown", handlePointerDown);
      }
      document.removeEventListener("mousemove", handlePointerMove);
      document.removeEventListener("mouseup", handlePointerUp);
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, [autoRotate, rotateSpeed]);
  return {
    odragRef,
    ospinRef,
    aImgRef,
    teamImages,
  };
};

export default useCarousel;
