import React from "react";
import { ContuctUs } from "../components/common/callUs/ContuctUs";
import { useParams } from "react-router-dom";

import "./styles/Catalog.scss";
import { Breadcrumbs } from "../components/common/breadCrumbs/Breadcrumbs";
import CatalogCards from "../components/catalog/catalogCards/CatalogCards";
import { Seo } from "../seo/Seo";

const Catalog = () => {
  const { pageNumber } = useParams();

  return (
    <div className="catalog">
      <Seo
        title="Товары"
        description="Сделайте свой выбор из эксклюзивных предложений в нашем каталоге."
        name="Манекен"
        type="product"
        keywords="Maneken, Манекен, Одежда оптом, Производство одежды, Женская одежда, Мужская одежда, Детская одежда, Ткани, Фулфилмент, Fulfilment, Конструктор одежды"
      />
      <div className="container position-relative">
        <div className="catalog__sort d-flex align-items-center justify-content-center flex-column gap-2 flex-lg-row justify-content-lg-between">
          <div>
            <h2>Товары</h2>
            <Breadcrumbs />
          </div>
        </div>
        <CatalogCards pageNumber={pageNumber} />
      </div>
      <ContuctUs />
    </div>
  );
};

export default Catalog;
