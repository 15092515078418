import React from "react";
import {
  accessories,
  categories,
  childGirlBoy,
  maleCategories,
  shoes,
} from "../../../../../constants/catalogCategoriesSortFilterData";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import {
  setActiveAgeGroup,
  setSelectedCategory,
} from "../../../../../redux/slices/CatalogSlice";

const SelectedCategoryDrawer = ({
  openChildSubCategory,
  selectedChildGroup,
  onClose,
  activeCategoryType,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = queryString.parse(location.search);

  const selectCategory = (category) => {
    dispatch(setSelectedCategory(category));
    const newSearchParams = { ...searchParams, category: category };
    const newSearch = queryString.stringify(newSearchParams);
    navigate(`?${newSearch}`);
    navigate("/catalogs");
    onClose();
  };

  const handleTitleClick = (ageGroup) => {
    dispatch(setActiveAgeGroup(ageGroup));
    navigate("/catalogs");
  };

  const selectGirlBoy = (category) => {
    dispatch(setSelectedCategory(category));
    navigate("/catalogs");
    onClose();
  };

  const uniqueCategories = [
    ...new Set([
      ...categories,
      ...maleCategories,
      ...accessories,
      ...shoes,
      ...childGirlBoy.flatMap((item) => item.categories),
    ]),
  ];

  return (
    <React.Fragment>
      {activeCategoryType === "Мужчинам" && (
        <div className="male-drawer px-4 py-3" id="style__scroll__profile">
          <ul>
            {maleCategories.map((el, index) => (
              <li
                key={index}
                onClick={() => {
                  selectCategory(el);
                }}
              >
                {el}
              </li>
            ))}
          </ul>
        </div>
      )}

      {activeCategoryType === "Новинки" && (
        <div className="male-drawer px-4 py-3" id="style__scroll__profile">
          <ul>
            {uniqueCategories?.map((el, index) => (
              <li
                key={index}
                onClick={() => {
                  selectCategory(el);
                }}
              >
                {el}
              </li>
            ))}
          </ul>
        </div>
      )}

      {(activeCategoryType === "Все товары" ||
        activeCategoryType === "Оптом" ||
        activeCategoryType === "Розница") && (
        <div className="male-drawer px-4 py-3" id="style__scroll__profile">
          <ul>
            {uniqueCategories?.map((el, index) => (
              <li
                key={index}
                onClick={() => {
                  selectCategory(el);
                }}
              >
                {el}
              </li>
            ))}
          </ul>
        </div>
      )}

      {activeCategoryType === "Женщинам" && (
        <div className="male-drawer px-4 py-3" id="style__scroll__profile">
          <ul>
            {categories.map((el, index) => (
              <li
                key={index}
                onClick={() => {
                  selectCategory(el);
                }}
              >
                {el}
              </li>
            ))}
          </ul>
        </div>
      )}

      {activeCategoryType === "Детям" && (
        <div className="male-drawer px-4 py-3" id="style__scroll__profile">
          <ul>
            {childGirlBoy?.map((el, index) => (
              <li
                onMouseEnter={() => openChildSubCategory(el.categories)}
                key={index}
                onClick={() => handleTitleClick(el.ageGroup)}
              >
                {el.ageGroup}
              </li>
            ))}
          </ul>
        </div>
      )}

      {activeCategoryType === "Детям" && (
        <div
          className="male-drawer child-sub-drawer px-4 py-3"
          id="style__scroll__profile"
        >
          <ul>
            {selectedChildGroup?.map((category, index) => (
              <li key={index} onClick={() => selectGirlBoy(category)}>
                {category}
              </li>
            ))}
          </ul>
        </div>
      )}

      {activeCategoryType === "Аксессуары" && (
        <div className="male-drawer px-4 py-3" id="style__scroll__profile">
          <ul>
            {accessories?.map((el, index) => (
              <li
                key={index}
                onClick={() => {
                  selectCategory(el);
                }}
              >
                {el}
              </li>
            ))}
          </ul>
        </div>
      )}
      {activeCategoryType === "Обувь" && (
        <div className="male-drawer px-4 py-3" id="style__scroll__profile">
          <ul>
            {shoes?.map((el, index) => (
              <li
                key={index}
                onClick={() => {
                  selectCategory(el);
                }}
              >
                {el}
              </li>
            ))}
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};

export default SelectedCategoryDrawer;
