import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeColors: 0,
  activeColorsImg: "",
  color: [],
  selectedOptionIndex: 0,
  qty: 4,
  size: [],
  count: [],
  ratings: 0,
  comment: "",
  isBrandCatalogOpen: false,
  activeSize: 0,
};

const singleCatalogItemSlice = createSlice({
  name: "singleCatalogItem",
  initialState,
  reducers: {
    setActiveColors: (state, action) => {
      state.activeColors = action.payload;
    },
    setActiveColorsImg: (state, action) => {
      state.activeColorsImg = action.payload;
    },
    setColors: (state, action) => {
      state.color = action.payload;
    },
    setSelectedOptionIndex: (state, action) => {
      state.selectedOptionIndex = action.payload;
    },
    setQty: (state, action) => {
      state.qty = action.payload;
    },
    setSizes: (state, action) => {
      state.size = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setRatings: (state, action) => {
      state.ratings = action.payload;
    },
    setComment: (state, action) => {
      state.comment = action.payload;
    },
    setIsBrandCatalogOpen: (state, action) => {
      state.isBrandCatalogOpen = action.payload;
    },
    setActiveSize: (state, action) => {
      state.activeSize = action.payload;
    },
  },
});

export const {
  setActiveColors,
  setActiveColorsImg,
  setColors,
  setSelectedOptionIndex,
  setQty,
  setSizes,
  setCount,
  setRatings,
  setComment,
  setIsBrandCatalogOpen,
  setActiveSize,
} = singleCatalogItemSlice.actions;

export const selectActiveColors = (state) =>
  state.singleCatalogItem.activeColors;
export const selectColorsImg = (state) =>
  state.singleCatalogItem.activeColorsImg;
export const selectColor = (state) => state.singleCatalogItem.color;
export const selectOptionIndex = (state) =>
  state.singleCatalogItem.selectOptionIndex;
export const selectQty = (state) => state.singleCatalogItem.qty;
export const selectSizes = (state) => state.singleCatalogItem.size;
export const selectCount = (state) => state.singleCatalogItem.count;
export const selectRatings = (state) => state.singleCatalogItem.ratings;
export const selectComment = (state) => state.singleCatalogItem.comment;
export const selectBrandCatalogOpen = (state) =>
  state.singleCatalogItem.isBrandCatalogOpen;
export const selectActiveSize = (state) => state.singleCatalogItem.activeSize;

export default singleCatalogItemSlice.reducer;
