import React, { useEffect, memo } from "react";
import currency from "currency.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchExchangeRate } from "../../../redux/slices/ExchageRateSlice";

export const CurrencyConverter = memo(
  ({ selectedModel, selectedQty, total }) => {
    const dispatch = useDispatch();

    const exchangeRate = useSelector(
      (state) => state.exchangeRate.exchangeRate
    );
    const status = useSelector((state) => state.exchangeRate.status);

    useEffect(() => {
      dispatch(fetchExchangeRate());
    }, [dispatch]);

    const calculatePriceInRUB = () => {
      if (status === "succeeded" && selectedModel?.price && selectedQty) {
        const priceInKGS =
          (selectedModel.qty !== 1
            ? selectedModel.price
            : selectedModel.samplePrice) * selectedQty;
        const priceInRUB = currency(priceInKGS).divide(exchangeRate.KGS).value;
        const formattedPrice = new Intl.NumberFormat("ru-RU", {
          style: "currency",
          currency: "RUB",
        }).format(priceInRUB);
        return formattedPrice;
      } else if (total !== null && total !== undefined) {
        if (total === 0) {
          return new Intl.NumberFormat("ru-RU", {
            style: "currency",
            currency: "RUB",
          }).format(0);
        }

        const priceInRUB = currency(total).divide(exchangeRate.KGS).value;
        const formattedPrice = new Intl.NumberFormat("ru-RU", {
          style: "currency",
          currency: "RUB",
        }).format(priceInRUB);
        return formattedPrice;
      }
      return "-";
    };

    return <strong>{calculatePriceInRUB()}</strong>;
  }
);
