import { useMemo } from "react";

export const useProductState = () => {
  const initialState = useMemo(
    () => ({
      title: "",
      articul: "",
      newItem: "new",
      price: 0,
      oldPrice: 0,
      countInStock: 0,
      textile: "",
      madeIn: "",
      orderFrom: "catalog",
      brand: "",
      qtyOfGoodsInPack: 0,
    }),
    []
  );

  return { initialState };
};
