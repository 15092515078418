import { useEffect, useRef } from "react";

const useYandexMetrika = () => {
  const effectRun = useRef(false);

  useEffect(() => {
    if (effectRun.current === false) {
      const yandexScript = document.createElement("script");
      yandexScript.type = "text/javascript";
      yandexScript.innerHTML = `
        (function(m,e,t,r,i,k,a){
          m[i]=m[i] || function(){ (m[i].a=m[i].a||[]).push(arguments) };
          m[i].l=1*new Date();
          k=e.createElement(t), a=e.getElementsByTagName(t)[0];
          k.async=1; k.src=r;
          a.parentNode.insertBefore(k,a)
        })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(96136979, "init", {
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
        });
      `;
      document.head.appendChild(yandexScript);

      return () => {
        effectRun.current = true;
      };
    }
  }, []);

  return null; // Возвращаем null, так как этот хук не возвращает компонент
};

export default useYandexMetrika;
