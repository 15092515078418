export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_INCREASE_ITEM = 'CART_INCREASE_ITEM';
export const CART_DECREASE_ITEM = 'CART_DECREASE_ITEM';
export const CART_CLEAR_ITEMS = 'CART_CLEAR_ITEMS';
export const CART_SAVE_SHIPPING_ADDRESS = 'CART_SAVE_SHIPPING_ADDRESS';
export const CART_PAYMENT_METHOD = 'CART_PAYMENT_METHOD';

export const UPDATE_CART_DATA = 'UPDATE_CART_DATA'

