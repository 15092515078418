import React, { useEffect, useState } from "react";
import { ContuctUs } from "../components/common/callUs/ContuctUs";
import { useSelector, useDispatch } from "react-redux";
import { productionCatalogAction } from "../redux/Actions/ProductionAction";
import { Loader } from "../components/common/loader/Loader";
import "./styles/ProductionPage.scss";
import LoadingError from "../components/common/errorMessage/LoadingError";
import BrandNameSlider from "../components/common/BrandNameSlider/BrandNameSlider";
import ProductionMain from "../components/productionPage/productionMain/ProductionMain";
import { Seo } from "../seo/Seo";
import ProductionCards from "../components/productionPage/productionCards/ProductionCards";
import { Breadcrumbs } from "../components/common/breadCrumbs/Breadcrumbs";

const ProductionPage = () => {
  const dispatch = useDispatch();
  const productionCatalog = useSelector((state) => state.productionCatalog);
  const { catalogs, loading, error } = productionCatalog;

  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    dispatch(productionCatalogAction());
  }, [dispatch]);

  const scrollToElement = (id) => {
    const container = document.getElementById(id);
    container.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className="production">
      {loading ? (
        <Loader />
      ) : error ? (
        <LoadingError variant={alert()}>{error}</LoadingError>
      ) : (
        <React.Fragment>
          <Seo
            title="Производство"
            description="Производители одежды"
            name="Манекен"
            type="product"
            keywords="Maneken, Манекен, Одежда оптом, производство одежды, Production, Производство, Одежда оптом, Маркетплейс, Marketplace, Женская одежда, Мужская одежда, Детская одежда, Швейное производство"
          />
          <div className="container mt-4">
            <h1>Производство</h1>
            <Breadcrumbs />
          </div>

          <ProductionCards />

          <BrandNameSlider
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            catalogs={catalogs}
            scrollToElement={scrollToElement}
          />

          {catalogs?.map((el, index) => (
            <React.Fragment key={el._id}>
              <ProductionMain catalogs={el} index={index} />
            </React.Fragment>
          ))}
          <ContuctUs />
        </React.Fragment>
      )}
    </section>
  );
};

export default ProductionPage;
