import React from "react";

const ProductDescription = ({ setDescription, description }) => {
  return (
    <div className="mb-4">
      <label htmlFor="product_description" className="form-label">
        Описание товара
      </label>
      <textarea
        name="product_description"
        id="product_description"
        rows="7"
        required
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        className="form-control"
      ></textarea>
    </div>
  );
};

export default ProductDescription;
