import React, { memo } from "react";
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Flex,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { HiDotsHorizontal } from "react-icons/hi";
import { TfiEye } from "react-icons/tfi";
import { MdDeleteForever, MdReplyAll } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteApplicationAction,
  getApplicationsAction,
  getApplicationsListAction,
} from "../../../../../redux/Actions/ApplicationsAction";
import {
  selectApplicationCategory,
  selectSearch,
  setApplicationInfo,
} from "../../../../../redux/slices/ChatSlice";

const AppListsAdditionalBtns = memo(
  ({ message, accessChat, handleUpdateViewCount }) => {
    const dispatch = useDispatch();
    const applicationDelete = useSelector((state) => state.applicationDelete);
    const { deleting } = applicationDelete;
    const search = useSelector(selectSearch);
    const selectedApplicationCategory = useSelector(selectApplicationCategory);
    const viewCount = useSelector((state) => state.updateViewCount.viewCount);
    const userInfo = useSelector((state) => state.userLogin.userInfo);
    const onDeleteChat = (id) => {
      dispatch(deleteApplicationAction(id))
        .then(() => {
          dispatch(
            getApplicationsListAction(search, selectedApplicationCategory)
          );
          dispatch(getApplicationsAction());
        })
        .catch((error) => {
          console.error("Error deleting item:", error);
        });
    };

    return (
      <>
        {deleting && (
          <Spinner
            position="absolute"
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            top="10px"
            right="50px"
            size="xs"
          />
        )}
        <div className="message-icons d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center gap-1">
            <TfiEye />{" "}
            <span style={{ fontSize: "10px" }}>
              {viewCount?.viewCount[message._id] || message.viewCount}
            </span>
          </div>

          <Popover isLazy placement="bottom-end">
            <PopoverTrigger>
              <button type="button">
                <HiDotsHorizontal />
              </button>
            </PopoverTrigger>
            <PopoverContent style={{ width: "inherit" }}>
              <PopoverBody>
                {message?.userId !== userInfo?.id ? (
                  <Flex>
                    <button
                      type="button"
                      onClick={() => {
                        accessChat(message.userId);
                        dispatch(setApplicationInfo(message));
                        handleUpdateViewCount(message._id);
                      }}
                      disabled={userInfo ? false : true}
                    >
                      <Center>
                        <MdReplyAll />
                        <Text p={1}>Написать</Text>
                      </Center>
                    </button>
                  </Flex>
                ) : null}
                {message?.userId === userInfo?.id ? (
                  <Flex>
                    <button
                      type="button"
                      onClick={() => onDeleteChat(message._id)}
                    >
                      <Center>
                        <MdDeleteForever />
                        <Text p={1}>Удалить</Text>
                      </Center>
                    </button>
                  </Flex>
                ) : null}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </div>
      </>
    );
  }
);

export default AppListsAdditionalBtns;
