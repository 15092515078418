export const cartValidate = (values) => {
  const errors = {};

  if (!values.selectedService) {
    errors.selectedService = "Выберите способ доставки";
  }
  if (!values.country) {
    errors.country = "Укажите страну";
  }
  if (!values.city) {
    errors.city = "Укажите город";
  }
  if (!values.address) {
    errors.address = "Укажите адрес";
  }
  if (!values.nameLastName) {
    errors.nameLastName = "Укажите Ф.И.О получателя";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Укажите номер телефона";
  }
  if (!values.paymentMethod) {
    errors.paymentMethod = "Укажите способ оплаты";
  }
  if (
    (values.selectedService === "cdek - до склада" &&
      !values.selectedPickupPointInfo) ||
    (values.selectedService === "cdek - курьерская доставка" &&
      !values.selectedSettlement) ||
    (values.selectedService === "Ылдам-Экспресс" &&
      !values.selectedKgSettlement)
  ) {
    errors.selectedPickupPointInfo = "Укажите пункт выдачи";
  }

  return errors;
};
