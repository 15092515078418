import React, { useState } from "react";
import { CurrencyConverter } from "../../../common/currencyConverter/CurrencyConverter";
import moment from "moment";
import "moment/locale/ru";
import ProgressSteps from "../../../common/progressSteps/ProgressSteps";
import { Link } from "react-router-dom";
import showOrderSvg from "../../../../assets/images/profile/eye.svg";
import { useSelector } from "react-redux";
import { selectCurrency } from "../../../../redux/slices/CatalogSlice";

export const OrderedListsView = ({ el }) => {
  const selectedCurrency = useSelector(selectCurrency);
  const [showProgressSteps, setShowProgressSteps] = useState(false);
  const tableHeadText = ["заказ №", "статус", "дата", "общая цена"];

  const handleButtonClick = () => {
    setShowProgressSteps(!showProgressSteps);
  };
  return (
    <>
      <table className="table">
        <thead>
          <tr
            className={`${
              el.isPaid ? "alert alert-success" : "alert alert-danger"
            } d-flex flex-column d-sm-table-row`}
          >
            {tableHeadText.map((el) => (
              <th key={el}>{el}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr
            className={`${
              el.isPaid ? "alert alert-success" : "alert alert-danger"
            } d-flex flex-column d-sm-table-row`}
          >
            <td>
              <Link
                to={`/orders/${el._id}`}
                className="d-flex align-items-center gap-2"
              >
                <img src={showOrderSvg} alt="Показать заказ" />
                {el.orderNumber}
              </Link>
            </td>
            <td>{el.isPaid ? <>Оплачено</> : <>Не оплачено</>}</td>
            <td>
              {el.isPaid
                ? moment(el.paidAt).calendar()
                : moment(el.createdAt).calendar()}
            </td>
            <td>
              {selectedCurrency === "KGS" ? (
                <strong>{el.totalPrice} KGS</strong>
              ) : (
                <CurrencyConverter total={el.totalPrice} />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="progress-show-wrapper">
        <button
          onClick={handleButtonClick}
          className="progress-show-wrapper__btn"
        >
          Информация о заказе №{el.orderNumber}
        </button>
      </div>
      {showProgressSteps && <ProgressSteps order={el} />}
    </>
  );
};
