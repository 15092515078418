import React, { useEffect, useState } from "react";

const CapacityAdd = ({
  productionCapacity,
  setProductionCapacity,
  placeholder,
  label,
  fieldType,
  descriptionText,
  productionCapacityError,
  setProductionCapacityError,
}) => {
  const [input1, setInput1] = useState("");

  useEffect(() => {
    if (productionCapacity && productionCapacity.length > 0) {
      setInput1(productionCapacity[0].capacity || "");
    }
  }, [productionCapacity]);

  useEffect(() => {
    if (input1) {
      setProductionCapacity([{ title: "Мощность", capacity: input1 }]);
    }
  }, [input1, setProductionCapacity]);

  return (
    <React.Fragment>
      <p className="add-production-btn ">{descriptionText}</p>
      <div className="mb-4 product__owner__wrapper">
        <label htmlFor={fieldType} className="mt-4">
          {label}
        </label>
        <input
          className={`form-control ${
            productionCapacityError ? "cart-border-error" : ""
          } `}
          type="text"
          id={fieldType}
          placeholder={placeholder}
          value={input1}
          onChange={(e) => {
            setInput1(e.target.value);
            if (productionCapacityError) {
              setProductionCapacityError("");
            }
          }}
        />
        {productionCapacityError && (
          <p className="cart-error-message">{productionCapacityError}</p>
        )}
      </div>
    </React.Fragment>
  );
};

export default CapacityAdd;
