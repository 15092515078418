import { Link } from "react-router-dom";
import queryString from "query-string";
import { NextArrowSvg, PreviousArrowSvg } from "../../../assets/svg/Svg";

import "./CatalogPagination.scss";
import useSearchParamsNavigation from "../../../hooks/useSearchParamsNavigation";

export const CatalogPagination = ({
  page,
  pages,
  keyword = "",
  category = "",
  sortBy = "",
  hasPreviousPage,
  hasNextPage,
}) => {
  const { searchParams, navigate, location } = useSearchParamsNavigation({
    keyword,
    category,
    sortBy,
    page,
  });

  const handlePageChange = (pageNumber) => {
    const newSearchParams = { ...searchParams, pageNumber: pageNumber };
    const newSearch = queryString.stringify(newSearchParams);
    navigate(`${location.pathname}?${newSearch}`);
  };

  return (
    pages > 1 && (
      <div className="catalog__pagination">
        {hasPreviousPage && (
          <li className="page-item">
            <Link
              to={{
                pathname: keyword
                  ? `/catalogs/search/${keyword}/page/${page - 1}`
                  : `/catalogs/page/${page - 1}`,
                search: queryString.stringify(searchParams),
              }}
              onClick={() => handlePageChange(page - 1)}
            >
              <PreviousArrowSvg />
            </Link>
          </li>
        )}
        {[...Array(pages).keys()].map((x) => (
          <li
            className={`page-item ${x + 1 === page ? "active " : ""}`}
            key={x + 1}
          >
            <Link
              to={{
                pathname: keyword
                  ? `/catalogs/search/${keyword}/page/${x + 1}`
                  : `/catalogs/page/${x + 1}`,
                search: queryString.stringify(searchParams),
              }}
              onClick={() => handlePageChange(x + 1)}
            >
              {x + 1}
            </Link>
          </li>
        ))}
        {hasNextPage && (
          <li className="page-item">
            <Link
              to={{
                pathname: keyword
                  ? `/catalogs/search/${keyword}/page/${page + 1}`
                  : `/catalogs/page/${page + 1}`,
                search: queryString.stringify(searchParams),
              }}
              onClick={() => handlePageChange(page + 1)}
            >
              <NextArrowSvg />
            </Link>
          </li>
        )}
      </div>
    )
  );
};
