import React, { useEffect } from "react";
import "./ProfileAddProduction.scss";
import {
  createProductionItemAction,
  editProductionAction,
  productionCatalogAction,
  updateProductionAction,
} from "../../../redux/Actions/ProductionAction";
import { useDispatch, useSelector } from "react-redux";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from "@chakra-ui/react";
import AddProductionInfo from "./addProductionInfo/AddProductionInfo";
import AddProductionCatalogItems from "./addProductionCatalogItems/AddProductionCatalogItems";
import { PRODUCTION_UPDATE_RESET } from "../../../redux/constants/Production";
import ProductionLoadersErrors from "./productionLoadersErrors/ProductionLoadersErrors";
import { ProfileProductionState } from "../../../context/ProfileProductionProvider";
import { validateForm } from "./../../../helper/ValidateProductionForm";
import ProfileProductionCatalogProvider from "../../../context/ProfileProductionCatalogProvider";

/**
 * Renders a form for adding production information.
 *
 * The form includes fields for brand name, contact information, specialization, production capacity, quantity of equipment, and a production catalog.
 * The form data is stored in state variables and can be submitted for further processing.
 *
 * @returns {JSX.Element} The rendered form for adding production information.
 *
 * @example
 * <ProfileAddProduction />
 */
const ProfileAddProduction = ({ userInfo }) => {
  const {
    brandName,
    setBrandName,
    brandLogo,
    setBrandLogo,
    brandImage,
    setBrandImage,
    productionPhoneNumber,
    setProductionPhoneNumber,
    specialization,
    setSpecialization,
    productionCapacity,
    setProductionCapacity,
    quantityOfEquipment,
    setQuantityOfEquipment,
    productionCatalog,
    setProductionCatalog,
    specializationError,
    setBrandNameError,
    setBrandLogoError,
    setBrandImageError,
    setProductionPhoneNumberError,
    setSpecializationError,
    setProductionCapacityError,
    setQuantityOfEquipmentError,
    description,
    setDescriptionError,
    setDescription,
    webUrl,
    setWebUrl,
    adsCards,
    setAdsCards,
  } = ProfileProductionState();

  const dispatch = useDispatch();
  const toast = useToast();

  const deleteProfileProductionCatalogItem = useSelector(
    (state) => state.deleteProfileProductionCatalogItem
  );
  const {
    loading: deleteLoading,
    success,
    error: errorDelete,
  } = deleteProfileProductionCatalogItem;

  const {
    catalogs,
    loading: loadingProductionCatalog,
    error: errorProductionCatalog,
  } = useSelector((state) => state.productionCatalog);

  const filteredProducts = catalogs
    ? catalogs?.filter((el) => el.email === userInfo.email)
    : null;

  const filteredProduct = filteredProducts.find((item) => item._id);
  const filteredProductsId = filteredProduct ? filteredProduct._id : null;

  const {
    loading: loadingProduction,
    error: errorProduction,
    product: productProduction,
  } = useSelector((state) => state.productionEdit);

  const { loading, error, product } = useSelector(
    (state) => state.productionCreate
  );

  const {
    loading: loadingUpdate,
    error: updateError,
    success: successUpdate,
  } = useSelector((state) => state.productionUpdate);

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCTION_UPDATE_RESET });
    } else {
      if (filteredProductsId) {
        if (
          !productProduction ||
          productProduction._id !== filteredProductsId
        ) {
          dispatch(editProductionAction(filteredProductsId));
        } else {
          setBrandName(productProduction.brandInfo.brandName);
          setBrandLogo(productProduction.brandInfo.brandLogo);
          setBrandImage(productProduction.brandInfo.brandImage);
          setProductionPhoneNumber(productProduction.productionPhoneNumber);
          setSpecialization(productProduction.specialization);
          setProductionCapacity(productProduction.productionCapacity);
          setQuantityOfEquipment(productProduction.quantityOfEquipment);
          setProductionCatalog(productProduction?.productionCatalog || []);
          setDescription(productProduction?.description);
          setWebUrl(productProduction?.webUrl);
          setAdsCards(productProduction?.adsCards || []);
        }
      }
    }
  }, [
    dispatch,
    product,
    filteredProductsId,
    successUpdate,
    toast,
    productProduction,
    setBrandImage,
    setBrandLogo,
    setBrandName,
    setProductionCapacity,
    setProductionPhoneNumber,
    setQuantityOfEquipment,
    setSpecialization,
    setProductionCatalog,
    setDescription,
    setWebUrl,
    setAdsCards,
  ]);

  useEffect(() => {
    dispatch(productionCatalogAction());
  }, [dispatch, success]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateForm(
      brandName,
      brandLogo,
      brandImage,
      productionPhoneNumber,
      specialization,
      productionCapacity,
      quantityOfEquipment,
      description,
      setDescriptionError,
      setBrandNameError,
      setBrandLogoError,
      setBrandImageError,
      setProductionPhoneNumberError,
      setSpecializationError,
      setProductionCapacityError,
      setQuantityOfEquipmentError
    );
    const productionData = {
      brandName,
      brandLogo,
      brandImage,
      productionPhoneNumber,
      specialization,
      productionCapacity,
      quantityOfEquipment,
      productionCatalog,
      email: userInfo.email,
      description,
      webUrl,
      adsCards,
    };

    if (isValid) {
      if (filteredProductsId) {
        dispatch(
          updateProductionAction({ _id: filteredProductsId, ...productionData })
        );
        toast({
          title: "Производство успешно обновлено!",
          status: "success",
          isClosable: true,
          position: "top-right",
          duration: 1000,
        });
      } else {
        dispatch(createProductionItemAction(productionData));
        toast({
          title: "Производство успешно добавлено!",
          status: "success",
          isClosable: true,
          position: "top-right",
          duration: 1000,
        });
      }
    } else {
      toast({
        title: "Заполните все обязательные поля!",
        status: "error",
        isClosable: true,
        position: "top-right",
        duration: 2000,
      });
    }
  };

  return (
    <section className="profile__production__add">
      <form
        className="d-flex align-items-center flex-column"
        onSubmit={handleSubmit}
      >
        <Tabs isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab>
              <strong className={` ${specializationError ? "error-text" : ""}`}>
                Общая информация производства
              </strong>
            </Tab>
            <Tab>
              <strong>Товары производства</strong>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <AddProductionInfo />
            </TabPanel>
            <TabPanel>
              <ProfileProductionCatalogProvider>
                <AddProductionCatalogItems
                  filteredProductsId={filteredProductsId}
                  productionCatalog={productionCatalog}
                  setProductionCatalog={setProductionCatalog}
                />
              </ProfileProductionCatalogProvider>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <ProductionLoadersErrors
          loadingProduction={loadingProduction}
          loadingUpdate={loadingUpdate}
          loadingProductionCatalog={loadingProductionCatalog}
          errorProductionCatalog={errorProductionCatalog}
          updateError={updateError}
          errorProduction={errorProduction}
          loading={loading}
          error={error}
          errorDelete={errorDelete}
          deleteLoading={deleteLoading}
        />
      </form>
    </section>
  );
};

export default ProfileAddProduction;
