import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { AiOutlineStar } from 'react-icons/ai';

import './Ratings.scss';

export const Ratings = ({ value }) => {
  return (
    <div className="icon-style justify-content-lg-start justify-content-start">
      <i className={value >= 1 ? 'icon' : value >= 0.5 ? 'icon-half' : 'icon'}>
        {value >= 1 ? <FaStar /> : value >= 0.5 ? <FaStarHalfAlt /> : <AiOutlineStar className="icon"/>}
      </i>
      <i className={value >= 2 ? 'icon' : value >= 1.5 ? 'icon-half' : 'icon'}>
        {value >= 2 ? <FaStar /> : value >= 1.5 ? <FaStarHalfAlt /> : <AiOutlineStar className="icon"/>}
      </i>
      <i className={value >= 3 ? 'icon' : value >= 2.5 ? 'icon-half' : 'icon'}>
        {value >= 3 ? <FaStar /> : value >= 2.5 ? <FaStarHalfAlt /> : <AiOutlineStar className="icon"/>}
      </i>
      <i className={value >= 4 ? 'icon' : value >= 3.5 ? 'icon-half' : 'icon'}>
        {value >= 4 ? <FaStar /> : value >= 3.5 ? <FaStarHalfAlt /> : <AiOutlineStar className="icon"/>}
      </i>
      <i className={value >= 5 ? 'icon' : value >= 4.5 ? 'icon-half' : 'icon'}>
        {value >= 5 ? <FaStar /> : value >= 4.5 ? <FaStarHalfAlt /> : <AiOutlineStar className="icon"/>}
      </i>
    </div>
  );
};
