import React from "react";
import "./AddProductionCatalogItem.scss";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AddedProductionCatalogView from "./addedProductionCatalogView/AddedProductionCatalogView";
import AddProductionCatalogItem from "./addProductionCatalogItem/AddProductionCatalogItem";
import { ProfileProductionCatalogState } from "../../../../context/ProfileProductionCatalogProvider";
import {
  validateAddedCatalogItems,
  validateAddedColorImages,
} from "../../../../helper/ValidateProfileProductionCatalog";

const AddProductionCatalogItems = ({
  productionCatalog,
  setProductionCatalog,
  filteredProductsId,
}) => {
  const {
    productionTitle,
    setProductionTitle,
    productionOrderFrom,
    productionPrice,
    setProductionPrice,
    productionMainImage,
    setProductionMainImage,
    productionAdditionalImage,
    setProductionAdditionalImage,
    productionSizes,
    setProductionSizes,
    productionTextiles,
    setProductionTextiles,
    productionModelImage,
    setProductionModelImage,
    productionModelFlippedImage,
    setProductionModelFlippedImage,
    productionModelColor,
    setProductionModelColor,
    productionModelColors,
    setProductionModelColors,
    setProductionTitleError,
    setProductionPriceError,
    setProductionMainImageError,
    setProductionSizesError,
    setProductionTextilesError,
    setProductionModelImageError,
    setProductionModelFlippedImageError,
    setProductionModelColorError,
    productWeight,
  } = ProfileProductionCatalogState();

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [IsVisible, setIsVisible] = React.useState(true);
  const [isVisibleModelsAdd, setIsVisibleModelsAdd] = React.useState(false);

  const handleOpenPopup = () => {
    onOpen();
  };

  const handleAddProductionCatalogItem = () => {
    const isValid = validateAddedCatalogItems(
      productionTitle,
      productionPrice,
      productionMainImage,
      productionSizes,
      productionTextiles,
      productionModelImage,
      productionModelFlippedImage,
      productionModelColor,
      setProductionTitleError,
      setProductionPriceError,
      setProductionMainImageError,
      setProductionSizesError,
      setProductionTextilesError,
      setProductionModelImageError,
      setProductionModelFlippedImageError,
      setProductionModelColorError,
      productionModelColors
    );
    if (isValid) {
      setProductionCatalog([
        ...productionCatalog,
        {
          title: productionTitle,
          orderFrom: productionOrderFrom,
          price: productionPrice,
          color: productionModelColor,
          img: productionMainImage,
          modelImg: productionModelImage,
          sizes: productionSizes,
          textiles: productionTextiles,
          modelsColors: productionModelColors,
          additionalImages: productionAdditionalImage,
          weight: +productWeight,
          url: "www.maneken.shop",
        },
      ]);
      setIsVisible(true);
      onClose();
      setProductionTitle("");
      setProductionPrice("");
      setProductionMainImage("");
      setProductionSizes("");
      setProductionTextiles("");
      setProductionModelImage("");
      setProductionModelFlippedImage("");
      setProductionModelColor("");
      setProductionModelColors([]);
      setProductionAdditionalImage([]);
    } else {
      toast({
        title: "Заполните все объязательные поля!",
        status: "error",
        isClosable: true,
        position: "top-right",
        duration: 2000,
      });
    }
  };

  const handleSaveProductionCatalogItem = () => {
    const isValid = validateAddedColorImages(
      productionModelImage,
      productionModelFlippedImage,
      productionModelColor,
      setProductionModelImageError,
      setProductionModelFlippedImageError,
      setProductionModelColorError,
      productionModelColors
    );
    if (isValid) {
      setProductionModelColors([
        ...productionModelColors,
        {
          color: productionModelColor,
          imageFlipped: productionModelFlippedImage,
          img: productionModelImage,
        },
      ]);
      setIsVisibleModelsAdd(false);
      // setProductionModelImage("");
      // setProductionModelFlippedImage("");
      // setProductionModelColor("");
    } else {
      toast({
        title: "Заполните все объязательные поля!",
        status: "error",
        isClosable: true,
        position: "top-right",
        duration: 2000,
      });
    }
  };

  return (
    <React.Fragment>
      {IsVisible && (
        <AddedProductionCatalogView
          productionCatalog={productionCatalog}
          setProductionCatalog={setProductionCatalog}
          handleOpenPopup={handleOpenPopup}
          handleAddProductionCatalogItem={handleAddProductionCatalogItem}
          handleSaveProductionCatalogItem={handleSaveProductionCatalogItem}
          filteredProductsId={filteredProductsId}
        />
      )}

      <Modal size="3xl" onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Добавление</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddProductionCatalogItem
              isVisibleModelsAdd={isVisibleModelsAdd}
              setIsVisibleModelsAdd={setIsVisibleModelsAdd}
              productionCatalog={productionCatalog}
              handleAddProductionCatalogItem={handleAddProductionCatalogItem}
              handleSaveProductionCatalogItem={handleSaveProductionCatalogItem}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};

export default AddProductionCatalogItems;
