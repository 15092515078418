import { Skeleton, Stack } from "@chakra-ui/react";
import React, { useEffect } from "react";

import "./styles/ProductionInfoPage.scss";
import { productionItemAction } from "../redux/Actions/ProductionAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingError from "../components/common/errorMessage/LoadingError";
import ProductionInfoPageHeader from "../components/productionInfoPage/productionInfoPageHeader/ProductionInfoPageHeader";
import ProductionInfoPageMAin from "../components/productionInfoPage/productionInfoPageMain/ProductionInfoPageMAin";
import { Seo } from "./../seo/Seo";

const ProductionInfoPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const production = useSelector((state) => state.production);
  const { productionItem, loading, error } = production;

  useEffect(() => {
    dispatch(productionItemAction(id));
  }, [dispatch, id]);

  return (
    <section className="productionInfoPage__container container">
      <div className="d-flex align-items-center justify-content-center">
        {error && <LoadingError variant="alert-danger">{error}</LoadingError>}
      </div>
      {loading ? (
        <Stack>
          <Skeleton height="160px" borderRadius="lg" mb="20px" mt="20px" />
          <Skeleton height="50px" />
          <Skeleton height="400px" mb="20px" />
        </Stack>
      ) : (
        <div className="mb-5">
          <Seo
            title={productionItem?.brandInfo?.brandName}
            description={productionItem?.description}
            name={productionItem?.brandInfo?.brandName}
            type="product"
            keywords="Maneken, Манекен, Одежда оптом, Маркетплейс, Marketplace, Женская одежда, Мужская одежда, Детская одежда, Швейное производство, Производство одежды, Ткани, Фулфилмент, Fulfilment, Конструктор одежды, Конструктор, Манекен отзывы, Пошив под ключ"
          />
          <ProductionInfoPageHeader productionItem={productionItem} />
          <ProductionInfoPageMAin productionItem={productionItem} />
        </div>
      )}
    </section>
  );
};

export default ProductionInfoPage;
