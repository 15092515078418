export const ContactMap = () => {
  return (
    <section className="contuct__map">
      <div className="container">
        <iframe
          title="map"
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A9da533d270438c1bc565cf3e8a21c3b2baad95796a6eaf394c4dd3ddc5ebc25d&amp;source=constructor"
          width="500"
          height="400"
          frameBorder="0"></iframe>
      </div>
    </section>
  );
};
