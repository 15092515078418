import { Button } from "../../common/button/Button";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import "./ContuctUs.scss";
import { $api } from "../../../api/axios";

export const ContuctUs = () => {
  const [recipient_name, setContactUsName] = useState("");
  const [recipient_phone, setContactUsPhone] = useState("");

  const toast = useToast();

  const sendMail = async () => {
    if (recipient_name && recipient_phone) {
      await $api
        .post(`/send_email`, {
          recipient_name: recipient_name,
          recipient_phone: recipient_phone,
        })
        .then(() => console.log("Message sent successfully"))
        .catch(() => console.log("Ooops... Something went wrong"));

      setContactUsName("");
      setContactUsPhone("");
      toast({
        title: "Сообщение отправлено!",
        status: "success",
        isClosable: true,
        position: "top-right",
        duration: 500,
      });
    } else {
      toast({
        title: "Заполните все поля!",
        status: "info",
        isClosable: true,
        position: "top-right",
        duration: 1000,
      });
    }
  };

  return (
    <section className="contuctUsSection">
      <div className="container">
        <h2>Связаться с нами</h2>
        <form className="contactUsForm d-flex align-items-center flex-column justify-content-center align-items-sm-center flex-sm-column justify-content-sm-center align-items-md-center flex-md-column justify-md-content-center align-items-lg-end flex-lg-row justify-content-lg-between">
          <div className=" d-flex flex-column ">
            <label htmlFor="name">Имя</label>
            <input
              autoComplete="on"
              className="contuctUsNameInput"
              type="text"
              placeholder="Ваше имя"
              value={recipient_name}
              onChange={(e) => setContactUsName(e.target.value)}
              pattern=".{3,15}"
              title="Имя должно содержать от 3 до 15 символов"
              maxLength={15}
              id="name"
            />
          </div>
          <div className="d-flex flex-column  my-4 my-sm-4 my-md-4 my-lg-0">
            <label htmlFor="phone" style={{ marginBottom: "10px" }}>
              Номер телефона
            </label>
            <PhoneInput
              name="phoneNumber"
              type="text"
              country={"ru"}
              enableAreaCodes={true}
              inputProps={{
                id: "phone",
                autoComplete: "on",
                name: "Номер телефона",
                country: "us",
                required: true,
                autoFocus: false,
              }}
              onChange={(phone) => setContactUsPhone(phone)}
              value={recipient_phone}
              containerStyle={{
                width: "100%",
                height: "60px",
              }}
              inputStyle={{
                height: "69px",
                width: "100%",
                background: "#fff",
                border: "none",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                borderRadius: "0",
                color: "rgb(113 128 177)",
              }}
              buttonStyle={{
                background: "rgb(255 255 255 / 0%)",
                border: "none",
                fontSize: "10px",
              }}
              dropdownStyle={{
                fontSize: "10px",
                height: "67px",
                paddingLeft: "10px",
              }}
              placeholder="(996) 999-999"
              required
            />
          </div>
          <Button onClick={() => sendMail()}>Связаться</Button>
        </form>
        <p>
          Нажимая на кнопку "Связаться", я подтверждаю, что ознакомлен и
          согласен с условиями
          <Link to="/privacy-policy">
            <strong> политики конфиденциальности </strong>
          </Link>
          и
          <Link to="/privacy-policy">
            <strong> правилами обработки персональных данных </strong>
          </Link>
        </p>
      </div>
      <img
        className="contuctUsBgImg"
        src="https://res.cloudinary.com/drdqjr8r6/image/upload/v1691647257/bg%20images/IMG_2399_1_cci0rz.png"
        alt="Связаться с нами"
      />
    </section>
  );
};
