export const PRODUCTION_LIST_REQUEST = 'PRODUCTION_LIST_REQUEST';
export const PRODUCTION_LIST_SUCCESS = 'PRODUCTION_LIST_SUCCESS';
export const PRODUCTION_LIST_FAIL = 'PRODUCTION_LIST_FAIL';

export const PRODUCTION_CART_ADD_ITEM = 'PRODUCTION_CART_ADD_ITEM';

export const PRODUCTION_CREATE_REQUEST = 'PRODUCTION_CREATE_REQUEST';
export const PRODUCTION_CREATE_SUCCESS = 'PRODUCTION_CREATE_SUCCESS';
export const PRODUCTION_CREATE_FAIL = 'PRODUCTION_CREATE_FAIL';
export const PRODUCTION_CREATE_RESET = 'PRODUCTION_CREATE_RESET';

export const PRODUCTION_EDIT_REQUEST = 'PRODUCTION_EDIT_REQUEST';
export const PRODUCTION_EDIT_SUCCESS = 'PRODUCTION_EDIT_SUCCESS';
export const PRODUCTION_EDIT_FAIL = 'PRODUCTION_EDIT_FAIL';

export const PRODUCTION_UPDATE_REQUEST = 'PRODUCTION_UPDATE_REQUEST';
export const PRODUCTION_UPDATE_SUCCESS = 'PRODUCTION_UPDATE_SUCCESS';
export const PRODUCTION_UPDATE_FAIL = 'PRODUCTION_UPDATE_FAIL';
export const PRODUCTION_UPDATE_RESET = 'PRODUCTION_UPDATE_RESET';

export const PROFILE_PRODUCTION_CATALOG_DELETE_REQUEST = 'PROFILE_PRODUCTION_CATALOG_DELETE_REQUEST';
export const PROFILE_PRODUCTION_CATALOG_DELETE_SUCCESS = 'PROFILE_PRODUCTION_CATALOG_DELETE_SUCCESS';
export const PROFILE_PRODUCTION_CATALOG_DELETE_FAIL = 'PROFILE_PRODUCTION_CATALOG_DELETE_FAIL';

export const PRODUCTION_ADS_CARDS_GET_REQUEST = 'PRODUCTION_ADS_CARDS_GET_REQUEST';
export const PRODUCTION_ADS_CARDS_GET_SUCCESS = 'PRODUCTION_ADS_CARDS_GET_SUCCESS';
export const PRODUCTION_ADS_CARDS_GET_FAIL = 'PRODUCTION_ADS_CARDS_GET_FAIL';

export const PRODUCTION_DETAILS_REQUEST = 'PRODUCTION_DETAILS_REQUEST';
export const PRODUCTION_DETAILS_SUCCESS = 'PRODUCTION_DETAILS_SUCCESS';
export const PRODUCTION_DETAILS_FAIL = 'PRODUCTION_DETAILS_FAIL';