import { Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import FabricInfoColors from "./fabricInoColors/FabricInfoColors";
import FabricInfoDescription from "./fabricInfoDescription/FabricInfoDescription";
import FabricAdditionalInfo from "./fabricAdditionalInfo/FabricAdditionalInfo";
import { Link } from "react-router-dom";

const FabricInfo = ({ fabricData, setActiveColorsImg, activeColorsImg }) => {
  const [activeColors, setActiveColors] = useState(0);
  
  const whatsAppLink = `https://api.whatsapp.com/send/?phone=996772122111&text=${encodeURIComponent(
    `Здравствуйте! Меня интересует ткань ${fabricData?.title}: ${fabricData?.img}, цвет ткани: ${activeColorsImg}, артикул: ${fabricData?.articul}, цена: ${fabricData?.price}`
  )}`;

  return (
    <div className="fabric-info d-flex flex-column gap-4">
      <FabricInfoColors
        fabricData={fabricData}
        activeColors={activeColors}
        setActiveColors={setActiveColors}
        setActiveColorsImg={setActiveColorsImg}
      />
      <FabricInfoDescription fabricData={fabricData} />
      <FabricAdditionalInfo fabricData={fabricData} />

      <Tooltip
        label="Свяжитесь с нами для получения актуальной информации"
        borderRadius="3px"
        defaultIsOpen
        hasArrow
      >
        <Link to={whatsAppLink} target="_blank" className="fabric-btn">
          Связаться
        </Link>
      </Tooltip>
    </div>
  );
};

export default FabricInfo;
