import Slider from "react-slick";
import ZoomImageOnHover from "../../../common/zoomImageOnHover/ZoomImageOnHover";
import { useEffect } from "react";
import { useRef } from "react";
import { videoExtensions } from "../../../../constants";
import { useSelector } from "react-redux";
import { selectColorsImg } from "../../../../redux/slices/SingleCatalogItemSlice";

export const SliderSection = ({ item }) => {
  const { images } = item;
  const sliderRef = useRef(null);
  const activeColorsImg = useSelector(selectColorsImg);

  useEffect(() => {
    const index = images?.findIndex((color) => color === activeColorsImg);
    // Прокручиваем слайдер к активному слайду
    sliderRef.current?.slickGoTo(index);
  }, [activeColorsImg, images]);

  const settings = {
    customPaging: function (i) {
      const url = images[i];
      return (
        <a href="/">
          {videoExtensions.some((extension) => url.endsWith(extension)) ? (
            <video src={url} />
          ) : (
            <img src={url} alt="Фото" />
          )}
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="slider-wrapper col col-lg-5">
      <Slider ref={sliderRef} {...settings}>
        {images?.map((el, index) => (
          <ZoomImageOnHover
            className={`slider-wrapper__image ${
              activeColorsImg === el ? "slick-active" : ""
            }`}
            key={index}
            src={el}
          />
        ))}
      </Slider>
    </div>
  );
};
