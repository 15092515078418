import { useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdOutlineFilterList } from "react-icons/md";
import {
  selectActiveMobileChat,
  selectVisibleChat,
  setActiveMobileChat,
  setActiveMobileContacts,
  setActiveTab,
  setCurrentChatUser,
  setIsVisibleChat,
  setSearch,
} from "../../redux/slices/ChatSlice";
import { useDispatch, useSelector } from "react-redux";

const ApplicationsChatSearch = ({ inputRef }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const activeMobileChat = useSelector(selectActiveMobileChat);
  const isVisibleChat = useSelector(selectVisibleChat);
  const userInfo = useSelector((state) => state.userLogin.userInfo);

  const handleInfoMessage = () => {
    if (userInfo) {
      inputRef.current.focus();
    } else {
      toast({
        title: "Добро пожаловать в чат!",
        description:
          "Для размещения заявки, пожалуйста, зарегистрируйтесь или войдите в систему.",
        status: "info",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleSearch = (query) => {
    dispatch(setSearch(query));
    dispatch(setCurrentChatUser(""));
    dispatch(setIsVisibleChat(true));
    if (!query) {
      return;
    }
  };

  const updateMobileChatState = () => {
    dispatch(setActiveMobileChat(window.innerWidth <= 992));
  };

  useEffect(() => {
    updateMobileChatState();

    window.addEventListener("resize", updateMobileChatState);

    return () => {
      window.removeEventListener("resize", updateMobileChatState);
    };
    // eslint-disable-next-line
  }, []);

  const onMobileChatOpen = () => {
    dispatch(setActiveMobileContacts(true));
    dispatch(setCurrentChatUser(""));
    dispatch(setActiveTab(true));
  };

  const handleApplications = () => {
    dispatch(setIsVisibleChat(true));
    dispatch(setCurrentChatUser(""));
  };

  return (
    <div className="w-100">
      {activeMobileChat && (
        <button
          onClick={onMobileChatOpen}
          type="button"
          className=" chat-btn d-flex align-items-center justify-content-center gap-2 "
        >
          <MdOutlineFilterList size="40" />
        </button>
      )}

      {isVisibleChat === false ? (
        <button
          type="button"
          className="applicationsBtn back-btn d-flex align-items-center justify-content-center gap-2"
          onClick={handleApplications}
        >
          <IoMdArrowRoundBack /> Все заявки
        </button>
      ) : (
        <button
          type="button"
          className="applicationsBtn back-btn d-flex align-items-center justify-content-center gap-2 "
          onClick={handleInfoMessage}
        >
          <AiOutlinePlus /> Создать заявку
        </button>
      )}

      <input
        type="text"
        className="form-control"
        placeholder="Поиск..."
        onChange={(e) => handleSearch(e.target.value)}
      />
    </div>
  );
};

export default ApplicationsChatSearch;
