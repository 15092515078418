import { useEffect } from 'react';
import inMemoryJwt from '../services/inMemoryJwt';

const useLocalStorageLogout = () => {
  useEffect(() => {
    const handlePersistedLogout = (event) => {
      if (event.key === 'logout') {
        inMemoryJwt.deleteToken();
      }
    };

    window.addEventListener('storage', handlePersistedLogout);

    return () => {
      window.removeEventListener('storage', handlePersistedLogout);
    };
  }, []);
};

export default useLocalStorageLogout;
