import React, { memo } from "react";
import { About } from "../components/homePage/aboutBlock/About";
import { ContuctUs } from "../components/common/callUs/ContuctUs";
import { DepartmentCards } from "../components/homePage/departmentsCards/DepartmentCards";
import { NewCollection } from "../components/homePage/newCollection/NewCollection";
import { Reviews } from "../components/homePage/reviews/Reviews";

import "./styles/NewCollection.scss";
import "./styles/HomePage.scss";
import RecommendProducts from "../components/homePage/recommendProducts/RecommendProducts";
import { Loader } from "../components/common/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { newCollectionCatalogAction } from "../redux/Actions/NewCollecctionActions";
import IncrementingCounter from "../components/homePage/incrementingCounter/IncrementingCounter";
// import SnowFalls from "../components/homePage/snowFalls/SnowFalls";
import { getAllUsersAction } from "../redux/Actions/UserAction";
import { Seo } from "../seo/Seo";

export const HomePage = memo(() => {
  const dispatch = useDispatch();

  const newCollectionCatalog = useSelector(
    (state) => state.newCollectionCatalog
  );
  const { catalogs, loading, error } = newCollectionCatalog;

  const usersList = useSelector((state) => state.usersList);
  const { userCount, userLoading } = usersList;

  useEffect(() => {
    dispatch(getAllUsersAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(newCollectionCatalogAction());
  }, [dispatch]);

  return (
    <React.Fragment>
      {loading || userLoading ? (
        <Loader />
      ) : (
        <div>
          <Seo
            title="Оптовый интернет-магазин в Кыргызстане. Maneken | Манекен"
            description="Добро пожаловать в наш оптовый интернет-магазин Манекен! У нас вы найдете широкий выбор товаров высокого качества по привлекательным ценам. Приятных покупок!"
            name="Манекен"
            type="product"
            keywords="Maneken, Манекен, Одежда оптом, Маркетплейс, Marketplace, Женская одежда, Мужская одежда, Детская одежда, Швейное производство, Производство одежды, Ткани, Фулфилмент, Fulfilment, Конструктор одежды, Конструктор, Манекен отзывы, Пошив под ключ"
          />
          <About />
          <IncrementingCounter items={catalogs} userCount={userCount} />
          <NewCollection catalogs={catalogs} error={error} loading={loading} />
          <DepartmentCards />
          <RecommendProducts
            catalogs={catalogs}
            error={error}
            loading={loading}
          />
          <Reviews />
          <ContuctUs />
          {/* <SnowFalls /> */}
        </div>
      )}
    </React.Fragment>
  );
});
