import logoSvg from "../../../assets/images/ManekenLogo.svg";
import LinkNav from "./../../../hooks/LinkNav";
import { footerNavLinks } from "../../../constants/footerNavLinksData";

import "./Footer.scss";
import { deliveryPageData } from "../../../constants/deliveryData";
import { paymentMethodData } from "../../../constants/paymentsInstructionsData";
import FooterContacts from "./footerContacts/FooterContacts";

export const Footer = () => {
  return (
    <footer className="footer ">
      <div className="container">
        <div className="footer__grid">
          <nav className="footer__logo">
            <LinkNav to="/">
              <img src={logoSvg} alt="logo" width={400} />
            </LinkNav>
          </nav>
          <nav className="footer__navs">
            <ul className="footer__navs__one">
              {footerNavLinks.pageLinks.map((el) => (
                <li key={el.id}>{el.link}</li>
              ))}
            </ul>

            <ul className="footer__navs__two">
              {footerNavLinks.address.map((el) => (
                <li key={el.id}>{el.link}</li>
              ))}
              <li>пн–пт с 10:00 до 19:00</li>
            </ul>
            <ul className="footer__navs__three">
              <FooterContacts
                pageLink="contact"
                pageTitle="Контакты"
                data={footerNavLinks.contactLinks}
              />
              <FooterContacts
                pageLink="delivery"
                pageTitle="Доставка"
                data={deliveryPageData}
                style={{ width: "55px" }}
              />
              <FooterContacts
                pageLink="payment"
                pageTitle="Оплата"
                data={paymentMethodData}
                style={{ width: "40px" }}
              />
            </ul>
          </nav>
        </div>

        <hr />
        <div className="nav-links-hover political-center">
          <ul className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
            {footerNavLinks.documents.map((el) => (
              <li key={el.id}>{el.link}</li>
            ))}
          </ul>
        </div>
        <div className="copyright d-flex justify-content-center align-items-end">
          <p>ОсОО "МАНЕКЕН". © {new Date().getFullYear()} Все права защищены</p>
        </div>
      </div>
    </footer>
  );
};
