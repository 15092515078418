export const PROFILE_UPLOAD_AVATAR_REQUEST = "PROFILE_UPLOAD_AVATAR_REQUEST";
export const PROFILE_UPLOAD_AVATAR_SUCCESS = "PROFILE_UPLOAD_AVATAR_SUCCESS";
export const PROFILE_UPLOAD_AVATAR_FAIL = "PROFILE_UPLOAD_AVATAR_FAIL";
export const PROFILE_UPLOAD_AVATAR_RESET = "PROFILE_UPLOAD_AVATAR_RESET";

export const PROFILE_UPLOAD_CATALOG_IMAGES_REQUEST =
  "PROFILE_UPLOAD_CATALOG_IMAGES_REQUEST";
export const PROFILE_UPLOAD_CATALOG_IMAGES_SUCCESS =
  "PROFILE_UPLOAD_CATALOG_IMAGES_SUCCESS";
export const PROFILE_UPLOAD_CATALOG_IMAGES_FAIL =
  "PROFILE_UPLOAD_CATALOG_IMAGES_FAIL";
export const PROFILE_UPLOAD_CATALOG_IMAGES_RESET =
  "PROFILE_UPLOAD_CATALOG_IMAGES_RESET";

export const PROFILE_UPLOAD_CATALOG_IMAGE_REQUEST =
  "PROFILE_UPLOAD_CATALOG_IMAGE_REQUEST";
export const PROFILE_UPLOAD_CATALOG_IMAGE_SUCCESS =
  "PROFILE_UPLOAD_CATALOG_IMAGE_SUCCESS";
export const PROFILE_UPLOAD_CATALOG_IMAGE_FAIL =
  "PROFILE_UPLOAD_CATALOG_IMAGE_FAIL";
export const PROFILE_UPLOAD_CATALOG_IMAGE_RESET =
  "PROFILE_UPLOAD_CATALOG_IMAGE_RESET";
