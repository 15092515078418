import { Spinner } from "@chakra-ui/react";
import React from "react";

const ChakraUiSpinner = () => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100">
      <Spinner
        className="my-1"
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </div>
  );
};

export default ChakraUiSpinner;
