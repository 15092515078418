import { addToCart } from "../redux/Actions/CartActions";

export const handleCartAddition = (
  dispatch,
  id,
  activeColors,
  colors,
  additionaly,
  selectedOptionIndex,
  search
) => {
  const qty = search ? Number(search.split("=")[1]) : 1;
  if (id && activeColors !== undefined && additionaly !== undefined) {
    const color = colors[activeColors].name;
    const addition = additionaly[selectedOptionIndex].name;
    dispatch(addToCart(id, qty, color, addition));
  }
};
