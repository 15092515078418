import CatalogSteps from './CatalogSteps';
import ConstructorStep from './ConstructorStep';
import './ProgressSteps.scss';
import FulfillmentSteps from './FulfillmentSteps';

const ProgressSteps = ({ order }) => {
  return (
    <section className="progress-steps">
      <ConstructorStep order={order} />

      <CatalogSteps order={order} />

      <FulfillmentSteps order={order} />
    </section>
  );
};

export default ProgressSteps;
