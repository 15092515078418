import React from "react";
import {
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    Portal,
    PopoverTrigger,
  } from "@chakra-ui/react";
  import { RiFilter3Fill } from "react-icons/ri";

const FilterPopover = ({ items, onApply, activeType }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <button className="sort-popup__name d-flex align-items-center gap-2">
          <RiFilter3Fill style={{ width: "18px", height: "18px" }} />{" "}
          {activeType.name || "Фильтры"}
        </button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody className="d-flex flex-column align-items-start">
            {items.map((obj, index) => (
              <button
                className={`d-flex align-items-center gap-2 ${
                  activeType.type === obj.type ? "active" : ""
                }`}
                onClick={() => onApply(obj)}
                key={`${obj.id}_${index}`}
              >
                {obj.icon} {obj.name}
              </button>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default FilterPopover;
