import React from "react";
import AddProductionInstruction from "../profileAddProduction/addProductionCatalogItems/addProductionInstruction/AddProductionInstruction";

const ProfileCatalogItemsGabarits = ({ productWeight, setProductWeight }) => {
  return (
    <section className="items-gabarit mb-4">
      <AddProductionInstruction descriptionText="Для чего нужно указывать габариты?">
        Чтобы обеспечить точность и соответствие ожиданиям, просим вас уделить
        особое внимание указанию габаритов вашего товара. Габариты будут влиять
        на рассчет стоимости логистическими компаниями.
      </AddProductionInstruction>
      <label
        htmlFor="items-weight"
        className="items-weight d-flex flex-column align-items-start gap-1 mt-3"
      >
        Вес (в кг) Введите вес одной упаковки с товарами
        <input
          className="form-control"
          id="items-weight"
          type="number"
          placeholder="Вес (в кг), например: 2 или 2.5"
          value={productWeight}
          onChange={(e) => setProductWeight(e.target.value)}
        />
      </label>
    </section>
  );
};

export default ProfileCatalogItemsGabarits;
