import React from "react";
import {
  Avatar,
  Box,
  Center,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  WrapItem,
} from "@chakra-ui/react";
import { getSender } from "../../../../../helper/ChatSettings";
import { FaUserAlt } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentChatUser,
  selectNotifications,
  setActiveMobileContacts,
  setNotifications,
} from "../../../../../redux/slices/ChatSlice";

const ChatItem = ({ chat, onChatCreate, loggedUser, onDeleteChat }) => {
  const dispatch = useDispatch();
  const currentChatUser = useSelector(selectCurrentChatUser);
  const notifications = useSelector(selectNotifications);

  const handleChatItemClick = () => {
    onChatCreate(chat?._id, chat);
    if (window.innerWidth <= 992) {
      dispatch(setActiveMobileContacts(false));
    }
    const updatedNotifications = notifications.filter(
      (notification) => notification.chat._id !== currentChatUser._id
    );
    dispatch(setNotifications(updatedNotifications));
  };

  return (
    <div className="position-relative w-100">
      <Box
        onClick={handleChatItemClick}
        cursor="pointer"
        bg={currentChatUser === chat ? "#E8E8E8" : " #f5f7fb"}
        color={currentChatUser === chat ? "black" : "black"}
        px={3}
        py={2}
        borderRadius="lg"
      >
        <div>
          {chat ? (
            <WrapItem>
              <Avatar
                size="sm"
                name={
                  !chat?.isGroupChat
                    ? getSender(loggedUser, chat?.users)
                    : chat?.chatName
                }
                src={chat ? chat?.users[0]?.profile : ""}
              />
            </WrapItem>
          ) : (
            <FaUserAlt />
          )}
        </div>
        <div>
          <Text>
            {!chat?.isGroupChat
              ? getSender(loggedUser, chat?.users)
              : chat?.chatName}
          </Text>
          {chat?.latestMsg && (
            <Text fontSize="xs">
              <b>{chat?.latestMsg?.sender?.name} : </b>
              {chat?.latestMsg?.content?.length > 10
                ? chat?.latestMsg?.content.substring(0, 10) + "..."
                : chat?.latestMsg?.content}
            </Text>
          )}
        </div>
      </Box>
      <Popover isLazy placement="bottom-end">
        <PopoverTrigger>
          <button className="popover-btn" type="button">
            <HiDotsHorizontal />
          </button>
        </PopoverTrigger>
        <PopoverContent style={{ width: "inherit" }}>
          <PopoverBody>
            <Flex>
              <button type="button" onClick={() => onDeleteChat(chat._id)}>
                <Center>
                  <MdDeleteForever />
                  <Text p={1}>Удалить</Text>
                </Center>
              </button>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default ChatItem;
