import { $api } from "../api/axios";

const inMemmoryJwtService = () => {
  let inMemoryJWT = null;
  let refreshTimeoutId = null;

  const refreshToken = (expiration) => {
    const timeoutTrigger = expiration - 10000;
    refreshTimeoutId = setTimeout(() => {
      $api
        .post("/users/refresh")
        .then((response) => {
          const { data } = response;
          const { accessToken, accessTokenExpiration } = data;

          setToken(accessToken, accessTokenExpiration);
        })
        .catch((error) => {
          console.error("Error refreshing access token:", error);
        });
    }, timeoutTrigger);
  };

  const abortRefreshToken = () => {
    if (refreshTimeoutId) {
      clearTimeout(refreshTimeoutId);
    }
  };

  const getToken = () => inMemoryJWT;

  const setToken = (token, tokenExpiration) => {
    inMemoryJWT = token;
    abortRefreshToken();
    refreshToken(tokenExpiration);
  };

  const deleteToken = () => {
    inMemoryJWT = null;
    abortRefreshToken();
    localStorage.setItem("logout", Date.now());
  };

  return { getToken, setToken, deleteToken };
};

export default inMemmoryJwtService();
