import {
  PRODUCTION_LIST_REQUEST,
  PRODUCTION_LIST_SUCCESS,
  PRODUCTION_LIST_FAIL,
  PRODUCTION_CREATE_REQUEST,
  PRODUCTION_CREATE_SUCCESS,
  PRODUCTION_CREATE_FAIL,
  PRODUCTION_CREATE_RESET,
  PRODUCTION_EDIT_REQUEST,
  PRODUCTION_EDIT_SUCCESS,
  PRODUCTION_EDIT_FAIL,
  PRODUCTION_UPDATE_REQUEST,
  PRODUCTION_UPDATE_SUCCESS,
  PRODUCTION_UPDATE_FAIL,
  PRODUCTION_UPDATE_RESET,
  PROFILE_PRODUCTION_CATALOG_DELETE_REQUEST,
  PROFILE_PRODUCTION_CATALOG_DELETE_SUCCESS,
  PROFILE_PRODUCTION_CATALOG_DELETE_FAIL,
  PRODUCTION_ADS_CARDS_GET_REQUEST,
  PRODUCTION_ADS_CARDS_GET_SUCCESS,
  PRODUCTION_ADS_CARDS_GET_FAIL,
  PRODUCTION_DETAILS_REQUEST,
  PRODUCTION_DETAILS_SUCCESS,
  PRODUCTION_DETAILS_FAIL,
} from "./../constants/Production";

// PRODUCTION ITEMS LIST
export const productionCatalogReducer = (state = { catalogs: [] }, action) => {
  switch (action.type) {
    case PRODUCTION_LIST_REQUEST:
      return { loading: true, catalogs: [] };
    case PRODUCTION_LIST_SUCCESS:
      return {
        loading: false,
        catalogs: action.payload,
      };
    case PRODUCTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CREATE PRODUCTION ITEM
export const productionItemCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCTION_CREATE_REQUEST:
      return { loading: true };
    case PRODUCTION_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCTION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCTION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// EDIT PRODUCTION
export const productionEditReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCTION_EDIT_REQUEST:
      return { ...state, loading: true };
    case PRODUCTION_EDIT_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCTION_EDIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// PRODUCTION UPDATE
export const updateProductionReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCTION_UPDATE_REQUEST:
      return { loading: true };
    case PRODUCTION_UPDATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCTION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCTION_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

// DELETE PROFILE PRODUCTION CATALOGS ITEM
export const profileProductionCatalogItemDeleteReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case PROFILE_PRODUCTION_CATALOG_DELETE_REQUEST:
      return { loading: true };
    case PROFILE_PRODUCTION_CATALOG_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PROFILE_PRODUCTION_CATALOG_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// PRODUCTION ADS CARDS
export const productionAdsCardsReducer = (state = { adsCards: [] }, action) => {
  switch (action.type) {
    case PRODUCTION_ADS_CARDS_GET_REQUEST:
      return { loading: true, adsCards: [] };
    case PRODUCTION_ADS_CARDS_GET_SUCCESS:
      return {
        loading: false,
        adsCards: action.payload,
      };
    case PRODUCTION_ADS_CARDS_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// SINGLE PRODUCTION ITEM LIST
export const productionSingleItemReducer = (
  state = { productionItem: {} },
  action
) => {
  switch (action.type) {
    case PRODUCTION_DETAILS_REQUEST:
      return { ...state, loading: true };
    case PRODUCTION_DETAILS_SUCCESS:
      return { loading: false, productionItem: action.payload };
    case PRODUCTION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
