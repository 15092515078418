import React, { memo, useEffect, useState } from "react";
import {
  Badge,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import AddProductionTextiles from "../../addProductionTextiles/AddProductionTextiles";
import ProductSizes from "../../../../profileAddProductToCatalog/productSizes/ProductSizes";
import ProductMainImage from "../../../../profileAddProductToCatalog/productMainImage/ProductMainImage";
import AddProductionInstruction from "../../addProductionInstruction/AddProductionInstruction";
import AddedImageColors from "./addedImageColors/AddedImageColors";
import ProductImages from "../../../../profileAddProductToCatalog/productImages/ProductImages";

import CloseBtn from "../../../../../../assets/images/favorites/x-circle.svg";
import { useDispatch } from "react-redux";
import {
  deleteProfileProductionCatalogItemAction,
  updateProductionAction,
} from "../../../../../../redux/Actions/ProductionAction";
import ProfileCatalogItemsGabarits from "../../../../profileCatalogItemsGabarits/ProfileCatalogItemsGabarits";

/**
 * Renders a modal for editing a production item.
 *
 * @param {Object} el - The production item data.
 * @param {string} productId - The ID of the selected product.
 * @param {function} setProductionCatalog - A function to update the production catalog.
 * @param {array} productionCatalog - The current production catalog.
 * @returns {React.Fragment} - The rendered modal for editing a production item.
 */

const AddedProductionItem = memo(
  ({
    el,
    productId,
    setProductionCatalog,
    productionCatalog,
    filteredProductsId,
  }) => {
    const dispatch = useDispatch();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isVisibleModelsAdd, setIsVisibleModelsAdd] = React.useState(false);
    const [isFormChanged, setIsFormChanged] = useState(false);

    const [productionEditTitle, setProductionEditTitle] = useState("");
    const [productionEditPrice, setProductionEditPrice] = useState("");
    const [productionEditMainImage, setProductionEditMainImage] = useState("");
    const [productionEditSizes, setProductionEditSizes] = useState([]);
    const [productionEditTextiles, setProductionEditTextiles] = useState([]);
    const [
      productionEditAdditionalyImages,
      setProductionEditAdditionalyImages,
    ] = useState([]);
    const [productionEditModelImage, setProductionEditModelImage] =
      useState("");
    const [
      productionEditModelFlippedImage,
      setProductionEditModelFlippedImage,
    ] = useState("");
    const [productionEditModelColor, setProductionEditModelColor] =
      useState("");
    const [productionEditModelColors, setProductionEditModelColors] = useState(
      []
    );
    const [productWidth, setProductWidth] = useState("");
    const [productHeight, setProductHeight] = useState("");
    const [productLength, setProductLength] = useState("");
    const [productWeight, setProductWeight] = useState("");

    useEffect(() => {
      setProductionEditTitle(el.title);
      setProductionEditPrice(el.price);
      setProductionEditMainImage(el.img);
      setProductionEditSizes(el.sizes);
      setProductionEditTextiles(el.textiles);
      setProductionEditModelImage(el.modelsColors.img);
      setProductionEditModelFlippedImage(el.modelsColors.imageFlipped);
      setProductionEditModelColor(el.modelsColors.color);
      setProductionEditModelColors(el.modelsColors);
      setProductionEditAdditionalyImages(el.additionalImages);
      setProductHeight(el.height);
      setProductLength(el.length);
      setProductWeight(el.weight);
      setProductWidth(el.width);
    }, [
      el.title,
      el.price,
      el.img,
      el.modelsColors.img,
      el.modelsColors.imageFlipped,
      el.modelsColors.color,
      el.modelsColors,
      el.sizes,
      el.textiles,
      el.additionalImages,
      el.height,
      el.length,
      el.width,
      el.weight,
    ]);

    useEffect(() => {
      setIsFormChanged(true);
    }, [
      productionEditTitle,
      productionEditPrice,
      productionEditMainImage,
      productionEditSizes,
      productionEditTextiles,
      productionEditModelImage,
      productionEditModelFlippedImage,
      productionEditModelColor,
      productionEditAdditionalyImages,
      productHeight,
      productLength,
      productWeight,
      productWidth,
    ]);

    const handleAddProductionCatalogItem = () => {
      if (isFormChanged) {
        const updatedCatalog = productionCatalog.map((item) =>
          item._id === el._id
            ? {
                ...item,
                title: productionEditTitle,
                price: productionEditPrice,
                color: el.color,
                img: productionEditMainImage,
                modelImg: el.modelImg,
                sizes: productionEditSizes,
                textiles: productionEditTextiles,
                modelsColors: productionEditModelColors,
                additionalImages: productionEditAdditionalyImages,
                length: +productLength,
                height: +productHeight,
                width: +productWidth,
                weight: +productWeight,
                url: "www.maneken.shop",
              }
            : item
        );
        setProductionCatalog(updatedCatalog);
      }
      onClose();
    };

    const handleSaveProductionCatalogItem = () => {
      setProductionEditModelColors([
        ...productionEditModelColors,
        {
          color: productionEditModelColor,
          imageFlipped: productionEditModelFlippedImage,
          img: productionEditModelImage,
        },
      ]);
      setIsVisibleModelsAdd(false);
      setProductionEditModelImage("");
      setProductionEditModelFlippedImage("");
      setProductionEditModelColor("");
    };

    const handleOpenPopup = () => {
      setIsVisibleModelsAdd(!isVisibleModelsAdd);
    };

    const sizesString = productionEditSizes
      ?.map((item) => item.size)
      .join(", ");
    const textilesString = productionEditTextiles
      ?.map((item) => item.name)
      .join(", ");

    const deleteHandler = (filteredProductsId, id) => {
      if (window.confirm("Вы действительно хотите удалить товар?")) {
        dispatch(
          deleteProfileProductionCatalogItemAction(filteredProductsId, id)
        )
          .then(() => {
            dispatch(updateProductionAction({ _id: filteredProductsId }));
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
          });
      }
    };

    return (
      <React.Fragment>
        <figure>
          <img onClick={onOpen} src={el.img} alt={el.title} title={el.title} />
          <button type="button" className="productionCatalogCloseBtn">
            <img
              src={CloseBtn}
              alt="Удалить"
              onClick={() => deleteHandler(filteredProductsId, el._id)}
            />
          </button>
        </figure>
        <Modal
          size="3xl"
          onClose={onClose}
          isOpen={isOpen && el._id === productId}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Редактирование</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <section className={`profile__production__add__catalog `}>
                <section>
                  <p className="profile__production__add__catalog__header__text">
                    1. Информация о товаре
                  </p>
                  <div>
                    <label htmlFor="production-title">Название товара:</label>
                    <input
                      type="text"
                      id="production-title"
                      className={`form-control `}
                      placeholder="Например: Футболка"
                      value={productionEditTitle}
                      onChange={(e) => {
                        setProductionEditTitle(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="production-price" className="mt-4">
                      Цена:
                    </label>
                    <input
                      type="number"
                      id="production-price"
                      className={`form-control`}
                      placeholder="Например: 1000"
                      value={productionEditPrice}
                      onChange={(e) => {
                        setProductionEditPrice(e.target.value);
                      }}
                    />
                  </div>
                  <AddProductionTextiles
                    productionTextiles={textilesString}
                    setProductionTextiles={setProductionEditTextiles}
                    text="Ткань товара: (укажите через запятую)"
                    placeholder="Например: хлопок, шерсть, шелк и тд."
                  />
                  <ProductSizes
                    sizesString={sizesString}
                    setSizes={setProductionEditSizes}
                    text="Размер товара: (укажите через запятую)"
                    placeholder="Например: xs, s, m, xl и тд."
                  />
                  <ProfileCatalogItemsGabarits
                    setProductHeight={setProductHeight}
                    setProductLength={setProductLength}
                    productWeight={productWeight}
                    productWidth={productWidth}
                    productHeight={productHeight}
                    productLength={productLength}
                    setProductWeight={setProductWeight}
                    setProductWidth={setProductWidth}
                  />
                  <AddProductionInstruction descriptionText="Для чего основная картинка товара?">
                    Загрузите изображение товара. Это изображение будет
                    отображаться в качестве основной картинки товара, в вашей
                    карточке товара.
                  </AddProductionInstruction>

                  <ProductMainImage
                    text="Основная картинка товара:"
                    image={productionEditMainImage}
                    setImage={setProductionEditMainImage}
                  />
                  <ProductImages
                    images={productionEditAdditionalyImages}
                    setImages={setProductionEditAdditionalyImages}
                    text={"Дополнительные картинки и видео товара:"}
                    fieldType="ProductionImages"
                  />
                  <p className="profile__production__add__catalog__header__text">
                    2. Добавление товара по цветам
                  </p>
                  <AddProductionInstruction descriptionText='Как добавить товар по цветам?'>
                    Загрузите изображения товара (вид спереди и вид сзади) и
                    укажите его цвет. Эти изображения будут представлены в
                    качестве дополнительных вариантов при выборе товара. При
                    смене цвета будут автоматически изменяться соответствующие
                    изображения товара, которые вы загрузили. После заполнения
                    данной информации и нажатия кнопки "Сохранить" вы добавите
                    новый цвет товара с соответствующими изображениями. Можете
                    повторить эту операцию для каждого цвета товара.
                  </AddProductionInstruction>
                  <AddedImageColors
                    productionEditModelImage={productionEditModelImage}
                    setProductionEditModelImage={setProductionEditModelImage}
                    productionEditModelFlippedImage={
                      productionEditModelFlippedImage
                    }
                    setProductionEditModelFlippedImage={
                      setProductionEditModelFlippedImage
                    }
                    productionEditModelColor={productionEditModelColor}
                    setProductionEditModelColor={setProductionEditModelColor}
                    productionEditModelColors={productionEditModelColors}
                    handleSaveProductionCatalogItem={
                      handleSaveProductionCatalogItem
                    }
                    isVisibleModelsAdd={isVisibleModelsAdd}
                    handleOpenPopup={handleOpenPopup}
                    setProductionEditModelColors={setProductionEditModelColors}
                  />
                </section>

                <div className="d-flex justify-content-start mt-3 gap-3">
                  <button
                    type="button"
                    className="profile__production__add__submit__btn"
                    onClick={handleAddProductionCatalogItem}
                  >
                    Сохранить товар
                    <Badge ml="1" fontSize="0.8em" colorScheme="green">
                      {productionCatalog.length}
                    </Badge>
                  </button>
                </div>
              </section>
            </ModalBody>
          </ModalContent>
        </Modal>
      </React.Fragment>
    );
  }
);

export default AddedProductionItem;
