import React from "react";
import { Link } from "react-router-dom";
import trashSvg from "./../../../../assets/images/cart/x-square 1.svg";
import { CurrencyConverter } from "../../../common/currencyConverter/CurrencyConverter";
import { selectCurrency } from "../../../../redux/slices/CatalogSlice";
import { useSelector } from 'react-redux';

export const FulfillmentCart = ({ fulfillment, handleRemoveItem }) => {
  const selectedCurrency = useSelector(selectCurrency);
  return (
    <>
      {fulfillment.map((el, index) => (
        <section
          key={index}
          className="shopping-cart__item d-flex align-items-center justify-content-between flex-column p-4 flex-xl-row gap-4 "
        >
          <div className="shopping-cart__item__ff_l d-flex flex-column align-items-center flex-md-row justify-content-between ">
            <div className="shopping-cart__items_item w-100">
              <Link to={`/fulfillment`}>
                <p className="my-2 text-center text-md-start">
                  Заказ из «Фулфилмент»
                </p>
              </Link>
              <Link to={`/fulfillment`}>
                <p className="my-2 text-center text-md-start">
                  Наименование товара: {el.otherSelectedName || el.selectedName}
                </p>
                <p className="my-2 text-center text-md-start">
                  Колличество товара: {el.selectedQty || el.otherSelectedQty}
                </p>
              </Link>
              {el.selectedItems.map((el) => (
                <div
                  key={el.id}
                  className="fulfillment_items d-flex justify-content-between"
                >
                  <ul>
                    <li>
                      {" "}
                      {el.text} {el.matte} {el.from} {el.to}
                    </li>
                  </ul>
                  <span>
                    {el.price} {el.sizesPriceOfOnePackage} сом
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="shopping-cart__item__ff_r d-flex align-items-center flex-column flex-md-row ">
            <div className="shopping-cart__items_price d-flex align-items-center justify-content-between  ">
              <p className="d-flex gap-3 justify-content-between mb-0">
                <strong>Цена услуг :</strong>{" "}
                {selectedCurrency === "KGS" ? (
                  <strong style={{width:'max-content'}}>{el.totalItemsPrice.toFixed()} KGS</strong>
                ) : (
                  <CurrencyConverter total={el.totalItemsPrice.toFixed()} />
                )}
              </p>
              <img
                src={trashSvg}
                alt="удалить товар"
                title="удалить товар"
                onClick={() => handleRemoveItem(el)}
              />
            </div>
          </div>
        </section>
      ))}
    </>
  );
};
