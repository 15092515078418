import "./App.scss";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import React, { Suspense} from "react";

import { Loader } from "./components/common/loader/Loader";
import routes from './routes/Routes';

// Create the router instance
const router = createBrowserRouter(routes);

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
