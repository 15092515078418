import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

const FabricInfoDescription = ({ fabricData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div className="fabric-description">
      <h4>Описание</h4>
      <p>
        {fabricData?.description.slice(0, 250) + "..."}{" "}
        <span onClick={onOpen}>Подробнее</span>
      </p>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody margin="10px 0">{fabricData?.description}</ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default FabricInfoDescription;
