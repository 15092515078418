import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CurrencyConverter } from "../../../common/currencyConverter/CurrencyConverter";
import { Button } from "./../../../common/button/Button";
import LoadingError from "./../../../common/errorMessage/LoadingError";
import ChakraUiSpinner from "../../../common/loader/ChakraUiSpinner";
import PlaceOrderBtn from "./placeOrderBtn/PlaceOrderBtn";
import { calculateCartPrice } from "../../../../redux/Actions/CartActions";
import { selectCurrency } from "../../../../redux/slices/CatalogSlice";

export const CartItemsTotal = memo(
  ({
    formik,
    loading,
    error,
    cdekDeliveryLoading,
    cdekDeliveryError,
    totalDimensions,
  }) => {
    const dispatch = useDispatch();
    const selectedCurrency = useSelector(selectCurrency);

    const cartAdded = useSelector((state) => state.cart);
    const { cartItems, constructorItems, fulfillment } = cartAdded;

    const calculateTariff = useSelector((state) => state.calculateTariff);
    const {
      loading: tariffLoading,
      tariffs,
      error: tariffError,
    } = calculateTariff;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const cartTotalPrice = useSelector((state) => state.cartTotalPrice);
    const {
      totalFromCatalog,
      totalFromConstructor,
      totalFromFulfillment,
      alfaCargoDeliveryPrice,
      redExpressDeliveryPrice,
      yldamExressPrice,
      total,
      totalQty,
    } = cartTotalPrice;

    useEffect(() => {
      dispatch(
        calculateCartPrice({
          cartItems,
          constructorItems,
          fulfillment,
          tariffs,
          alfaCargoPrice: formik.values.alfaCargoPrice,
          redExpressCargoPrice: formik.values.redExpressCargoPrice,
          selectedKgSettlement: formik.values.selectedKgSettlement,
          totalDimensions,
          selectedService: formik.values.selectedService,
        })
      );
    }, [
      dispatch,
      cartItems,
      constructorItems,
      fulfillment,
      tariffs,
      formik.values.alfaCargoPrice,
      formik.values.redExpressCargoPrice,
      formik.values.selectedKgSettlement,
      totalDimensions,
      formik.values.selectedService,
    ]);

    const location = useLocation();

    return (
      <section className="shopping-cart__payment col h-100">
        {loading ||
          tariffLoading ||
          (cdekDeliveryLoading && <ChakraUiSpinner />)}

        {error ||
          tariffError ||
          (cdekDeliveryError && (
            <LoadingError variant="alert-danger">
              {error || cdekDeliveryError || tariffError}
            </LoadingError>
          ))}
        <p className="text-start">
          Всего: <strong>{cartItems.length + constructorItems.length}</strong>{" "}
          категории(я) товара в колличестве <strong>{totalQty}</strong> шт.
        </p>
        <div className="d-flex align-items-center w-100 justify-content-between">
          <p className="text-start">Сумма заказа из «Товары»:</p>
          <p className="text-end">
            {selectedCurrency === "KGS" ? (
              <strong>{totalFromCatalog} KGS</strong>
            ) : (
              <CurrencyConverter total={totalFromCatalog} />
            )}
          </p>
        </div>
        <div className="d-flex align-items-center w-100 justify-content-between">
          <p className="text-start">Сумма заказа из «Конструктор»:</p>
          <p className="text-end">
            {selectedCurrency === "KGS" ? (
              <strong>{totalFromConstructor} KGS</strong>
            ) : (
              <CurrencyConverter total={totalFromConstructor} />
            )}
          </p>
        </div>
        <div className="d-flex align-items-center w-100 justify-content-between">
          <p className="text-start">Сумма заказа «Фулфилмент»:</p>
          <p className="text-end">
            {selectedCurrency === "KGS" ? (
              <strong>{totalFromFulfillment} KGS</strong>
            ) : (
              <CurrencyConverter total={totalFromFulfillment} />
            )}
          </p>
        </div>
        <div className="d-flex align-items-center w-100 justify-content-between">
          <p
            className="d-flex align-items-start flex-column "
            style={{ color: "#23155b" }}
          >
            <strong>Доставка:</strong>
            {formik.values.selectedService ? (
              <React.Fragment>
                <strong>{formik.values.selectedService}</strong>
                {(tariffs &&
                  formik.values.selectedService === "cdek - до склада") ||
                formik.values.selectedService ===
                  "cdek - курьерская доставка" ? (
                  <strong>
                    от {tariffs?.period_min} до {tariffs?.period_max} рабочих
                    дней
                  </strong>
                ) : (
                  ""
                )}
                {formik.values.selectedService === "Ылдам-Экспресс" ? (
                  <strong>от 1 до 5 рабочих дней</strong>
                ) : (
                  ""
                )}
              </React.Fragment>
            ) : (
              ""
            )}
          </p>
          <p className="text-end">
            {selectedCurrency === "KGS" ? (
              <strong>
                {formik.values.selectedService === "cdek - до склада"
                  ? tariffs?.delivery_sum ?? 0
                  : formik.values.selectedService ===
                    "cdek - курьерская доставка"
                  ? tariffs?.delivery_sum ?? 0
                  : formik.values.selectedService === "Альфа-Карго"
                  ? alfaCargoDeliveryPrice.toFixed(2) ?? 0
                  : formik.values.selectedService === "Рэд-Экспресс"
                  ? redExpressDeliveryPrice.toFixed(2) ?? 0
                  : formik.values.selectedService === "Ылдам-Экспресс"
                  ? yldamExressPrice.toFixed(2) ?? 0
                  : 0}{" "}
                KGS
              </strong>
            ) : (
              <CurrencyConverter
                total={
                  formik.values.selectedService === "cdek - до склада"
                    ? tariffs?.delivery_sum ?? 0
                    : formik.values.selectedService ===
                      "cdek - курьерская доставка"
                    ? tariffs?.delivery_sum ?? 0
                    : formik.values.selectedService === "Альфа-Карго"
                    ? alfaCargoDeliveryPrice.toFixed(2) ?? 0
                    : formik.values.selectedService === "Рэд-Экспресс"
                    ? redExpressDeliveryPrice.toFixed(2) ?? 0
                    : formik.values.selectedService === "Ылдам-Экспресс"
                    ? yldamExressPrice.toFixed(2) ?? 0
                    : 0
                }
              />
            )}
          </p>
        </div>
        <div
          style={{ color: "#23155b" }}
          className="d-flex align-items-center w-100 justify-content-between"
        >
          <p className="text-start">
            <strong>Общая сумма заказа:</strong>
          </p>
          <p className="text-end">
            {selectedCurrency === "KGS" ? (
              <strong>{total.toFixed(2)} KGS</strong>
            ) : (
              <CurrencyConverter total={total} />
            )}
          </p>
        </div>

        {total > 0 && userInfo ? (
          <PlaceOrderBtn loading={loading} />
        ) : (
          <Button
            to={`/login?redirect=${encodeURIComponent(location.pathname)}`}
          >
            Войти или зарегистрироваться, для оформления заказа
          </Button>
        )}
      </section>
    );
  }
);
