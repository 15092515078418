import React from "react";
import { Button } from "../../common/button/Button";
import { Link } from "react-router-dom";
import { CurrencyConverter } from "./../../common/currencyConverter/CurrencyConverter";
import LoadingError from "../../common/errorMessage/LoadingError";
import { Skeleton } from "@chakra-ui/react";
import { selectCurrency } from "../../../redux/slices/CatalogSlice";
import { useSelector } from "react-redux";
import SaleType from "../../common/catalogCardSaleType/SaleType";

export const NewCollection = ({ catalogs, error, loading }) => {
  const selectedCurrency = useSelector(selectCurrency);
  return (
    <div
      id="newCollection"
      className="new-collection d-flex align-items-center justify-content-center"
    >
      <div className="container mb-5 mt-4 px-0" id="#newCollection">
        <h2>Новинки</h2>
        <div>
          {loading
            ? [...new Array(15)].map((_, index) => <Skeleton key={index} />)
            : catalogs
                ?.filter((el) => el.newItem.toUpperCase() === "NEW")
                .reverse()
                .slice(0, 20)
                .map((el) => (
                  <div key={el._id} className="catalog__card">
                    <div className="catalog__card__image">
                      <Link
                        to={`/catalogs/new-collection/${el._id.toString()}`}
                      >
                        <img
                          key={el.id}
                          className="h-100 object-fit-cover w-100 "
                          src={el.image}
                          alt={el.title}
                        />
                      </Link>
                    </div>

                    <div className="catalog__card_description">
                      <div className="saleType mt-1 d-flex gap-1">
                        <SaleType item={el} />
                        {el.hasSample ? <p>Образец</p> : ""}
                      </div>
                      <h4 className="ellipsis-text">{el.title}</h4>
                      <p>
                        {selectedCurrency === "KGS" ? (
                          <strong>{el.price} KGS</strong>
                        ) : (
                          <CurrencyConverter total={el.price} />
                        )}
                      </p>
                    </div>
                  </div>
                ))}
        </div>

        <div className="newCollection-btn d-flex flex-column">
          <Button to="/catalogs">Перейти в «Товары»</Button>
          {error && <LoadingError variant="alert-danger">{error}</LoadingError>}
        </div>
      </div>
    </div>
  );
};
