import { createSlice } from "@reduxjs/toolkit";

const selectedCurrencyFromLocalStorage =
  localStorage.getItem("selectedCurrency");
const initialState = {
  keyword: "",
  selectedCategory: "",
  activeSort: "",
  activeFilterType: {
    name: "Все товары",
    type: "allItems",
  },
  activeAgeGroup: null,
  selectedCurrency: selectedCurrencyFromLocalStorage
    ? JSON.parse(selectedCurrencyFromLocalStorage)
    : "RUB",
};

const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setActiveSort: (state, action) => {
      state.activeSort = action.payload;
    },
    setActiveFilterType: (state, action) => {
      state.activeFilterType = action.payload;
    },
    setActiveAgeGroup: (state, action) => {
      state.activeAgeGroup = action.payload;
    },
    setSelectedCurrency: (state, action) => {
      state.selectedCurrency = action.payload;
    },
  },
});

export const {
  setKeyword,
  setSelectedCategory,
  setActiveSort,
  setActiveAgeGroup,
  setSelectedCurrency,
  setActiveFilterType,
} = catalogSlice.actions;

export const selectKeyword = (state) => state.catalog.keyword;
export const selectCatalogCategory = (state) => state.catalog.selectedCategory;
export const selectActiveSort = (state) => state.catalog.activeSort;
export const selectActiveAgeGroup = (state) => state.catalog.activeAgeGroup;
export const selectCurrency = (state) => state.catalog.selectedCurrency;
export const selectFilterType = (state) => state.catalog.activeFilterType;

export default catalogSlice.reducer;
