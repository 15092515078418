import { useEffect, useRef, useState } from 'react';

const StepsUi = ({ activeStep, el }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const popupRef = useRef(null);
  const { step, img, image, label, text, matte } = el;

  const handlePopupOpen = () => {
    setPopupVisible(!isPopupVisible);
  };

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setPopupVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <section className="progress-steps__wrapper" key={step}>
      <section
        className={`progress-steps__step ${activeStep >= step ? 'completed' : 'incomplete'}`}
        ref={popupRef}
        onClick={handlePopupOpen}>
        {activeStep > step ? (
          <div className="progress-steps__checkmarks" title={label || text || matte}>
            L
          </div>
        ) : (
          <img
            className="progress-steps__count"
            src={img || image}
            alt={label || matte || text}
            title={label || matte || text}
          />
        )}
      </section>
      {isPopupVisible && (
        <div className="progress-steps__label">
          <div className="progress-steps__lbl" key={step}>
            <p>{label || matte || text}</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default StepsUi;
