import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ChatLoading } from "./ChatLoading";
import ChatBox from "./chatBox/ChatBox";
import { Link } from "react-router-dom";
import ApplicationsCategory from "../ApplicationsCategory";
import { IoArrowBackOutline } from "react-icons/io5";
import { clearStorage } from "../../../redux/Actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { $apiPrivate } from "../../../api/axios";
import {
  selectActiveMobileChat,
  selectActiveMobileContacts,
  selectActiveTab,
  selectActiveTabCategory,
  selectChats,
  selectFatchAgain,
  setActiveMobileContacts,
  setActiveTab,
  setActiveTabCategory,
  setChats,
  setCurrentChatUser,
  setIsVisibleChat,
} from "../../../redux/slices/ChatSlice";

const ApplicationsActiveContacts = ({ onChatCreate }) => {
  const [loggedUser, setLoggedUser] = useState([]);
  const dispatch = useDispatch();
  const chats = useSelector(selectChats);
  const activeMobileChat = useSelector(selectActiveMobileChat);
  const activeMobileContacts = useSelector(selectActiveMobileContacts);
  const fetchAgain = useSelector(selectFatchAgain);
  const activeTab = useSelector(selectActiveTab);
  const activeTabCategory = useSelector(selectActiveTabCategory);
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const toast = useToast();

  const fetchChats = async () => {
    if (userInfo) {
      try {
        const { data } = await $apiPrivate.get(`/applications/chats`);
        dispatch(setChats(data));
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (error.response.status === 401) {
          dispatch(clearStorage());
        }
        toast({
          title: "Error Occured!",
          description: `Failed to Load the chats - ${message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  };

  useEffect(() => {
    setLoggedUser(userInfo);
    fetchChats();
    // eslint-disable-next-line
  }, [fetchAgain]);

  return (
    <React.Fragment>
      {activeMobileContacts && (
        <div className="applications__content__body__wrapper__contacts">
          <div className="d-flex align-items-center justify-content-between h-auto ">
            {activeMobileChat && (
              <button
                type="button"
                onClick={() => {
                  dispatch(setActiveMobileContacts(false));
                }}
              >
                <IoArrowBackOutline size="25" />
              </button>
            )}

            <button
              className="applicationsBtn"
              type="button"
              onClick={() => {
                dispatch(setActiveTab(true));
                dispatch(setActiveTabCategory(false));
                dispatch(setCurrentChatUser(""));
                dispatch(setIsVisibleChat(true));
              }}
            >
              Чаты
            </button>
            <button
              className="applicationsBtn"
              type="button"
              onClick={() => {
                dispatch(setActiveTabCategory(true));
                dispatch(setActiveTab(false));
                dispatch(setCurrentChatUser(""));
                dispatch(setIsVisibleChat(true));
              }}
            >
              Категории
            </button>
          </div>
          {activeTab ? (
            <div
              className="d-flex flex-column align-items-start px-0 gap-3"
              id="style__scroll__chat"
            >
              {userInfo ? (
                chats ? (
                  <ChatBox
                    loggedUser={loggedUser}
                    onChatCreate={onChatCreate}
                    fetchChats={fetchChats}
                  />
                ) : (
                  <ChatLoading />
                )
              ) : (
                <p className="text-center my-3 w-100">
                  <Link to="/register">Зарегистрироваться</Link> или{" "}
                  <Link to="/login">Войти</Link>
                </p>
              )}
            </div>
          ) : activeTabCategory ? (
            <ApplicationsCategory />
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

export default ApplicationsActiveContacts;
