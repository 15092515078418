import React from "react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  Portal,
  PopoverTrigger,
} from "@chakra-ui/react";
import { PiSortAscending } from "react-icons/pi";
import { sortByRatingPrice } from "../../../constants/catalogCategoriesSortFilterData";

const SortByRatingPrice = ({
  activeSortRatingPrice,
  setActiveSortRatingPrice,
}) => {
  const onSortCatalog = (type) => {
    setActiveSortRatingPrice(type);
  };

  const onSelectSortType = (index) => {
    if (onSortCatalog) {
      onSortCatalog(index);
    }
    setActiveSortRatingPrice(index);
  };

  return (
    <Popover>
      <PopoverTrigger>
        <button className="sort-popup__name d-flex align-items-center gap-2">
          <PiSortAscending style={{ width: "18px", height: "18px" }} />{" "}
          {activeSortRatingPrice.name || "Сортировка"}
        </button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody className="d-flex flex-column align-items-start">
            {sortByRatingPrice.map((obj, index) => (
              <button
                className={
                  activeSortRatingPrice.type === obj.type ? "active" : ""
                }
                onClick={() => onSelectSortType(obj)}
                key={`${obj.id}_${index}`}
              >
                {obj.name}
              </button>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default SortByRatingPrice;
