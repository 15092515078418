import React from "react";

import "./ColorsByImage.scss";

import { Tooltip } from "@chakra-ui/react";
import Slider from "react-slick";

import { ColorsByImageSettings } from "../../../../../../../constants/reactSlickSettingsData";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveColors,
  selectColor,
  setActiveColors,
  setActiveColorsImg,
} from "../../../../../../../redux/slices/SingleCatalogItemSlice";

const ColorsByImage = () => {
  const dispatch = useDispatch();
  const colors = useSelector(selectColor);
  const activeColors = useSelector(selectActiveColors);
  const hasImages = colors.filter((color) => color.img).length > 0;
  return (
    <div className="colors__by__image d-flex align-items-center gap-3">
      {hasImages ? (
        <Slider {...ColorsByImageSettings}>
          {colors.map((color, index) => (
            <figure
              key={color._id}
              className={`colors__by__image__card ${
                activeColors === index ? "active" : ""
              }`}
              onClick={() => {
                dispatch(setActiveColors(index));
                dispatch(setActiveColorsImg(color.img));
              }}
            >
              <Tooltip
                hasArrow
                label={
                  <img
                    src={color.img}
                    style={{
                      width: "150px",
                      height: "200px",
                      objectFit: "cover",
                      padding: "5px 0",
                    }}
                    alt="placeholder"
                  />
                }
                placement="bottom"
                bg="white.300"
              >
                <img
                  className="colors__by__image__card__img"
                  src={color.img}
                  alt="placeholder"
                />
              </Tooltip>
            </figure>
          ))}
        </Slider>
      ) : null}
    </div>
  );
};

export default ColorsByImage;
