import React from "react";
import { applicationCategories } from "../../constants/applicationsData";
import { MdDensitySmall } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  selectApplicationCategory,
  setActiveMobileContacts,
  setIsVisibleChat,
  setSelectedApplicationCategory,
} from "../../redux/slices/ChatSlice";

const ApplicationsCategory = () => {
  const dispatch = useDispatch();
  const lists = useSelector((state) => state.applications.lists);
  const selectedApplicationCategory = useSelector(selectApplicationCategory);

  const categoriesInLists = lists?.map((item) => item.category);

  return (
    <div
      className="applications__content__body__category"
      id="style__scroll__chat"
    >
      <ul className="d-flex flex-column  gap-2 mb-0">
        <li
          className={`d-flex align-items-center gap-2 ${
            selectedApplicationCategory === "" ? "active" : ""
          } `}
          onClick={() => {
            dispatch(setSelectedApplicationCategory(""));
            if (window.innerWidth <= 992) {
              dispatch(setActiveMobileContacts(false));
            }
          }}
        >
          <MdDensitySmall /> Все
        </li>
        {applicationCategories?.map((category, index) => (
          <li
            className={`d-flex align-items-center gap-2 ${
              selectedApplicationCategory === category.title ? "active" : ""
            } ${categoriesInLists?.includes(category.title) ? "" : "disabled"}`}
            key={index}
            onClick={() => {
              dispatch(setSelectedApplicationCategory(category.title));
              dispatch(setIsVisibleChat(true));
              if (window.innerWidth <= 992) {
                dispatch(setActiveMobileContacts(false));
              }
            }}
          >
            {category.icon} <p>{category.title}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ApplicationsCategory;
