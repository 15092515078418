import React, { useEffect, useState } from 'react';
import { CatalogOrderSteps } from '../../../constants/profilePageOrderStepsData';
import StepsUi from './StepsUi';

const CatalogSteps = ({ order }) => {
  const [activeStepCatalog, setActiveStepCatalog] = useState(1);

  const {
    isCatalogPaid,
    isCatalogItemsPreparingForShipping,
    isCatalogItemsDelivered,
    isCatalogItemsShipping,
  } = order.catalogStepsInfo;

  const catalogTotalSteps = CatalogOrderSteps.length;
  const catalogWidth = `${(100 / (catalogTotalSteps - 1)) * (activeStepCatalog - 1)}%`;

  useEffect(() => {
    if (order.orderItems.some((el) => el.orderFrom === 'catalog')) {
      if (!isCatalogPaid) {
        setActiveStepCatalog(1);
      } else if (!isCatalogItemsPreparingForShipping) {
        setActiveStepCatalog(2);
      } else if (!isCatalogItemsShipping) {
        setActiveStepCatalog(3);
      } else if (!isCatalogItemsDelivered) {
        setActiveStepCatalog(4);
      }
    }
  }, [
    isCatalogItemsDelivered,
    isCatalogItemsPreparingForShipping,
    isCatalogItemsShipping,
    isCatalogPaid,
    order.orderItems,
  ]);

  return (
    <>
      {order.orderItems.some((el) => el.orderFrom === 'catalog') ? (
        <>
          <h6 className="">Заказ из отдела "Товары" на стадии:</h6>
          <section
            className="progress-steps__container mb-5"
            style={{ '--progress-width': catalogWidth }}>
            {CatalogOrderSteps.map((el) => (
              <StepsUi key={el.step} activeStep={activeStepCatalog} el={el} {...el} />
            ))}
          </section>
        </>
      ) : null}
    </>
  );
};

export default CatalogSteps;
