import { useCallback, useEffect, useState } from "react";

export const useCdekItems = (cartItems, constructorItems) => {
  const [cdekItems, setCdekItems] = useState([]);
  const [totalDimensions, setTotalDimensions] = useState({
    weight: 0,
  });
  const [packageCounter, setPackageCounter] = useState(1);

  const updateCdekDeliveryOrder = useCallback(() => {
    const updatedCdekItems = [
      ...cartItems?.map((cartItem) => ({
        ware_key: cartItem.articul,
        payment: {
          value: 0,
        },
        name: cartItem.title,
        cost: cartItem.price,
        amount: cartItem.qty,
        weight: cartItem.weight,
        url: cartItem.url,
      })),
      ...constructorItems?.map((constructorItem) => ({
        ware_key: "CD34GDFDDv",
        payment: {
          value: 0,
        },
        name: constructorItem.title,
        cost: constructorItem.price,
        amount: constructorItem.qty,
        weight: constructorItem.weight,
        url: constructorItem.url,
      })),
    ];

    const totalGabarits = cartItems.concat(constructorItems);

    // Рассчитываем общие габариты и вес
    const totalDimensions = totalGabarits.reduce(
      (acc, item) => {
        const construtorQty = item.qty / 4;
        const totalPackages = Math.ceil(
          item.qty / item.qtyOfGoodsInPack || construtorQty
        );

        return {
          weight: acc.weight + item.weight * totalPackages,
        };
      },
      { weight: 0 }
    );
    setCdekItems(updatedCdekItems);
    setTotalDimensions(totalDimensions);
  }, [cartItems, constructorItems]);

  useEffect(() => {
    updateCdekDeliveryOrder();
  }, [updateCdekDeliveryOrder]);

  useEffect(() => {
    setPackageCounter((prevCounter) => prevCounter + 1);
  }, [cartItems, constructorItems]);

  return { cdekItems, totalDimensions, packageCounter };
};
