import React from "react";
import { Helmet } from "react-helmet-async";

export const Seo = ({ title, description, name, type, keywords }) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="https://maneken.shop/" />
      <meta
        property="og:image"
        content="https://res.cloudinary.com/drdqjr8r6/image/upload/v1695140639/bg%20images/poixwsf9k4bhu9cq4sfo.svg"
      />
      <meta property="og:description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* End Twitter tags */}

      <script type="text/javascript">
        {`
            var _tmr = window._tmr || (window._tmr = []);
            _tmr.push({id: "3475918", type: "pageView", start: (new Date()).getTime()});
            (function (d, w, id) {
              if (d.getElementById(id)) return;
              var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
              ts.src = "https://top-fwz1.mail.ru/js/code.js";
              var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
              if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
            })(document, window, "tmr-code");
          `}
      </script>
    </Helmet>
  );
};
