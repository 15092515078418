import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import "./AddShowSellersItemsBtn.scss";

import { Loader } from "../../../../../common/loader/Loader";

import { RiMailSendLine } from "react-icons/ri";
import { $apiPrivate } from "../../../../../../api/axios";
import SampleSectionWithSizesModal from "./sampleSectionWithSizesModal/SampleSectionWithSizesModal";
import { useDispatch, useSelector } from "react-redux";
import {
  selectChats,
  setChats,
  setCurrentChatUser,
  setIsVisibleChat,
} from "../../../../../../redux/slices/ChatSlice";
import {
  selectBrandCatalogOpen,
  setIsBrandCatalogOpen,
} from "../../../../../../redux/slices/SingleCatalogItemSlice";
import useAddToCart from "../../../../../../hooks/useAddToCart";

const AddShowSellersItemsBtns = ({ item }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [loadingChat, setLoadingChat] = useState(false);

  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const chats = useSelector(selectChats);
  const isBrandCatalogOpen = useSelector(selectBrandCatalogOpen);

  const addToCartHandler = useAddToCart(id, item);

  const handleOpenBrandsCatalog = () => {
    dispatch(setIsBrandCatalogOpen(!isBrandCatalogOpen));
  };

  const accessChat = async (userId) => {
    if (userInfo) {
      try {
        setLoadingChat(true);

        const { data } = await $apiPrivate.post(`/applications/chat`, {
          userId,
        });
        if (!chats?.find((c) => c._id === data._id))
          dispatch(setChats([data, ...chats]));
        dispatch(setCurrentChatUser(data));
        dispatch(setIsVisibleChat(false));
        setLoadingChat(false);
        navigate("/applications");
      } catch (error) {
        toast({
          title: "Error fetching the chat",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <React.Fragment>
      {loadingChat ? (
        <Loader />
      ) : (
        <div className="d-flex flex-column flex-md-row gap-3 ">
          <SampleSectionWithSizesModal
            item={item}
            addToCartHandler={addToCartHandler}
          />
          <button
            type="button"
            onClick={() => accessChat(item?.userId)}
            className="addToCartBtn w-100"
          >
            <RiMailSendLine /> Чат с продавцом
          </button>

          <button
            type="button"
            className="addToCartBtn w-100"
            onClick={handleOpenBrandsCatalog}
          >
            Каталог{" "}
            {item.brand !== "-" && item.brand !== "" ? item.brand : "продавца"}
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default AddShowSellersItemsBtns;
