import { Checkbox } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  accessories,
  categories,
  childGirlBoy,
  genders,
  maleCategories,
  shoes,
} from "../../../../constants/catalogCategoriesSortFilterData";
import { AiFillCloseCircle } from "react-icons/ai";

const ProductGenderChoose = ({
  gender,
  setGender,
  setCategory,
  setAgeGroup,
  product,
}) => {
  const [selectedAgeGroup, setSelectedAgeGroup] = useState("");
  const [showCategories, setCategories] = useState(true);
  const [showSubCategories, setSubCategories] = useState(true);
  const [activeCategory, setActiveCategory] = useState("");

  useEffect(() => {
    if (product) {
      setGender("");
      setCategory("");
      setAgeGroup("");
      setSelectedAgeGroup("");
      setActiveCategory("");
      setCategories(true);
      setSubCategories(true);
    }
  }, [product, setGender, setCategory, setAgeGroup]);

  const resetSelection = () => {
    setSelectedAgeGroup("");
    setCategories(true);
    setSubCategories(true);
  };

  const handleCheckboxChange = (event) => {
    const selectedGenderValue = event.target.value;
    setGender(selectedGenderValue);
    setSubCategories(true);
    resetSelection();
  };

  const handleAgeGroupChange = (ageGroup) => {
    setSelectedAgeGroup(ageGroup);
    setCategories(false);
    setSubCategories(true);
    setAgeGroup(ageGroup.ageGroup);
  };

  const handleCloseSubcategories = () => {
    setCategories(true);
    setSubCategories(false);
  };

  const handleCategoryChange = (category) => {
    setCategory(category);
    setActiveCategory(category);
  };

  return (
    <React.Fragment>
      <p>Выберите категорию:</p>
      <div className=" d-flex align-items-start align-items-md-center  flex-column flex-md-row gap-4 mb-3">
        {genders.map((genderOption, index) => (
          <div className="d-flex align-items-center" key={genderOption.id}>
            <Checkbox
              size="lg"
              colorScheme="blue"
              type="checkbox"
              id={genderOption.id}
              value={genderOption.value}
              isChecked={gender === genderOption.value}
              onChange={handleCheckboxChange}
              name={genderOption.value}
            >
              {genderOption.label}
            </Checkbox>
          </div>
        ))}
      </div>
      {showCategories && gender === "male" && (
        <div className="mb-3">
          <p className="mb-1">Категории:</p>
          <div className="d-flex align-items-center gap-3 flex-wrap">
            {maleCategories.map((category) => (
              <button
                key={category}
                className={`card-body-gender ${
                  activeCategory === category ? "active" : ""
                }`}
                type="button"
                onClick={() => handleCategoryChange(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
      )}

      {showCategories && gender === "female" && (
        <div className="mb-3">
          <p className="mb-1">Категории:</p>
          <div className="d-flex align-items-center gap-3 flex-wrap">
            {categories.map((category) => (
              <button
                key={category}
                className={`card-body-gender ${
                  activeCategory === category ? "active" : ""
                }`}
                type="button"
                onClick={() => handleCategoryChange(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
      )}

      {showCategories && gender === "accessories" && (
        <div className="mb-3">
          <p className="mb-1">Категории:</p>
          <div className="d-flex align-items-center gap-3 flex-wrap">
            {accessories.map((category) => (
              <button
                key={category}
                className={`card-body-gender ${
                  activeCategory === category ? "active" : ""
                }`}
                type="button"
                onClick={() => handleCategoryChange(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
      )}
      {showCategories && gender === "shoes" && (
        <div className="mb-3">
          <p className="mb-1">Категории:</p>
          <div className="d-flex align-items-center gap-3 flex-wrap">
            {shoes.map((category) => (
              <button
                key={category}
                className={`card-body-gender ${
                  activeCategory === category ? "active" : ""
                }`}
                type="button"
                onClick={() => handleCategoryChange(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
      )}
      {showCategories && gender === "child" && (
        <div className="mb-3">
          <p className="mb-1">Выберите возрастную группу:</p>
          <div className="d-flex align-items-center gap-3 flex-wrap">
            {childGirlBoy.map((ageGroup) => (
              <button
                key={ageGroup.id}
                className="card-body-gender"
                type="button"
                onClick={() => handleAgeGroupChange(ageGroup)}
              >
                {ageGroup.ageGroup}
              </button>
            ))}
          </div>
        </div>
      )}
      {selectedAgeGroup && showSubCategories && (
        <div className="mb-3">
          <button
            type="button"
            className="card-body-gender d-flex align-items-center gap-2"
            onClick={handleCloseSubcategories}
          >
            {selectedAgeGroup.ageGroup} <AiFillCloseCircle />
          </button>
          <p className="mb-1">Категории:</p>
          <div className="d-flex align-items-center gap-3 flex-wrap">
            {selectedAgeGroup.categories.map((category) => (
              <button
                onClick={() => handleCategoryChange(category)}
                type="button"
                className={`card-body-gender ${
                  activeCategory === category ? "active" : ""
                }`}
                key={category}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ProductGenderChoose;
