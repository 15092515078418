import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadCatalogImageAction } from "../../../../redux/Actions/ProfieActions";

import { FaCloudUploadAlt } from "react-icons/fa";
import { MdOutlineDeleteForever } from "react-icons/md";

import { Skeleton, Stack } from "@chakra-ui/react";

import { ImageColorPicker } from "react-image-color-picker";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { isVideo } from "../../../../helper/isVideo";

const ProductMainImage = ({
  setImage,
  image,
  text,
  style,
  brandLogoError,
  setBrandLogoError,
  fieldType,
  setProductionColor,
  productionMainImageError,
  setProductionMainImageError,
  productionColorError,
  setProductionColorError,
  productionModelImageError,
  setProductionModelImageError,
  productionModelFlippedImageError,
  setProductionModelFlippedImageError,
  productionModelColorError,
  setProductionModelColorError,
}) => {
  const dispatch = useDispatch();
  const uniqueIdentifierRef = useRef(null);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        uniqueIdentifierRef.current = uuidv4();
        if (acceptedFiles.length === 0) {
          console.error("No files to upload");
          return;
        }
        if (brandLogoError) {
          setBrandLogoError("");
        }
        if (productionMainImageError) {
          setProductionMainImageError("");
        }
        if (productionModelImageError) {
          setProductionModelImageError("");
        }
        if (productionModelFlippedImageError) {
          setProductionModelFlippedImageError("");
        }

        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
          const uploadedImage = reader.result;

          const uploadResult = await dispatch(
            uploadCatalogImageAction(uploadedImage, uniqueIdentifierRef.current)
          );

          if (uploadResult.success) {
            setImage(uploadResult.response.secure_url);
          }
        };
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },
    [
      dispatch,
      setImage,
      brandLogoError,
      setBrandLogoError,
      productionMainImageError,
      setProductionMainImageError,
      productionModelFlippedImageError,
      setProductionModelFlippedImageError,
      productionModelImageError,
      setProductionModelImageError,
    ]
  );

  const catalogUploadImage = useSelector(
    (state) => state.catalogUploadImage || {}
  );
  const { catUploadError, catUploadLoading } =
    catalogUploadImage[uniqueIdentifierRef.current] || {};

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const handleRemoveImage = () => {
    setImage("");
  };

  const handleColorPick = (color) => {
    setProductionColor(color);
    if (productionColorError) {
      setProductionColorError("");
    }
    if (productionModelColorError) {
      setProductionModelColorError("");
    }
  };

  return (
    <section style={style}>
      <label htmlFor="product_image">{text}</label>
      <div className={`mb-4 main-profile-image`}>
        <label htmlFor="product_image" className="form-label d-none">
          Основная картинка товара
        </label>

        <div
          className={`main-profile-image__content d-flex align-items-center flex-column justify-content-center ${
            brandLogoError ||
            productionMainImageError ||
            productionModelImageError ||
            productionModelFlippedImageError
              ? "cart-border-error"
              : ""
          }`}
        >
          <div
            className="d-flex align-items-center flex-column justify-content-center"
            {...getRootProps()}
          >
            <FaCloudUploadAlt className="uploadImage" />
            <input {...getInputProps()} id="product_image" />
            {isDragActive ? (
              <p className="mb-0 text-center ">Перетащите файл сюда...</p>
            ) : (
              <>
                <p className="mb-0 text-center ">
                  Перетащите файл сюда или нажмите, чтобы выбрать файл.
                </p>
                <p className="mb-0 text-center ">
                  Максимальное количество файлов для загрузки - 1 файл.
                </p>
                <p className="mb-0 text-center ">
                  Размер файла для загрузки - до 10мб.
                </p>
              </>
            )}
          </div>
        </div>

        {brandLogoError ||
          (catUploadError && (
            <p className="cart-error-message">
              {brandLogoError || catUploadError}
            </p>
          ))}
        {productionMainImageError && (
          <p className="cart-error-message">{productionMainImageError}</p>
        )}
        {productionModelImageError && (
          <p className="cart-error-message">{productionModelImageError}</p>
        )}
        {productionModelFlippedImageError && (
          <p className="cart-error-message">
            {productionModelFlippedImageError}
          </p>
        )}
        {catUploadLoading ? (
          <Stack>
            <Skeleton height="20px" borderRadius="10px" />
            <Skeleton height="20px" borderRadius="10px" />
            <Skeleton height="20px" borderRadius="10px" />
          </Stack>
        ) : (
          <div className="profile-images-container">
            {image ? (
              <div
                className={`position-relative ${
                  fieldType === "ProductionCatalogImage"
                    ? "image-preview-colorPicker-container"
                    : "image-preview-container "
                }`}
              >
                {fieldType === "ProductionCatalogImage" ? (
                  <ImageColorPicker
                    className="mb-1 mt-3"
                    onColorPick={handleColorPick}
                    imgSrc={image}
                    zoom={1}
                  />
                ) : isVideo(image) ? (
                  <figure>
                    <video src={image} controls className="mb-1">
                      Ваш браузер не поддерживает воспроизведение видео.
                    </video>
                  </figure>
                ) : (
                  <figure>
                    <img src={image} alt="Картинка товара" className="mb-1" />
                  </figure>
                )}
                <div className="d-flex justify-content-end align-items-center gap-2 w-100 ">
                  <button
                    type="button"
                    onClick={() => handleRemoveImage(image)}
                    className="add__colors__image d-flex align-items-center  gap-2"
                  >
                    Удалить <MdOutlineDeleteForever />
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </section>
  );
};

export default ProductMainImage;
