import React, { useEffect, useState } from 'react';
import StepsUi from './StepsUi';

const FulfillmentSteps = ({ order }) => {
  const [activeStepFF, setActiveStepFF] = useState(1);

  const ffTotalSteps = Math.max(...order.orderItems.map((el) => el.selectedItems.length));

  const ffWidth = `${(100 / (ffTotalSteps - 1)) * (activeStepFF - 1)}%`;

  useEffect(() => {
    if (order.orderItems.some((el) => el.selectedItems.length > 0)) {
      let foundIndex = 1;

      order.orderItems.forEach((item) => {
        item.selectedItems.forEach((selectedItem) => {
          if (selectedItem.isDone && selectedItem.step > foundIndex) {
            foundIndex = selectedItem.step;
          }
        });
      });

      setActiveStepFF(foundIndex);
    }
  }, [order.orderItems]);

  return (
    <>
      {order.orderItems.some((el) => el.selectedItems.length > 0) ? (
        <>
          <h6 className="">Фулфилмента на стадии:</h6>
          <section
            className="progress-steps__container mb-5"
            style={{ '--progress-width': ffWidth }}>
            {order.orderItems.map((el) =>
              el.selectedItems.map((el) => <StepsUi key={el._id} activeStep={activeStepFF} el={el} {...el} />),
            )}
          </section>
        </>
      ) : null}
    </>
  );
};

export default FulfillmentSteps;
