import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./styles/CatalogItemPage.scss";
import { useToast } from "@chakra-ui/react";

import { singleCatalogItemAction } from "../redux/Actions/CatalogActions";
import { CATALOG_CREATE_REVIEW_RESET } from "../redux/constants/CatalogConstants";
import { CatalogItemPageContent } from "../components/catalog/catalogItemPage/catalogItemPageContent/CatalogItemPageContent";
import {
  setColors,
  setComment,
  setCount,
  setRatings,
  setSizes,
} from "../redux/slices/SingleCatalogItemSlice";

const CatalogItemPage = memo(() => {
  const toast = useToast();

  const catalogList = useSelector((state) => state.catalogDetails);
  const { item, loading, error } = catalogList;

  const successCreateReview = useSelector(
    (state) => state.catalogReviewCreate.success
  );

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (item?.colors) {
      dispatch(setColors(item.colors));
      dispatch(setSizes(item.sizes));
      dispatch(setCount(item.countInStock));
    }
  }, [dispatch, item]);

  useEffect(() => {
    if (successCreateReview) {
      toast({
        title: "Комментарий добавлен!",
        status: "success",
        isClosable: true,
        position: "top-right",
        duration: 3000,
      });
      dispatch(setRatings(0));
      dispatch(setComment(""));
      dispatch({ type: CATALOG_CREATE_REVIEW_RESET });
    }
    dispatch(singleCatalogItemAction(id));
  }, [dispatch, id, successCreateReview, toast]);

  return <CatalogItemPageContent item={item} loading={loading} error={error} />;
});

export default CatalogItemPage;
