import React, { memo } from "react";
import "./Carousel.scss";
import useCarousel from "../../../hooks/useCarousel";

const Carousel = memo(() => {
  const { odragRef, ospinRef, aImgRef, teamImages } = useCarousel();

  return (
    <div className="carousel-wrapper">
      <div id="drag-container" ref={odragRef}>
        <div id="spin-container" ref={ospinRef}>
          {teamImages?.map((product, index) => (
            <React.Fragment key={product.id}>
              {product.img ? (
                <img
                  src={product.img}
                  alt="Картинка товара"
                  ref={(el) => (aImgRef.current[index] = el)}
                />
              ) : null}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div id="ground" />
    </div>
  );
});

export default Carousel;
