import React, { useEffect, useRef } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { ImAttachment } from "react-icons/im";
import { useSelector } from "react-redux";
import { selectInputMsg } from "../../../../redux/slices/ChatSlice";

const PrivateChatInput = ({ typingHandler, sendMessageToUser }) => {
  const inputRef = useRef(null);
  const inputMessage = useSelector(selectInputMsg);
  
  useEffect(() => {
    const delay = 1000;

    const scrollToInput = () => {
      if (inputRef.current) {
        inputRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        inputRef.current.focus();
      }
    };
    const timeoutId = setTimeout(scrollToInput, delay);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="w-100 ">
      <input
        ref={inputRef}
        type="text"
        className="form-control"
        placeholder="Введите сообщение"
        value={inputMessage}
        onChange={typingHandler}
      />

      <div className="d-flex align-items-center justify-content-center m-2">
        <ImAttachment />
      </div>
      <button
        className="d-flex align-items-center justify-content-between applicationsBtn"
        type="button"
        onClick={sendMessageToUser}
      >
        <AiOutlineSend />
      </button>
    </div>
  );
};

export default PrivateChatInput;
