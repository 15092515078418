import { removeItem } from "../../../redux/Actions/CartActions";
import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { CatalogCart } from "./catalogCart/CatalogCart";
import { ConstructorCart } from "./constructorCart/ConstructorCart";
import { FulfillmentCart } from "./fulfillmentCart/FulfillmentCart";
import { useEffect, useState } from "react";

export const CartItems = ({ fulfillment, cartItems, constructorItems }) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const [cart, setCart] = useState([]);
  const [cartConstructor, setCartConstructor] = useState([]);

  useEffect(() => {
    setCart(cartItems);
    setCartConstructor(constructorItems);
  }, [constructorItems, cartItems]);

  const handleRemoveItem = (item) => {
    dispatch(removeItem(item));
    toast({
      title: "Товар удален из корзины!",
      status: "success",
      isClosable: true,
      position: "top-right",
      duration: 1000,
    });
  };

  return (
    <section className="shopping-cart__items">
      <CatalogCart cart={cart} handleRemoveItem={handleRemoveItem} />
      <ConstructorCart
        cartConstructor={cartConstructor}
        handleRemoveItem={handleRemoveItem}
      />
      <FulfillmentCart
        fulfillment={fulfillment}
        handleRemoveItem={handleRemoveItem}
      />
    </section>
  );
};
