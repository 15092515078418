export const YldamExpressDelivery = [
  // Бишкек
  {
    id: 1,
    city: "Аламединский район",
    long: 42.886916,
    lat: 74.669871,
    region: "Chuy",
  },
  {
    id: 2,
    city: "Сокулук",
    long: 42.857593,
    lat: 74.299163,
    region: "Chuy",
  },
  {
    id: 3,
    city: "Беловодское",
    long: 42.821861,
    lat: 74.102693,
    region: "Chuy",
  },
  {
    id: 4,
    city: "Кант",
    long: 42.889704,
    lat: 74.852238,
    region: "Chuy",
  },
  {
    id: 5,
    city: "Кара-Балта",
    long: 42.819791,
    lat: 73.844014,
    region: "Chuy",
  },
  {
    id: 6,
    city: "Токмок",
    long: 42.837485,
    lat: 75.295116,
    region: "Chuy",
  },
  {
    id: 7,
    city: "Александровка",
    long: 42.85413,
    lat: 74.224881,
    region: "Chuy",
  },
  {
    id: 8,
    city: "Алексеевка",
    long: 42.815043,
    lat: 73.815375,
    region: "Chuy",
  },
  {
    id: 9,
    city: "Каинды",
    long: 42.831231,
    lat: 73.679469,
    region: "Chuy",
  },
  {
    id: 10,
    city: "Новониколаевка",
    long: 42.833717,
    lat: 73.902746,
    region: "Chuy",
  },
  {
    id: 11,
    city: "Новопавловка",
    long: 42.875058,
    lat: 74.48524,
    region: "Chuy",
  },
  {
    id: 12,
    city: "Петровка",
    long: 42.839178,
    lat: 74.020308,
    region: "Chuy",
  },
  {
    id: 13,
    city: "Шопоков",
    long: 42.856509,
    lat: 74.343567,
    region: "Chuy",
  },
  {
    id: 14,
    city: "Новопокровка",
    long: 42.869322,
    lat: 74.730947,
    region: "Chuy",
  },
  {
    id: 15,
    city: "Люксембург",
    long: 42.890582,
    lat: 74.828262,
    region: "Chuy",
  },
  {
    id: 16,
    city: "Киршёлк",
    long: 42.882986,
    lat: 74.794063,
    region: "Chuy",
  },
  {
    id: 17,
    city: "Дмитриевка",
    long: 42.894149,
    lat: 74.900936,
    region: "Chuy",
  },
  {
    id: 18,
    city: "Кенеш",
    long: 42.899558,
    lat: 74.951394,
    region: "Chuy",
  },
  {
    id: 19,
    city: "Искра",
    long: 42.811293,
    lat: 75.440158,
    region: "Chuy",
  },
  {
    id: 20,
    city: "Будённовка",
    long: 42.896144,
    lat: 74.9319,
    region: "Chuy",
  },
  {
    id: 21,
    city: "Кен-Булун",
    long: 42.871311,
    lat: 75.130554,
    region: "Chuy",
  },
  {
    id: 22,
    city: "Ивановка",
    long: 42.886117,
    lat: 75.083841,
    region: "Chuy",
  },
  {
    id: 23,
    city: "Кемин",
    long: 42.781027,
    lat: 75.685533,
    region: "Chuy",
  },
  {
    id: 24,
    city: "Бишкек",
    long: 42.875969,
    lat: 74.603701,
    region: "Chuy",
  },
  // ТАЛАС
  {
    id: 25,
    city: "Талас",
    long: 42.520755,
    lat: 72.250591,
    region: "Talas",
  },
  {
    id: 26,
    city: "Кара-Буура",
    long: 42.523386,
    lat: 71.580053,
    region: "Talas",
  },
  {
    id: 27,
    city: "Бакай-Ата",
    long: 42.488105,
    lat: 71.927063,
    region: "Talas",
  },
  {
    id: 28,
    city: "Кызыл-Адыр",
    long: 42.616085,
    lat: 71.586368,
    region: "Talas",
  },
  {
    id: 29,
    city: "Манас",
    long: 42.485458,
    lat: 72.524735,
    region: "Talas",
  },
  {
    id: 30,
    city: "Покровка айылы",
    long: 42.729724,
    lat: 71.60825,
    region: "Talas",
  },
  // НАРЫН
  {
    id: 31,
    city: "Кочкорка",
    long: 42.215927,
    lat: 75.755367,
    region: "Naryn",
  },
  {
    id: 32,
    city: "Джумгал",
    long: 42.030222,
    lat: 74.982952,
    region: "Naryn",
  },
  {
    id: 33,
    city: "Чаек",
    long: 41.929111,
    lat: 74.520526,
    region: "Naryn",
  },
  {
    id: 34,
    city: "Ак-Талаа",
    long: 42.180247,
    lat: 75.324213,
    region: "Naryn",
  },
  {
    id: 35,
    city: "Баетово",
    long: 41.264141,
    lat: 74.955993,
    region: "Naryn",
  },
  {
    id: 36,
    city: "поселок Казарман",
    long: 41.406834,
    lat: 74.028149,
    region: "Naryn",
  },
  {
    id: 37,
    city: "село Ат-Баши",
    long: 41.168662,
    lat: 75.813139,
    region: "Naryn",
  },
  {
    id: 38,
    city: "Нарын",
    long: 41.428296,
    lat: 75.997626,
    region: "Naryn",
  },
  // ЫССЫК-КОЛ
  {
    id: 39,
    city: "Чолпон-Ата",
    long: 42.649742,
    lat: 77.085755,
    region: "IssykKol",
  },
  {
    id: 40,
    city: "Балыкчи",
    long: 42.456909,
    lat: 76.196306,
    region: "IssykKol",
  },
  {
    id: 41,
    city: "село Джети-Огуз",
    long: 42.432265,
    lat: 78.21359,
    region: "IssykKol",
  },
  {
    id: 42,
    city: "поселок Тон",
    long: 42.154478,
    lat: 77.04295,
    region: "IssykKol",
  },
  {
    id: 43,
    city: "село Тюп",
    long: 42.725683,
    lat: 78.363806,
    region: "IssykKol",
  },
  {
    id: 44,
    city: "село Тамчы",
    long: 42.565845,
    lat: 76.660439,
    region: "IssykKol",
  },
  {
    id: 43,
    city: "село Тору-Айгыр",
    long: 42.491981,
    lat: 76.429437,
    region: "IssykKol",
  },
  {
    id: 44,
    city: "село Чырпыкты",
    long: 42.517817,
    lat: 76.583902,
    region: "IssykKol",
  },
  {
    id: 45,
    city: "село Чок-Тал",
    long: 42.586241,
    lat: 76.742419,
    region: "IssykKol",
  },
  {
    id: 46,
    city: "село Григорьевка",
    long: 42.718635,
    lat: 77.484706,
    region: "IssykKol",
  },
  {
    id: 47,
    city: "село Ананьево",
    long: 42.734431,
    lat: 77.669417,
    region: "IssykKol",
  },
  {
    id: 48,
    city: "Боомское ущелье",
    long: 42.583247,
    lat: 75.806114,
    region: "IssykKol",
  },
  {
    id: 49,
    city: "село Бостери",
    long: 42.656551,
    lat: 77.189636,
    region: "IssykKol",
  },
  {
    id: 50,
    city: "село Булан-Сёгётту",
    long: 42.675101,
    lat: 77.300273,
    region: "IssykKol",
  },
  {
    id: 51,
    city: "село Бактуу-Долоноту",
    long: 42.652493,
    lat: 77.137938,
    region: "IssykKol",
  },
  {
    id: 52,
    city: "село Кара-Ой",
    long: 42.634196,
    lat: 77.017789,
    region: "IssykKol",
  },
  {
    id: 53,
    city: "село Сары-Ой",
    long: 42.612914,
    lat: 76.948394,
    region: "IssykKol",
  },
  {
    id: 54,
    city: "село Чон-Сары-Ой",
    long: 42.605714,
    lat: 76.908769,
    region: "IssykKol",
  },
  {
    id: 55,
    city: "село Баетовка",
    long: 42.620497,
    lat: 76.975649,
    region: "IssykKol",
  },
  {
    id: 56,
    city: "Каракол",
    long: 42.491144,
    lat: 78.399568,
    region: "IssykKol",
  },
  {
    id: 57,
    city: "село Джети-Огуз",
    long: 42.432265,
    lat: 78.21359,
    region: "IssykKol",
  },
  {
    id: 58,
    city: "село Барскоон",
    long: 42.151744,
    lat: 77.606401,
    region: "IssykKol",
  },
  {
    id: 59,
    city: "село Кызыл-Суу",
    long: 42.338958,
    lat: 78.004157,
    region: "IssykKol",
  },
  {
    id: 60,
    city: "село Тамга",
    long: 42.151236,
    lat: 77.547282,
    region: "IssykKol",
  },
  {
    id: 61,
    city: "село Боконбаево",
    long: 42.115102,
    lat: 76.992618,
    region: "IssykKol",
  },
  {
    id: 62,
    city: "село Каджы-Сай",
    long: 42.141335,
    lat: 77.177329,
    region: "IssykKol",
  },
  {
    id: 63,
    city: "село Боконбаево",
    long: 42.115102,
    lat: 76.992618,
    region: "IssykKol",
  },
  {
    id: 64,
    city: "село Кызыл-Суу",
    long: 42.338958,
    lat: 78.004157,
    region: "IssykKol",
  },
  {
    id: 65,
    city: "село Ак-Суу",
    long: 42.499653,
    lat: 78.524497,
    region: "IssykKol",
  },
  // Баткен
  {
    id: 66,
    city: "Баткен",
    long: 40.060518,
    lat: 70.819638,
    region: "Batken",
  },
  {
    id: 67,
    city: "Айдаркен",
    long: 39.946759,
    lat: 71.337058,
    region: "Batken",
  },
  {
    id: 68,
    city: "Кадамжай",
    long: 40.132694,
    lat: 71.726711,
    region: "Batken",
  },
  {
    id: 69,
    city: "село Кулунду",
    long: 40.106853,
    lat: 69.643132,
    region: "Batken",
  },
  {
    id: 70,
    city: "Кызыл-Кия",
    long: 40.258392,
    lat: 72.122312,
    region: "Batken",
  },
  {
    id: 71,
    city: "деревня Лейлек",
    long: 39.878004,
    lat: 70.090313,
    region: "Batken",
  },
  {
    id: 72,
    city: "село Самаркандык",
    long: 39.997807,
    lat: 70.536444,
    region: "Batken",
  },
  {
    id: 73,
    city: "село Коргон",
    long: 39.883015,
    lat: 69.952835,
    region: "Batken",
  },
  {
    id: 74,
    city: "село Халмион",
    long: 40.212059,
    lat: 71.632559,
    region: "Batken",
  },
  {
    id: 75,
    city: "город Раззаков",
    long: 39.838515,
    lat: 69.53175,
    region: "Batken",
  },
  // ЖАЛАЛ-АБАД
  {
    id: 76,
    city: "Джалал-Абад",
    long: 40.938049,
    lat: 72.993309,
    region: "JalalAbad",
  },
  {
    id: 77,
    city: "город Базар-Коргон",
    long: 41.033207,
    lat: 72.742437,
    region: "JalalAbad",
  },
  {
    id: 78,
    city: "Таш-Кумыр",
    long: 41.34666,
    lat: 72.225806,
    region: "JalalAbad",
  },
  {
    id: 79,
    city: "Аксыйский район",
    long: 41.544587,
    lat: 72.048919,
    region: "JalalAbad",
  },
  {
    id: 80,
    city: "Шамалды-Сай",
    long: 41.172219,
    lat: 72.184655,
    region: "JalalAbad",
  },
  {
    id: 81,
    city: "Токтогул",
    long: 41.871844,
    lat: 72.937748,
    region: "JalalAbad",
  },
  {
    id: 82,
    city: "Кара-Куль",
    long: 41.631317,
    lat: 72.671784,
    region: "JalalAbad",
  },
  {
    id: 83,
    city: "Майлуу-Суу",
    long: 41.263728,
    lat: 72.468423,
    region: "JalalAbad",
  },
  {
    id: 84,
    city: "село Ноокен",
    long: 41.040219,
    lat: 72.58789,
    region: "JalalAbad",
  },
  {
    id: 85,
    city: "село Ала-Бука",
    long: 41.406055,
    lat: 71.486331,
    region: "JalalAbad",
  },
  {
    id: 86,
    city: "поселок Казарман",
    long: 41.406833,
    lat: 74.028177,
    region: "JalalAbad",
  },
  {
    id: 87,
    city: "посёлок Сузак",
    long: 40.897647,
    lat: 72.900989,
    region: "JalalAbad",
  },
  {
    id: 88,
    city: "Чаткальский район",
    long: 41.821972,
    lat: 71.043291,
    region: "JalalAbad",
  },
  // OSH
  {
    id: 89,
    city: "Ош",
    long: 40.517525,
    lat: 72.80557,
    region: "Osh",
  },
  {
    id: 90,
    city: "Кара-Суу",
    long: 40.706444,
    lat: 72.880121,
    region: "Osh",
  },
  {
    id: 91,
    city: "село Куршаб",
    long: 40.690076,
    lat: 73.187678,
    region: "Osh",
  },
  {
    id: 92,
    city: "село Алай",
    long: 40.650289,
    lat: 73.084371,
    region: "Osh",
  },
  {
    id: 93,
    city: "село Кара-Кульджа",
    long: 40.632354,
    lat: 73.590473,
    region: "Osh",
  },
  {
    id: 94,
    city: "Ноокат",
    long: 40.265467,
    lat: 72.618343,
    region: "Osh",
  },
  {
    id: 95,
    city: "посёлок Араван",
    long: 40.513377,
    lat: 72.503772,
    region: "Osh",
  },
  {
    id: 96,
    city: "Узген",
    long: 40.770311,
    lat: 73.29932,
    region: "Osh",
  },
];

export const clientsList = [
  {
    id: 1,
    imgAlt: "г. Омск",
    city: "г. Омск",
    text: "Срок доставки 5 - 7 дней",
    text2: "Цена - 50 руб. за 1 кг.",
  },
  {
    id: 2,
    imgAlt: "г. Новосибирск",
    city: "г. Новосибирск",
    text: "Срок доставки 5 - 7 дней",
    text2: "Цена - 50 руб. за 1 кг.",
    text3: "Авиа",
    text4: "Цена - 130 руб. за 1 кг.",
  },
  {
    id: 3,
    imgAlt: "г. Москва",
    city: "г. Москва",
    text: "Срок доставки 6 - 9 дней",
    text2: "Цена - 50 руб. за 1 кг.",
    text3: "Авиа",
    text4: "Цена - 185 руб. за 1 кг.",
  },
  {
    id: 4,
    imgAlt: "г. Уфа",
    city: "г. Уфа",
    text: "Срок доставки 6 - 9 дней",
    text2: "Цена - 45 руб. за 1 кг.",
  },
  {
    id: 5,
    imgAlt: "г. Алматы",
    city: "г. Алматы",
    text: "Срок доставки 1 - 2 дня",
    text2: "Цена - 150 тенге. за 1 кг.",
  },
  {
    id: 6,
    imgAlt: "г. Бишкек",
    city: "г. Бишкек",
    text: "Отправитель",
  },
  {
    id: 7,
    imgAlt: "г. Самара",
    city: "г. Самара",
    text: "Срок доставки 6 - 9 дней",
    text2: "Цена - 55 руб. за 1 кг.",
  },
  {
    id: 8,
    imgAlt: "г. Казань",
    city: "г. Казань",
    text: "Срок доставки 6 - 9 дней",
    text2: "Цена - 45 руб. за 1 кг.",
  },
  {
    id: 9,
    imgAlt: "г. Оренбург",
    city: "г. Оренбург",
    text: "Срок доставки 5 - 7 дней",
    text2: "Цена - 60 руб. за 1 кг.",
  },
  {
    id: 10,
    imgAlt: "г. Челябинск",
    city: "г. Челябинск",
    text: "Срок доставки 5 - 7 дней",
    text2: "Цена - 45 руб. за 1 кг.",
  },
  {
    id: 11,
    imgAlt: "г. Екатеринбург",
    city: "г. Екатеринбург",
    text: "Срок доставки 6 - 9 дней",
    text2: "Цена - 50 руб. за 1 кг.",
  },
  {
    id: 12,
    imgAlt: "г. Красноярск",
    city: "г. Красноярск",
    text: "Срок доставки 6 - 9 дней",
    text2: "Цена - 55 руб. за 1 кг.",
  },
  {
    id: 13,
    imgAlt: "г. Абакан",
    city: "г. Абакан",
    text: "Срок доставки 5 - 7 дней",
    text2: "Цена - 65 руб. за 1 кг.",
  },
  {
    id: 14,
    imgAlt: "г. Иркутск",
    city: "г. Иркутск",
    text: "Срок доставки 6 - 9 дней",
    text2: "Цена - 65 руб. за 1 кг.",
    text3: "Авиа",
    text4: "Цена - 120 руб. за 1 кг.",
  },
];

export const alfaCargo = [
  {
    title: "Москва",
    price: "52",
    text: "Срок доставки 6 - 9 дней",
  },
  {
    title: "Самара",
    price: "57",
    text: "Срок доставки 6 - 9 дней",
  },
  {
    title: "Казань",
    price: "47",
    text: "Срок доставки 6 - 9 дней",
  },
  {
    title: "Уфа",
    price: "47",
    text: "Срок доставки 6 - 9 дней",
  },
  {
    title: "Оренбург",
    price: "62",
    text: "Срок доставки 5 - 7 дней",
  },
  {
    title: "Челябинск",
    price: "47",
    text: "Срок доставки 5 - 7 дней",
  },
  {
    title: "Екатеринбург",
    price: "52",
    text: "Срок доставки 6 - 9 дней",
  },
  {
    title: "Омск",
    price: "52",
    text: "Срок доставки 5 - 7 дней",
  },
  {
    title: "Новосибирск",
    price: "52",
    text: "Срок доставки 5 - 7 дней",
  },
  {
    title: "Красноярск",
    price: "57",
    text: "Срок доставки 6 - 9 дней",
  },
  {
    title: "Абакан",
    price: "67",
    text: "Срок доставки 5 - 7 дней",
  },
  {
    title: "Иркутск",
    price: "67",
    text: "Срок доставки 6 - 9 дней",
  },
];

export const redExpressCargo = [
  {
    title: "Москва",
    price: "47",
    text: "Срок доставки 6 - 9 дней",
  },
  {
    title: "Самара",
    price: "57",
    text: "Срок доставки 6 - 9 дней",
  },
  {
    title: "Казань",
    price: "47",
    text: "Срок доставки 6 - 9 дней",
  },
  {
    title: "Уфа",
    price: "47",
    text: "Срок доставки 6 - 9 дней",
  },
  {
    title: "Челябинск",
    price: "47",
    text: "Срок доставки 5 - 7 дней",
  },
  {
    title: "Екатеринбург",
    price: "52",
    text: "Срок доставки 6 - 9 дней",
  },
];

export const avtoDeliveryServices = [
  {
    id: "cdek - до склада",
    name: "CDEK",
    description: "До пункта выдачи",
  },
  {
    id: "cdek - курьерская доставка",
    name: "CDEK",
    description: "Курьерская доставка - до двери",
  },
  {
    id: "Альфа-Карго",
    name: "Альфа Карго",
    description: "До склада",
    info: "Доставка до 30 кг - 1800 сом",
  },
  {
    id: "Рэд-Экспресс",
    name: "Рэд Экспресс Карго",
    description: "До склада",
    info: "Доставка до 30 кг - 1800 сом",
  },
  {
    id: "Ылдам-Экспресс",
    name: "Курьерская доставка",
    description: "По Кыргызстану",
    info: "Доставка от 1 кг",
  },
];

export const deliveryPageData = [
  {
    id: 1,
    link: "https://www.cdek.ru/ru/",
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1703483059/bg%20images/image_21_fowjli.png",
    name: "Cdek delivery",
  },
  {
    id: 2,
    link: "https://www.alpha-cargo.kg/",
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1703483288/bg%20images/logo_rbab1b.png",
    name: "Alfa Cargo delivery",
  },
  {
    id: 3,
    link: "https://expresscargo.kg/",
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1703483879/bg%20images/logo_jxks0s.png",
    name: "Red express cargo",
  },
  {
    id: 4,
    link: "https://www.instagram.com/yldam.express/",
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1710833630/bg%20images/%D0%91%D0%B5%D0%B7_%D0%BD%D0%B0%D0%B7%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F_1_hcdjsk.svg",
    name: "Ылдам express",
  },
];
