import { AddIcon } from "@chakra-ui/icons";
import React from "react";
import AddedProductionItem from "./addedProductionCatalogItem/AddedProductionItem";
import { ProfileProductionCatalogState } from "../../../../../context/ProfileProductionCatalogProvider";

const AddedProductionCatalogView = ({
  productionCatalog,
  setProductionCatalog,
  handleOpenPopup,
  handleAddProductionCatalogItem,
  handleSaveProductionCatalogItem,
  filteredProductsId,
}) => {
  const { productionModelColors, setProductionModelColors } =
    ProfileProductionCatalogState();
  return (
    <React.Fragment>
      <section className="profile__production__add__catalog__grid">
        <div
          className="h-100 w-100 d-flex align-items-center justify-content-center"
          onClick={handleOpenPopup}
        >
          <AddIcon boxSize="2rem" color="#1475cf" />
        </div>
        {productionCatalog?.map((el,index) => (
          <React.Fragment key={index}>
            <AddedProductionItem
              el={el}
              productId={el._id}
              setProductionCatalog={setProductionCatalog}
              productionCatalog={productionCatalog}
              handleSaveProductionCatalogItem={handleSaveProductionCatalogItem}
              handleAddProductionCatalogItem={handleAddProductionCatalogItem}
              setProductionModelColors={setProductionModelColors}
              productionModelColors={productionModelColors}
              filteredProductsId={filteredProductsId}
            />
          </React.Fragment>
        ))}
      </section>
    </React.Fragment>
  );
};

export default AddedProductionCatalogView;
