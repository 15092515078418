import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
} from "./../constants/UserConstants";
import { ORDER_LIST_MY_RESET } from "./../constants/OrderConstants";
import { $api, $apiPrivate } from "../../api/axios";
import inMemoryJwt from "./../../services/inMemoryJwt";

// LOGIN
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const { data } = await $api.post(`/users/login`, { email, password });

    const { accessToken, accessTokenExpiration } = data;

    inMemoryJwt.setToken(accessToken, accessTokenExpiration);

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response?.data?.message ?? error.message,
    });
  }
};

//LOGOUT
export const logout = () => async (dispatch) => {
  try {
    await $api.post(`/users/logout`, {}, { withCredentials: true });
    inMemoryJwt.deleteToken();

    dispatch({ type: USER_LOGOUT });
    dispatch({ type: USER_DETAILS_RESET });
    dispatch({ type: ORDER_LIST_MY_RESET });
    document.location.href = "/login";
  } catch (error) {
    console.log(error);
  }
};

//CLEAR STORAGE
export const clearStorage = () => async (dispatch) => {
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: ORDER_LIST_MY_RESET });
  document.location.href = "/login";
};

// REGISTER
export const register =
  (name, phone, email, password, profile, role) => async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const { data } = await $api.post(`/users`, {
        name,
        phone,
        email,
        password,
        profile,
        role,
      });

      dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: error.response?.data?.message ?? error.message,
      });
    }
  };

// USER DETAILS ACTION
export const getUserDetailsAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const { data } = await $apiPrivate.get(`/users/${id}`);

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message = error.response?.data?.message ?? error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: USER_REGISTER_FAIL,
      payload: message,
    });
  }
};

//UPDATE PROFILE

export const updateProfileAction = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const { data } = await $apiPrivate.put(`/users/profile`, user);

    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
  } catch (error) {
    const message = error.response?.data?.message ?? error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    });
  }
};

// GET ALL USERS
export const getAllUsersAction = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const { data } = await $api.get(`/users/count`);

    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    });
  }
};
