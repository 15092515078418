import React from "react";
import PhoneInput from "react-phone-input-2";

export const CustomPhoneInput = ({
  state,
  setState,
  id,
  productionPhoneNumberError,
  setProductionPhoneNumberError,
}) => {
  return (
    <section className="mb-4">
      <PhoneInput
        name="phoneNumber"
        type="text"
        country={"ru"}
        onlyCountries={["ru", "kz", "uz", "kg"]}
        enableAreaCodes={true}
        inputProps={{
          name: "Номер телефона",
          country: "us",
          autoFocus: false,
          id: id ,
        }}
        className={` ${productionPhoneNumberError ? "cart-border-error" : ""}`}
        onChange={(state) => {
          setState(state);
          if (productionPhoneNumberError) {
            setProductionPhoneNumberError("");
          }
        }}
        placeholder="Введите номер телефона"
        value={state}
        containerStyle={{
          width: "100%",
          borderRadius: "6px",
          height: "37.5px",
        }}
        inputStyle={{
          width: "100%",
          background: "#fff",
          border: "1px solid #dee2e6",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          height: "100%",
          borderRadius: "6px",
          color: "rgb(113 128 177)",
        }}
        buttonStyle={{
          background: "rgb(255 255 255 / 0%)",
          border: "none",
          fontSize: "10px",
        }}
        dropdownStyle={{
          fontSize: "10px",
          paddingLeft: "10px",
        }}
      />
      {productionPhoneNumberError && (
        <p className="cart-error-message" style={{ fontWeight: "initial" }}>
          {productionPhoneNumberError}
        </p>
      )}
    </section>
  );
};
