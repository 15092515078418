import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    const scrollTimeout = setTimeout(scrollToTop, 100);
    return () => clearTimeout(scrollTimeout);
  }, [location.pathname]);

  return children;
};

export default ScrollToTop;
