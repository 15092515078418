import plusSvg from "./../../../../assets/images/cart/plus-square 1.svg";
import minusSvg from "./../../../../assets/images/cart/dash-square 1.svg";
import trashSvg from "./../../../../assets/images/cart/x-square 1.svg";
import { Link } from "react-router-dom";
import {
  constructorIncreaseQty,
  decreaseQtyConstructor,
} from "../../../../redux/Actions/CartActions";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyConverter } from "../../../common/currencyConverter/CurrencyConverter";
import { selectCurrency } from "../../../../redux/slices/CatalogSlice";

export const ConstructorCart = ({ cartConstructor, handleRemoveItem }) => {
  const dispatch = useDispatch();
  const selectedCurrency = useSelector(selectCurrency);

  const handleIncreaseConstructorQty = (index) => {
    dispatch(constructorIncreaseQty(index));
  };

  const handleDecreaseConstructorQty = (index) => {
    dispatch(decreaseQtyConstructor(index));
  };

  return (
    <>
      {cartConstructor.map((el, index) => (
        <section
          key={index}
          className="shopping-cart__item d-flex align-items-center justify-content-between flex-column p-4 flex-xl-row"
        >
          <div className="shopping-cart__item_description d-flex flex-column align-items-center flex-md-row">
            <figure className="d-flex align-items-center justify-content-center">
              <img src={el.image} alt={el.title} title="картинка товара" />
            </figure>
            <div className="shopping-cart__items_item">
              <Link to={`/constructor `}>
                <p className="my-2 text-center text-md-start">
                  Заказ из «Конструктор»
                </p>
              </Link>
              <Link to={`/constructor`}>
                <p className="my-2 text-center text-md-start">{el.title}</p>
              </Link>
              <p>
                <strong>Доп-но:</strong>
                {el.additionaly || "стандартная упаковка"}
              </p>
              <p className="d-flex align-items-center gap-1">
                <strong>Цвет:</strong>
                {el.color.map((el, index) => (
                  <span
                    key={index}
                    className="color-circle"
                    style={{ backgroundColor: el.color || el }}
                  ></span>
                ))}
              </p>
              <p>
                <strong>Ткань:</strong>
                {el.textiles.name || el.textiles}
              </p>
              <p className="d-flex align-items-center gap-1">
                <strong>Размер:</strong>
                {el.sizes.map((el, index) => (
                  <span key={index} className="color-circle">
                    {" "}
                    {el.size || el}
                  </span>
                ))}
              </p>
            </div>
          </div>
          <div className="shopping-cart__count d-flex align-items-center flex-column flex-md-row">
            <div className="shopping-cart__items_count my-2 d-flex align-items-center justify-content-between">
              <img
                src={minusSvg}
                alt="удалить один товар"
                title="удалить один товар"
                onClick={() => handleDecreaseConstructorQty(index)}
              />
              <span>
                <p className="mb-0">
                  {Math.round(el.qty / 4)} уп.
                </p>
                <strong>{el.qty} шт.</strong>
              </span>
              <img
                src={plusSvg}
                alt="добавить один товар"
                title="добавить один товар"
                onClick={() => handleIncreaseConstructorQty(index)}
              />
            </div>
            <div className="shopping-cart__items_price d-flex align-items-center justify-content-between flex-column flex-md-row">
              <div className="shopping-cart__items_price_width">
                <p className="mb-0 mx-0 mx-md-1 mx-lg-4 d-flex  gap-3 gap-md-0 justify-content-between">
                  <span>цена за 1 шт.:</span>
                  {selectedCurrency === "KGS" ? (
                    <strong>{el.price} KGS</strong>
                  ) : (
                    <CurrencyConverter total={el.price} />
                  )}
                </p>
                <p className="mb-0 mx-0 mx-md-1 mx-lg-4 d-flex  gap-3 gap-md-0 justify-content-between">
                  <span>цена за общее кол-во.:</span>
                  {selectedCurrency === "KGS" ? (
                    <strong>{el.price * el.qty} KGS</strong>
                  ) : (
                    <CurrencyConverter total={el.price * el.qty} />
                  )}
                </p>
              </div>

              <img
                src={trashSvg}
                alt="удалить товар"
                title="удалить товар"
                onClick={() => handleRemoveItem(el)}
              />
            </div>
          </div>
        </section>
      ))}
    </>
  );
};
