import React from "react";
import ChakraUiSpinner from "../../../common/loader/ChakraUiSpinner";
import LoadingError from "../../../common/errorMessage/LoadingError";

const ProductionLoadersErrors = (props) => {
  const {
    loadingProduction,
    loadingUpdate,
    loadingProductionCatalog,
    errorProductionCatalog,
    updateError,
    errorProduction,
    loading,
    error,
    errorDelete,
    deleteLoading,
  } = props;
  return (
    <div className="mt-3 ">
      {loadingProduction && <ChakraUiSpinner />}
      {loadingUpdate || (deleteLoading && <ChakraUiSpinner />)}
      {loadingProductionCatalog && <ChakraUiSpinner />}
      {errorProductionCatalog && (
        <LoadingError variant="alert-danger">
          {errorProductionCatalog}
        </LoadingError>
      )}
      {updateError && (
        <LoadingError variant="alert-danger">{updateError}</LoadingError>
      )}
      {errorProduction ||
        (errorDelete && (
          <LoadingError variant="alert-danger">
            {errorProduction || errorDelete}
          </LoadingError>
        ))}
      {loading ? (
        <ChakraUiSpinner />
      ) : error ? (
        <LoadingError variant="alert-danger">{error}</LoadingError>
      ) : (
        <button className="profile__production__add__submit__btn" type="submit">
          Опубликовать
        </button>
      )}
    </div>
  );
};

export default ProductionLoadersErrors;
