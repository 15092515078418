import React from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";
import PaymentTimelineElement from "../components/paymentPage/PaymentTimelineElement/PaymentTimelineElement";
import {
  sberPaymentInstructions,
  tinkoffPaymentInstructions,
} from "../constants/paymentsInstructionsData";
import "react-vertical-timeline-component/style.min.css";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { Breadcrumbs } from "../components/common/breadCrumbs/Breadcrumbs";

const PaymentPage = () => {
  return (
    <section className="container my-3">
      <nav className="mb-5">
        <h2>Оплата</h2>
        <Breadcrumbs />
      </nav>

      <Tabs isFitted variant="enclosed">
        <TabList mb="1em">
          <Tab>Тинькофф</Tab>
          <Tab>Сбербанк</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VerticalTimeline>
              {tinkoffPaymentInstructions.map((instruction) => (
                <PaymentTimelineElement
                  key={instruction.title}
                  {...instruction}
                  instruction={instruction}
                />
              ))}
            </VerticalTimeline>
          </TabPanel>
          <TabPanel>
            <VerticalTimeline>
              {sberPaymentInstructions.map((instruction) => (
                <PaymentTimelineElement
                  key={instruction.title}
                  {...instruction}
                  instruction={instruction}
                />
              ))}
            </VerticalTimeline>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </section>
  );
};

export default PaymentPage;
