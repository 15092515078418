import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const crumbLabels = {
  catalogs: "Товары",
  page: "Страница",
  designWorks: "Дизайн отдел",
  production: "Производство",
  fulfillment: "Фулфилмент",
  applications: "Заявки",
  about: "О нас",
  contact: "Контакты",
  constructor: "Конструктор",
  favorite: <Link to="/favorite">Избранное</Link>,
  profile: <Link to="/profile">Профиль</Link>,
  cart: "Корзина",
  delivery: "Доставка",
  cooperation: "Условия сотрудничества",
  faq: "Часто задаваемые вопросы",
  payment: "Оплата",
  "new-collection": <HashLink to="/#newCollection">Новинки</HashLink>,
  "privacy-policy": "Политика конфиденциальности",
  "public-offer": "Публичная оферта",
  // fabrics: "Ткани",
};

export const productInputData = [
  {
    id: 1,
    value: "title",
    placeholder: "Введите название",
    type: "text",
    label: "Название товара",
    required: true,
  },
  {
    id: 2,
    value: "brand",
    placeholder: "Введите бренд вашего товара",
    type: "text",
    label: "Бренд товара",
    required: false,
  },
  {
    id: 3,
    value: "articul",
    placeholder: "Введите артикул",
    type: "text",
    label: "Артикул",
    required: false,
    style: { display: "none" },
  },
  {
    id: 4,
    value: "newItem",
    placeholder: "Введите NEW",
    type: "text",
    label: "Новинка",
    required: false,
    style: { display: "none" },
  },
  {
    id: 5,
    value: "price",
    placeholder: "Введите оптовую цену",
    type: "number",
    label: "Оптовая цена",
    required: true,
  },
  {
    id: 6,
    value: "countInStock",
    placeholder: "Введите количество товара на складе",
    type: "number",
    label: "Количество товара на складе",
    required: true,
  },
  {
    id: 7,
    value: "qtyOfGoodsInPack",
    placeholder: "Введите количество товара в одной упаковке",
    type: "number",
    label: "Количество товара в одной упаковке",
    required: true,
  },
  {
    id: 8,
    value: "madeIn",
    placeholder: "Введите страну",
    type: "text",
    label: "Страна производства",
    required: true,
  },
  {
    id: 9,
    value: "textile",
    placeholder: "Введите ткань",
    type: "text",
    label: "Ткань",
    required: true,
  },
  {
    id: 10,
    value: "orderFrom",
    placeholder: "Введите товар",
    type: "text",
    label: "Заказ из (catalog)",
    required: false,
    style: { display: "none" },
  },
];

export const videoExtensions = [
  ".mp4",
  ".webm",
  ".ogg",
  ".avi",
  ".mkv",
  ".mov",
  ".flv",
];

export const routeTitles = {
  "/": "Главная",
  "/catalogs": "Товары",
  "/constructor": "Конструктор",
  "/about": "О нас",
  "/designWorks": "Дизайн отдел",
  "/contact": "Контакты",
  "/cart": "Корзина",
  "/production": "Производство",
  "/cooperation": "Условия сотрудничества",
  "/fulfillment": "Фулфилмент",
  "/applications": "Заявки",
  "/delivery": "Доставка",
  // "/fabrics": "Ткани",
};

export const aboutRightCards = [
  {
    id: 1,
    name: "Производство",
    link: "production",
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1700729272/bg%20images/azazefir_an_image_of_modern_clothing_production_68d504de-3cd0-47ce-b895-e76096023c1f_lkpfrf.png",
  },
  {
    id: 2,
    name: "Фулфилмент",
    link: "fulfillment",
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1700729591/bg%20images/azazefir_an_image_that_describes_wholesale_ecommerce_wear_cloth_ccb7bbc1-d7d2-4933-beb0-1a1d25c32aff_vobuhf.png",
  },
];
