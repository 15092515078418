import React from "react";
import { lazy } from "react";

// Layouts
import { RootLayout } from "./RootLayout";
import { ConstructorLayout } from "./ConstructorLayout";
import { CatalogLayout } from "./CatalogLayout";
import { DesignLayout } from "./DesignLayout";
import { ShoppingCartLayout } from "./ShoppingCartLayout";
import { ProductionLayout } from "./ProductionLayout";
import PrivateRoutes from "./PrivateRoutes";

// Pages
import { HomePage } from "../pages/HomePage";
import Catalog from "../pages/Catalog";
import DesignDepPage from "../pages/DesignDepPage";
import AboutUsPage from "../pages/AboutUsPage";
import ContuctPage from "../pages/ContuctPage";
import ShoppingCart from "../pages/ShoppingCart";
import ProductionPage from "../pages/ProductionPage";
import ConstructorPage from "../pages/ConstructorPage";
import ApplicationsPage from "../pages/ApplicationsPage";
import ProfilePage from "../pages/ProfilePage";
import CatalogItemPage from "../pages/CatalogItemPage";
import DeliveryPage from "../pages/DeliveryPage";
import FabricPage from "../pages/FabricPage";
import ProductionInfoPage from "../pages/ProductionInfoPage";
import PaymentPage from "../pages/PaymentPage";

// Lazy loaded pages
const Cooperation = lazy(() => import("../pages/Cooperation"));
const FulfillmentPage = lazy(() => import("../pages/FulfillmentPage"));
const FavoriteItemsPage = lazy(() => import("../pages/FavoriteItemsPage"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const FaqPage = lazy(() => import("../pages/FaqPage"));
const PublicOffer = lazy(() => import("../pages/PublicOffer"));
const NotFound = lazy(() => import("../pages/NotFound"));
const Login = lazy(() => import("../pages/auth/login/Login"));
const Register = lazy(() => import("../pages/auth/register/Register"));
const OrderedPage = lazy(() => import("../pages/OrderedPage"));
const OrderedList = lazy(() =>
  import("../components/profilePage/orderedList/OrderedList")
);
const Recovery = lazy(() => import("../pages/auth/recovery/Recovery"));
const Reset = lazy(() => import("../pages/auth/reset/Reset"));
const EmailVerify = lazy(() =>
  import("../pages/auth/EmailVerify/EmailVerify")
);
const PaymentMethodPage = lazy(() => import("../pages/PaymentMethodPage"));

// Define all routes
const routes = [
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "constructor",
        element: <ConstructorLayout />,
        children: [{ index: true, element: <ConstructorPage /> }],
      },
      {
        path: "catalogs",
        element: <CatalogLayout />,
        children: [
          { index: true, element: <Catalog /> },
          { path: ":id", element: <CatalogItemPage /> },
          { path: "new-collection/:id", element: <CatalogItemPage /> },
          { path: "favorite/:id", element: <CatalogItemPage /> },
          { path: "profile/:id", element: <CatalogItemPage /> },
          { path: "search/:keyword", element: <Catalog /> },
          { path: "page/:pageNumber", element: <Catalog /> },
          { path: "page/:pageNumber/:id", element: <CatalogItemPage /> },
          { path: "search/:keyword/page/:pageNumber", element: <Catalog /> },
        ],
      },
      { path: "about", element: <AboutUsPage /> },
      {
        path: "designworks",
        element: <DesignLayout />,
        children: [{ index: true, element: <DesignDepPage /> }],
      },
      { path: "contact", element: <ContuctPage /> },
      {
        path: "cart",
        element: <ShoppingCartLayout />,
        children: [
          { index: true, element: <ShoppingCart /> },
          { path: ":id", element: <ShoppingCart /> },
        ],
      },
      {
        path: "",
        element: <PrivateRoutes />,
        children: [
          { path: "profile", element: <ProfilePage /> },
          { path: "orders/:id", element: <OrderedPage /> },
          { path: "orderList", element: <OrderedList /> },
          { path: "payment-method-page/:id", element: <PaymentMethodPage /> },
        ],
      },
      {
        path: "production",
        element: <ProductionLayout />,
        children: [
          { index: true, element: <ProductionPage /> },
          { path: ":id", element: <ProductionInfoPage /> },
        ],
      },
      { path: "cooperation", element: <Cooperation /> },
      { path: "payment", element: <PaymentPage /> },
      { path: "delivery", element: <DeliveryPage /> },
      { path: "applications", element: <ApplicationsPage /> },
      { path: "fulfillment", element: <FulfillmentPage /> },
      { path: "login", element: <Login /> },
      { path: "register", element: <Register /> },
      { path: "recovery", element: <Recovery /> },
      { path: "verify", element: <EmailVerify /> },
      { path: "reset", element: <Reset /> },
      { path: "favorite", element: <FavoriteItemsPage /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "public-offer", element: <PublicOffer /> },
      { path: "faq", element: <FaqPage /> },
      { path: "fabrics", element: <FabricPage /> },
      { path: "*", element: <NotFound /> },
    ],
  },
];

export default routes;
