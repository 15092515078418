import {
  GiHeartOrgan,
  GiClothes,
  GiLipstick,
  GiMedicines,
} from "react-icons/gi";

import { MdChildCare, MdKitchen, MdOutlineSportsTennis } from "react-icons/md";
import { IoFastFoodOutline, IoGiftSharp } from "react-icons/io5";
import { FaLaptopHouse } from "react-icons/fa";
import { PiBooks, PiPaintBrushHousehold } from "react-icons/pi";
import { LuSofa } from "react-icons/lu";

export const applicationCategories = [
  { icon: <GiClothes />, title: "Одежда" },
  { icon: <MdChildCare />, title: "Товары для детей" },
  { icon: <IoGiftSharp />, title: "Подарки, сувениры" },
  { icon: <MdKitchen />, title: "Бытовая техника" },
  { icon: <FaLaptopHouse />, title: "Товары для дома" },
  { icon: <PiPaintBrushHousehold />, title: "Строительство, ремонт" },
  { icon: <GiMedicines />, title: "Медицина" },
  { icon: <PiBooks />, title: "Книги, канцелярия" },
  { icon: <MdOutlineSportsTennis />, title: "Спорт, охота, туризм" },
  { icon: <LuSofa />, title: "Мебель" },
  { icon: <GiLipstick />, title: "Косметика" },
  { icon: <IoFastFoodOutline />, title: "Продукты питания, напитки" },
  { icon: <GiHeartOrgan />, title: "Здоровье и красота" },
];


export const applicationInputFields = [
  {
    key: "quantity",
    label: "Объем покупки",
    type: "text",
    placeholder: "Введите объем покупки",
    required: true,
  },
  {
    key: "productRequirements",
    label: "Требования к продукту",
    type: "text",
    placeholder: "Введите требования к продукту",
    required: true,
  },
  {
    key: "location",
    label: "Место реализации товара",
    type: "text",
    placeholder: "Введите место реализации товара",
    required: true,
  },
  {
    key: "city",
    label: "Город",
    type: "text",
    placeholder: "Введите город",
    required: true,
  },
  {
    key: "category",
    label: "Выберите категорию",
    type: "select",
    selectOptions: applicationCategories.map((category) => ({
      value: category.title,
      label: category.title,
    })),
    required: true,
  },
];