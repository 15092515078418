import {
  PROFILE_UPLOAD_AVATAR_FAIL,
  PROFILE_UPLOAD_AVATAR_REQUEST,
  PROFILE_UPLOAD_AVATAR_SUCCESS,
  PROFILE_UPLOAD_CATALOG_IMAGES_REQUEST,
  PROFILE_UPLOAD_CATALOG_IMAGES_SUCCESS,
  PROFILE_UPLOAD_CATALOG_IMAGE_FAIL,
  PROFILE_UPLOAD_CATALOG_IMAGE_REQUEST,
  PROFILE_UPLOAD_CATALOG_IMAGE_SUCCESS,
} from "../constants/ProfileConstants";
import { clearStorage } from "./UserAction";
import { PROFILE_UPLOAD_CATALOG_IMAGES_FAIL } from "./../constants/ProfileConstants";
import { $apiPrivate } from "../../api/axios";

export const uploadProfileAvatarAction =
  ({ file }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PROFILE_UPLOAD_AVATAR_REQUEST,
      });

      const { data } = await $apiPrivate.post(`/users/upload-photo`, { file });

      dispatch({ type: PROFILE_UPLOAD_AVATAR_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (error.response.status === 401) {
        dispatch(clearStorage());
      }
      dispatch({
        type: PROFILE_UPLOAD_AVATAR_FAIL,
        payload: message,
      });
    }
  };

export const uploadCatalogImagesAction =
  ({ images, id }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PROFILE_UPLOAD_CATALOG_IMAGES_REQUEST,
        id,
      });

      const { data } = await $apiPrivate.post(
        `/production/upload-catalog-photo`,
        { images, id }
      );

      dispatch({
        type: PROFILE_UPLOAD_CATALOG_IMAGES_SUCCESS,
        payload: data,
        id,
      });
      return { success: true, responseArr: data.responseArr };
    } catch (error) {
      let errorMessage = "";

      if (error.response) {
        if (
          error.response.status === 413 ||
          error.response.data.message === "request entity too large"
        ) {
          errorMessage =
            "Превышен общий размер файлов - 10мб. Загрузите по одному файлу.";
        } else {
          errorMessage =
            error.response.data.message ||
            "Произошла ошибка при загрузке фотографии.";
        }
      } else {
        errorMessage =
          error.message || "Произошла ошибка при загрузке фотографии.";
      }

      if (error.response.status === 401) {
        dispatch(clearStorage());
      }

      dispatch({
        type: PROFILE_UPLOAD_CATALOG_IMAGES_FAIL,
        payload: errorMessage,
        id,
      });
      return { success: false, responseArr: [] };
    }
  };

export const uploadCatalogImageAction = (image, id) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_UPLOAD_CATALOG_IMAGE_REQUEST,
      id,
    });

    const { data } = await $apiPrivate.post(
      `/production/upload-catalog-main-photo`,
      { image, id }
    );

    dispatch({
      type: PROFILE_UPLOAD_CATALOG_IMAGE_SUCCESS,
      payload: data,
      id,
    });
    return { success: true, response: data.response };
  } catch (error) {
    let errorMessage = "";

    if (error.response) {
      if (
        error.response.status === 413 ||
        error.response.data.message === "request entity too large"
      ) {
        errorMessage = "Превышен размер файла - 10мб.";
      } else {
        errorMessage =
          error.response.data.message ||
          "Произошла ошибка при загрузке фотографии.";
      }
    } else {
      errorMessage =
        error.message || "Произошла ошибка при загрузке фотографии.";
    }

    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: PROFILE_UPLOAD_CATALOG_IMAGE_FAIL,
      payload: errorMessage,
      id,
    });
    return { success: false, response: [] };
  }
};
