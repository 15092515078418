import { DELETE_CHAT_FAIL, DELETE_CHAT_REQUEST, DELETE_CHAT_SUCCESS } from "../constants/ChatConsts";

const initialState = {
    deleting: false,
    deletedChatId: null,
    error: null,
  };
  
  export const chatDeleteReducer = (state = initialState, action) => {
    switch (action.type) {
      case DELETE_CHAT_REQUEST:
        return {
          ...state,
          deleting: true,
          error: null,
        };
      case DELETE_CHAT_SUCCESS:
        return {
          ...state,
          deleting: false,
          deletedChatId: action.payload,
          error: null,
        };
      case DELETE_CHAT_FAIL:
        return {
          ...state,
          deleting: false,
          deletedChatId: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };