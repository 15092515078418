import React from "react";
import { ContuctUs } from "../../../common/callUs/ContuctUs";
import LoadingError from "../../../common/errorMessage/LoadingError";
import { Loader } from "../../../common/loader/Loader";
import { CatalogItemInfo } from "./catalogItemInfo/CatalogItemInfo";
import { ReviewSection } from "./reviewSection/ReviewSection";
import { SliderSection } from "../sliderSection/SliderSection";

import CatalogBrandSection from "./catalogBrandsSection/CatalogBrandSection";
import { Seo } from "../../../../seo/Seo";
import { useSelector } from "react-redux";
import { selectBrandCatalogOpen } from "../../../../redux/slices/SingleCatalogItemSlice";
import { IoArrowBackSharp } from "react-icons/io5";
import { Breadcrumbs } from "../../../common/breadCrumbs/Breadcrumbs";
import { useNavigate } from "react-router-dom";

export const CatalogItemPageContent = (props) => {
  const { item, loading, error } = props;
  const navigate = useNavigate();

  const isBrandCatalogOpen = useSelector(selectBrandCatalogOpen);

  const handleGoBackBtn = () => {
    navigate(-1);
  };
  return (
    <React.Fragment>
      <Seo
        title={item.title}
        description={item.description}
        name={item.category}
        type="product"
        keywords="Maneken, Манекен, Одежда оптом, Одежда, Производство одежды, Производство, Женская одежда, Женская, Мужская одежда, Детская одежда, Ткани, Фулфилмент, Fulfilment, Конструктор одежды, Бренды, В наличии"
      />
      {item &&
        (loading ? (
          <Loader />
        ) : error ? (
          <LoadingError variant="alert-danger">{error}</LoadingError>
        ) : (
          <>
            <section className="single-item-wrapper container">
              <div className="d-flex align-items-center gap-2 mt-4">
                <button
                  style={{ marginBottom: "1rem" }}
                  onClick={handleGoBackBtn}
                >
                  <IoArrowBackSharp size="18px" color="#a0a2a9" />
                </button>
                <Breadcrumbs className="mb-0" />
              </div>
              <div className="single-item-wrapper_wr">
                <div className="row flex-column flex-lg-row">
                  <SliderSection item={item} />
                  <CatalogItemInfo item={item} />
                </div>
              </div>
              {isBrandCatalogOpen ? <CatalogBrandSection item={item} /> : null}
              <ReviewSection item={item} />
            </section>
            <ContuctUs />
          </>
        ))}
    </React.Fragment>
  );
};
