import {
  FETCH_CDEK_SETTLEMENTS_FAILURE,
  FETCH_CDEK_SETTLEMENTS_REQUEST,
  FETCH_CDEK_SETTLEMENTS_SUCCESS,
  FETCH_PICKUP_POINTS_FAILURE,
  FETCH_PICKUP_POINTS_REQUEST,
  FETCH_PICKUP_POINTS_SUCCESS,
  ORDER_CDEK_CALCULATE_TARIFF_FAIL,
  ORDER_CDEK_CALCULATE_TARIFF_REQUEST,
  ORDER_CDEK_CALCULATE_TARIFF_RESET,
  ORDER_CDEK_CALCULATE_TARIFF_SUCCESS,
  ORDER_CDEK_CREATE_FAIL,
  ORDER_CDEK_CREATE_REQUEST,
  ORDER_CDEK_CREATE_RESET,
  ORDER_CDEK_CREATE_SUCCESS,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_RESET,
  ORDER_LIST_MY_SUCCESS,
  ORDER_UPDATE_FAIL,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
} from "./../constants/OrderConstants";
import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_RESET,
  ORDER_CREATE_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
} from "../constants/OrderConstants";

// ORDER CREATE
export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return { loading: true };
    case ORDER_CREATE_SUCCESS:
      return { loading: false, success: true, order: action.payload };
    case ORDER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// ORDER DETAIlS
export const orderDetailsReducer = (
  state = { loading: true, orderItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ORDER_DETAILS_SUCCESS:
      return { loading: false, order: action.payload };
    case ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// USER ORDERS
export const orderListMyReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_MY_REQUEST:
      return { loading: true };
    case ORDER_LIST_MY_SUCCESS:
      return { loading: false, orders: action.payload };
    case ORDER_LIST_MY_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_LIST_MY_RESET:
      return { orders: [] };
    default:
      return state;
  }
};

// Reducer для обновления поля paymentMethod заказа
export const orderUpdatePaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_UPDATE_REQUEST:
      return { loading: true };
    case ORDER_UPDATE_SUCCESS:
      return { loading: false, success: true, order: action.payload };
    case ORDER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// ORDER CREATE CDEK DELIVERY
export const orderCreateCdekDeliveryReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CDEK_CREATE_REQUEST:
      return { loading: true };
    case ORDER_CDEK_CREATE_SUCCESS:
      return { loading: false, success: true, orders: action.payload };
    case ORDER_CDEK_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_CDEK_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

//GET CDEK DELIVERY POINTS
const initialState = {
  pickupPoints: [],
  loading: false,
  error: null,
};
export const pickupPointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PICKUP_POINTS_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_PICKUP_POINTS_SUCCESS:
      return {
        ...state,
        pickupPoints: action.payload,
        loading: false,
        error: null,
      };

    case FETCH_PICKUP_POINTS_FAILURE:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
};

//GET CDEK DELIVERY SETTLEMENTS
const settlemntInitialState = {
  settlementsList: [],
  loading: false,
  error: null,
};
export const cdekSettlementReducer = (
  state = settlemntInitialState,
  action
) => {
  switch (action.type) {
    case FETCH_CDEK_SETTLEMENTS_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_CDEK_SETTLEMENTS_SUCCESS:
      return {
        ...state,
        settlementsList: action.payload,
        loading: false,
        error: null,
      };

    case FETCH_CDEK_SETTLEMENTS_FAILURE:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
};

// ORDER CREATE CDEK DELIVERY
export const orderCreateCdekCalculateTariffReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CDEK_CALCULATE_TARIFF_REQUEST:
      return { loading: true };
    case ORDER_CDEK_CALCULATE_TARIFF_SUCCESS:
      return { loading: false, success: true, tariffs: action.payload };
    case ORDER_CDEK_CALCULATE_TARIFF_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_CDEK_CALCULATE_TARIFF_RESET:
      return {};
    default:
      return state;
  }
};
