import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../components/common/footer/Footer";
import { Header } from "../components/common/header/Header";
import ScrollToTop from "./../hoc/ScrollToTop";
import { useCookies } from "react-cookie";
import CookieConsent from "../components/cookieConsent/CookieConsent";
import { Loader } from "../components/common/loader/Loader";
import { useSelector } from "react-redux";
import useRefreshAccessToken from "../hooks/useRefreshToken";
import useMessageHandler from "../hooks/useSocketMessageHandler";
import useYandexMetrika from "../hooks/useYandexMetrika";
import useSocketConnection from "../hooks/useSocketConnection";
import useLocalStorageLogout from "../hooks/useLocalStorageLogout";

export const RootLayout = () => {
  const [isUserLogged, setIsUserLogged] = useState(false);

  const [favorite, setFavorite] = useState(() => {
    const storedFavorite = localStorage.getItem("favorites");
    return storedFavorite ? JSON.parse(storedFavorite) : [];
  });
  const user = useSelector((state) => state.userLogin.userInfo);

  const [cookies] = useCookies(["cookieConsent"]);

  useRefreshAccessToken(user, setIsUserLogged);
  useMessageHandler();
  useYandexMetrika();
  useSocketConnection(user);
  useLocalStorageLogout();

  useEffect(() => {
    localStorage.setItem("favorites", JSON.stringify(favorite));
  }, [favorite]);

  return (
    <ScrollToTop>
      {!isUserLogged ? (
        <Loader />
      ) : (
        <div className="App">
          <Header context={[favorite]} />
          <Outlet context={[favorite, setFavorite]} />
          <Footer />
          {!cookies.cookieConsent && <CookieConsent />}
        </div>
      )}
    </ScrollToTop>
  );
};
