import React from "react";

import { sortItems } from "../../../../constants/catalogCategoriesSortFilterData";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  selectActiveSort,
  setActiveAgeGroup,
  setActiveSort,
  setSelectedCategory,
} from "../../../../redux/slices/CatalogSlice";
import { useDispatch, useSelector } from "react-redux";
import FilterPopover from "../../../common/catalogFilterPopover/FilterPopover";

const FilterBtn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const activeSort = useSelector(selectActiveSort);
  const searchParams = queryString.parse(location.search);

  const onSelectSortType = (index) => {
    dispatch(setActiveSort(index));
    const newSearchParams = { ...searchParams, sortBy: activeSort.type };
    const newSearch = queryString.stringify(newSearchParams);
    navigate(`?${newSearch}`);
    dispatch(setSelectedCategory(""));
    dispatch(setActiveAgeGroup(""));
  };

  return (
    <FilterPopover
      items={sortItems}
      onApply={onSelectSortType}
      activeType={activeSort}
    />
  );
};

export default FilterBtn;
