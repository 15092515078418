import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editProfileCatalogItemAction,
  updateProfileCatalogItemAction,
} from "../../../../redux/Actions/CatalogActions";
import { useToast } from "@chakra-ui/react";
import { PROFILE_CATALOG_UPDATE_RESET } from "../../../../redux/constants/CatalogConstants";
import { productInputData } from "../../../../constants";
import ProductDescription from "../../profileAddProductToCatalog/productDescription/ProductDescription";
import ProductMainImage from "../../profileAddProductToCatalog/productMainImage/ProductMainImage";
import ProductImages from "../../profileAddProductToCatalog/productImages/ProductImages";
import ProductColors from "../../profileAddProductToCatalog/productColors/ProductColors";
import ProductSizes from "../../profileAddProductToCatalog/productSizes/ProductSizes";
import ProductOwner from "../../profileAddProductToCatalog/productOwner/ProductOwner";
import ProductAddHeader from "../../profileAddProductToCatalog/productAddHeader/ProductAddHeader";
import ProductInput from "../../profileAddProductToCatalog/productInput/ProductInput";
import LoadingError from "../../../common/errorMessage/LoadingError";
import { useProductState } from "../../../../hooks/useProductState";
import ProductGenderChoose from "../../profileAddProductToCatalog/productGenderChoose/ProductGenderChoose";
import ChakraUiSpinner from "../../../common/loader/ChakraUiSpinner";
import ProfileCatalogItemsGabarits from "../../profileCatalogItemsGabarits/ProfileCatalogItemsGabarits";
import ProductSampleBuy from "../../profileAddProductToCatalog/productSampleBuy/ProductSampleBuy";
import ProductSalesTypeChoose from "../../profileAddProductToCatalog/productSalesTypeChoose/ProductSalesTypeChoose";

const ProfileEditProduct = ({ productId }) => {
  const { initialState } = useProductState();
  const [state, setState] = useState(initialState);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const [owner, setOwner] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [gender, setGender] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [category, setCategory] = useState("");
  const [productWeight, setProductWeight] = useState("");
  const [isSample, setIsSample] = useState(false);
  const [samplePrice, setSamplePrice] = useState(0);
  const [isWholesale, setIsWholesale] = useState(null);

  const dispatch = useDispatch();
  const toast = useToast();

  const profileItemEdit = useSelector((state) => state.profileItemEdit);
  const { loading, error, product } = profileItemEdit;

  const profileCatalogUpdate = useSelector(
    (state) => state.profileCatalogUpdate
  );
  const {
    loading: loadingUpdate,
    error: updateError,
    success: successUpdate,
  } = profileCatalogUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PROFILE_CATALOG_UPDATE_RESET });
      toast({
        title: "Товар успешно обновлен!",
        status: "success",
        isClosable: true,
        position: "top-right",
        duration: 1000,
      });
    } else {
      if (!product || product._id !== productId) {
        dispatch(editProfileCatalogItemAction(productId));
      } else {
        setState({
          title: product.title,
          articul: product.articul,
          newItem: product.newItem,
          price: product.price,
          oldPrice: product.oldPrice,
          countInStock: product.countInStock,
          textile: product.textile,
          madeIn: product.madeIn,
          orderFrom: product.orderFrom,
          brand: product.brand,
          qtyOfGoodsInPack: product.qtyOfGoodsInPack,
        });
        setDescription(product.description);
        setImage(product.image);
        setImages(product.images);
        setOwner(product.owner);
        setColors(product.colors);
        setSizes(product.sizes);
        setGender(product.gender);
        setAgeGroup(product.ageGroup);
        setCategory(product.category);
        setProductWeight(product.weight);
        setIsSample(product.hasSample);
        setSamplePrice(product.samplePrice);
        setIsWholesale(product.isWholesale);
      }
    }
  }, [dispatch, product, productId, successUpdate, toast, initialState.value]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProfileCatalogItemAction({
        _id: productId,
        title: state.title,
        price: state.price,
        description,
        image,
        countInStock: state.countInStock,
        textile: state.textile,
        madeIn: state.madeIn,
        gender,
        ageGroup,
        oldPrice: state.oldPrice,
        category,
        orderFrom: state.orderFrom,
        images,
        owner,
        articul: state.articul,
        colors,
        sizes,
        newItem: state.newItem,
        brand: state.brand,
        weight: productWeight,
        url: "www.maneken.shop",
        hasSample: isWholesale ? isSample : 0,
        samplePrice: isWholesale ? samplePrice : 0,
        qtyOfGoodsInPack: isWholesale ? state.qtyOfGoodsInPack : 0,
        isWholesale,
      })
    );
  };
  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const sizesString = sizes?.map((item) => item.size).join(", ");

  return (
    <div className="profile-edit__container">
      <div
        className="profile-edit__product position-relative"
        id="style__scroll__profile"
      >
        <section className="content-main" style={{ maxWidth: "1200px" }}>
          <form onSubmit={submitHandler}>
            <div className="row mb-4">
              <div className="col">
                <div
                  className="card"
                  style={{ border: "none", borderRadius: "0" }}
                >
                  <div className="card-body">
                    {updateError && (
                      <LoadingError variant="alert-danger">
                        {updateError}
                      </LoadingError>
                    )}
                    {loadingUpdate && <ChakraUiSpinner />}
                    {error && (
                      <LoadingError variant="alert-danger">
                        {error}
                      </LoadingError>
                    )}
                    {loading && <ChakraUiSpinner />}
                    <ProductSalesTypeChoose
                      isWholesale={isWholesale}
                      setIsWholesale={setIsWholesale}
                      setIsSample={setIsSample}
                    />
                    <ProductGenderChoose
                      gender={gender}
                      setGender={setGender}
                      categoryToEdit={category}
                      setCategory={setCategory}
                      ageGroup={ageGroup}
                      setAgeGroup={setAgeGroup}
                    />
                    <ProductSampleBuy
                      isSample={isSample}
                      isWholesale={isWholesale}
                      setIsSample={setIsSample}
                      setSamplePrice={setSamplePrice}
                    />
                    {isSample && (
                      <ProductInput
                        value={samplePrice}
                        placeholder="Введите розничную цену"
                        type="number"
                        setState={setSamplePrice}
                        label="Розничная цена"
                        required={isSample}
                        productValue="samplePrice"
                      />
                    )}
                    {productInputData.map((el) => (
                      <ProductInput
                        key={el.id}
                        value={state[el.value]}
                        placeholder={
                          el.value === "price" && !isWholesale
                            ? "Введите розничную цену"
                            : el.placeholder
                        }
                        type={el.type}
                        label={
                          el.value === "price" && !isWholesale
                            ? "Розничная цена"
                            : el.label
                        }
                        setState={(value) => updateState(el.value, value)}
                        required={el.required}
                        productValue={el.value}
                        style={
                          el.value === "qtyOfGoodsInPack" && !isWholesale
                            ? { display: "none" }
                            : el.style
                        }
                      />
                    ))}

                    <ProductSizes
                      sizes={sizes}
                      setSizes={setSizes}
                      sizesString={sizesString}
                      text="Размер товара: (укажите через запятую)"
                    />

                    <ProfileCatalogItemsGabarits
                      productWeight={productWeight}
                      setProductWeight={setProductWeight}
                    />

                    <ProductDescription
                      setDescription={setDescription}
                      description={description}
                    />
                    <ProductMainImage
                      image={image}
                      setImage={setImage}
                      text="Основная картинка товара:"
                    />
                    <ProductImages
                      images={images}
                      setImages={setImages}
                      setColors={setColors}
                      fieldType="ProductImages"
                      text={"Картинки и видео товара:"}
                    />
                    <ProductColors colors={colors} setColors={setColors} />
                    <ProductOwner
                      owner={owner}
                      setOwner={setOwner}
                      ownerName="Владелец товара"
                      text="Введите имя владельца"
                    />
                  </div>
                </div>
              </div>
            </div>
            <ProductAddHeader />
          </form>
        </section>
      </div>
    </div>
  );
};

export default ProfileEditProduct;
