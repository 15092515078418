import { CatalogCard } from "./catalogCard/CatalogCard";
import { useSelector, useDispatch } from "react-redux";
import LoadingError from "../../common/errorMessage/LoadingError";
import { catalogAction } from "../../../redux/Actions/CatalogActions";
import { memo, useEffect, useState } from "react";
import { CatalogPagination } from "../catalogPagination/CatalogPagination";
import { useOutletContext } from "react-router-dom";
import searchSvg from "../../../assets/images/catalogImg/SearchSvg.svg";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import { Skeleton, Tooltip } from "@chakra-ui/react";

import "./CatalogCards.scss";
import SortByRatingPrice from "../catalogSort/SortByRatingPrice";
import CatalogFilter from "./catalogFilter/CatalogFilter";
import FilterBtn from "./filterBtn/FilterBtn";
import {
  selectActiveAgeGroup,
  selectActiveSort,
  selectCatalogCategory,
  selectFilterType,
  selectKeyword,
} from "../../../redux/slices/CatalogSlice";
import FilterByType from "./filterByType/FilterByType";

const CatalogCards = ({ pageNumber }) => {
  const dispatch = useDispatch();

  const [favorite, setFavorite] = useOutletContext();

  const [viewMode, setViewMode] = useState("grid");
  const [activeSortRatingPrice, setActiveSortRatingPrice] = useState("");

  const keyword = useSelector(selectKeyword);
  const selectedCategory = useSelector(selectCatalogCategory);
  const activeAgeGroup = useSelector(selectActiveAgeGroup);
  const activeSort = useSelector(selectActiveSort);
  const activeFilterType = useSelector(selectFilterType);
  const catalogList = useSelector((state) => state.catalogList);
  const {
    catalogs,
    loading,
    error,
    page,
    pages,
    hasPreviousPage,
    hasNextPage,
  } = catalogList;

  useEffect(() => {
    const fetchParams = {
      keyword: keyword || "",
      pageNumber: pageNumber || 1,
      category: selectedCategory || "",
      sortBy: activeSort.type || "",
      ageGroup: activeAgeGroup || "",
      sortRatingPrice: activeSortRatingPrice.type || "",
      filterByType: activeFilterType.type || "",
    };

    dispatch(catalogAction(fetchParams));
  }, [
    dispatch,
    keyword,
    pageNumber,
    selectedCategory,
    activeSort.type,
    activeAgeGroup,
    activeSortRatingPrice.type,
    activeFilterType.type,
  ]);

  const handleGridClick = () => {
    setViewMode("grid");
  };

  const handleColumnClick = () => {
    setViewMode("column");
  };

  return (
    <div className="catalogs-cards-wrapper px-0 container d-flex flex-column gap-2 align-items-center">
      <div className="d-flex justify-content-between align-items-center w-100 my-3 gap-2">
        <div className="d-flex justify-content-between align-items-center gap-2">
          <FilterByType />
          <FilterBtn />
          <SortByRatingPrice
            activeSortRatingPrice={activeSortRatingPrice}
            setActiveSortRatingPrice={setActiveSortRatingPrice}
          />
        </div>

        <div className="view-mode-buttons">
          <Tooltip label="Сетка" fontSize="md">
            <button onClick={handleGridClick}>
              <BsFillGrid3X3GapFill style={{ width: "28px", height: "28px" }} />
            </button>
          </Tooltip>
          <Tooltip label="Список" fontSize="md">
            <button onClick={handleColumnClick}>
              <FaList style={{ width: "30px", height: "30px" }} />
            </button>
          </Tooltip>
        </div>
      </div>
      <CatalogFilter />
      <div className={`catalog__cards px-0 ${viewMode}-view`}>
        {loading
          ? [...new Array(25)].map((_, index) => <Skeleton key={index} />)
          : catalogs?.map((item) => (
              <CatalogCard
                key={item._id}
                {...item}
                item={item}
                favorite={favorite}
                setFavorite={setFavorite}
              />
            ))}
      </div>
      {error && <LoadingError variant={alert()}>{error}</LoadingError>}
      {catalogs?.length === 0 && !loading ? (
        <figure
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ width: "250px" }}
        >
          <img src={searchSvg} alt="Search" />
          <figcaption style={{ fontWeight: "700" }}>
            Товар не найден!
          </figcaption>
        </figure>
      ) : null}
      <CatalogPagination
        page={page}
        pages={pages}
        keyword={keyword ? keyword : ""}
        sortBy={activeSort.type}
        category={selectedCategory}
        hasPreviousPage={hasPreviousPage}
        hasNextPage={hasNextPage}
      />
    </div>
  );
};
export default memo(CatalogCards);
