import React from "react";

import CurrentChatMsgs from "./currentChatMsgs/CurrentChatMsgs";
import CurrentChatMsgTheme from "./currentChatMsgTheme/CurrentChatMsgTheme";
import CurrentChatUserInfo from "./currentChatUserInfo/CurrentChatUserInfo";

const CurrentChat = () => {
  return (
    <div className="messages" id="style__scroll__chat">
      <CurrentChatUserInfo />
      <CurrentChatMsgTheme />
      <CurrentChatMsgs />
    </div>
  );
};

export default CurrentChat;
