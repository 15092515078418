import { Search2Icon } from "@chakra-ui/icons";
import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectKeyword,
  setKeyword,
} from "../../../../redux/slices/CatalogSlice";

const HeaderSearch = () => {
  const dispatch = useDispatch() 
  const navigate = useNavigate();
  const keyword = useSelector(selectKeyword);
  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      navigate({
        pathname: "/catalogs",
        search: `?keyword=${keyword}`,
      });
    } else {
      navigate(`/catalogs`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submitHandler(e);
    }
  };

  return (
    <form className="mobile d-flex w-100" onSubmit={submitHandler}>
      <InputGroup width="inherit" borderColor="">
        <Input
          type="text"
          placeholder="Поиск..."
          value={keyword}
          onChange={(e) => dispatch(setKeyword(e.target.value))}
          onKeyDown={handleKeyDown}
          name="Search"
        />
        <InputRightElement>
          <Button type="submit" background="transparent">
            <Search2Icon color="gray.300" />
          </Button>
        </InputRightElement>
      </InputGroup>
    </form>
  );
};

export default HeaderSearch;
