import React, { createContext, useContext, useState } from "react";

const ProfileProductionContext = createContext();

const ProfileProductionProvider = ({ children }) => {
  const [brandName, setBrandName] = useState("");
  const [brandLogo, setBrandLogo] = useState("");
  const [description, setDescription] = useState("");
  const [brandImage, setBrandImage] = useState([]);
  const [productionPhoneNumber, setProductionPhoneNumber] = useState("");
  const [specialization, setSpecialization] = useState([]);
  const [productionCapacity, setProductionCapacity] = useState([]);
  const [quantityOfEquipment, setQuantityOfEquipment] = useState([]);
  const [productionCatalog, setProductionCatalog] = useState([]);
  const [webUrl, setWebUrl] = useState("");
  const [adsCards, setAdsCards] = useState([]);

  const [brandNameError, setBrandNameError] = useState("");
  const [brandLogoError, setBrandLogoError] = useState("");
  const [brandImageError, setBrandImageError] = useState("");
  const [productionPhoneNumberError, setProductionPhoneNumberError] =
    useState("");
  const [specializationError, setSpecializationError] = useState("");
  const [productionCapacityError, setProductionCapacityError] = useState("");
  const [quantityOfEquipmentError, setQuantityOfEquipmentError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  return (
    <ProfileProductionContext.Provider
      value={{
        brandName,
        setBrandName,
        brandLogo,
        setBrandLogo,
        brandImage,
        setBrandImage,
        productionPhoneNumber,
        setProductionPhoneNumber,
        specialization,
        setSpecialization,
        productionCapacity,
        setProductionCapacity,
        quantityOfEquipment,
        setQuantityOfEquipment,
        productionCatalog,
        setProductionCatalog,
        brandNameError,
        setBrandNameError,
        brandLogoError,
        setBrandLogoError,
        brandImageError,
        setBrandImageError,
        productionPhoneNumberError,
        setProductionPhoneNumberError,
        specializationError,
        setSpecializationError,
        productionCapacityError,
        setProductionCapacityError,
        quantityOfEquipmentError,
        setQuantityOfEquipmentError,
        description,
        setDescription,
        descriptionError,
        setDescriptionError,
        webUrl,
        setWebUrl,
        adsCards,
        setAdsCards,
      }}
    >
      {children}
    </ProfileProductionContext.Provider>
  );
};

export const ProfileProductionState = () => {
  return useContext(ProfileProductionContext);
};

export default ProfileProductionProvider;
