import React from "react";

import "./styles/ProfilePage.scss";

import { useSelector } from "react-redux";
import ProfileAvatar from "../components/profilePage/profileAvatar/ProfileAvatar";
import ProfileUserInfo from "../components/profilePage/profileUserInfo/ProfileUserInfo";
import ProfileDetailsBtn from "../components/profilePage/profileDetailsBtn/ProfileDetailsBtn";
import ProfileTabContent from "./../components/profilePage/profileTabContent/ProfileTabContent";
import { Seo } from "../seo/Seo";

const ProfilePage = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { orders } = orderListMy;

  return (
    <div className="profilePage-wrapper container">
      <Seo
        title="Профиль пользователя"
        name="Манекен"
        type="product"
        keywords="Maneken, Манекен, Одежда оптом, производство одежды"
      />
      <div className="row container mx-0 px-0">
        <div className="col col-lg-3 ">
          <div className="profile__details d-flex flex-column align-items-center gap-4 py-4 ">
            <ProfileAvatar userInfo={userInfo} />
            <ProfileUserInfo userInfo={userInfo} />
            <ProfileDetailsBtn
              userInfo={userInfo}
              orders={orders}
            />
          </div>
        </div>
        <ProfileTabContent userInfo={userInfo} />
      </div>
    </div>
  );
};

export default ProfilePage;
