import React from "react";
import { CurrencyConverter } from "../currencyConverter/CurrencyConverter";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { selectCurrency } from "../../../redux/slices/CatalogSlice";
import { useSelector } from "react-redux";

export const CustomTable = ({ data, thead1, thead2, thead3 }) => {
  const selectedCurrency = useSelector(selectCurrency);
  return (
    <TableContainer>
      <Table variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th>{thead1}</Th>
            <Th>{thead2}</Th>
            <Th>{thead3}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((el, index) => (
            <Tr key={index}>
              <Td>{el.title || el.text}</Td>
              <Td>
                {el?.fromPrice || el?.price ? (
                  selectedCurrency === "KGS" ? (
                    <strong>от {el.fromPrice || el.price}</strong>
                  ) : (
                    <CurrencyConverter total={el.fromPrice || el.price} />
                  )
                ) : (
                  <strong>{el.capacity || el.pryamostrochka || el.from}</strong>
                )}
              </Td>
              <Td>
                <strong>{el.specmashinka}</strong>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
