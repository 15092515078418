import React from "react";
import { Avatar, WrapItem } from "@chakra-ui/react";
import { FaUserAlt } from "react-icons/fa";
import { setApplicationInfo } from "../../../../../redux/slices/ChatSlice";
import { useDispatch } from "react-redux";

const AppListsUserAvatar = ({ accessChat, handleUpdateViewCount, message }) => {
  const dispatch = useDispatch();
  return (
    <div
      className="avatar"
      onClick={() => {
        accessChat(message.userId);
        dispatch(setApplicationInfo(message));
        handleUpdateViewCount(message._id);
      }}
    >
      {message ? (
        <WrapItem>
          <Avatar
            size="sm"
            name={message ? message.user : ""}
            src={message ? message.profile : ""}
          />
        </WrapItem>
      ) : (
        <FaUserAlt />
      )}
    </div>
  );
};

export default AppListsUserAvatar;
