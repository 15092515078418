import React, { useState } from "react";
import {
  accessories,
  categories,
  childGirlBoy,
  maleCategories,
  shoes,
} from "../../../../constants/catalogCategoriesSortFilterData";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  selectActiveAgeGroup,
  selectActiveSort,
  selectCatalogCategory,
  selectFilterType,
  setActiveAgeGroup,
  setSelectedCategory,
} from "../../../../redux/slices/CatalogSlice";
import { useDispatch, useSelector } from "react-redux";

const CatalogFilter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = queryString.parse(location.search);
  const activeSort = useSelector(selectActiveSort);
  const selectedCategory = useSelector(selectCatalogCategory);
  const activeAgeGroup = useSelector(selectActiveAgeGroup);
  const activeFilterType = useSelector(selectFilterType);
  const [selectedChildGroup, setSelectedChildGroup] = useState([]);

  const selectCategory = (category) => {
    dispatch(setSelectedCategory(category));
    const newSearchParams = { ...searchParams, category: category };
    const newSearch = queryString.stringify(newSearchParams);
    navigate(`?${newSearch}`);
  };

  const openChildSubCategory = (categories) => {
    setSelectedChildGroup(categories);
  };

  const handleTitleClick = (ageGroup, categories) => {
    dispatch(setActiveAgeGroup(ageGroup));
    openChildSubCategory(categories);
  };

  const uniqueCategories = [
    ...new Set([
      ...categories,
      ...maleCategories,
      ...accessories,
      ...childGirlBoy.flatMap((item) => item.categories),
    ]),
  ];

  return (
    <div className="catalog-cards-wrapper-filter-block w-100">
      <ul
        className="d-flex align-items-center justify-content-start gap-2 w-100"
        id="style__scroll"
      >
        {(activeFilterType.type === "allItems" ||
          activeFilterType.type === "wholesale" ||
          activeFilterType.type === "retail") &&
        activeSort.type !== "male" &&
        activeSort.type !== "female" &&
        activeSort.type !== "shoes" &&
        activeSort.type !== "child" &&
        activeSort.type !== "accessories"
          ? uniqueCategories.map((el, index) => (
              <li
                key={index}
                onClick={() => {
                  selectCategory(el);
                }}
                className={`${selectedCategory === el ? "active" : ""}`}
              >
                {el}
              </li>
            ))
          : activeSort.type === "male"
          ? maleCategories.map((el, index) => (
              <li
                key={index}
                onClick={() => {
                  selectCategory(el);
                }}
                className={`${selectedCategory === el ? "active" : ""}`}
              >
                {el}
              </li>
            ))
          : activeSort.type === "female"
          ? categories.map((el, index) => (
              <li
                key={index}
                onClick={() => {
                  selectCategory(el);
                }}
                className={`${selectedCategory === el ? "active" : ""}`}
              >
                {el}
              </li>
            ))
          : activeSort.type === "child"
          ? childGirlBoy?.map((el, index) => (
              <li
                key={index}
                onClick={() => handleTitleClick(el.ageGroup, el.categories)}
                className={`${activeAgeGroup === el.ageGroup ? "active" : ""}`}
              >
                {el.ageGroup}
              </li>
            ))
          : activeSort.type === "accessories"
          ? accessories?.map((el, index) => (
              <li
                key={index}
                onClick={() => {
                  selectCategory(el);
                }}
                className={`${selectedCategory === el ? "active" : ""}`}
              >
                {el}
              </li>
            ))
          : activeSort.type === "shoes"
          ? shoes?.map((el, index) => (
              <li
                key={index}
                onClick={() => {
                  selectCategory(el);
                }}
                className={`${selectedCategory === el ? "active" : ""}`}
              >
                {el}
              </li>
            ))
          : activeSort.type === "newItems"
          ? uniqueCategories.map((el, index) => (
              <li
                key={index}
                onClick={() => {
                  selectCategory(el);
                }}
                className={`${selectedCategory === el ? "active" : ""}`}
              >
                {el}
              </li>
            ))
          : null}
      </ul>
      <ul
        className="d-flex align-items-center justify-content-between gap-2 w-100"
        id="style__scroll"
      >
        {activeAgeGroup &&
          selectedChildGroup?.map((el, index) => (
            <li
              key={index}
              onClick={() => {
                selectCategory(el);
              }}
              className={`${selectedCategory === el ? "active" : ""}`}
            >
              {el}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CatalogFilter;
