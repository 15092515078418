const GenerateRandomArticul = (productName) => {
  const randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const prefix = productName.slice(0, 2).toUpperCase().padEnd(2, "X");

  let result = prefix;
  for (let i = 0; i < 6; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
};

export default GenerateRandomArticul;
