import React from "react";
import { Link } from "react-router-dom";
import { buyersNavsLinks, sellersNavsLinks } from "./../../../../constants/headerNavLinksData";
import { DropdownMenu } from "./dropdownMenu/DropdownMenu";

export const DesktopHeader = ({ active, setActive }) => {
  return (
    <nav className="d-flex align-items-center w-100">
      <Link to="/catalogs" style={{ paddingRight: "16px" }}>
        Товары
      </Link>
      <DropdownMenu
        title="Покупателям"
        links={buyersNavsLinks}
        setActive={setActive}
        active={active}
      />
      <DropdownMenu
        title="Поставщикам"
        links={sellersNavsLinks}
        setActive={setActive}
        active={active}
      />
      <Link to="/faq" style={{ paddingLeft: "16px" }}>
        Частые вопросы
      </Link>
    </nav>
  );
};
