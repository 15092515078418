import React, { useState } from "react";
import { ProfileProductionCatalogState } from "../../../../../context/ProfileProductionCatalogProvider";

const AddProductionTextiles = ({ text, placeholder ,productionTextiles}) => {
  const {
    setProductionTextiles,
    productionTextilesError,
    setProductionTextilesError,
  } = ProfileProductionCatalogState();
  
  const [textilesInput, setTextilesInput] = useState(productionTextiles);

  const handleTextilesInputChange = (e) => {
    const input = e.target.value;
    setTextilesInput(input);

    const regex = /[\s,.]+/;
    const inputArray = input.split(regex).map((name) => name.trim());

    const newTextile = inputArray
      .filter((name) => name !== "")
      .map((name) => ({
        name,
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgt8qwPqHV6k_a73HCQKPvsJgFix9NOZAztg&usqp=CAU",
      }));

    setProductionTextiles(newTextile);
    if (productionTextilesError) {
      setProductionTextilesError("");
    }
  };

  return (
    <>
      <div className="my-4">
        <label className="form-label">{text}</label>
        <div>
          <input
            type="text"
            placeholder={placeholder}
            className={`form-control ${
              productionTextilesError ? "cart-border-error" : ""
            }`}
            value={textilesInput}
            onChange={handleTextilesInputChange}
          />
          {productionTextilesError && (
            <p className="cart-error-message">{productionTextilesError}</p>
          )}
        </div>
      </div>
    </>
  );
};
export default AddProductionTextiles;
