import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveSize,
  selectSizes,
  setActiveSize,
} from "../../../../../../redux/slices/SingleCatalogItemSlice";

const CatalogItemSizes = ({ item }) => {
  const dispatch = useDispatch();
  const size = useSelector(selectSizes);
  const activeSize = useSelector(selectActiveSize);

  return (
    <div className="catalog__card__sizes align-items-center">
      <h6>Размеры</h6>
      <p className="d-flex gap-2">
        {size.map((size, index) =>
          item.category === "Белье" ||
          (item.isWholesale !== undefined && !item.isWholesale) ? (
            <span
              key={index}
              className={`activeSize ${activeSize === index ? "active" : ""}`}
              onClick={() => {
                dispatch(setActiveSize(index));
              }}
            >
              {size.size}
            </span>
          ) : (
            <span key={index}>
              <strong>{size.size}</strong>
            </span>
          )
        )}
      </p>
    </div>
  );
};

export default CatalogItemSizes;
