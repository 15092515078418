import { Avatar, Box, Spinner, Tooltip, WrapItem } from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import { MdAddAPhoto } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import convertToBase64 from "./../../../helper/Convert";
import { updateProfileAction } from "../../../redux/Actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { uploadProfileAvatarAction } from "../../../redux/Actions/ProfieActions";

const ProfileAvatar = ({ userInfo }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const [file, setFile] = useState();
  const [activeFile, setActiveFile] = useState(false);

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const avatarUpload = useSelector((state) => state.avatarUpload);
  const { avatar, loading: avatarLoading, error, success } = avatarUpload;

  const onUploadFile = async (e) => {
    const base64 = await convertToBase64(e.target.files[0]);
    setFile(base64);
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
    setActiveFile(true);
  };

  const onCloseUpload = () => {
    setFile(userInfo?.profile);
    setActiveFile(false);
  };

  const onSaveUpload = () => {
    setActiveFile(false);
    dispatch(uploadProfileAvatarAction({ file }));
  };

  useEffect(() => {
    if (success && avatar) {
      dispatch(updateProfileAction({ id: user._id }));
      setFile(null);
    }
  }, [dispatch, success, user, avatar]);

  return (
    <React.Fragment>
      <WrapItem className="position-relative">
        {activeFile && (
          <React.Fragment>
            <Tooltip label="Сохранить" placement="right-start" hasArrow>
              <span className="profile__save__photo" onClick={onSaveUpload}>
                <IoMdCheckmark color="green" size="20px" />
              </span>
            </Tooltip>

            <Tooltip label="Закрыть" placement="right-start" hasArrow>
              <span className="profile__remove__photo" onClick={onCloseUpload}>
                <IoCloseSharp color="green" size="20px" />
              </span>
            </Tooltip>
          </React.Fragment>
        )}
        {avatarLoading ? (
          <Box
            style={{ width: "128px", height: "128px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Spinner color="blue.500" />
          </Box>
        ) : (
          <Tooltip
            label={
              error
                ? error === "request entity too large"
                  ? "Пожалуйста, выберите файл размером до 3 МБ."
                  : `Ошибка: ${error}`
                : ""
            }
            placement="top"
            hasArrow
            defaultIsOpen
          >
            <Avatar
              size="2xl"
              name={userInfo ? userInfo.name : ""}
              src={file || (userInfo ? userInfo.profile : "")}
            />
          </Tooltip>
        )}

        <Tooltip label="Загрузить" placement="right-start" hasArrow>
          <span className="profile__add__photo">
            <input
              onChange={onUploadFile}
              className="uploadFileInput"
              type="file"
              id="profilePhoto"
              name="profile"
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            <MdAddAPhoto
              size="20px"
              color="#23155b"
              onClick={handleIconClick}
            />
          </span>
        </Tooltip>
      </WrapItem>
    </React.Fragment>
  );
};

export default ProfileAvatar;
