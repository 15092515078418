import React from "react";
import ProductColors from "../../../../profileAddProductToCatalog/productColors/ProductColors";
import CustomSaveBtn from "../../../../../common/customSaveBtn/CustomSaveBtn";
import ProductMainImage from "../../../../profileAddProductToCatalog/productMainImage/ProductMainImage";
import { AddIcon } from "@chakra-ui/icons";
import { ProfileProductionCatalogState } from "../../../../../../context/ProfileProductionCatalogProvider";

import CloseBtn from "../../../../../../assets/images/favorites/x-circle.svg";

const AddImageColors = (props) => {
  const {
    handleSaveProductionCatalogItem,
    isVisibleModelsAdd,
    handleOpenPopup
  } = props;

  const {
    productionModelImage,
    setProductionModelImage,
    productionModelFlippedImage,
    setProductionModelFlippedImage,
    productionModelColor,
    setProductionModelColor,
    productionModelColors,
    productionModelImageError,
    setProductionModelImageError,
    productionModelFlippedImageError,
    setProductionModelFlippedImageError,
    productionModelColorError,
    setProductionModelColorError,
    setProductionModelColors
  } = ProfileProductionCatalogState();
  
  const handleDeleteColor = (index) => {
    const updatedColors = productionModelColors.filter((_, i) => i !== index);
    setProductionModelColors(updatedColors);
  };

  return (
    <React.Fragment>
      <section className="profile__production__add__catalog__models__grid">
        {productionModelColors.map((item, index) => (
          <figure key={index}>
            <img src={item.img} alt="Цвет" title={item.color} />
            <span
              className="profile__production__add__catalog__models__grid__colors"
              style={{ background: `${item.color}` }}
            ></span>
             <button type="button" className="productionCatalogCloseBtn">
            <img
              src={CloseBtn}
              alt="Удалить"
              onClick={() => handleDeleteColor(index)} 
            />
          </button>
          </figure>
        ))}
        <div
          className="addProductionModels h-100 w-100 "
          onClick={handleOpenPopup}
        >
          <div className="d-flex align-items-center justify-content-center">
            <AddIcon boxSize="2rem" color="#1475cf" />
          </div>
        </div>
      </section>

      {isVisibleModelsAdd && (
        <React.Fragment>
          <ProductMainImage
            text="Картинка товара: (вид спереди)"
            image={productionModelImage}
            setImage={setProductionModelImage}
            productionModelImageError={productionModelImageError}
            setProductionModelImageError={setProductionModelImageError}
          />
          <ProductMainImage
            text="Картинка товара: (вид сзади)"
            image={productionModelFlippedImage}
            setImage={setProductionModelFlippedImage}
            fieldType="ProductionCatalogImage"
            setProductionColor={setProductionModelColor}
            productionModelFlippedImageError={productionModelFlippedImageError}
            setProductionModelFlippedImageError={
              setProductionModelFlippedImageError
            }
            setProductionModelColorError={setProductionModelColorError}
            productionModelColorError={productionModelColorError}
          />
          <ProductColors
            productionColor={productionModelColor}
            setProductionColor={setProductionModelColor}
            productionModelColorError={productionModelColorError}
          />
          <CustomSaveBtn
            handleAdd={handleSaveProductionCatalogItem}
            data={productionModelColors}
            text='Примечание: Нажмите "Сохранить", чтобы добавить введенные данные в
              список карточек товара. Заполнив один раз данные и нажав сохранить вы добавляете по одной карточке товара.'
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default AddImageColors;
