import React from "react";

import { Tooltip } from "@chakra-ui/react";
import Slider from "react-slick";

import { brandNameSliderSettings } from "../../../constants/reactSlickSettingsData";

const BrandNameSlider = ({
  selectedItem,
  setSelectedItem,
  catalogs,
  scrollToElement,
}) => {
  return (
    <section className="container mt-4">
      <div className="production__design-links">
        <Slider {...brandNameSliderSettings}>
          {catalogs?.map((el) => (
            <figure
              className={`d-flex align-items-center justify-content-center ${
                selectedItem === el._id ? "active" : ""
              }`}
              key={el._id}
              onClick={() => {
                scrollToElement(el._id);
                setSelectedItem(el._id);
              }}
              title={el.brandInfo.brandName}
            >
              <div
                className={`${
                  el.brandInfo.brandLogo === "" ? null : "h-100 w-100"
                }`}
              >
                {el.brandInfo.brandLogo ? (
                  <Tooltip
                    label={`Швейное производство ${el.brandInfo.brandName}`}
                    placement="top"
                    size='sm'
                    closeDelay={2000}
                    hasArrow
                    colorScheme="green.500"
                  >
                    <img
                      src={el?.brandInfo.brandLogo}
                      alt="Лого производства"
                    />
                  </Tooltip>
                ) : (
                  el.brandInfo.brandName
                )}
              </div>
            </figure>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default BrandNameSlider;
