import { useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomSaveBtn from "../../../../common/customSaveBtn/CustomSaveBtn";
import ProductionAddedInputsView from "./productionAddedInputsView/ProductionAddedInputsView";

const SpecializationAdd = (props) => {
  const {
    setState,
    state,
    placeholder,
    placeholder2,
    label,
    label2,
    fieldType,
    descriptionText,
    specializationError,
    setSpecializationError,
  } = props;

  const [firstInput, setFirstInput] = useState("");
  const [secondInput, setSecondInput] = useState("");
  const toast = useToast();

  const handleAdd = () => {
    if (secondInput === "") {
      toast({
        title: "Заполните все поля!",
        status: "info",
        isClosable: true,
        position: "top-right",
        duration: 3000,
      });
    } else {
      if (fieldType === "specialization") {
        setState((prev) => [
          ...prev,
          { title: firstInput, fromPrice: secondInput },
        ]);
      }
      setFirstInput("");
      setSecondInput("");
    }
  };
  const handleRemoveOwner = (index) => {
    const updated = [...state];
    updated.splice(index, 1);
    setState(updated);
  };

  return (
    <React.Fragment>
      <p className="add-production-btn ">{descriptionText}</p>
      <div className="mb-4 product__owner__wrapper">
        <div>
          <React.Fragment>
            <label htmlFor={fieldType} className="mt-4">
              {label}
            </label>
            <input
              className={`form-control ${
                specializationError ? "cart-border-error" : ""
              }`}
              type="text"
              id={fieldType}
              placeholder={placeholder}
              value={firstInput}
              onChange={(e) => {
                setFirstInput(e.target.value);
                if (specializationError) {
                  setSpecializationError("");
                }
              }}
            />
            {specializationError && (
              <p className="cart-error-message">{specializationError}</p>
            )}
          </React.Fragment>

          <label htmlFor="specPrice" className="mt-4">
            {label2}
          </label>
          <input
            type="text"
            id="specPrice"
            className={`form-control ${
              specializationError ? "cart-border-error" : ""
            }`}
            onChange={(e) => {
              setSecondInput(e.target.value);
              if (specializationError) {
                setSpecializationError("");
              }
            }}
            placeholder={placeholder2}
            value={secondInput}
          />
          {specializationError && (
            <p className="cart-error-message">{specializationError}</p>
          )}
        </div>

        <CustomSaveBtn handleAdd={handleAdd} data={state} />

        <ProductionAddedInputsView
          state={state}
          setState={setState}
          handleRemoveOwner={handleRemoveOwner}
        />
      </div>
    </React.Fragment>
  );
};

export default SpecializationAdd;
