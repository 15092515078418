import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { CATALOG_CREATE_RESET } from "../../../redux/constants/CatalogConstants";
import {
  createCatalogItemAction,
  getAllCatalogItemsAction,
} from "../../../redux/Actions/CatalogActions";
import GenerateRandomArticul from "../../../helper/GenerateRandomArticul";
import { useProductState } from "../../../hooks/useProductState";
import { AddIcon } from "@chakra-ui/icons";
import ProfileAddProductionItemModal from "./profileAddProductionItemModal/ProfileAddProductionItemModal";
import ChakraUiSpinner from "../../common/loader/ChakraUiSpinner";
import LoadingError from "../../common/errorMessage/LoadingError";
import ProfileProductCard from "../profileMyProducts/profileMyProductsCards/ProfileProductCard";

const ProfileAddProductToCatalog = () => {
  const { initialState } = useProductState();
  const [state, setState] = useState(initialState);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const [owner, setOwner] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [gender, setGender] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [category, setCategory] = useState("");
  const [productWeight, setProductWeight] = useState("");
  const [isSample, setIsSample] = useState(false);
  const [samplePrice, setSamplePrice] = useState(0);
  const [isWholesale, setIsWholesale] = useState(true);

  const dispatch = useDispatch();

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const profileCatalog = useSelector((state) => state.profileCatalog);
  const {
    loading: profileLoading,
    products,
    error: profileError,
  } = profileCatalog;

  const deleteProfileCatalogItem = useSelector(
    (state) => state.deleteProfileCatalogItem
  );
  const { success, error: errorDelete } = deleteProfileCatalogItem;

  const catalogCreateItem = useSelector((state) => state.catalogCreateItem);
  const { loading, error, product } = catalogCreateItem;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch(getAllCatalogItemsAction());
  }, [dispatch, success]);

  const filteredProducts = products
    ? products?.filter((product) => product.email === userInfo.email)
    : null;

  useEffect(() => {
    if (product) {
      dispatch({ type: CATALOG_CREATE_RESET });
      toast({
        title: "Товар успешно добавлен!",
        status: "success",
        isClosable: true,
        position: "top-right",
        duration: 1000,
      });
      setState(initialState);
      setDescription("");
      setImage("");
      setImages([]);
      setOwner([]);
      setColors([]);
      setSizes([]);
      setGender("");
      setAgeGroup("");
      setCategory("");
      setProductWeight("");
      setIsSample(false);
      setSamplePrice(0);
      setIsWholesale(true);
    }
  }, [dispatch, product, initialState, toast]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      !image ||
      !state.textile ||
      !gender ||
      !category ||
      images.length === 0 ||
      owner.length === 0 ||
      sizes.length === 0 ||
      colors.length === 0
    ) {
      toast({
        title: "Заполните все объязательные поля!",
        status: "error",
        isClosable: true,
        position: "top-right",
        duration: 2000,
      });
      return;
    }
    dispatch(
      createCatalogItemAction({
        title: state.title,
        price: state.price,
        description,
        image,
        countInStock: state.countInStock,
        textile: state.textile,
        madeIn: state.madeIn,
        gender: gender,
        ageGroup: ageGroup,
        oldPrice: state.oldPrice,
        category,
        orderFrom: state.orderFrom,
        images,
        owner,
        articul: state.articul,
        colors,
        sizes,
        newItem: state.newItem.toUpperCase(),
        email: userInfo.email,
        userId: userInfo.id,
        brand: state.brand.toUpperCase(),
        weight: productWeight,
        url: "www.maneken.shop",
        hasSample: isSample,
        samplePrice,
        qtyOfGoodsInPack: state.qtyOfGoodsInPack,
        isWholesale: isWholesale,
      })
    );
  };

  const updateState = (key, value) => {
    if (key === "title") {
      const newArticul = GenerateRandomArticul(value);
      setState((prevState) => ({
        ...prevState,
        [key]: value,
        articul: newArticul,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  return (
    <section className="content-main" style={{ maxWidth: "1200px" }}>
      <React.Fragment>
        <section className="profile__production__add__catalog__grid">
          <div
            className="h-100 w-100 d-flex align-items-center justify-content-center"
            onClick={onOpen}
          >
            <AddIcon boxSize="2rem" color="#1475cf" />
          </div>
          {profileLoading && <ChakraUiSpinner />}
          {profileError && (
            <LoadingError variant={alert()}>{profileError}</LoadingError>
          )}
          {errorDelete && (
            <LoadingError variant="alert-danger">{errorDelete}</LoadingError>
          )}
          {filteredProducts?.map((item) => (
            <ProfileProductCard
              item={item}
              productId={item._id}
              key={item._id}
            />
          ))}
        </section>
      </React.Fragment>

      <ProfileAddProductionItemModal
        submitHandler={submitHandler}
        error={error}
        loading={loading}
        state={state}
        updateState={updateState}
        category={category}
        setCategory={setCategory}
        sizes={sizes}
        setSizes={setSizes}
        owner={owner}
        setOwner={setOwner}
        onClose={onClose}
        isOpen={isOpen}
        gender={gender}
        setGender={setGender}
        ageGroup={ageGroup}
        setAgeGroup={setAgeGroup}
        description={description}
        setDescription={setDescription}
        image={image}
        setImage={setImage}
        images={images}
        setImages={setImages}
        colors={colors}
        setColors={setColors}
        product={product}
        productWeight={productWeight}
        setProductWeight={setProductWeight}
        isSample={isSample}
        setIsSample={setIsSample}
        samplePrice={samplePrice}
        setSamplePrice={setSamplePrice}
        isWholesale={isWholesale}
        setIsWholesale={setIsWholesale}
      />
    </section>
  );
};

export default ProfileAddProductToCatalog;
