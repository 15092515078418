import { Spinner } from "@chakra-ui/react";
import React, { memo, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { deleteChatAction } from "../../../../redux/Actions/ChatActions";
import ChatItem from "./chatItem/ChatItem";
import {
  selectChats,
  selectFatchAgain,
  setIsVisibleChat,
} from "../../../../redux/slices/ChatSlice";

const ChatBox = memo(({ loggedUser, onChatCreate, fetchChats }) => {
  const dispatch = useDispatch();

  const chatDelete = useSelector((state) => state.chatDelete);
  const { deleting, deletedChatId } = chatDelete;
  const chats = useSelector(selectChats);
  const fetchAgain = useSelector(selectFatchAgain);

  const onDeleteChat = (id) => {
    dispatch(deleteChatAction(id));
    dispatch(setIsVisibleChat(true));
  };

  useEffect(() => {
    if (deletedChatId) {
      fetchChats();
    }
    // eslint-disable-next-line
  }, [deletedChatId, fetchAgain]);

  return (
    <React.Fragment>
      {chats?.length > 0 ? (
        deleting ? (
          <div className="d-flex align-items-center  justify-content-center  w-100 ">
            <Spinner
              thickness="3px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
            />
          </div>
        ) : (
          chats?.map((chat) => (
            <ChatItem
              key={chat._id}
              chat={chat}
              onChatCreate={onChatCreate}
              loggedUser={loggedUser}
              onDeleteChat={onDeleteChat}
              {...chat}
            />
          ))
        )
      ) : (
        <div className="d-flex flex-column text-center">
          <p className="w-100">Нет активных чатов</p>
          <p className="w-100">
            Чтобы начать новый чат, нажмите на кнопку
            <strong style={{ color: "#4eac6d" }}> "Написать"</strong> на заявке.
          </p>
        </div>
      )}
    </React.Fragment>
  );
});

export default ChatBox;
