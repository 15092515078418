import React from "react";
import { BiTime } from "react-icons/bi";
import moment from "moment";
import "moment/locale/ru";
import { useSelector } from "react-redux";
import { selectApplicationInfo, selectCurrentChatUser } from "../../../../../redux/slices/ChatSlice";

const CurrentChatMsgTheme = () => {
  const currentChatUser = useSelector(selectCurrentChatUser);
  const applicationInfo = useSelector(selectApplicationInfo);
  return (
    <React.Fragment>
      {applicationInfo?.userId === currentChatUser?.users[1]?._id ? (
        <div className="message-chat message-personal-chat">
          <h5>{applicationInfo.itemName}</h5>
          <p>
            <strong>Объем покупки:</strong> {applicationInfo.quantity}
          </p>
          <p>
            <strong>Место реализации товара:</strong> {applicationInfo.location}
          </p>
          <p>
            <strong>Требования к продукту:</strong>{" "}
            {applicationInfo.productRequirements}
          </p>

          <div className="d-flex align-items-center justify-content-between">
            <p className="message-date d-flex align-items-center mt-2">
              <BiTime />
              {moment(applicationInfo.createdAt).format("LL")}
            </p>
            <p className="message-city mb-0">{applicationInfo.city}</p>
          </div>
          <div className="message__images">
            {currentChatUser?.images?.map((el, index) => (
              <figure key={index}>
                <img src={el.img} alt={currentChatUser.itemName} />
              </figure>
            ))}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default CurrentChatMsgTheme;
