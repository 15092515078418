import { CalculateYldamExpressDelivery } from "../../helper/СalculateYldamExpressDelivery";
import {
  CONSTRUCTOR_CART_ADD_ITEM,
  CONSTRUCTOR_CART_DECREASE_ITEM,
  CONSTRUCTOR_CART_INCREASE_ITEM,
} from "../constants/ConstructorCart";
import { FULFILLMENT_CART_ADD_ITEM } from "../constants/Fulfillment";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_INCREASE_ITEM,
  CART_DECREASE_ITEM,
  CART_CLEAR_ITEMS,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_PAYMENT_METHOD,
  UPDATE_CART_DATA,
} from "./../constants/CartConstants";

export const cartReducer = (
  state = {
    cartItems: [],
    shippingAddress: {},
    constructorItems: [],
    fulfillment: [],
    paymentMethod: "",
  },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload;
      const existItemIndex = state.cartItems.findIndex(
        (el) =>
          (el.item === item.item &&
            el.color.some((c) => c.color === item.color[0].color) &&
            el.qty === item.qty) ||
          el.color.some((c) => c.color === item.color[0].color)
      );
      if (existItemIndex !== -1) {
        const updatedCartItems = [...state.cartItems];
        updatedCartItems[existItemIndex] = item;
        return {
          ...state,
          cartItems: updatedCartItems,
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      }

    case CONSTRUCTOR_CART_ADD_ITEM:
      const newItem = action.payload;
      const existingItemIndex = state.constructorItems.findIndex(
        (item) =>
          item.item === newItem.item &&
          item.color[0].color === newItem.color[0].color &&
          item.textiles._id === newItem.textiles._id &&
          item.sizes.toString() === newItem.sizes.toString()
      );
      if (existingItemIndex !== -1) {
        const updatedCartItems = [...state.constructorItems];
        updatedCartItems[existingItemIndex] = newItem;
        return {
          ...state,
          constructorItems: updatedCartItems,
        };
      } else {
        return {
          ...state,
          constructorItems: [...state.constructorItems, newItem],
        };
      }

    case FULFILLMENT_CART_ADD_ITEM:
      const newFulfillmentItem = action.payload;
      const existingFulfillmentItemIndex = state.fulfillment.findIndex(
        (item) =>
          item.selectedName === newFulfillmentItem.selectedName &&
          item.otherSelectedQty === newFulfillmentItem.otherSelectedQty &&
          item.selectedQty === newFulfillmentItem.selectedQty &&
          item.otherSelectedName === newFulfillmentItem.otherSelectedName
      );
      if (existingFulfillmentItemIndex !== -1) {
        const updatedFulfillmentItems = [...state.fulfillment];
        updatedFulfillmentItems[existingFulfillmentItemIndex] =
          newFulfillmentItem;
        return {
          ...state,
          fulfillment: updatedFulfillmentItems,
        };
      } else {
        return {
          ...state,
          fulfillment: [...state.fulfillment, newFulfillmentItem],
        };
      }

    case CART_REMOVE_ITEM:
      const { cartItems, constructorItems, fulfillment } = state;
      const { id, color, qty, price, textiles, selectedItems } = action.payload;
      const updatedCartItems = cartItems.filter(
        (item) =>
          item.id !== id ||
          item.color !== color ||
          item.qty !== qty ||
          item.price !== price
      );
      const updatedConstructorItems = constructorItems.filter(
        (item) =>
          item.id !== id ||
          item.color !== color ||
          item.qty !== qty ||
          item.price !== price ||
          item.textiles.name !== textiles.name
      );
      const updatedFulfillmentItems = fulfillment.filter(
        (item) =>
          item.id !== id ||
          item.selectedItems !== selectedItems ||
          item.price !== price
      );
      return {
        ...state,
        cartItems: updatedCartItems,
        constructorItems: updatedConstructorItems,
        fulfillment: updatedFulfillmentItems,
      };

    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };

    case CART_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };

    case CART_INCREASE_ITEM:
      const incItemIndex = action.payload;
      const incItem = state.cartItems[incItemIndex];
      const newQty = +incItem.qty + incItem.qtyOfGoodsInPack;
      if (newQty <= incItem.countInStock) {
        const updatedIncItem = { ...incItem, qty: newQty };
        const updatedIncCartItems = [...state.cartItems];
        updatedIncCartItems[incItemIndex] = updatedIncItem;
        return {
          ...state,
          cartItems: updatedIncCartItems,
        };
      } else {
        return state;
      }

    case CART_DECREASE_ITEM:
      const decItemIndex = action.payload;
      const decItem = state.cartItems[decItemIndex];
      const updatedDecItem = {
        ...decItem,
        qty: Math.max(decItem.qty - decItem.qtyOfGoodsInPack, 0),
      };

      // Check if the updated qty is zero
      if (updatedDecItem.qty === 0) {
        // Remove the item from the updated cart items array
        const updatedDecCartItems = [
          ...state.cartItems.slice(0, decItemIndex),
          ...state.cartItems.slice(decItemIndex + 1),
        ];

        return {
          ...state,
          cartItems: updatedDecCartItems,
        };
      } else {
        const updatedDecCartItems = [...state.cartItems];
        updatedDecCartItems[decItemIndex] = updatedDecItem;
        return {
          ...state,
          cartItems: updatedDecCartItems,
        };
      }

    case CONSTRUCTOR_CART_INCREASE_ITEM:
      const incItemInd = action.payload;
      const incIt = state.constructorItems[incItemInd];
      const updatedIncIt = { ...incIt, qty: +incIt.qty + 50 };
      const updatedIncCartIt = [...state.constructorItems];
      updatedIncCartIt[incItemInd] = updatedIncIt;
      return {
        ...state,
        constructorItems: updatedIncCartIt,
      };

    case CONSTRUCTOR_CART_DECREASE_ITEM:
      const decItemIn = action.payload;
      const decIt = state.constructorItems[decItemIn];
      const updatedDecIt = { ...decIt, qty: Math.max(decIt.qty - 50, 0) };

      // Check if the updated qty is zero
      if (updatedDecIt.qty === 0) {
        // Remove the item from the updated constructor cart items array
        return {
          ...state,
          constructorItems: [
            ...state.constructorItems.slice(0, decItemIn),
            ...state.constructorItems.slice(decItemIn + 1),
          ],
        };
      } else {
        const updatedDecCartIt = [...state.constructorItems];
        updatedDecCartIt[decItemIn] = updatedDecIt;
        return {
          ...state,
          constructorItems: updatedDecCartIt,
        };
      }

    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItems: [],
        constructorItems: [],
        fulfillment: [],
      };

    default:
      return state;
  }
};

const initialState = {
  cartItems: [],
  constructorItems: [],
  fulfillment: [],
  tariffs: {},
  alfaCargoPrice: 0,
  redExpressCargoPrice: 0,
  selectedKgSettlement: null,
  totalFromCatalog: 0,
  totalFromConstructor: 0,
  totalFromFulfillment: 0,
  cdekDeliveryPrice: 0,
  alfaCargoDeliveryPrice: 0,
  redExpressDeliveryPrice: 0,
  yldamExressPrice: 0,
  total: 0,
  cartQty: 0,
  constructorQty: 0,
  totalQty: 0,
};

// CART TOTAL PRICE SUM WITH DELIVERY PRICE
export const cartTotalPriceSumWithDeliveryReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_CART_DATA:
      const {
        cartItems,
        constructorItems,
        fulfillment,
        tariffs,
        alfaCargoPrice,
        redExpressCargoPrice,
        selectedKgSettlement,
        totalDimensions,
        selectedService,
      } = action.payload;

      const totalFromCatalog = cartItems.reduce(
        (acc, item) =>
          acc +
          item.qty *
            (item.qty !== 1 ||
            (!item.isWholesale && item.isWholesale !== undefined)
              ? item.price
              : item.samplePrice),
        0
      );
      const totalFromConstructor = constructorItems.reduce(
        (acc, item) => acc + item.qty * item.price,
        0
      );
      const totalFromFulfillment = fulfillment.reduce(
        (acc, item) => acc + item.totalItemsPrice,
        0
      );
      const cdekDeliveryPrice =
        tariffs?.delivery_sum &&
        (selectedService === "cdek - до склада" ||
          selectedService === "cdek - курьерская доставка")
          ? +tariffs?.delivery_sum
          : 0;

      const alfaCargoDeliveryPrice =
        alfaCargoPrice && selectedService === "Альфа-Карго"
          ? totalDimensions?.weight <= 30
            ? 1800
            : alfaCargoPrice * totalDimensions?.weight +
              Math.ceil(totalDimensions?.weight / 50) * 200
          : 0;

      const redExpressDeliveryPrice =
        redExpressCargoPrice && selectedService === "Рэд-Экспресс"
          ? totalDimensions?.weight <= 30
            ? 1800
            : redExpressCargoPrice * totalDimensions?.weight +
              Math.ceil(totalDimensions?.weight / 50) * 200
          : 0;

      const yldamExressPrice = selectedKgSettlement?.region
        ? selectedKgSettlement?.region && totalDimensions?.weight !== undefined
          ? CalculateYldamExpressDelivery(
              selectedKgSettlement.region,
              totalDimensions.weight
            )
          : 0
        : 0;

      const total =
        +totalFromConstructor +
        +totalFromCatalog +
        +totalFromFulfillment +
        +cdekDeliveryPrice +
        +alfaCargoDeliveryPrice +
        +redExpressDeliveryPrice +
        +yldamExressPrice;

      const cartQty = cartItems.reduce((acc, el) => acc + el.qty, 0);
      const constructorQty = constructorItems.reduce(
        (acc, el) => acc + el.qty,
        0
      );

      const combinedItemsPrice =
        Number(totalFromCatalog) +
        Number(totalFromConstructor) +
        totalFromFulfillment;

      const totalQty = +cartQty + +constructorQty;

      return {
        ...state,
        totalFromCatalog,
        totalFromConstructor,
        totalFromFulfillment,
        cdekDeliveryPrice,
        alfaCargoDeliveryPrice,
        redExpressDeliveryPrice,
        yldamExressPrice,
        total,
        combinedItemsPrice,
        totalQty: totalQty,
      };

    default:
      return state;
  }
};
