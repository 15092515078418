import React from "react";
import LoadingError from "../../common/errorMessage/LoadingError";
import { Link } from "react-router-dom";
import { Button } from "./../../common/button/Button";
import { CurrencyConverter } from "./../../common/currencyConverter/CurrencyConverter";
import { Skeleton } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectCurrency } from "../../../redux/slices/CatalogSlice";
import SaleType from "../../common/catalogCardSaleType/SaleType";

const RecommendProducts = ({ catalogs, error, loading }) => {
  const selectedCurrency = useSelector(selectCurrency);
  if (!catalogs) {
    return null;
  }
  const ratedCatalogs = catalogs?.filter((el) => el.ratings);
  const unratedCatalogs = catalogs?.filter((el) => !el.ratings);

  const displayCatalogs = [
    ...(ratedCatalogs?.sort((a, b) => b.ratings - a.ratings) || []),
    ...(unratedCatalogs || []),
  ].slice(0, 30);

  return (
    <div
      id="newCollection"
      className="new-collection d-flex align-items-center justify-content-center"
    >
      {error && <LoadingError variant="alert-danger">{error}</LoadingError>}

      <div className="container my-5 px-0" id="#newCollection">
        <h2>Рекомендуемые товары</h2>
        <div>
          {loading
            ? [...new Array(15)].map((_, index) => <Skeleton key={index} />)
            : displayCatalogs.map((el) => (
                <div key={el._id} className="catalog__card">
                  <div className="catalog__card__image">
                    <Link to={`/catalogs/new-collection/${el._id.toString()}`}>
                      <img
                        key={el.id}
                        className="h-100 object-fit-cover w-100"
                        src={el.image}
                        alt={el.title}
                      />
                    </Link>
                  </div>
                  <div className="catalog__card_description">
                    <div className="saleType mt-1 d-flex gap-1">
                      <SaleType item={el} />
                      {el.hasSample ? <p>Образец</p> : ""}
                    </div>
                    <h4 className="ellipsis-text">{el.title}</h4>
                    <p>
                      {selectedCurrency === "KGS" ? (
                        <strong>{el.price} KGS</strong>
                      ) : (
                        <CurrencyConverter total={el.price} />
                      )}
                    </p>
                  </div>
                </div>
              ))}
        </div>

        <div className="newCollection-btn">
          <Button to="/catalogs">Перейти в «Товары»</Button>
        </div>
      </div>
    </div>
  );
};

export default RecommendProducts;
