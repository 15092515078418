import { HashLink } from "react-router-hash-link";
import LinkNav from "../hooks/LinkNav";
import { Link } from "react-router-dom";

export const footerNavLinks = {
  pageLinks: [
    {
      id: 1,
      link: (
        <HashLink to="/#about" className="active" disabled>
          Главная
        </HashLink>
      ),
    },
    {
      id: 2,
      link: <LinkNav to="/catalogs">Товары</LinkNav>,
    },
    {
      id: 3,
      link: <LinkNav to="/about">О нас</LinkNav>,
    },
    {
      id: 4,
      link: <LinkNav to="/fulfillment">Фулфилмент</LinkNav>,
    },
    {
      id: 5,
      link: <LinkNav to="/constructor">Конструктор</LinkNav>,
    },
    {
      id: 6,
      link: <HashLink to="/#newCollection">Новинки</HashLink>,
    },
    {
      id: 7,
      link: <HashLink to="/#reviews">Отзывы</HashLink>,
    },
    // {
    //   id: 9,
    //   link: <LinkNav to="/fabrics">Ткани</LinkNav>,
    // },
  ],
  contactLinks: [
    {
      id: 1,
      link: "mailto:haymaneken@gmail.com",
      name: "Gmail",
      img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1700125430/bg%20images/gmail_ng4lzv.png",
    },
    {
      id: 2,
      link: "https://instagram.com/maneken.bishkek?igshid=YmMyMTA2M2Y=",
      name: "instagram",
      img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1700125740/bg%20images/instagram_fl4abo.png",
    },
    {
      id: 3,
      link: "https://t.me/+996772122111",
      name: "telegram",
      img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1700125774/bg%20images/telegram_btidzh.png",
    },
    {
      id: 4,
      link: "https://api.whatsapp.com/send/?phone=996772122111",
      name: "whatsapp",
      img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1700125819/bg%20images/whatsapp_d1al1y.png",
    },
  ],
  address: [
    {
      id: 1,
      link: (
        <Link
          className="active"
          target="target_blank"
          to="https://yandex.ru/map-widget/v1/?um=constructor%3A9da533d270438c1bc565cf3e8a21c3b2baad95796a6eaf394c4dd3ddc5ebc25d&amp;source=constructor"
          style={{ height: "100%" }}
        >
          Адрес
        </Link>
      ),
    },
    {
      id: 2,
      link: (
        <Link
          target="target_blank"
          to="https://yandex.ru/map-widget/v1/?um=constructor%3A9da533d270438c1bc565cf3e8a21c3b2baad95796a6eaf394c4dd3ddc5ebc25d&amp;source=constructor"
          style={{ height: "100%" }}
        >
          Село Восток, ул. Ленина 58А
        </Link>
      ),
    },
    {
      id: 3,
      link: (
        <Link
          to="https://api.whatsapp.com/send/?phone=996772122111"
          target="target_blank"
        >
          + 996 772 12 21 11
        </Link>
      ),
    },
  ],
  documents: [
    {
      id: 1,
      link: <LinkNav to="/privacy-policy">Политика конфиденциальности</LinkNav>,
    },
    {
      id: 2,
      link: <LinkNav to="/public-offer">Публичная оферта</LinkNav>,
    },
    {
      id: 3,
      link: <LinkNav to="/faq">Часто задаваемые вопросы</LinkNav>,
    },
    {
      id: 4,
      link: <LinkNav to="/cooperation">Условия сотрудничества</LinkNav>,
    },
  ],
};
