import React, { useState } from "react";
import { DecreaseQty, IncreaseQty } from "../../../../../../../assets/svg/Svg";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import "./CatalogCardsAddToCardsSection.scss";
import { useSelector } from "react-redux";
import {
  selectQty,
  setQty,
} from "../../../../../../../redux/slices/SingleCatalogItemSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useAddToCart from "../../../../../../../hooks/useAddToCart";

const CatalogCardsAddToCardsSection = ({ item }) => {
  const dispatch = useDispatch();
  const qty = useSelector(selectQty);
  const { id } = useParams();

  const [onePackOfGoods, setOnePackOfGoods] = useState(1);

  const qtyOfGoodsInPack =
    !item.isWholesale && item.isWholesale !== undefined
      ? onePackOfGoods
      : item.qtyOfGoodsInPack
      ? item.qtyOfGoodsInPack
      : 4;
  const qtyOfGood =
    !item.isWholesale && item.isWholesale !== undefined
      ? onePackOfGoods
      : item.qtyOfGoodsInPack
      ? onePackOfGoods * qtyOfGoodsInPack
      : qty;

  const addToCartHandler = useAddToCart(id, item, setOnePackOfGoods);

  const handleIncreaseQty = () => {
    if (!item.isWholesale && item.isWholesale !== undefined) {
      const newQty = onePackOfGoods + 1;
      if (newQty > item.countInStock) {
        dispatch(setQty(item.countInStock));
      } else {
        setOnePackOfGoods(newQty);
        dispatch(setQty(newQty));
      }
    } else {
      const newQty = (onePackOfGoods + 1) * qtyOfGoodsInPack;
      if (newQty > item.countInStock) {
        dispatch(setQty(item.countInStock));
      } else {
        setOnePackOfGoods(onePackOfGoods + 1);
        dispatch(setQty(newQty));
      }
    }
  };
  const handleDecreaseQty = () => {
    if (!item.isWholesale && item.isWholesale !== undefined) {
      if (onePackOfGoods > 1) {
        const newQty = onePackOfGoods - 1;
        setOnePackOfGoods(newQty);
        dispatch(setQty(newQty));
      } else {
        setOnePackOfGoods(1);
        dispatch(setQty(1));
      }
    } else {
      if (onePackOfGoods > 1) {
        const newQty = (onePackOfGoods - 1) * qtyOfGoodsInPack;
        setOnePackOfGoods(onePackOfGoods - 1);
        dispatch(setQty(newQty));
      } else {
        setOnePackOfGoods(1);
        dispatch(setQty(qtyOfGoodsInPack));
      }
    }
  };
  const handleBuyAll = () => {
    if (item.countInStock > 0) {
      addToCartHandler(item.countInStock);
    }
  };
  return (
    <section className="catalog__card__add__to__card__section ">
      <div className="catalog__card_add_price d-flex  align-items-center justify-content-between mb-4">
        {item.countInStock <= 0 || item.countInStock === "" ? (
          <h6>Нет в наличии</h6>
        ) : (
          <h6>В наличии</h6>
        )}
        <p className="d-flex align-items-center gap-3">
          <strong>
            {item.countInStock <= 0 || item.countInStock === ""
              ? ""
              : item.countInStock + " шт"}
          </strong>
          <button
            type="button"
            className={`catalog-items-btn ${
              item.countInStock <= 0 ? "disabled-btn" : ""
            }`}
            style={{ width: "150px", height: "45px" }}
            onClick={handleBuyAll}
          >
            Добавить все
          </button>
        </p>
      </div>
      <div className="catalog__card_add_qty d-flex align-items-center justify-content-between mb-4">
        <div className="d-flex flex-column">
          <h6>Колличество</h6>
          {(item.isWholesale || item.isWholesale === undefined) && (
            <p style={{ color: "#008000" }}>
              {onePackOfGoods <= 1
                ? `в одной упаковке ${qtyOfGoodsInPack} шт.`
                : `  в ${onePackOfGoods} упаковках ${qty} шт.`}
            </p>
          )}
        </div>

        <div className="d-flex align-items-center">
          <button
            type="button"
            className={`catalog-items-btn ${
              item.countInStock <= 0 ? "disabled-btn" : ""
            }`}
            onClick={handleDecreaseQty}
          >
            <DecreaseQty />
          </button>
          <span>
            <strong>{item.countInStock <= 0 ? 0 : onePackOfGoods}</strong>{" "}
          </span>
          <button
            type="button"
            className={`catalog-items-btn ${
              item.countInStock <= 0 ? "disabled-btn" : ""
            }`}
            onClick={handleIncreaseQty}
          >
            <IncreaseQty />
          </button>
        </div>
      </div>
      <button
        type="button"
        className={`catalog-items-btn w-100 ${
          item.countInStock <= 0 ? "disabled-btn" : ""
        }`}
        onClick={() => addToCartHandler(qtyOfGood)}
      >
        <MdOutlineAddShoppingCart />
        <span>Добавить в корзину</span>
      </button>
    </section>
  );
};

export default CatalogCardsAddToCardsSection;
