import React from "react";
import { Avatar, Tooltip } from "@chakra-ui/react";
import {
  getChatUserName,
  getChatUserProfile,
} from "../../../../../helper/ChatSettings";
import { selectCurrentChatUser } from "../../../../../redux/slices/ChatSlice";
import { useSelector } from "react-redux";

const CurrentChatUserInfo = () => {
  const currentChatUser = useSelector(selectCurrentChatUser);
  const user = useSelector((state) => state.userLogin.userInfo);
  return (
    <div className="chat-info d-flex align-items-center ">
      <Tooltip
        label={getChatUserName(currentChatUser, user)}
        placement="bottom-start"
        hasArrow
      >
        <Avatar
          mr={1}
          size="sm"
          cursor="pointer"
          name={getChatUserName(currentChatUser, user)}
          src={getChatUserProfile(currentChatUser, user)}
        />
      </Tooltip>
      <p>{getChatUserName(currentChatUser, user)}</p>
    </div>
  );
};

export default CurrentChatUserInfo;
