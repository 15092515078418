import { cdekDeliveryAction } from "../redux/Actions/OrderActions";
import { ORDER_CREATE_RESET } from "../redux/constants/OrderConstants";

export const handleOrderCreation = (
  order,
  success,
  tariffCodeChoose,
  locationData,
  dispatch,
  navigate,
  packageCounter,
  cdekItems,
  totalDimensions
) => {
  if (order && success) {
    if (tariffCodeChoose && locationData) {
      dispatch(
        cdekDeliveryAction({
          comment: order.shippingAddress.additionalInfo,
          packages: [
            {
              number: packageCounter,
              items: cdekItems,
              weight: totalDimensions.weight * 1000,
            },
          ],
          recipient: {
            name: order.shippingAddress.nameLastName,
            phones: [
              {
                number: order.shippingAddress.phoneNumber,
              },
            ],
          },
          sender: {
            company: "Компания Манекен",
            name: "Адилет",
            email: "haymaneken@gmail.com",
            phones: [
              {
                number: "+ 996 772 12 21 11",
              },
            ],
          },
          tariff_code: tariffCodeChoose,
          ...locationData,
        })
      );
    }
    navigate(`/payment-method-page/${order._id}`);
    dispatch({ type: ORDER_CREATE_RESET });
  }
};
