import React, { useEffect, useState } from "react";

import FabricList from "./fabricList/FabricList";
import FabricMainSlider from "./fabricContentMainSlider/FabricMainSlider";
import FabricInfo from "./fabricInfo/FabricInfo";
import { useDispatch, useSelector } from "react-redux";
import { fabricsAction } from "../../../redux/Actions/FabricsAction";
import LoadingError from "./../../common/errorMessage/LoadingError";
import { Skeleton } from "@chakra-ui/skeleton";

const FabricMain = () => {
  const dispatch = useDispatch();

  const fabrics = useSelector((state) => state.fabrics);
  const { fabricsList, loading, error } = fabrics;

  useEffect(() => {
    dispatch(fabricsAction());
  }, [dispatch]);

  const [activeColorsImg, setActiveColorsImg] = useState("");
  const [selectedFabric, setSelectedFabric] = useState(fabricsList[0]);

  const handleFabricSelect = (fabric) => {
    setSelectedFabric(fabric);
  };

  return (
    <>
      {loading ? (
        <div className="container fabric-skeleton">
          <Skeleton height="100vh" startColor='#ececec' endColor='#f5f5f5'>
            <div></div>
            <div></div>
          </Skeleton>
          <Skeleton height="100vh" startColor='#ececec' endColor='#f5f5f5'>
            <div></div>
            <div></div>
          </Skeleton>
        </div>
      ) : error ? (
        <LoadingError variant="alert-danger">{error}</LoadingError>
      ) : (
        <section className="fabric-content container ">
          <div className="fabric-content-left">
            <FabricList
              fabricData={fabricsList}
              onFabricSelect={handleFabricSelect}
            />
          </div>
          <div className="fabric-content-right">
            <h2 className="mb-4 text-start">{selectedFabric?.title}</h2>
            <div className="fabric-content">
              <FabricMainSlider
                fabricData={selectedFabric ? selectedFabric : fabricsList[0]}
                activeColorsImg={activeColorsImg}
              />
              <FabricInfo
                fabricData={selectedFabric ? selectedFabric : fabricsList[0]}
                setActiveColorsImg={setActiveColorsImg}
                activeColorsImg={activeColorsImg}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default FabricMain;
