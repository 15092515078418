import React, { useState } from "react";
import moment from "moment";
import "moment/locale/ru";

import { Spinner, useToast } from "@chakra-ui/react";
import { BiTime } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { updateApplicationViewCountAction } from "../../../../redux/Actions/ApplicationsAction";
import AppListsAdditionalBtns from "./appListsAdditioanalBtns/AppListsAdditionalBtns";
import AppListsUserAvatar from "./appListsUserAvatar/AppListsUserAvatar";
import { $apiPrivate } from "../../../../api/axios";
import {
  selectChats,
  setChats,
  setCurrentChatUser,
  setIsVisibleChat,
} from "../../../../redux/slices/ChatSlice";

const ApplicationsLists = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [loadingChat, setLoadingChat] = useState(false);
  const chats = useSelector(selectChats);
  const lists = useSelector((state) => state.applicationLists.lists);

  const accessChat = async (userId) => {
    try {
      setLoadingChat(true);

      const { data } = await $apiPrivate.post(`/applications/chat`, { userId });
      if (!chats.find((c) => c._id === data._id)) {
        dispatch(setChats([data, ...chats]));
      }
      dispatch(setCurrentChatUser(data));
      dispatch(setIsVisibleChat(false));
      setLoadingChat(false);
    } catch (error) {
      toast({
        title: "Error fetching the chat",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleUpdateViewCount = (id) => {
    dispatch(updateApplicationViewCountAction({ messageId: id }));
  };

  return (
    <div className="messages position-relative" id="style__scroll__chat">
      {loadingChat && (
        <Spinner
          position="absolute"
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          top="50%"
          left="50%"
          size="xl"
        />
      )}
      {lists?.map((message, index) => (
        <div className="message message-personal" key={index}>
          <AppListsUserAvatar
            accessChat={accessChat}
            handleUpdateViewCount={handleUpdateViewCount}
            message={message}
          />
          <AppListsAdditionalBtns
            message={message}
            accessChat={accessChat}
            handleUpdateViewCount={handleUpdateViewCount}
          />
          <h5>{message.itemName}</h5>
          <p>
            <strong>Объем покупки:</strong> {message.quantity}
          </p>
          <p>
            <strong>Место реализации товара:</strong> {message.location}
          </p>
          <p>
            <strong>Требования к продукту:</strong>{" "}
            {message.productRequirements}
          </p>
          <div className="d-flex align-items-center justify-content-between">
            <p className="message-date d-flex align-items-center mt-2">
              <BiTime />
              {moment(message.createdAt).format("LL")}
            </p>
            <p className="message-city mb-0">{message.city}</p>
          </div>

          <div className="message__images">
            {message?.images?.map((el, index) => (
              <figure key={index}>
                <img src={el.img} alt={message.itemName} />
              </figure>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ApplicationsLists;
