import React from "react";
import Slider from "react-slick";
import "./ProductionImagesSlider.scss";
import { videoExtensions } from "../../../../constants/index";
const ProductionImagesSlider = ({ catalogs }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings} className="production__images__slider">
      {catalogs?.brandInfo?.brandImage?.map((el, index) => (
        <React.Fragment key={index}>
          {videoExtensions.some((ext) => el.toLowerCase().endsWith(ext)) ? (
            <div>
              <video src={el} controls></video>
            </div>
          ) : (
            <figure>
              <img src={el} alt="production" />
            </figure>
          )}
        </React.Fragment>
      ))}
    </Slider>
  );
};

export default ProductionImagesSlider;
