import { selectCurrency } from "../../../../redux/slices/CatalogSlice";
import { CustomTable } from "../../../common/customTable/CustomTable";
import { useSelector } from "react-redux";

export const ProductionPriceInfoSection = ({ catalogs }) => {
  const selectedCurrency = useSelector(selectCurrency);
  return (
    <div>
      <CustomTable
        data={catalogs?.specialization}
        thead1="Специализация"
        thead2={selectedCurrency === "KGS" ? "KGS" : "RUB"}
      />
      <CustomTable
        data={catalogs?.productionCapacity}
        thead1="Производство"
        thead2="ед. в день"
      />
      <CustomTable
        data={catalogs?.quantityOfEquipment}
        thead1="Кол-во"
        thead2="Прямострочек"
        thead3="Спецмашинок"
      />
    </div>
  );
};
