export const APPLICATIONS_LIST_REQUEST = "APPLICATIONS_LIST_REQUEST";
export const APPLICATIONS_LIST_SUCCESS = "APPLICATIONS_LIST_SUCCESS";
export const APPLICATIONS_LIST_FAIL = "APPLICATIONS_LIST_FAIL";
export const APPLICATIONS_LIST_RESET = "APPLICATIONS_LIST_RESET";

export const APPLICATIONS_REQUEST = "APPLICATIONS_REQUEST";
export const APPLICATIONS_SUCCESS = "APPLICATIONS_SUCCESS";
export const APPLICATIONS_FAIL = "APPLICATIONS_FAIL";
export const APPLICATIONS_RESET = "APPLICATIONS_RESET";

export const APPLICATIONS_LIST_CREATE_REQUEST =
  "APPLICATIONS_LIST_CREATE_REQUEST";
export const APPLICATIONS_LIST_CREATE_SUCCESS =
  "APPLICATIONS_LIST_CREATE_SUCCESS";
export const APPLICATIONS_LIST_CREATE_FAIL = "APPLICATIONS_LIST_CREATE_FAIL";
export const APPLICATIONS_LIST_CREATE_RESET = "APPLICATIONS_LIST_CREATE_RESET";

export const UPDATE_APPLICATION_VIEW_COUNT_REQUEST =
  "UPDATE_APPLICATION_VIEW_COUNT_REQUEST";
export const UPDATE_APPLICATION_VIEW_COUNT_SUCCESS =
  "UPDATE_APPLICATION_VIEW_COUNT_SUCCESS";
export const UPDATE_APPLICATION_VIEW_COUNT_FAIL =
  "UPDATE_APPLICATION_VIEW_COUNT_FAIL";
export const UPDATE_APPLICATION_VIEW_COUNT_RESET =
  "UPDATE_APPLICATION_VIEW_COUNT_RESET";

export const DELETE_APPLICATION_REQUEST = "DELETE_APPLICATION_REQUEST";
export const DELETE_APPLICATION_SUCCESS = "DELETE_APPLICATION_SUCCESS";
export const DELETE_APPLICATION_FAIL = "DELETE_APPLICATION_FAIL";
