import React from "react";
import { AiOutlineSend } from "react-icons/ai";
import { ImAttachment } from "react-icons/im";
import Form from "react-bootstrap/Form";
import { applicationInputFields } from "../../../../constants/applicationsData";
import { useDispatch, useSelector } from "react-redux";
import {
  selectShowInputFields,
  setShowInputFields,
} from "../../../../redux/slices/ChatSlice";

const PublicChatInputs = ({ purchaseInfo, setPurchaseInfo, inputRef }) => {
  const dispatch = useDispatch();
  const showInputFields = useSelector(selectShowInputFields);
  const handleInputChange = (key, value) => {
    setPurchaseInfo({
      ...purchaseInfo,
      [key]: value,
    });
  };
  return (
    <div className="w-100">
      <div className="w-100 d-flex align-items-center flex-column gap-2">
        <input
          ref={inputRef}
          type="text"
          placeholder="Введите наименование товара"
          className="form-control"
          required
          value={purchaseInfo.itemName}
          onFocus={() => dispatch(setShowInputFields(true))}
          onChange={(e) =>
            setPurchaseInfo({
              ...purchaseInfo,
              itemName: e.target.value,
            })
          }
        />
        {applicationInputFields.map((field, index) => (
          <React.Fragment key={index}>
            {showInputFields && (
              <>
                {field.type === "select" ? (
                  <Form.Select
                    aria-label={field.label}
                    value={purchaseInfo[field.key]}
                    className="form-control"
                    onChange={(e) =>
                      handleInputChange(field.key, e.target.value)
                    }
                    required={field.required}
                  >
                    <option>Выберите категорию</option>
                    {field.selectOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <input
                    type={field.type}
                    placeholder={field.placeholder}
                    className="form-control"
                    required={field.required}
                    value={purchaseInfo[field.key]}
                    onChange={(e) =>
                      handleInputChange(field.key, e.target.value)
                    }
                  />
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </div>

      <div className="d-flex align-items-center justify-content-center m-2">
        <ImAttachment />
      </div>
      <button
        className="d-flex align-items-center justify-content-between applicationsBtn"
        type="submit"
      >
        <AiOutlineSend />
      </button>
    </div>
  );
};

export default PublicChatInputs;
