import React from "react";
import infoCircle from "../../../../../assets/images/constrImg/info-circle.svg";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";

const AddProductionInstruction = ({ children, descriptionText }) => {
  return (
    <Popover placement="top">
      <PopoverTrigger>
        <button
          type="button"
          className="profile__info__title d-flex align-items-center gap-2"
          style={{ cursor: "pointer", width: "fit-content" }}
        >
          <img src={infoCircle} alt="information about size" />
          <p className="mb-0">{descriptionText}</p>
        </button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Ответ:</PopoverHeader>
        <PopoverBody>
          <p className="mb-0">{children}</p>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AddProductionInstruction;
