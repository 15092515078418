import React from "react";
import { Breadcrumbs } from "../common/breadCrumbs/Breadcrumbs";

const ApplicationsChatHeader = () => {
  return (
    <div className="applications__content__header container ">
      <h1 className="text-center text-md-start">
        Заявки на покупку товаров оптом - B2B
      </h1>
      <Breadcrumbs />

      <p className="p-0 text-center text-lg-start ">
        На этой странице размещаются заявки на закупки товаров оптом. Если вы не
        нашли подходящий товар и требуется приобрести нужные вам товары
        оптом, вам достаточно опубликовать свою заявку на покупку. Поставщики
        автоматически предложат вам свои условия и цены, обеспечивая удобный и
        эффективный способ нахождения оптовых поставщиков и осуществления
        закупок товаров по выгодным условиям.
      </p>
    </div>
  );
};

export default ApplicationsChatHeader;
