import {
  NEW_COLLECTION_LIST_FAIL,
  NEW_COLLECTION_LIST_REQUEST,
  NEW_COLLECTION_LIST_SUCCESS,
} from '../constants/NewCollcetionConstants';

// NEW COLLECTION CATALOG
export const newCollectionCatalogReducer = (state = { catalogs: [] }, action) => {
  switch (action.type) {
    case NEW_COLLECTION_LIST_REQUEST:
      return { loading: true, catalogs: [] };
    case NEW_COLLECTION_LIST_SUCCESS:
      return {
        loading: false,
        catalogs: action.payload,
      };
    case NEW_COLLECTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
