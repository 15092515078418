import React from "react";
import { selectionOfSalesType } from "../../../../constants/catalogCategoriesSortFilterData";
import { Checkbox } from "@chakra-ui/react";

const ProductSalesTypeChoose = ({ isWholesale, setIsWholesale, setIsSample }) => {
  const handleCheckboxChange = (event) => {
    const selectedSaleValue = event.target.value;
    setIsWholesale(selectedSaleValue === "wholesale");
    setIsSample(false)
  };

  return (
    <div className=" d-flex align-items-start align-items-md-center  flex-column flex-md-row gap-4 mb-3">
      {selectionOfSalesType.map((saleOption) => (
        <div className="d-flex align-items-center" key={saleOption.id}>
          <Checkbox
            size="lg"
            colorScheme="blue"
            type="checkbox"
            id={saleOption.id}
            value={saleOption.value}
            isChecked={isWholesale === (saleOption.value === "wholesale")}
            onChange={handleCheckboxChange}
            name={saleOption.value}
          >
            {saleOption.label}
          </Checkbox>
        </div>
      ))}
    </div>
  );
};

export default ProductSalesTypeChoose;
