import React, { createContext, useContext, useState } from "react";

const ProfileProductionCatalogContext = createContext();

const ProfileProductionCatalogProvider = ({ children }) => {
  const [productionTitle, setProductionTitle] = useState("");
  const [productionOrderFrom] = useState("constructor");
  const [productionPrice, setProductionPrice] = useState("");
  const [productionMainImage, setProductionMainImage] = useState("");
  const [productionAdditionalImage, setProductionAdditionalImage] = useState(
    []
  );
  const [productionSizes, setProductionSizes] = useState([]);
  const [productionTextiles, setProductionTextiles] = useState([]);

  const [productionModelImage, setProductionModelImage] = useState("");
  const [productionModelFlippedImage, setProductionModelFlippedImage] =
    useState("");
  const [productionModelColor, setProductionModelColor] = useState("");
  const [productionModelColors, setProductionModelColors] = useState([]);

  const [productionCatalogError, setProductionCatalogError] = useState("");
  const [productionTitleError, setProductionTitleError] = useState("");
  const [productionPriceError, setProductionPriceError] = useState("");
  const [productionMainImageError, setProductionMainImageError] = useState("");
  const [productionSizesError, setProductionSizesError] = useState("");
  const [productionTextilesError, setProductionTextilesError] = useState("");
  const [productionModelImageError, setProductionModelImageError] =
    useState("");
  const [
    productionModelFlippedImageError,
    setProductionModelFlippedImageError,
  ] = useState("");
  const [productionModelColorError, setProductionModelColorError] =
    useState("");
  const [productWeight, setProductWeight] = useState("");

  return (
    <ProfileProductionCatalogContext.Provider
      value={{
        productionTitle,
        setProductionTitle,
        productionOrderFrom,
        productionPrice,
        setProductionPrice,
        productionAdditionalImage,
        setProductionAdditionalImage,
        productionSizes,
        setProductionSizes,
        productionTextiles,
        setProductionTextiles,
        productionModelImage,
        setProductionModelImage,
        productionModelFlippedImage,
        setProductionModelFlippedImage,
        productionMainImage,
        setProductionMainImage,
        productionModelColor,
        setProductionModelColor,
        productionModelColors,
        setProductionModelColors,
        productionCatalogError,
        setProductionCatalogError,
        productionTitleError,
        setProductionTitleError,
        productionPriceError,
        setProductionPriceError,
        productionMainImageError,
        setProductionMainImageError,
        productionSizesError,
        setProductionSizesError,
        productionTextilesError,
        setProductionTextilesError,
        productionModelImageError,
        setProductionModelImageError,
        productionModelFlippedImageError,
        setProductionModelFlippedImageError,
        productionModelColorError,
        setProductionModelColorError,
        productWeight,
        setProductWeight,
      }}
    >
      {children}
    </ProfileProductionCatalogContext.Provider>
  );
};

export const ProfileProductionCatalogState = () => {
  return useContext(ProfileProductionCatalogContext);
};

export default ProfileProductionCatalogProvider;
