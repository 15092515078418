export const aboutUsPageData = [
  {
    id: 1,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691643911/bg%20images/maneken-building_qgex1g.jpg",
    title: '"Наша компания - ваш источник моды и элегантности."',
    description:
      "Наша компания - это ведущий производитель и дизайнер одежды, специализирующийся на создании высококачественных и стильных коллекций. Мы объединяем креативность, инновации и профессионализм, чтобы предложить нашим клиентам уникальные и модные решения. Наша команда состоит из опытных конструкторов, дизайнеров и специалистов в области производства, которые с готовностью воплощают идеи в реальность. Мы ценим индивидуальность каждого клиента и стремимся предоставить им высокий уровень сервиса и удовлетворение. Наша миссия - создавать одежду, которая подчеркивает стиль и самовыражение наших клиентов, делая их уверенными и модными.",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691644055/bg%20images/about2_lhg96h.jpg",
    title: '"Одежда, воплощающая вашу индивидуальность с момента основания"',
    description:
      "Производство было основано в 2010 году. С момента нашего основания мы постоянно развивались и расширяли свои возможности. Мы начинали с небольшого производства, но с прогрессом и увеличением спроса на нашу продукцию, мы постепенно расширяли наши мощности и современные производственные площади. Сегодня мы гордимся тем, что наше производство представляет собой современный и хорошо организованный комплекс, оснащенный передовым оборудованием и технологиями. Мы стремимся к постоянному совершенствованию и инновациям, чтобы предлагать нашим клиентам высококачественную продукцию и удовлетворять их потребности.",
  },
  {
    id: 3,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691644161/bg%20images/about3_mcyejf.jpg",
    title: '"Наша миссия"',
    description:
      "Наша миссия - создавать модные и качественные коллекции, отражающие последние тенденции и удовлетворяющие разнообразные вкусы. Мы гордимся своим профессионализмом, вниманием к деталям и превосходным качеством нашей продукции. Наша компания стремится стать источником вдохновения для наших клиентов, помогая им выражать свою индивидуальность через стиль и моду. Мы ценим долгосрочные отношения с нашими клиентами и стремимся превзойти их ожидания во всем, что мы делаем.",
  },
];

export const ourTeamData = [
  {
    id: 1,
    title: "Наша команда",
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691644229/bg%20images/common_omeor2.jpg",

    description:
      "Наша команда состоит из высококвалифицированных и талантливых специалистов, которые объединены общей страстью к моде и конструированию одежды. У нас есть опытные конструкторы, дизайнеры, модельеры, швеи и технические специалисты, каждый из которых вносит свой вклад в процесс создания превосходных моделей одежды. Мы работаем в креативной и вдохновляющей среде, где мы поощряем идеи, инновации и сотрудничество. Наша команда гордится своей профессиональностью, профессиональным отношением к работе и стремится к достижению высоких стандартов качества и удовлетворения клиентов. Мы всегда готовы принять новые вызовы и сделать все возможное, чтобы превзойти ожидания наших клиентов.",
  },
];
export const teamImages = [
  {
    id: 1,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691644329/bg%20images/adi_xmbuif.jpg",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691644354/bg%20images/azim_zecacc.jpg",
  },
  {
    id: 3,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691644389/bg%20images/jantai_yukc7c.jpg",
  },
  {
    id: 4,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691644426/bg%20images/aza_ghxqjm.jpg",
  },
  {
    id: 5,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691644486/bg%20images/aidin_kzk52w.jpg",
  },
  {
    id: 6,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691644490/bg%20images/aza1_dsjqa1.jpg",
  },
];
