import React, { useEffect, useState } from "react";

const EquipmentAdd = ({
  quantityOfEquipment,
  setQuantityOfEquipment,
  placeholder1,
  placeholder2,
  label1,
  label2,
  fieldType,
  descriptionText,
  setQuantityOfEquipmentError,
  quantityOfEquipmentError,
}) => {
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (quantityOfEquipment && quantityOfEquipment.length > 0 && !isUpdating) {
      setInput1(quantityOfEquipment[0].pryamostrochka || "");
      setInput2(quantityOfEquipment[0].specmashinka || "");
    }
  }, [quantityOfEquipment, isUpdating]);

  useEffect(() => {
    if (input1 && input2 && !isUpdating) {
      setIsUpdating(true);
      setQuantityOfEquipment([
        { title: "Машинок", pryamostrochka: input1, specmashinka: input2 },
      ]);
      setIsUpdating(false);
    }
  }, [input1, input2, setQuantityOfEquipment, isUpdating]);

  return (
    <React.Fragment>
      <p className="add-production-btn ">{descriptionText}</p>
      <div className="mb-4 product__owner__wrapper">
        <div>
          <React.Fragment>
            <label htmlFor={`${fieldType}-input1`} className="mt-4">
              {label1}
            </label>
            <input
              className={`form-control ${
                quantityOfEquipmentError ? "cart-border-error" : ""
              } `}
              type="text"
              id={`${fieldType}-input1`}
              placeholder={placeholder1}
              value={input1}
              onChange={(e) => {
                setInput1(e.target.value);
                if (quantityOfEquipmentError) {
                  setQuantityOfEquipmentError("");
                }
              }}
            />
            {quantityOfEquipmentError && (
              <p className="cart-error-message">{quantityOfEquipmentError}</p>
            )}
          </React.Fragment>

          <label htmlFor={`${fieldType}-input2`} className="mt-4">
            {label2}
          </label>
          <input
            type="text"
            id={`${fieldType}-input2`}
            className={`form-control    ${
              quantityOfEquipmentError ? "cart-border-error" : ""
            }`}
            onChange={(e) => {
              setInput2(e.target.value);
              if (quantityOfEquipmentError) {
                setQuantityOfEquipmentError("");
              }
            }}
            placeholder={placeholder2}
            value={input2}
          />
          {quantityOfEquipmentError && (
            <p className="cart-error-message">{quantityOfEquipmentError}</p>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default EquipmentAdd;
