import React from "react";
import { Link } from "react-router-dom";

const PlaceOrderBtn = ({ loading }) => {
  return (
    <React.Fragment>
      <button
        type="submit"
        disabled={loading ? true : false}
        className={`${loading ? "disabled" : ""} `}
      >
        {loading ? "Идет отправка..." : "Оформить заказ"}
      </button>
      <p
        className="privacy-policy-section text-center text-lg-start"
        style={{ fontSize: "10px" }}
      >
        Нажимая на кнопку "Оформить заказ", я подтверждаю, что ознакомлен и
        согласен с условиями
        <Link to="/privacy-policy">
          <strong> политики конфиденциальности </strong>
        </Link>
        и
        <Link to="/privacy-policy">
          <strong> правилами обработки персональных данных </strong>
        </Link>
      </p>
    </React.Fragment>
  );
};

export default PlaceOrderBtn;
