import React from "react";
import { useSelector } from "react-redux";

import LoadingError from "../../common/errorMessage/LoadingError";
import { Button } from "../../common/button/Button";
import { OrderedListsView } from "./orderedListsView/OrderedListsView";
import ChakraUiSpinner from "../../common/loader/ChakraUiSpinner";

const OrderedList = () => {
  const orderListMy = useSelector((state) => state.orderListMy);
  const { orders, loading, error } = orderListMy;

  return (
    <div className="d-flex justify-content-center align-items-center flex-column position-relative">
      {loading ? (
        <ChakraUiSpinner/>
      ) : error ? (
        <LoadingError variant="alert-danger">{error}</LoadingError>
      ) : (
        <React.Fragment>
          {orders.length === 0 ? (
            <div className="col-12 alert alert-info text-center d-flex align-items-center justify-content-center flex-column gap-5 my-3">
              Нет заказов
              <Button to="/catalogs">Сделать покупку</Button>
            </div>
          ) : (
            <div className="table-responsive w-100">
              {orders.map((el) => (
                <OrderedListsView key={el._id} el={el} {...el} />
              ))}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default OrderedList;
