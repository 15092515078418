import { saveShippingAddress } from "../redux/Actions/CartActions";

export const handleFormikValues = (values, dispatch) => {
  dispatch(
    saveShippingAddress({
      country: values.country,
      city: values.city,
      address: values.address,
      nameLastName: values.nameLastName,
      phoneNumber: values.phoneNumber,
      additionalInfo: values.additionalInfo,
    })
  );
};
