import React from "react";
import { HashLink } from "react-router-hash-link";
import { Ratings } from "../../../../common/ratings/Ratings";
import AddShowSellersItemsBtns from "./addShowSellersItemsBtns/AddShowSellersItemsBtns";
import CatalogItemColors from "./itemColors/CatalogItemColors";
import CatalogItemSizes from "./itemSizes/CatalogItemSizes";
import CatalogOtherInfo from "./catalogOtherInfo/CatalogOtherInfo";
import ItemDescription from "./itemDescription/ItemDescription";
import SaleType from "../../../../common/catalogCardSaleType/SaleType";

export const CatalogItemInfo = ({ item }) => {

  return (
    <div className="single-item-wrapper__types col col-lg-7   mt-5 mt-lg-0">
      <h2 className="text-left mb-3 text-center text-lg-start">{item.title}</h2>
      <div className="d-flex  align-items-center justify-content-center justify-content-lg-start gap-2">
        <Ratings value={item.ratings} text={`${item.numReviews} отзыва`} />
        <HashLink to="#catalogItemReviews">{item.numReviews} отзыва</HashLink>
        <div className="saleType d-flex gap-1">
          <SaleType item={item} />
          {item.hasSample ? <p>Образец</p> : ""}
        </div>
      </div>

      <ItemDescription item={item} />

      <div className="catalog__card_add_price d-flex  align-items-center justify-content-between mb-2">
        <h6>Бренд</h6>
        <p>
          {item.brand ? (
            <strong>{item.brand.toUpperCase()}</strong>
          ) : (
            <strong style={{ color: "#99999947" }}>Не указан</strong>
          )}
        </p>
      </div>
      <CatalogItemColors />

      <CatalogItemSizes item={item} />
      <CatalogOtherInfo item={item} />
      <AddShowSellersItemsBtns item={item} />
    </div>
  );
};
