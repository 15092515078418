import React from "react";
import { Link } from "react-router-dom";
import { navsLinks } from "../../../../constants/headerNavLinksData";
import CurrencyChoose from "../currencyChoose/CurrencyChoose";

export const MobileHeader = ({ toggle, active, setActive, setToggle }) => {
  return (
    <div className={`${!toggle ? "hidden" : "toggleMenu"}`}>
      <ul>
        {navsLinks?.map((link) => (
          <li key={link.id} className="d-flex align-items-start w-100">
            <Link
              to={link.id}
              className={`${active === link.title ? "links-lightBlue" : ""}`}
              style={{ "--dynamic-background": `url(${link.img})` }}
              onClick={() => {
                setActive(link.title);
                setToggle(!toggle);
              }}
            >
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
      <div className="d-flex align-items-center justify-content-center">
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <CurrencyChoose />
        </div>
      </div>
    </div>
  );
};
