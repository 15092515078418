export const departmentCardsData = [
  {
    id: 1,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1709096415/catalog__images/ewcelaljqsuupxttbpr4.jpg",
    title: "Производство",
    link: "/production",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1704798427/fxbucvgfyg0pxisgzvof.jpg",
    title: "Товары",
    link: "/catalogs",
  },
  {
    id: 3,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1706186791/fiop233knjj92g2cyipv.jpg",
    title: "Фулфилмент",
    link: "/fulfillment",
  },
  {
    id: 4,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1691644703/bg%20images/hp1_wlpxbm.png",
    title: "Дизайн отдел",
    link: "/designWorks",
  },
  {
    id: 5,
    img: "https://res.cloudinary.com/drdqjr8r6/image/upload/v1700219127/bg%20images/azazefir_chat_provides_a_convenient_and_effective_way_to_find_w_3cc801c2-9b20-4bc1-902a-951df3699731_g4gkc7.png",
    title: "Заявки",
    link: "/applications",
  },
];
