import { ProfileProductionState } from "../../../../context/ProfileProductionProvider";
import React from "react";
import ProductInput from "../../profileAddProductToCatalog/productInput/ProductInput";
import { CustomPhoneInput } from "../../../common/customPhoneInput/CustomPhoneInput";
import ProductMainImage from "../../profileAddProductToCatalog/productMainImage/ProductMainImage";
import ProductImages from "../../profileAddProductToCatalog/productImages/ProductImages";
import SpecializationAdd from "./specializationAdd/SpecializationAdd";
import CapacityAdd from "./capacityAdd/CapacityAdd";
import EquipmentAdd from "./equipmentAdd/EquipmentAdd";
import ProductionBannersAds from "./productionBannerAds/ProductionBannersAds";

const AddProductionInfo = () => {
  const {
    brandName,
    setBrandName,
    brandLogo,
    setBrandLogo,
    brandImage,
    setBrandImage,
    productionPhoneNumber,
    setProductionPhoneNumber,
    specialization,
    setSpecialization,
    productionCapacity,
    setProductionCapacity,
    quantityOfEquipment,
    setQuantityOfEquipment,
    description,
    setDescription,
    brandNameError,
    setBrandNameError,
    brandLogoError,
    setBrandLogoError,
    brandImageError,
    setBrandImageError,
    productionPhoneNumberError,
    setProductionPhoneNumberError,
    specializationError,
    setSpecializationError,
    productionCapacityError,
    setProductionCapacityError,
    quantityOfEquipmentError,
    setQuantityOfEquipmentError,
    descriptionError,
    setDescriptionError,
    webUrl,
    setWebUrl,
    adsCards,
    setAdsCards,
  } = ProfileProductionState();

  return (
    <section className="profile__production__add__info">
      <div className="profile__production__add__brand-info">
        <p>1. Информация о производстве:</p>
        <ProductInput
          value={brandName}
          placeholder={"Например: ОcOО 'Манекен'"}
          type="text"
          setState={(newValue) => setBrandName(newValue)}
          label="Название производства:"
          productValue="brandName"
          brandNameError={brandNameError}
          setBrandNameError={setBrandNameError}
        />
        <ProductInput
          value={description}
          placeholder={"Описание производства"}
          type="text"
          setState={(newValue) => setDescription(newValue)}
          label="Описание:"
          productValue="productionDescription"
          descriptionError={descriptionError}
          setDescriptionError={setDescriptionError}
        />
        <ProductInput
          value={webUrl}
          placeholder={"Например: www.maneken.shop"}
          type="text"
          setState={(newValue) => setWebUrl(newValue)}
          label="Сайт производства(если есть):"
          productValue="productionWebUrl"
        />
        <label htmlFor="productionOwnerPphone" className="form-label mb-1">
          Контакты производства:
        </label>
        <CustomPhoneInput
          state={productionPhoneNumber}
          setState={setProductionPhoneNumber}
          id="productionOwnerPphone"
          productionPhoneNumberError={productionPhoneNumberError}
          setProductionPhoneNumberError={setProductionPhoneNumberError}
        />
        <ProductMainImage
          image={brandLogo}
          setImage={setBrandLogo}
          text="Логотип производства:"
          brandLogoError={brandLogoError}
          setBrandLogoError={setBrandLogoError}
        />
        <ProductImages
          images={brandImage}
          text="Фото, видео производства (можете загрузить несколько):"
          fieldType="ProductionImages"
          setImages={setBrandImage}
          brandImageError={brandImageError}
          setBrandImageError={setBrandImageError}
        />
        <ProductionBannersAds
          images={adsCards}
          text="Три банера с вашими заголовками и описаниями производства:"
          fieldType="ProductionImagesBanner"
          setImages={setAdsCards}
        />
      </div>

      <div className="profile__production__add__capacity">
        <CapacityAdd
          productionCapacity={productionCapacity}
          setProductionCapacity={setProductionCapacity}
          placeholder={"Например: 1000"}
          label={"Ед в день:"}
          fieldType={"productionCapacity"}
          descriptionText={"2. Производственная мощность цеха:"}
          productionCapacityError={productionCapacityError}
          setProductionCapacityError={setProductionCapacityError}
        />
      </div>

      <div className="profile__production__add__equipment">
        <EquipmentAdd
          quantityOfEquipment={quantityOfEquipment}
          setQuantityOfEquipment={setQuantityOfEquipment}
          placeholder1={"Например: 40"}
          placeholder2={"Например: 15"}
          label1={"Прямострочек:"}
          label2={"Спецмашинок:"}
          fieldType={"quantityOfEquipment"}
          descriptionText={"3. Количество оборудования:"}
          setQuantityOfEquipmentError={setQuantityOfEquipmentError}
          quantityOfEquipmentError={quantityOfEquipmentError}
        />
      </div>
      <div className="profile__production__add__specialization">
        <SpecializationAdd
          state={specialization}
          setState={setSpecialization}
          placeholder={"Например: Футболки"}
          placeholder2={"Например: 1000"}
          label={"Предмет одежды:"}
          label2={"Цена от:"}
          fieldType={"specialization"}
          descriptionText={"4. Специализация производства:"}
          specializationError={specializationError}
          setSpecializationError={setSpecializationError}
        />
      </div>
    </section>
  );
};

export default AddProductionInfo;
