import { useEffect, useMemo } from "react";
import { cdekCalculateTariffAction } from "../redux/Actions/OrderActions";

const useCdekTariffCalculator = (dispatch, formik, totalDimensions) => {
  const tariffCodeChoose = useMemo(() => {
    if (
      formik.values.selectedService === "cdek - до склада" &&
      formik.values.selectedPickupPointInfo
    ) {
      return totalDimensions?.weight < 30
        ? process.env.REACT_APP_CDEK_TARIFF_SKLAD_UP_TO_30
        : process.env.REACT_APP_CDEK_TARIFF_SKLAD_AFTER_30;
    } else if (
      formik.values.selectedService === "cdek - курьерская доставка" &&
      formik.values.selectedSettlement
    ) {
      return totalDimensions?.weight < 30
        ? process.env.REACT_APP_CDEK_TARIFF_COURIER_UP_TO_30
        : process.env.REACT_APP_CDEK_TARIFF_COURIER_AFTER_30;
    }
    return null;
  }, [
    formik.values.selectedService,
    formik.values.selectedPickupPointInfo,
    formik.values.selectedSettlement,
    totalDimensions,
  ]);

  const toLocation = useMemo(() => {
    if (
      formik.values.selectedService === "cdek - до склада" &&
      formik.values.selectedPickupPointInfo
    ) {
      return {
        postal_code:
          formik.values.selectedPickupPointInfo?.location.postal_code,
        city: formik.values.selectedPickupPointInfo?.location.city,
        address: formik.values.selectedPickupPointInfo?.location.address,
      };
    }
    if (
      formik.values.selectedService === "cdek - курьерская доставка" &&
      formik.values.selectedSettlement
    ) {
      return {
        code: formik.values.selectedSettlement?.code,
        address: formik.values.selectedSettlement.address,
      };
    }
    return null;
  }, [
    formik.values.selectedService,
    formik.values.selectedPickupPointInfo,
    formik.values.selectedSettlement,
  ]);

  useEffect(() => {
    const calculateTariff = (tariffCode, toLocation) => {
      if (tariffCode && toLocation) {
        dispatch(
          cdekCalculateTariffAction({
            currency: 9,
            tariff_code: tariffCode,
            from_location: {
              country_code: "KG",
              postal_code: "724311",
              city: "Бишкек",
              address: "с. Восток, ул. Ленина, 58а",
            },
            to_location: toLocation,
            packages: [
              {
                weight: totalDimensions?.weight * 1000,
                length: 0,
                height: 0,
                width: 0,
              },
            ],
          })
        );
      }
    };

    calculateTariff(tariffCodeChoose, toLocation);
  }, [dispatch, tariffCodeChoose, toLocation, totalDimensions?.weight]);

  return { tariffCodeChoose, toLocation };
};

export default useCdekTariffCalculator;
