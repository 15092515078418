import React, { useEffect, useState } from "react";
import { CustomPhoneInput } from "../../../common/customPhoneInput/CustomPhoneInput";

const ProductOwner = ({ owner, setOwner, ownerName, text }) => {
  const [ownerInput, setOwnerInput] = useState("");
  const [ownerPhoneNumber, setOwnerPhoneNumber] = useState("");

  useEffect(() => {
    if (owner?.length > 0) {
      setOwnerInput(owner[0]?.name || "");
      setOwnerPhoneNumber(owner[0]?.phoneNumber || "");
    }
  }, [owner]);

  useEffect(() => {
    if (ownerInput !== "") {
      const newOwner = { name: ownerInput, phoneNumber: ownerPhoneNumber };
      setOwner([newOwner]);
    }
  }, [ownerInput, ownerPhoneNumber, setOwner]);

  return (
    <React.Fragment>
      <p style={{ color: "initial", fontWeight: "initial" }}>{ownerName}:</p>
      <div className="mb-4 product__owner__wrapper">
        <div>
          <input
            type="text"
            id="product_owner_name"
            placeholder={text}
            className="form-control mb-4"
            value={ownerInput}
            onChange={(e) => setOwnerInput(e.target.value)}
          />
          <div className="mb-4">
            <CustomPhoneInput
              state={ownerPhoneNumber}
              setState={setOwnerPhoneNumber}
              id="production_owner_name"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductOwner;
