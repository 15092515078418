import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { addToCart } from "../redux/Actions/CartActions";
import {
  selectActiveColors,
  selectActiveSize,
  selectColor,
  selectSizes,
  setActiveColors,
  setActiveSize,
  setQty,
} from "../redux/slices/SingleCatalogItemSlice";
import { useLocation } from "react-router-dom";

const useAddToCart = (id, item, setOnePackOfGoods) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();

  const color = useSelector(selectColor);
  const size = useSelector(selectSizes);
  const activeColors = useSelector(selectActiveColors);
  const activeSize = useSelector(selectActiveSize);

  const pathnameSplit = location.pathname.split("/")[1];

  const qtyOfGoodsInPack =
    item.isWholesale === false
      ? 1
      : item.qtyOfGoodsInPack
      ? item.qtyOfGoodsInPack
      : 4;

  const getSelectedColor = () => {
    if (color.length === 0) {
      return null;
    }
    return [{ color: color[activeColors].color || color[activeColors].img }];
  };

  const getSelectedActiveSize = (qty) => {
    if (size.length === 0) {
      return null;
    } else if (
      item.category === "Белье" ||
      (!item.isWholesale &&
        item.isWholesale !== undefined &&
        qty !== item.countInStock)
    ) {
      return [{ size: size[activeSize].size }];
    } else {
      return size;
    }
  };

  const addToCartHandler = (qty, sampleSize) => {
    const selectedColor = getSelectedColor();
    const selectedActiveSize = getSelectedActiveSize(qty);

    dispatch(
      addToCart(
        id,
        qty,
        selectedColor,
        item.additionaly[0],
        pathnameSplit,
        sampleSize,
        selectedActiveSize,
        qtyOfGoodsInPack,
        item.isWholesale,
        item.hasSample
      )
    );

    toast({
      title: "Товар добавлен в корзину!",
      status: "success",
      isClosable: true,
      position: "top-right",
      duration: 500,
    });

    dispatch(setQty(4));
    dispatch(setActiveColors(0));
    dispatch(setActiveSize(0));
    if (!sampleSize) {
      setOnePackOfGoods(1);
    }
  };

  return addToCartHandler;
};

export default useAddToCart;
