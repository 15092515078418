const ProfileDetailsBtn = ({ userInfo, orders }) => {
  return (
    <div className="profile__details_btn d-flex flex-column align-items-center gap-3">
      <div
        className="nav align-items-start flex-column col-12 nav-pills "
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <button
          className="nav-link active mb-3 gap-2 d-flex justify-content-between align-items-center"
          id="v-pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-home"
          type="button"
          role="tab"
          aria-controls="v-pills-home "
          aria-selected="true"
        >
          Заказанный товар{" "}
          <span className="badge2">{orders ? orders.length : 0}</span>
        </button>
        <button
          className="nav-link mb-3"
          id="v-pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-profile"
          type="button"
          role="tab"
          aria-controls="v-pills-profile "
          aria-selected="false"
        >
          Настройки профиля
        </button>
        <button
          className="nav-link mb-3"
          id="v-pills-currency-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-currency"
          type="button"
          role="tab"
          aria-controls="v-pills-currency "
          aria-selected="false"
        >
          Курсы валют
        </button>
        {userInfo?.role === "seller" ? (
          <button
            className="nav-link mb-3"
            id="v-pills-role-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-role"
            type="button"
            role="tab"
            aria-controls="v-pills-role "
            aria-selected="false"
          >
            Товары
          </button>
        ) : null}
        {userInfo?.role === "seller" ? (
          <button
            className="nav-link mb-3"
            id="v-pills-my-production-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-my-production"
            type="button"
            role="tab"
            aria-controls="v-pills-my-production "
            aria-selected="false"
          >
            Производство
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default ProfileDetailsBtn;
