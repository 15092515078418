import React from "react";

import { Tooltip } from "@chakra-ui/react";
import Slider from "react-slick";

import { fabricInfoColorsSettings } from "../../../../../constants/reactSlickSettingsData";

const FabricInfoColors = ({
  fabricData,
  activeColors,
  setActiveColors,
  setActiveColorsImg,
}) => {
  const hasImages =
    fabricData?.fabricColors.filter((color) => color.img).length > 0;

  return (
    <div className="d-flex flex-column align-items-center ">
      <h4 className="text-start w-100 ">Цвета</h4>
      {hasImages ? (
        <Slider {...fabricInfoColorsSettings}>
          {fabricData?.fabricColors.map((el, index) => (
            <figure
              key={index}
              className={`fabric-images mb-0`}
              onClick={() => {
                setActiveColors(index);
                setActiveColorsImg(el.img);
              }}
            >
              <Tooltip
                hasArrow
                label={
                  <div>
                    <img
                      src={el.img}
                      style={{
                        width: "150px",
                        height: "200px",
                        objectFit: "cover",
                        padding: "5px 0",
                      }}
                      alt="placeholder"
                    />
                    <p style={{ color: "#000" }} className="text-center m-0">
                      {el.colorName}
                    </p>
                  </div>
                }
                placement="bottom"
                bg="white"
              >
                <img
                  className={`fabricImg ${
                    activeColors === index ? "active" : ""
                  }`}
                  src={el.img}
                  alt="placeholder"
                />
              </Tooltip>
            </figure>
          ))}
        </Slider>
      ) : null}
    </div>
  );
};

export default FabricInfoColors;
