import { ContuctCompanyData } from '../../../constants/requisitesData';
import './ContuctCompany.scss';

export const ContuctCompany = () => {
  return (
    <div className="container">
      <h4>Контакты компании</h4>
      <div className="d-flex flex-column flex-md-row gap-4">
        {ContuctCompanyData.map((el) => (
          <div key={el.id}>
            <p className="d-flex gap-3 align-items-center mb-3 justify-content-center justify-content-md-start">
              <img src={el.img} alt={el.title} /> {el.title}
            </p>
            <p className="work-time">{el.link}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
