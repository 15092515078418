import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { IoMdClose } from "react-icons/io";
import "./CookieConsent.scss";

const CookieConsent = () => {
  const [cookies, setCookies] = useCookies(["cookieConsent"]);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const hasConsent = cookies.cookieConsent === true;

    if (!hasConsent) {
      setIsVisible(true);
    }
  }, [cookies]);

  const giveCookieConsent = () => {
    setCookies("cookieConsent", true, { path: "/" });
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  return isVisible ? (
    <section className="cookies__consent__container d-flex align-items-center justify-content-center flex-column">
      <IoMdClose
        className="cookies__consent__container__close"
        onClick={handleClose}
      />

      <p className="cookies__consent__container__text my-2">
        Мы используем файлы cookie. Они помогают улучшить ваше взаимодействие с
        сайтом. 
      </p>
      <button
        className="cookies__consent__container__btn"
        onClick={giveCookieConsent}
      >
        Принять
      </button>
    </section>
  ) : null;
};

export default CookieConsent;
