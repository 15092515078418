import { Checkbox } from "@chakra-ui/react";
import React from "react";

const ProductSampleBuy = ({
  isSample,
  setIsSample,
  setSamplePrice,
  isWholesale,
}) => {
  const handleSampleChange = () => {
    setIsSample(!isSample);
    setSamplePrice(0);
  };
  return (
    <div
      className={`d-flex align-items-center mb-3 ${
        !isWholesale ? "d-none" : ""
      }`}
    >
      <Checkbox
        size="lg"
        colorScheme="blue"
        type="checkbox"
        id="sample"
        isChecked={isSample}
        onChange={handleSampleChange}
        name="sample"
        style={{ fontSize: "1rem" }}
      >
        Разрешить продажу в качестве образца
      </Checkbox>
    </div>
  );
};

export default ProductSampleBuy;
