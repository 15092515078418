import { AboutSection } from "../components/aboutUsPage/aboutSection/AboutSection";
import { ComandSection } from "../components/aboutUsPage/comandSection/ComandSection";
import { ContuctUs } from "../components/common/callUs/ContuctUs";
import Carousel from "../components/aboutUsPage/carousel/Carousel";

import "./styles/AboutUsPage.scss";
import { Breadcrumbs } from "./../components/common/breadCrumbs/Breadcrumbs";
import { Seo } from "../seo/Seo";

const AboutUsPage = () => {
  return (
    <div className="about-us-page">
      <Seo
        title="О нас"
        description="Наша миссия - создавать одежду, которая подчеркивает стиль и самовыражение наших клиентов, делая их уверенными и модными."
        name="Манекен"
        type="website"
        keywords="Maneken, Манекен, Одежда оптом, Производство одежды, Ткани, Фулфилмент, Fulfilment, Конструктор одежды, О компании манекен"
      />
      <div className="container">
        <h1>О нас</h1>
        <Breadcrumbs />
        <Carousel />
        <AboutSection />
        <ComandSection />
      </div>
      <ContuctUs />
    </div>
  );
};

export default AboutUsPage;
