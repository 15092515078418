import React from "react";
import { CurrencyConverter } from "../../../../../common/currencyConverter/CurrencyConverter";

import CatalogCardsAddToCardsSection from "./catalogCardsAddToCardSection/CatalogCardsAddToCardsSection";
import { useSelector } from "react-redux";
import { selectCurrency } from "../../../../../../redux/slices/CatalogSlice";

const CatalogOtherInfo = ({ item }) => {
  const selectedCurrency = useSelector(selectCurrency);
  return (
    <div className="catalog__card_add">
      <div className="catalog__card_add_price d-flex  align-items-center justify-content-between mb-4">
        <h6>Артикул</h6>
        <p>
          <strong>{item.articul}</strong>
        </p>
      </div>
      <div className="catalog__card_add_price d-flex  align-items-center justify-content-between mb-4">
        <h6>Ткань</h6>
        <p>
          <strong>{item.textile}</strong>
        </p>
      </div>
      <div className="catalog__card_add_price d-flex  align-items-center justify-content-between mb-4">
        <h6>
          {!item.isWholesale && item.isWholesale === undefined
            ? "Цена за одну единицу"
            : "Цена"}{" "}
        </h6>
        <p>
          {selectedCurrency === "KGS" ? (
            <strong>{item.price} KGS</strong>
          ) : (
            <CurrencyConverter total={item.price} />
          )}
          <strong className="oldPrice">
            {item.oldPrice !== "0" ? " / " + item.oldPrice + " сом" : ""}
          </strong>
        </p>
      </div>
      <div className="catalog__card_add_price d-flex  align-items-center justify-content-between mb-4">
        <h6>Страна производства</h6>
        <p>
          <strong>{item.madeIn}</strong>
        </p>
      </div>

      <CatalogCardsAddToCardsSection item={item} />
    </div>
  );
};

export default CatalogOtherInfo;
