import React from "react";
import ColorsByImage from "./colorsByImage/ColorsByImage";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveColors,
  selectColor,
  setActiveColors,
  setActiveColorsImg,
} from "../../../../../../redux/slices/SingleCatalogItemSlice";

const CatalogItemColors = () => {
  const dispatch = useDispatch();
  const colors = useSelector(selectColor);
  const activeColors = useSelector(selectActiveColors);
  return (
    <React.Fragment>
      {colors.length > 0 && (
        <div className="catalog__card__colors d-flex mb-2 align-items-center">
          <h6>Цвета</h6>
          <p className="d-flex gap-2 flex-wrap justify-content-end w-100">
            {colors.map((color, index) =>
              color.color ? (
                <span
                  key={index}
                  onClick={() => {
                    dispatch(setActiveColors(index));
                    dispatch(setActiveColorsImg(color.img));
                  }}
                  className={`catalog__card__colors__item ${
                    activeColors === index ? "active" : ""
                  }`}
                  style={{ backgroundColor: color.color || color }}
                ></span>
              ) : null
            )}
          </p>

          <ColorsByImage />
        </div>
      )}
    </React.Fragment>
  );
};

export default CatalogItemColors;
