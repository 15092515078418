import React from "react";

import { Seo } from "../seo/Seo";

import FabricDescription from "../components/fabricPage/fabricDescription/FabricDescription";

import "./styles/FabricPage.scss";
import FabricMain from "../components/fabricPage/fabricMain/FabricMain";

const FabricPage = () => {
  return (
    <section className="fabric-container">
      <Seo
        title="Ткани"
        description="Мир тканей! Откройте для себя нашу обширную коллекцию тканей, идеально подходящих для любого проекта."
        name="Манекен"
        type="Product"
        keywords="Ткани, Заказ ткани, Фулфилмент, Fulfilment, Maneken, Манекен, Одежда оптом, Производство одежды"
      />
      <FabricDescription />
      <FabricMain/>
    </section>
  );
};

export default FabricPage;
