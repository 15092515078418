import { AddIcon } from "@chakra-ui/icons";
import React from "react";
import ProductMainImage from "../../../../../profileAddProductToCatalog/productMainImage/ProductMainImage";
import ProductColors from "../../../../../profileAddProductToCatalog/productColors/ProductColors";
import CustomSaveBtn from "../../../../../../common/customSaveBtn/CustomSaveBtn";
import CloseBtn from "../../../../../../../assets/images/favorites/x-circle.svg";

const AddedImageColors = (props) => {
  const {
    productionEditModelImage,
    setProductionEditModelImage,
    productionEditModelFlippedImage,
    setProductionEditModelFlippedImage,
    productionEditModelColor,
    setProductionEditModelColor,
    productionEditModelColors,
    handleSaveProductionCatalogItem,
    isVisibleModelsAdd,
    handleOpenPopup,
    setProductionEditModelColors
  } = props;
  const handleDeleteColor = (index) => {
    const updatedColors = productionEditModelColors.filter((_, i) => i !== index);
    setProductionEditModelColors(updatedColors);
  };

  return (
    <React.Fragment>
      <section className="profile__production__add__catalog__models__grid">
        {productionEditModelColors.map((item, index) => (
          <figure key={index}>
            <img src={item.img} alt="Цвет" title={item.color} />
            <span
              className="profile__production__add__catalog__models__grid__colors"
              style={{ background: `${item.color}` }}
            ></span>
            <button type="button" className="productionCatalogCloseBtn">
            <img
              src={CloseBtn}
              alt="Удалить"
              onClick={() => handleDeleteColor(index)} 
            />
          </button>
          </figure>
        ))}
        <div
          className="addProductionModels h-100 w-100 "
          onClick={handleOpenPopup}
        >
          <div className="d-flex align-items-center justify-content-center">
            <AddIcon boxSize="2rem" color="#1475cf" />
          </div>
        </div>
      </section>
      {isVisibleModelsAdd && (
        <React.Fragment>
          <ProductMainImage
            text="Картинка товара: (вид спереди)"
            image={productionEditModelImage}
            setImage={setProductionEditModelImage}
          />
          <ProductMainImage
            text="Картинка товара: (вид сзади)"
            image={productionEditModelFlippedImage}
            setImage={setProductionEditModelFlippedImage}
            fieldType="ProductionCatalogImage"
            setProductionColor={setProductionEditModelColor}
          />
          <ProductColors
            productionColor={productionEditModelColor}
            setProductionColor={setProductionEditModelColor}
          />

          <CustomSaveBtn
            handleAdd={handleSaveProductionCatalogItem}
            data={productionEditModelColors}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default AddedImageColors;
