export const CATALOG_LIST_REQUEST = 'CATALOG_LIST_REQUEST';
export const CATALOG_LIST_SUCCESS = 'CATALOG_LIST_SUCCESS';
export const CATALOG_LIST_FAIL = 'CATALOG_LIST_FAIL';

export const CATALOG_DETAILS_REQUEST = 'CATALOG_DETAILS_REQUEST';
export const CATALOG_DETAILS_SUCCESS = 'CATALOG_DETAILS_SUCCESS';
export const CATALOG_DETAILS_FAIL = 'CATALOG_DETAILS_FAIL';

export const CATALOG_CREATE_REVIEW_REQUEST = 'CATALOG_CREATE_REVIEW_REQUEST';
export const CATALOG_CREATE_REVIEW_SUCCESS = 'CATALOG_CREATE_REVIEW_SUCCESS';
export const CATALOG_CREATE_REVIEW_FAIL = 'CATALOG_CREATE_REVIEW_FAIL';
export const CATALOG_CREATE_REVIEW_RESET = 'CATALOG_CREATE_REVIEW_RESET';

export const CATALOG_REVIEW_REQUEST = 'CATALOG_REVIEW_REQUEST';
export const CATALOG_REVIEW_SUCCESS = 'CATALOG_REVIEW_SUCCESS';
export const CATALOG_REVIEW_FAIL = 'CATALOG_REVIEW_FAIL';

export const CATALOG_CREATE_REQUEST = 'CATALOG_CREATE_REQUEST';
export const CATALOG_CREATE_SUCCESS = 'CATALOG_CREATE_SUCCESS';
export const CATALOG_CREATE_FAIL = 'CATALOG_CREATE_FAIL';
export const CATALOG_CREATE_RESET = 'CATALOG_CREATE_RESET';

export const PROFILE_LIST_REQUEST = 'PROFILE_LIST_REQUEST';
export const PROFILE_LIST_SUCCESS = 'PROFILE_LIST_SUCCESS';
export const PROFILE_LIST_FAIL = 'PROFILE_LIST_FAIL';

export const PROFILE_CATALOG_DELETE_REQUEST = 'PROFILE_CATALOG_DELETE_REQUEST';
export const PROFILE_CATALOG_DELETE_SUCCESS = 'PROFILE_CATALOG_DELETE_SUCCESS';
export const PROFILE_CATALOG_DELETE_FAIL = 'PROFILE_CATALOG_DELETE_FAIL';

export const PROFILE_CATALOG_EDIT_REQUEST = 'PROFILE_CATALOG_EDIT_REQUEST';
export const PROFILE_CATALOG_EDIT_SUCCESS = 'PROFILE_CATALOG_EDIT_SUCCESS';
export const PROFILE_CATALOG_EDIT_FAIL = 'PROFILE_CATALOG_EDIT_FAIL';

export const PROFILE_CATALOG_UPDATE_REQUEST = 'PROFILE_CATALOG_UPDATE_REQUEST';
export const PROFILE_CATALOG_UPDATE_SUCCESS = 'PROFILE_CATALOG_UPDATE_SUCCESS';
export const PROFILE_CATALOG_UPDATE_FAIL = 'PROFILE_CATALOG_UPDATE_FAIL';
export const PROFILE_CATALOG_UPDATE_RESET = 'PROFILE_CATALOG_UPDATE_RESET';