import { ContucForm } from "../components/contuctPage/contuctForm/ContucForm";
import { ContuctCompany } from "../components/contuctPage/contuctCompany/ContuctCompany";
import { ContuctComand } from "../components/contuctPage/contuctComand/ContuctComand";
import { ContactMap } from "../components/contuctPage/contactMap/ContactMap";
import { Requisites } from "../components/contuctPage/requisites/Requisites";

import "./styles/ContuctPage.scss";
import { Breadcrumbs } from "./../components/common/breadCrumbs/Breadcrumbs";
import { Seo } from "../seo/Seo";

const ContuctPage = () => {
  return (
    <section className="contuct-page">
      <Seo
        title="Контакты"
        name="Манекен"
        type="Product"
        keywords="Контакты, Связаться, Maneken, Манекен, Одежда оптом, Одежда, Производство, Производство одежды, Ткани, Фулфилмент, Fulfilment, Конструктор одежды, Конструктор,  Женская одежда, Мужская одежда, Детская одежда"
      />
      <div className="container contuct-page__header__text">
        <h2>Контакты</h2>
        <Breadcrumbs />
      </div>
      <section className="contuct-page__form">
        <ContucForm />
      </section>
      <section className="contuct-page__company">
        <ContuctCompany />
      </section>
      <section className="contuct-page__comand">
        <ContuctComand />
      </section>
      <Requisites />
      <ContactMap />
    </section>
  );
};

export default ContuctPage;
