// PRODUCTION ADS CARDS GET ACTION
import {
  FABRICS_GET_FAIL,
  FABRICS_GET_REQUEST,
  FABRICS_GET_SUCCESS,
} from "./../constants/FabricsConsts";
import { $api } from "../../api/axios";

// FABRIC GET ACTION
export const fabricsAction = (params) => async (dispatch) => {
  try {
    dispatch({
      type: FABRICS_GET_REQUEST,
    });
    const { data } = await $api.get(`/fabrics`, {
      params,
    });
    dispatch({ type: FABRICS_GET_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FABRICS_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
