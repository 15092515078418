import { useToast } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myOrdersListAction } from "../../../redux/Actions/OrderActions";
import {
  getUserDetailsAction,
  updateProfileAction,
} from "../../../redux/Actions/UserAction";
import { CustomRaidioCheckbox } from "../../common/customRadioCheckbox/CustomRaidioCheckbox";

export const ProfileForm = () => {
  const toast = useToast();
  const toastId = useRef(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const submitHandler = (e) => {
    e.preventDefault();
    //passwordMatch
    if (password !== confirmPassword) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast({
          title: "Пороль не совпадает!",
          status: "error",
          isClosable: true,
          position: "top-right",
          duration: 1000,
        });
      }
    } else {
      // update profile
      dispatch(updateProfileAction({ id: user._id, name, email, password }));

      if (!toast.isActive(toastId.current)) {
        toastId.current = toast({
          title: "Профиль обновлён!",
          status: "success",
          isClosable: true,
          position: "top-right",
          duration: 1000,
        });
      }
    }
  };

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
    }
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(myOrdersListAction());
    dispatch(getUserDetailsAction("profile"));
  }, [dispatch]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="profile_form col">
      <form onSubmit={submitHandler}>
        <div className="profile__form_edit d-flex align-items-start gap-4 justify-content-end mb-5 flex-column flex-md-row">
          <div className="d-flex flex-column gap-4 w-100">
            <div>
              <label htmlFor="userName">Имя пользователя</label>
              <input
                type="text"
                value={name || ""}
                id="userName"
                onChange={(e) => setName(e.target.value)}
                placeholder="Ваше имя"
                required
                autoComplete="on"
              />
            </div>

            <div>
              <label htmlFor="emailAddress">Email адрес</label>
              <input
                type="email"
                value={email || ""}
                id="emailAddress"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Ваш email"
                required
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-4 w-100">
            <div>
              <label htmlFor="newPassword">Новый пароль</label>
              <input
                type={showPassword ? "text" : "password"}
                value={password || ""}
                id="newPassword"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Ваш пароль"
                required
                autoComplete="on"
              />
            </div>
            <div>
              <label htmlFor="confPassword">Подтвердите пароль</label>
              <input
                type={showPassword ? "text" : "password"}
                value={confirmPassword || ""}
                id="confPassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Подтвердите пароль"
                required
                autoComplete="on"
              />
            </div>
            <div className="show-password d-flex align-items-center justify-content-start">
              <CustomRaidioCheckbox
                type="checkbox"
                id="showPassword"
                name="showPassword"
                checked={showPassword}
                onChange={toggleShowPassword}
              />
              <label htmlFor="showPassword" className="w-100">
                Показать пароль
              </label>
            </div>
          </div>
        </div>
        <button type="submit">Сохранить изменения</button>
      </form>
    </div>
  );
};
