export const isSameSenderMargin = (messages, m, i, userId) => {
  if (
    i < messages?.length - 1 &&
    messages[i + 1]?.sender?._id === m?.sender?._id &&
    messages[i]?.sender?._id !== userId
  )
    return 36;
  else if (
    (i < messages?.length - 1 &&
      messages[i + 1]?.sender?._id !== m?.sender?._id &&
      messages[i]?.sender?._id !== userId) ||
    (i === messages?.length - 1 && messages[i]?.sender?._id !== userId)
  )
    return 0;
  else return "auto";
};

export const isSameSender = (messages, m, i, userId) => {
  return (
    i < messages?.length - 1 &&
    (messages[i + 1]?.sender?._id !== m?.sender?._id ||
      messages[i + 1]?.sender?._id === undefined) &&
    messages[i]?.sender?._id !== userId
  );
};

export const isLastMessage = (messages, i, userId) => {
  return (
    i === messages?.length - 1 &&
    messages[messages?.length - 1]?.sender?._id !== userId &&
    messages[messages?.length - 1]?.sender?._id
  );
};

export const isSameUser = (messages, m, i) => {
  return i > 0 && messages[i - 1]?.sender?._id === m?.sender?._id;
};

export const getSender = (loggedUser, users) => {
  return users[0]?._id === loggedUser?.id ? users[1]?.name : users[0]?.name;
};

export const getChatUserName = (currentChatUser, user) => {
  return currentChatUser?.users[1]?._id === user?.id
    ? currentChatUser?.users[0]?.name
    : currentChatUser?.users[1]?.name;
};

export const getChatUserProfile = (currentChatUser, user) => {
  return currentChatUser?.users[1]?._id === user?.id
    ? currentChatUser?.users[0]?.profile
    : currentChatUser?.users[1]?.profile;
};