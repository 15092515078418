import React from "react";
import "./EmptyCart.scss";
import { Button } from "../button/Button";
import ErrorBoundary from "./../errorMessage/ErrorBoundary";
import LoadingError from "./../errorMessage/LoadingError";

export const EmptyCart = () => {
  return (
    <ErrorBoundary
      fallback={<LoadingError variant="alert-danger">Error</LoadingError>}
    >
      <div className="empty-cart w-50 d-flex flex-column justify-content-center align-items-center position-relative mb-3">
        <h2 className="my-3">Ваша корзина пуста!</h2>
        <div className="d-flex flex-column justify-content-center align-items-center gap-2">
          <figure>
            <img
              style={{ width: "300px" }}
              src='https://res.cloudinary.com/drdqjr8r6/image/upload/v1700130656/bg%20images/azazefir_black_3d_empty_cart_illustration_no_bg_9dd56ee0-8e83-45d5-9a53-418dae21d946_1_yry2wc.png'
              alt="Пустая корзина"
            />
          </figure>
          <Button to="/catalogs">Сделать покупку</Button>
        </div>
      </div>
    </ErrorBoundary>
  );
};
