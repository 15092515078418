import {
  PRODUCTION_LIST_REQUEST,
  PRODUCTION_LIST_SUCCESS,
  PRODUCTION_LIST_FAIL,
  PRODUCTION_CREATE_REQUEST,
  PRODUCTION_CREATE_SUCCESS,
  PRODUCTION_CREATE_FAIL,
  PRODUCTION_UPDATE_FAIL,
  PRODUCTION_EDIT_SUCCESS,
  PRODUCTION_UPDATE_SUCCESS,
  PRODUCTION_UPDATE_REQUEST,
  PRODUCTION_EDIT_FAIL,
  PRODUCTION_EDIT_REQUEST,
  PROFILE_PRODUCTION_CATALOG_DELETE_REQUEST,
  PROFILE_PRODUCTION_CATALOG_DELETE_SUCCESS,
  PROFILE_PRODUCTION_CATALOG_DELETE_FAIL,
  PRODUCTION_ADS_CARDS_GET_REQUEST,
  PRODUCTION_ADS_CARDS_GET_SUCCESS,
  PRODUCTION_ADS_CARDS_GET_FAIL,
  PRODUCTION_DETAILS_REQUEST,
  PRODUCTION_DETAILS_SUCCESS,
  PRODUCTION_DETAILS_FAIL,
} from "./../constants/Production";
import { clearStorage } from "./UserAction";
import { CONSTRUCTOR_CART_ADD_ITEM } from "../constants/ConstructorCart";
import { $api, $apiPrivate } from "../../api/axios";

// ADD TO CART FROM PRODUCTION PAGE
export const productionAddToCart =
  (id, qty, selectedColor, selectedImage, textiles, sizes) =>
  async (dispatch) => {
    const { data } = await $api.get(`/production/${id}`);
    dispatch({
      type: CONSTRUCTOR_CART_ADD_ITEM,
      payload: {
        item: data._id,
        title: data.title,
        image: selectedImage,
        orderFrom: data.orderFrom,
        price: data.price,
        sizes: sizes,
        color: selectedColor,
        qty: qty,
        textiles: textiles,
        height: data.height,
        url: data.url,
        length: data.length,
        weight: data.weight,
        width: data.width,
      },
    });
  };

// PRODUCTION CATALOG ITEMS ACTION
export const productionCatalogAction = () => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCTION_LIST_REQUEST,
    });
    const { data } = await $api.get(`/production`);
    dispatch({ type: PRODUCTION_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCTION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// CREATE PRODUCTION ITEM
export const createProductionItemAction =
  ({
    brandName,
    brandStories,
    brandLogo,
    brandImage,
    productionPhoneNumber,
    specialization,
    productionCapacity,
    quantityOfEquipment,
    productionCatalog,
    email,
    description,
    webUrl,
    adsCards,
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PRODUCTION_CREATE_REQUEST,
      });

      const { data } = await $apiPrivate.post(`/production/`, {
        brandName,
        brandStories,
        brandLogo,
        brandImage,
        productionPhoneNumber,
        specialization,
        productionCapacity,
        quantityOfEquipment,
        productionCatalog,
        email,
        description,
        webUrl,
        adsCards,
      });

      dispatch({ type: PRODUCTION_CREATE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (error.response.status === 401) {
        dispatch(clearStorage());
      }
      dispatch({
        type: PRODUCTION_CREATE_FAIL,
        payload: message,
      });
    }
  };

// EDIT PROFILE CATALOG ITEM
export const editProductionAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCTION_EDIT_REQUEST,
    });
    const { data } = await $api.get(`/production/profile/${id}`);

    dispatch({ type: PRODUCTION_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: PRODUCTION_EDIT_FAIL,
      payload: message,
    });
  }
};

// UPDATE PROFILE CATALOG ITEM
export const updateProductionAction = (product) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCTION_UPDATE_REQUEST,
    });

    const { data } = await $apiPrivate.put(
      `/production/${product._id}`,
      product
    );

    dispatch({ type: PRODUCTION_UPDATE_SUCCESS, payload: data });
    dispatch({ type: PRODUCTION_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (error.response.status === 401) {
      dispatch(clearStorage());
    }
    dispatch({
      type: PRODUCTION_UPDATE_FAIL,
      payload: message,
    });
  }
};

// DELETE PROFILE PRODUCTION CATALOG ITEM
export const deleteProfileProductionCatalogItemAction =
  (filteredProductsId, id) => async (dispatch) => {
    try {
      dispatch({
        type: PROFILE_PRODUCTION_CATALOG_DELETE_REQUEST,
      });

      await $apiPrivate.delete(`/production/${filteredProductsId}/${id}`);

      dispatch({ type: PROFILE_PRODUCTION_CATALOG_DELETE_SUCCESS });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (error.response.status === 401) {
        dispatch(clearStorage());
      }
      dispatch({
        type: PROFILE_PRODUCTION_CATALOG_DELETE_FAIL,
        payload: message,
      });
    }
  };

// PRODUCTION ADS CARDS GET ACTION
export const productionAdsCardsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCTION_ADS_CARDS_GET_REQUEST,
    });
    const { data } = await $api.get(`/production/production/ads-cards`);
    dispatch({ type: PRODUCTION_ADS_CARDS_GET_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCTION_ADS_CARDS_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// SINGLE PRODUCTION ITEM ACTION
export const productionItemAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCTION_DETAILS_REQUEST,
    });
    const { data } = await $api.get(`/production/item/${id}`);
    dispatch({ type: PRODUCTION_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCTION_DETAILS_FAIL,
      payload: error.response?.data?.message ?? error.message,
    });
  }
};
