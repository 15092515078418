import React from "react";
import { DeleteProfileImageSvg } from "../../../../../../assets/svg/Svg";

const ProductionAddedInputsView = (props) => {
  const { state, setState, handleRemoveOwner } = props;
  return (
    <React.Fragment>
      {state
        ? state?.map((obj, index) => (
            <div
              key={index}
              className={`${"mb-2 gap-2 d-flex flex-column"}`}
              style={{
                border: "1px solid #ccc",
                padding: "6px",
                marginTop: "10px",
                position: "relative",
              }}
            >
              <div className="d-flex">
                <DeleteProfileImageSvg
                  handleRemoveImage={handleRemoveOwner}
                  url={obj}
                />
                {obj?.title === "Мощность" ||
                obj?.title === "Машинок" ? null : (
                  <input
                    className="form-control"
                    type="text"
                    value={obj.title}
                    onChange={(e) => {
                      setState((prev) =>
                        prev.map((c, i) =>
                          i === index ? { ...c, title: e.target.value } : c
                        )
                      );
                    }}
                  />
                )}
              </div>

              <input
                className="form-control mb-2"
                type="text"
                onChange={(e) => {
                  const updatedState = state.map((c, i) =>
                    i === index ? { ...c, fromPrice: e.target.value } : c
                  );
                  setState(updatedState);
                }}
                value={obj.fromPrice}
              />
            </div>
          ))
        : null}
    </React.Fragment>
  );
};

export default ProductionAddedInputsView;
