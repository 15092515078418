import { RequisitesData } from '../../../constants/requisitesData';

export const Requisites = () => {
  return (
    <section className=" requisites">
      <div className="container d-flex flex-column align-items-center">
        <h4>Реквизиты </h4>
        {RequisitesData.map((el) => (
          <p key={el}>{el}</p>
        ))}
      </div>
    </section>
  );
};
